import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DashboardNavbar from '../navbar/DashboardNavbar';
import NavTab from '../navtab/NavTab';
import Footer from '../Footer/Footer';
import '../style.css';
import { useLocation, useNavigate } from 'react-router-dom';
import ProgressBar from 'react-bootstrap/ProgressBar';
import image1 from './loading.gif'
import { listVideo } from '../api/Service';
import { useModal } from '../../context/ModalContext';
// import { truncateSync } from 'fs';
import { useDarkMode } from '../../context/DarkModeContext';
import { CheckAiVideo } from '../api/Service';

const Preview = () => {
    const themefile = localStorage.getItem("theme");
    const plan = localStorage.getItem('plan');
    const credit = localStorage.getItem('credit');
    const {isModalOpen} = useModal()
    const { darkMode } = useDarkMode();
    const server_App_URl = process.env.REACT_APP_API_URL
    const location = useLocation();
    const { path, scriptPath, videoUrl, videoid, type, userid } = location.state;
    //const [interval1, setInterval1] = useState('');
    const [images, setImages] = useState(null);
    const [fileContent, setFileContent] = useState([]);
    //const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [scriptCreated, setScriptCreated] = useState(false);
    const [videoCreated, setvideoCreated] = useState(false);
    const [completed, setCompleted] = useState(0);
    const [loading, setLoading] = useState(true)
    const [showModal, setShowModal] = React.useState(false);
    const [outputPath, setOutputPath] = useState(null)
    const [generatedImages, setGeneratedImages] = useState([
               ])
    const [s3, setS3] = useState(null)
    const navigate = useNavigate()
    const [script, setScript] = useState([])






    useEffect(() => {
        console.log('start interval');
        fetchData();
    }, []);


    useEffect(() => {
        const savedProgress = localStorage.getItem('progress');
        if (savedProgress) {
            setCompleted(parseFloat(savedProgress));
        }

        const duration = 510;
        const increment = 100 / duration;
        const interval = setInterval(() => {
            setCompleted(prev => {
                if (prev + increment >= 100) {
                    clearInterval(interval);
                    localStorage.removeItem('progress');
                    return 100;
                }
                const newProgress = prev + increment;
                localStorage.setItem('progress', newProgress);
                return newProgress;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    function sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    const fetchData = async () => {
        try {
            const imageResponse = await listVideo({
                videoId: videoid
            });
            console.log(imageResponse)
                await checkVideoCreation();
                if(imageResponse.response.status=== 4 ){
                    setvideoCreated(true)
                }
                if (plan ==="Free") {
                    setImages(8)
                }else{

                    setImages(imageResponse.response.imagesLength);                
                }
                setOutputPath(imageResponse.response.outputPath)
                // console.log("newwww", imageResponse);
                if (imageResponse.response.status >=1 ) {
                    console.log('helooo enteretd' , plan)
                    let scriptResponse ;
                    if (plan === "Free") {
                        scriptResponse = await axios.get(`https://autotube.in/backend/media/default/AiImage/generated_scripts.txt`);
                    }
                    else{
                        scriptResponse = await axios.get(`${server_App_URl}/media${scriptPath}`);
                    }

                    console.log(scriptResponse, 'scripttt')
                    const cleanedContent = scriptResponse.data
                        .split('\n')
                        .map(line => line.replace(/^Script: /, '').trim())
                        .filter(line => line.length > 0);
                    setFileContent(cleanedContent);
                    console.log(cleanedContent,'------------------------')
                }
                if(imageResponse.response.status>= 1){
                    setScriptCreated(true)
                }else{
                    setScriptCreated(false)
                }

                async function checkVideoCreation() {
                    if (imageResponse.response.status !== 4) {
                        await sleep(25000);
                        fetchData();
                    } else {
                        localStorage.removeItem('progress');
                        return false;
                    }
                }
            


                console.log(fileContent , "script===============")

        } catch (error) {
            console.error('Error fetching data', error);
            setError(error.message);
        }
        // } finally {
        //     setLoading(false);
        // }
    }

// console.log(images)
    useEffect(() => {

        if (images !== null && images > 0) {
            let imagesArray = [];
            if (plan === "Free") {
                setGeneratedImages([
                    `https://autotube.in/backend/media/default/AiImage/images/generated_image_1.jpg`,
                    `https://autotube.in/backend/media/default/AiImage/images/generated_image_2.jpg`,
                    `https://autotube.in/backend/media/default/AiImage/images/generated_image_3.jpg`,
                    `https://autotube.in/backend/media/default/AiImage/images/generated_image_4.jpg`,
                    `https://autotube.in/backend/media/default/AiImage/images/generated_image_5.jpg`,
                    `https://autotube.in/backend/media/default/AiImage/images/generated_image_6.jpg`,
                    `https://autotube.in/backend/media/default/AiImage/images/generated_image_7.jpg`,
                    `https://autotube.in/backend/media/default/AiImage/images/generated_image_8.jpg`,
             ]);
            }else{
                for (let i = 1; i <= images; i++) {
                    imagesArray.push(`generated_image_${i}.png`);
                }
                setGeneratedImages(imagesArray);
            }
        }
    }, [images])


    const handleDownload = async () => {
        try {

            const response = await axios.get(videoLink, {
                responseType: 'blob',
            });

            const blobUrl = window.URL.createObjectURL(new Blob([response.data]));

            const newWindow = window.open('');

            const link = newWindow.document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', videoUrl.split('/').pop());
            newWindow.document.body.appendChild(link);

            link.click();

            newWindow.document.body.removeChild(link);
            newWindow.close();
        } catch (error) {
            console.error('Error downloading video', error);
        }
    };
    let imageUrl;
    if (plan === "Free") {
        imageUrl =`https://autotube.in/backend/media/default/AiImage/images/generated_image_1.jpg`
    }
    else{
        if (s3 === 1) {
            imageUrl = `https://shortsmedia.s3.us-east-2.amazonaws.com/AiImage/${userid}/${videoid}/images`
        } else {
            imageUrl = `${server_App_URl}/media/${userid}/${videoid}/images`
        }
    }
    let videoLink;
    if (plan === "Free") {
        videoLink=`https://autotube.in/backend/media/default/AiImage/output.mp4`
    }else{
        if(s3===1 ){
            videoLink=`https://shortsmedia.s3.us-east-2.amazonaws.com/AiVideo/${userid}/${videoid}/${outputPath}.mp4`
        }else{
            videoLink=`${server_App_URl}/media/${userid}/${videoid}/${outputPath}.mp4`
        }
    }

    return (
        <>
            <div className={`w-[100%] max-w-screen `}>
                <DashboardNavbar />
                    <div className={`w-full p-[2.5rem] lg:p-[44px] overflow-x-hidden font-montserrat min-h-screen space-y-6 flex justify-center pt-12 pb-20 flex-col`}>
                        <div className=' min-h-screen'>
                            {videoCreated === false ? (
                                <>
                                    <div className="App">
                                        {completed < 100 && (
                                            <ProgressBar
                                                now={completed}
                                                label={`${Math.round(completed)}%`}
                                            />
                                        )}
                                    </div>
                                </>
                            ) : null}

                            <div className='video-center  min-h-screen flex justify-evenly gap-3 items-start w-full'>
                                <div className={`margen-set md:p-12 p-[12px] md:w-[80%] lg:p-6 mx-auto  rounded-md shadow-md space-y-4`}>
                                    <div className='h-max w-full mb-5'>
                                        <h1 className={`font-bold text-[16px]   pt-1 pb-2`}>Scripts</h1>
                                        <div className='p-4 w-[100%] font-montserrat shadow-md border border-gray-100'>
                                            {scriptCreated === false ? (
                                                <>
                                                    <div className="spinner-border  " role="status">
                                                        <span className="visually-hidden  ">Loading...</span>
                                                    </div>
                                                    <p className=' '>Loading Scripts....</p>
                                                </>
                                            ) : (
                                                fileContent.map((line, index) => (
                                                    <p key={index} className={`mb-2   cursor-context-menu`}>{line}</p>
                                                ))
                                            )}
                                        </div>
                                    </div>

                                    <div className="imgset">
                                        <h1 className={`font-bold text-[16px]   pt-1 pb-2`}>Images</h1>
                                        <div className="flex p-2 overflow-x-auto space-x-4">

                                            {!images ? (
                                                <>
                                                    <div className="spinner-border  " role="status">
                                                        <span className="visually-hidden  ">Loading...</span>
                                                    </div>
                                                    <p className=' '>Loading Images....</p>
                                                </>
                                            ) : (
                                                generatedImages.map((image, index) => (
                                                    
                                                    <img
                                                        key={index}
                                                        
                                                        src={plan !== 'Free' ? `${imageUrl}/${image}` : `${image}`}
                                                        alt={image}
                                                        style={{ height: '200px', margin: '10px', borderRadius: "15px", display: 'block' }}
                                                        onError={(e) => {
                                                            e.target.style.display = 'none'; // Hides the image if it fails to load
                                                        }}
                                                    />
                                                ))
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {type === 'landscape_16_9' ?
                                    (<div className='video-h p-2 h-[350px]  '>
                                        <h1 className={` `}>Output Example</h1>
                                        <div className='down-btn h-[100%]'>
                                            <>
                                                {videoCreated === true ? (<> <video className={!videoCreated ? 'd-none' : 'h-[280px] w-[800px] rounded-md'} autoPlay controls loop muted
                                                    preload={'auto'}
                                                    onCanPlayThrough={() => {
                                                        console.log('video loaded')
                                                        setLoading(false)
                                                    }}>
                                                    {/* {outputPath && videoCreated === true ? (<source src={`https://shortsmedia.s3.us-east-2.amazonaws.com/AiImage/${userid}/${videoid}/${outputPath}.mp4`} />) : (<source src={`${server_App_URl}/media/AiCaption${videoUrl}`} />)} */}
                                                    <source src={videoLink}/>
                                                </video>
                                                    <div className='flex justify-center'>
                                                        <button
                                                            onClick={handleDownload}
                                                            className='mt-4 inline-block px-4 py-2     rounded hover:bg-gray-800'
                                                        >
                                                            Download Video
                                                        </button></div></>) : (<>

                                                            <p className=' '>Loading Video....</p>
                                                            <img src={image1} alt="" />
                                                        </>)}
                                            </>

                                        </div>
                                    </div>) :
                                    (<div className='video-h p-2 h-[350px] '>
                                        <h1 className={` `}>Output Example</h1>
                                        <div className='down-btn h-[100%]'>

                                            <>
                                                {videoCreated === true ? (<> <video className={!videoCreated ? 'd-none' : 'h-[400px] rounded-md'} autoPlay controls loop muted
                                                    preload={'auto'}
                                                    onCanPlayThrough={() => {
                                                        console.log('video loaded')
                                                        setLoading(false)
                                                    }}>
                                                    {/* {outputPath && videoCreated === true ? (<source src={`https://shortsmedia.s3.us-east-2.amazonaws.com/AiImage/${userid}/${videoid}/${outputPath}.mp4`} />) : (<source src={`${server_App_URl}/media/AiCaption${videoUrl}`} />)} */}
                                                    <source src={videoLink}/>
                                                </video>
                                                    <div className='flex justify-center'>
                                                        <button
                                                            onClick={handleDownload}
                                                            className='mt-4 inline-block px-4 py-2  rounded hover:bg-green-300 bg-[#00C805]'
                                                        >
                                                            Download Video
                                                        </button></div></>) : (<>
                                                            {/* <div className="spinner-border text-[#467ff7]" role="status">
                                        <span className="visually-hidden text-[#467ff7]">Loading...</span>
                                    </div> */}
                                                            <p className=' '>Loading Video....</p>
                                                            <img src={image1} alt="" />
                                                        </>)}
                                            </>

                                        </div>
                                    </div>)}

                            </div>
                            {showModal ? (
                                <>
                                    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-hidden">
                                        <div className="relative w-full max-w-3xl bg-white rounded-lg shadow-lg">
                                            {/* Modal Content */}
                                            <div className="p-6">
                                                <h1 className="text-lg font-bold">Video Creation is failed Please Try Again</h1>
                                            </div>

                                            {/* Modal Footer */}
                                            <div className="flex items-center justify-end p-4">
                                                <button
                                                    className="px-4 py-2 text-sm font-bold text-black transition-all duration-150 ease-linear bg-transparent rounded focus:outline-none"
                                                    type="button"
                                                    onClick={() => {
                                                        navigate('/view/aishorts');
                                                        setShowModal(false)
                                                    }}
                                                >
                                                    Okay
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                </>
                            ) : null}
                        </div>
                    </div>
                </div>

                <Footer />
            </>
    );
};

export default Preview;