import React, { useRef, useEffect, useState } from "react";
import DashboardNavbar from "../navbar/DashboardNavbar";
import NavTab from "./NavTab";
import Footer from "../Footer/Footer";
import "../style.css?v1";
import { MdPlayArrow, MdPause, MdInfoOutline, MdLock, MdHelpOutline, MdCropSquare } from "react-icons/md";
import { DropVoices } from "../../voices/voice";
import { GenerateTwitter, Plans } from "../api/Service";
import { useNavigate } from "react-router-dom";
import { useModal } from "../../context/ModalContext";
import { useDarkMode } from "../../context/DarkModeContext";
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';

const TwitterCreate = () => {
    const navigate = useNavigate()
    const { darkMode } = useDarkMode();
    const { isModalOpen } = useModal()
    const server_App_URl = process.env.REACT_APP_API_URL;
    const [tweetUrl, setTweetUrl] = useState("");
    const [activeId, setActiveId] = useState(null);
    const [playingIndex, setPlayingIndex] = useState(null);
    const [playbackTimes, setPlaybackTimes] = useState({});
    const [pictureType, setPictureType] = useState("raytraced");
    const audioRefs = useRef([]);
    const [selectedButton, setSelectedButton] = useState('stockVideos');
    const [errors, setErrors] = useState({
        destination: "",
        content: "",
        duration: "",
    });
    const [selectedTemplateId, setSelectedTemplateId] = useState(
        "e7e758de-4eb4-460f-aeca-b2801ac7f8cc"
    );
    const [preview, setPreview] = useState("raytraced.mp4");
    const [loading, setLoading] = useState(true)
    const [loading1, setLoading1] = useState(true);
    const [plan, setPlan] = useState("");
    const [modalMessage, setModalMessage] = useState('')
    const [checkbox, setCheckbox] = useState(false)
    const [socialmedia, setSocialmedia] = useState('')
    const [postInDay, setPostInDay] = useState('')
    const [value, onChange] = useState('');
    const [isToggled, setIsToggled] = useState(false);
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState('')

    const handleToggle = () => {
        setIsToggled((prev) => !prev);
        // Add any additional actions you want here
    }

    const togglePlayback = (index) => {
        if (playingIndex === index) {
            audioRefs.current[index].pause();
            setPlayingIndex(null);
        } else {
            if (playingIndex !== null) {
                audioRefs.current[playingIndex].pause();
            }
            setPlayingIndex(index);
            audioRefs.current[index].play();
        }
    };

    const handleAudioEnded = (index) => {
        setPlayingIndex(null);
    };
    const handlePostInDay = (e) => {
        console.log(e)
        setPostInDay(e)
    }

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60)
            .toString()
            .padStart(2, "0");
        return `${minutes}:${seconds}`;
    };

    const handleTimeUpdate = (index) => {
        const audioElement = audioRefs.current[index];
        if (audioElement) {
            setPlaybackTimes((prevTimes) => ({
                ...prevTimes,
                [index]: audioElement.currentTime,
            }));
        }
    };

    const handleInputChange = (e) => {
        setTweetUrl(e.target.value);
    };

    const handleButtonClick = (buttonText) => {
        setSelectedButton(buttonText); // Update state with selected button's text
    };
    const Templates = [
        {
            id: "e7e758de-4eb4-460f-aeca-b2801ac7f8cc",
            categories: ["animated", "highlighted"],
            pre: "1.mp4",
            previewUrl:
                "https://cdn.zapcap.ai/templates/e7e758de-4eb4-460f-aeca-b2801ac7f8cc.mp4",
            previews: {
                previewMp4:
                    "https://cdn.zapcap.ai/templates/e7e758de-4eb4-460f-aeca-b2801ac7f8cc.mp4",
                previewGif:
                    "https://cdn.zapcap.ai/template-gifs/e7e758de-4eb4-460f-aeca-b2801ac7f8cc.gif",
            },
        },
        {
            id: "21327a45-df89-46bc-8d56-34b8d29d3a0e",
            categories: ["basic"],
            pre: "2.mp4",
            previewUrl:
                "https://cdn.zapcap.ai/templates/21327a45-df89-46bc-8d56-34b8d29d3a0e.mp4",
            previews: {
                previewMp4:
                    "https://cdn.zapcap.ai/templates/21327a45-df89-46bc-8d56-34b8d29d3a0e.mp4",
                previewGif:
                    "https://cdn.zapcap.ai/template-gifs/21327a45-df89-46bc-8d56-34b8d29d3a0e.gif",
            },
        },
        {
            id: "a6760d82-72c1-4190-bfdb-7d9c908732f1",
            categories: ["animated"],
            pre: "3.mp4",
            previewUrl:
                "https://cdn.zapcap.ai/templates/a6760d82-72c1-4190-bfdb-7d9c908732f1.mp4",
            previews: {
                previewMp4:
                    "https://cdn.zapcap.ai/templates/a6760d82-72c1-4190-bfdb-7d9c908732f1.mp4",
                previewGif:
                    "https://cdn.zapcap.ai/template-gifs/a6760d82-72c1-4190-bfdb-7d9c908732f1.gif",
            },
        },
        {
            id: "982ad276-a76f-4d80-a4e2-b8fae0038464",
            categories: ["basic", "highlighted"],
            pre: "4.mp4",
            previewUrl:
                "https://cdn.zapcap.ai/templates/982ad276-a76f-4d80-a4e2-b8fae0038464.mp4",
            previews: {
                previewMp4:
                    "https://cdn.zapcap.ai/templates/982ad276-a76f-4d80-a4e2-b8fae0038464.mp4",
                previewGif:
                    "https://cdn.zapcap.ai/template-gifs/982ad276-a76f-4d80-a4e2-b8fae0038464.gif",
            },
        },
        {
            id: "07ffd4b8-4e1a-4ee3-8921-d58802953bcd",
            categories: ["basic", "highlighted"],
            pre: "5.mp4",
            previewUrl:
                "https://cdn.zapcap.ai/templates/07ffd4b8-4e1a-4ee3-8921-d58802953bcd.mp4",
            previews: {
                previewMp4:
                    "https://cdn.zapcap.ai/templates/07ffd4b8-4e1a-4ee3-8921-d58802953bcd.mp4",
                previewGif:
                    "https://cdn.zapcap.ai/template-gifs/07ffd4b8-4e1a-4ee3-8921-d58802953bcd.gif",
            },
        },
        {
            id: "7b946549-ae16-4085-9dd3-c20c82504daa",
            categories: ["basic", "highlighted"],
            pre: "6.mp4",
            previewUrl:
                "https://cdn.zapcap.ai/templates/7b946549-ae16-4085-9dd3-c20c82504daa.mp4",
            previews: {
                previewMp4:
                    "https://cdn.zapcap.ai/templates/7b946549-ae16-4085-9dd3-c20c82504daa.mp4",
                previewGif:
                    "https://cdn.zapcap.ai/template-gifs/7b946549-ae16-4085-9dd3-c20c82504daa.gif",
            },
        },
        {
            id: "14bcd077-3f98-465b-b788-1b628951c340",
            categories: ["basic", "highlighted"],
            pre: "7.mp4",
            previewUrl:
                "https://cdn.zapcap.ai/templates/14bcd077-3f98-465b-b788-1b628951c340.mp4",
            previews: {
                previewMp4:
                    "https://cdn.zapcap.ai/templates/14bcd077-3f98-465b-b788-1b628951c340.mp4",
                previewGif:
                    "https://cdn.zapcap.ai/template-gifs/14bcd077-3f98-465b-b788-1b628951c340.gif",
            },
        },
        {
            id: "d46bb0da-cce0-4507-909d-fa8904fb8ed7",
            categories: ["animated"],
            pre: "8.mp4",
            previewUrl:
                "https://cdn.zapcap.ai/templates/d46bb0da-cce0-4507-909d-fa8904fb8ed7.mp4",
            previews: {
                previewMp4:
                    "https://cdn.zapcap.ai/templates/d46bb0da-cce0-4507-909d-fa8904fb8ed7.mp4",
                previewGif:
                    "https://cdn.zapcap.ai/template-gifs/d46bb0da-cce0-4507-909d-fa8904fb8ed7.gif",
            },
        },
        {
            id: "decf5309-2094-4257-a646-cabe1f1ba89a",
            categories: ["animated"],
            pre: "9.mp4",
            previewUrl:
                "https://cdn.zapcap.ai/templates/decf5309-2094-4257-a646-cabe1f1ba89a.mp4",
            previews: {
                previewMp4:
                    "https://cdn.zapcap.ai/templates/decf5309-2094-4257-a646-cabe1f1ba89a.mp4",
                previewGif:
                    "https://cdn.zapcap.ai/template-gifs/decf5309-2094-4257-a646-cabe1f1ba89a.gif",
            },
        },
        {
            id: "dfe027d9-bd9d-4e55-a94f-d57ed368a060",
            categories: ["animated", "highlighted"],
            pre: "10.mp4",
            previewUrl:
                "https://cdn.zapcap.ai/templates/dfe027d9-bd9d-4e55-a94f-d57ed368a060.mp4",
            previews: {
                previewMp4:
                    "https://cdn.zapcap.ai/templates/dfe027d9-bd9d-4e55-a94f-d57ed368a060.mp4",
                previewGif:
                    "https://cdn.zapcap.ai/template-gifs/dfe027d9-bd9d-4e55-a94f-d57ed368a060.gif",
            },
        },
        {
            id: "1bb3b68b-6a93-453a-afd7-a774b62cdab8",
            categories: [],
            pre: "11.mp4",
            previewUrl:
                "https://cdn.zapcap.ai/templates/1bb3b68b-6a93-453a-afd7-a774b62cdab8.mp4",
            previews: {
                previewMp4:
                    "https://cdn.zapcap.ai/templates/1bb3b68b-6a93-453a-afd7-a774b62cdab8.mp4",
                previewGif:
                    "https://cdn.zapcap.ai/template-gifs/1bb3b68b-6a93-453a-afd7-a774b62cdab8.gif",
            },
        },
        {
            id: "46d20d67-255c-4c6a-b971-31fddcfea7f0",
            categories: ["animated", "highlighted"],
            pre: "12.mp4",
            previewUrl:
                "https://cdn.zapcap.ai/templates/46d20d67-255c-4c6a-b971-31fddcfea7f0.mp4",
            previews: {
                previewMp4:
                    "https://cdn.zapcap.ai/templates/46d20d67-255c-4c6a-b971-31fddcfea7f0.mp4",
                previewGif:
                    "https://cdn.zapcap.ai/template-gifs/46d20d67-255c-4c6a-b971-31fddcfea7f0.gif",
            },
        },
        {
            id: "c88bff11-7f03-4066-94cd-88f71f9ecc68",
            categories: ["basic"],
            pre: "13.mp4",
            previewUrl:
                "https://cdn.zapcap.ai/templates/c88bff11-7f03-4066-94cd-88f71f9ecc68.mp4",
            previews: {
                previewMp4:
                    "https://cdn.zapcap.ai/templates/c88bff11-7f03-4066-94cd-88f71f9ecc68.mp4",
                previewGif:
                    "https://cdn.zapcap.ai/template-gifs/c88bff11-7f03-4066-94cd-88f71f9ecc68.gif",
            },
        },
        {
            id: "a104df87-5b1a-4490-8cca-62e504a84615",
            categories: ["highlighted"],
            pre: "14.mp4",
            previewUrl:
                "https://cdn.zapcap.ai/templates/a104df87-5b1a-4490-8cca-62e504a84615.mp4",
            previews: {
                previewMp4:
                    "https://cdn.zapcap.ai/templates/a104df87-5b1a-4490-8cca-62e504a84615.mp4",
                previewGif:
                    "https://cdn.zapcap.ai/template-gifs/a104df87-5b1a-4490-8cca-62e504a84615.gif",
            },
        },
        {
            id: "ca050348-e2d0-49a7-9c75-7a5e8335c67d",
            categories: ["animated"],
            pre: "15.mp4",
            previewUrl:
                "https://cdn.zapcap.ai/templates/ca050348-e2d0-49a7-9c75-7a5e8335c67d.mp4",
            previews: {
                previewMp4:
                    "https://cdn.zapcap.ai/templates/ca050348-e2d0-49a7-9c75-7a5e8335c67d.mp4",
                previewGif:
                    "https://cdn.zapcap.ai/template-gifs/ca050348-e2d0-49a7-9c75-7a5e8335c67d.gif",
            },
        },
        {
            id: "eb5de878-2997-41fe-858a-726e9e3712df",
            categories: ["basic"],
            pre: "16.mp4",
            previewUrl:
                "https://cdn.zapcap.ai/templates/eb5de878-2997-41fe-858a-726e9e3712df.mp4",
            previews: {
                previewMp4:
                    "https://cdn.zapcap.ai/templates/eb5de878-2997-41fe-858a-726e9e3712df.mp4",
                previewGif:
                    "https://cdn.zapcap.ai/template-gifs/eb5de878-2997-41fe-858a-726e9e3712df.gif",
            },
        },
        {
            id: "a51c5222-47a7-4c37-b052-7b9853d66bf6",
            categories: ["animated", "highlighted"],
            pre: "17.mp4",
            previewUrl:
                "https://cdn.zapcap.ai/templates/a51c5222-47a7-4c37-b052-7b9853d66bf6.mp4",
            previews: {
                previewMp4:
                    "https://cdn.zapcap.ai/templates/a51c5222-47a7-4c37-b052-7b9853d66bf6.mp4",
                previewGif:
                    "https://cdn.zapcap.ai/template-gifs/a51c5222-47a7-4c37-b052-7b9853d66bf6.gif",
            },
        },
        {
            id: "5de632e7-0b02-4d15-8137-e004871e861b",
            categories: ["animated"],
            pre: "18.mp4",
            previewUrl:
                "https://cdn.zapcap.ai/templates/5de632e7-0b02-4d15-8137-e004871e861b.mp4",
            previews: {
                previewMp4:
                    "https://cdn.zapcap.ai/templates/5de632e7-0b02-4d15-8137-e004871e861b.mp4",
                previewGif:
                    "https://cdn.zapcap.ai/template-gifs/5de632e7-0b02-4d15-8137-e004871e861b.gif",
            },
        },
        {
            id: "55267be2-9eec-4d06-aff8-edcb401b112e",
            categories: ["highlighted"],
            pre: "19.mp4",
            previewUrl:
                "https://cdn.zapcap.ai/templates/55267be2-9eec-4d06-aff8-edcb401b112e.mp4",
            previews: {
                previewMp4:
                    "https://cdn.zapcap.ai/templates/55267be2-9eec-4d06-aff8-edcb401b112e.mp4",
                previewGif:
                    "https://cdn.zapcap.ai/template-gifs/55267be2-9eec-4d06-aff8-edcb401b112e.gif",
            },
        },
        {
            id: "50cdfac1-0a7a-48dd-af14-4d24971e213a",
            categories: ["highlighted"],
            pre: "20.mp4",
            previewUrl:
                "https://cdn.zapcap.ai/templates/50cdfac1-0a7a-48dd-af14-4d24971e213a.mp4",
            previews: {
                previewMp4:
                    "https://cdn.zapcap.ai/templates/50cdfac1-0a7a-48dd-af14-4d24971e213a.mp4",
                previewGif:
                    "https://cdn.zapcap.ai/template-gifs/50cdfac1-0a7a-48dd-af14-4d24971e213a.gif",
            },
        },
        {
            id: "cfa6a20f-cacc-4fb6-b1d0-464a81fed6cf",
            categories: ["highlighted"],
            pre: "21.mp4",
            previewUrl:
                "https://cdn.zapcap.ai/templates/cfa6a20f-cacc-4fb6-b1d0-464a81fed6cf.mp4",
            previews: {
                previewMp4:
                    "https://cdn.zapcap.ai/templates/cfa6a20f-cacc-4fb6-b1d0-464a81fed6cf.mp4",
                previewGif:
                    "https://cdn.zapcap.ai/template-gifs/cfa6a20f-cacc-4fb6-b1d0-464a81fed6cf.gif",
            },
        },
    ];
    const handleTemplateClick = (id) => {
        setSelectedTemplateId(id);
        console.log("Selected Template ID:", id);
    };
    const [selectedAspectRatio, setSelectedAspectRatio] = useState('portrait');
    const [aiAspectRatio, setAiAspectRatio] = useState('portrait_16_9')
    const images = [
        {
            name: "raytraced",
            imageLink:
                `${server_App_URl}/media/images/RAYTRACED.avif`,
            pre: "raytraced.mp4",
        },
        {
            name: "Default",
            imageLink:
                `${server_App_URl}/media/images/DEFAULT.avif`,
            pre: "default.mp4",
        },
        {
            name: "anime",
            imageLink:
                `${server_App_URl}/media/images/ANIME.avif`,
            pre: "anime.mp4",
        },
        {
            name: "illustration",
            imageLink:
                `${server_App_URl}/media/images/ILLUSTRATION.avif`,
            pre: "illustration.mp4",
        },
        {
            name: "sketch color",
            imageLink:
                `${server_App_URl}/media/images/SKETCH_COLOR.avif`,
            pre: "sketch_color.mp4",
        },
        {
            name: "sketch black and white",
            imageLink:
                `${server_App_URl}/media/images/SKETCH_BLACK_AND_WHITE.avif`,
            pre: "sketch_black_and_white.mp4",
        },
        {
            name: "pixar",
            imageLink:
                `${server_App_URl}/media/images/PIXAR.avif`,
            pre: "pixar.mp4",
        },
        {
            name: "japanese ink",
            imageLink:
                `${server_App_URl}/media/images/INK.avif`,
            pre: "japanese_ink.mp4",
        },
        {
            name: "3D render",
            imageLink:
                `${server_App_URl}/media/images/RENDER_3D.avif`,
            pre: "3D_render.mp4",
        },
        {
            name: "lego",
            imageLink:
                `${server_App_URl}/media/images/LEGO.avif`,
            pre: "lego.mp4",
        },
        {
            name: "Sci-fi",
            imageLink:
                `${server_App_URl}/media/images/SCIFI.avif`,
            pre: "sci_fi.mp4",
        },
        {
            name: "retro cartoon",
            imageLink:
                `${server_App_URl}/media/images/RETRO_CARTOON.avif`,
            pre: "retro_cartoon.mp4",
        },
        {
            name: "pixel art",
            imageLink:
                `${server_App_URl}/media/images/PIXEL_ART.avif`,
            pre: "pixel_art.mp4",
        },
        {
            name: "creative",
            imageLink:
                `${server_App_URl}/media/images/CREATIVE.avif`,
            pre: "creative.mp4",
        },
        {
            name: "photography",
            imageLink:
                `${server_App_URl}/media/images/PHOTOGRAPHY.avif`,
            pre: "photography.mp4",
        },

        {
            name: "environment",
            imageLink:
                `${server_App_URl}/media/images/ENVIRONMENT.avif`,
            pre: "environment.mp4",
        },
        {
            name: "realist",
            imageLink:
                `${server_App_URl}/media/images/REALISM.avif`,
            pre: "realist.mp4",
        },
    ];
    const [series, setSeries] = useState('')
    const planResponse = async () => {
        try {
            const response = await Plans();
            setSeries(response.PlanTypeName)
            setPlan(response.credit);
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        if (loading1) {
            planResponse();
        }
    }, [loading1]);
    const [showModal, setShowModal] = React.useState(false);

    const handleCreate = async (e) => {
        e.preventDefault();
        if (!plan || series === 'Free') {
            navigate("/app/billing");
            return;
        } else {
            let data;
            if (selectedButton === 'Select AI Images') {
                data = {
                    x_userlink: tweetUrl,
                    parameterType: selectedButton,
                    imageType: pictureType,
                    voice_id: activeId,
                    templateId: selectedTemplateId,
                    orientation: aiAspectRatio
                }
            } else {
                data = {
                    x_userlink: tweetUrl,
                    parameterType: "stockVideos",
                    imageType: null,
                    voice_id: activeId,
                    templateId: selectedTemplateId,
                    orientation: selectedAspectRatio
                }
            }

            try {

                const response = await GenerateTwitter(data)
                localStorage.removeItem('progress')
                console.log(response, 'twitter response')
                if (response) {
                    const videoId = response.data.data.response.insertId;
                    const userId = response.data.data.UserId;
                    navigate("/app/twitter-preview", {
                        state: {
                            videoUrl: `/${userId}/${videoId}/output.mp4`,
                            videoid: videoId,
                            userid: userId
                        },
                    });
                }

            } catch (error) {
                console.error("Error:", error);
                if (
                    (error.response && error.response.data) ||
                    error.response.data.message === "Un-authorized"
                ) {
                    if (error.response.data.message === "Un-authorized") {
                        localStorage.clear();
                        navigate("/app");
                        window.location.reload();

                        setModalMessage(
                            ` ${error.response.data.message || "Something went wrong"}`
                        );
                        setShowModal(true)
                    }
                    else {

                        setModalMessage(
                            ` ${error.response.data.message || "Something went wrong"}`
                        );
                        setShowModal(true)

                    }
                } else {
                    setModalMessage("An unexpected error occurred");
                    setShowModal(true)
                }
            }

        }
    }

    return (
        <div className={darkMode ? "bg-black text-white" : "bg-white text-black"}>
            <DashboardNavbar />
            <div className="flex md:h-max h-max">
                {!isModalOpen ? (
                    <div className="absolute md:relative md:w-[23%] w-[21%] bg text-white">
                        <NavTab />
                    </div>
                ) : (<div className="absolute md:relative md:w-[23%] w-[21%]  bg-white bg-opacity-50">

                </div>)}
                <div className={`maindiv w-full pt-0 lg:p-[44px] overflow-x-hidden font-montserrat h-max lg:h-full space-y-6 flex justify-center pb-20 flex-col  ${darkMode ? "bg-black" : "bg-gray-100"} text-[#467ff7] overflow-auto`}>
                    <div className="flex flex-col">
                        <h1 className="createh1 text-center font-bold text-[2.2rem] space-y-6">
                            AI Twitter/X To Video
                        </h1>
                        <p className="pragcreate text-center">
                            Schedule a video To generate from Text.
                        </p>
                    </div>
                    <div className="pt-5 padding-class">
                        <div className="flex w-full avatar-video-h flex-col md:w-100% mx-auto space-y-4 md:space-y-0 md:space-x-4">
                            <div className={`${darkMode ? "bg-black" : "bg-white"} layout-cantent w-full flex rounded-md`}>
                                <div className=
                                    "name w-[80%] p-[12px] lg:p-2 space-y-4 transition-all duration-500"
                                >
                                    <form className="mx-auto form-w" onSubmit={handleCreate}>
                                        <div className="mx-auto">
                                            <div className="flex mb-2 justify-content-between p-1">
                                                <label
                                                    htmlFor="tweetUrl"
                                                    className="block text-sm font-medium"
                                                >
                                                    <h1 className={`font-bold text-[16px]  ${darkMode ? "text-white" : "text-[#467ff7]"} pt-1`}>
                                                        Enter Your Tweet URL
                                                    </h1>
                                                </label>
                                            </div>
                                            <input
                                                type="text"
                                                id="tweetUrl"
                                                name="tweetUrl"
                                                placeholder="Enter the tweet URL"
                                                value={tweetUrl}
                                                onChange={handleInputChange}
                                                className={`w-full p-2 ${darkMode ? "bg-[#1E1E1E]" : "bg-white"} ${darkMode ? "text-white" : "text-black"} border border-gray-300 rounded-md`}
                                                required
                                            />
                                            <div className="mt-4">
                                                <label
                                                    htmlFor="Select Voice"
                                                    className={`block text-sm font-medium ${darkMode ? "text-white" : "text-[#467ff7]"}`}
                                                >
                                                    <h1 className="font-bold text-[16px] pt-1 ">
                                                        Select Voice
                                                    </h1>
                                                    <h4 className="pb-3 pt-1 font-sans">
                                                        Preferences for every video in your series
                                                    </h4>
                                                </label>
                                                <div className={`${darkMode ? "bg-[#1E1E1E]" : "bg-white"} rounded-md w-full flex-[100%]`}>
                                                    <section className="flex flex-col items-start gap-3">
                                                        <div className="w-full flex justify-between">
                                                            <div className="flex gap-2 text-gray-700 font-medium w-full items-start"></div>
                                                        </div>
                                                        <div className="w-full flex flex-col gap-4"></div>
                                                    </section>
                                                    {errors.voice && (
                                                        <p className="text-red-600 text-sm mt-2">
                                                            {errors.voice}
                                                        </p>
                                                    )}
                                                    <div className="sidescroll border w-full border-gray-100 rounded-md shadow-sm overflow-auto h-96">
                                                        <div className="voicecal grid grid-cols-1 p-2 md:grid-cols-2 gap-2">
                                                            {DropVoices.map((voice, index) => (
                                                                <div
                                                                    key={voice.voice_id}
                                                                    onClick={() => setActiveId(voice.voice_id)}
                                                                    className={`relative p-2 flex justify-between border rounded-lg cursor-pointer ${activeId === voice.voice_id
                                                                        ? "outline outline-[#467ff7] outline-2 "
                                                                        : "border-gray-300"
                                                                        }`}
                                                                >
                                                                    <div className="flex gap-2 w-full items-center mb-2">
                                                                        <div>
                                                                            <audio
                                                                                ref={(el) =>
                                                                                    (audioRefs.current[index] = el)
                                                                                }
                                                                                src={voice.preview_url}
                                                                                onEnded={() => handleAudioEnded(index)}
                                                                                onTimeUpdate={() =>
                                                                                    handleTimeUpdate(index)
                                                                                }
                                                                            />
                                                                            <button
                                                                                onClick={() => togglePlayback(index)}
                                                                                className={`h-6 w-6 ${darkMode ? "text-white" : "text-gray-900"}`}
                                                                                type="button"
                                                                            >
                                                                                {playingIndex === index ? (
                                                                                    <MdPause />
                                                                                ) : (
                                                                                    <MdPlayArrow />
                                                                                )}
                                                                            </button>
                                                                        </div>
                                                                        <div className="flex flex-col flex-1">
                                                                            <div className="flex justify-between items-center">
                                                                                <div className={`font-semibold text-[12px] ${darkMode ? "text-white" : "text-gray-800"}`}>
                                                                                    {voice.name}
                                                                                </div>
                                                                                <div className={`${darkMode ? "text-white" : "text-gray-600"} text-gray-600 text-[10px]`}>
                                                                                    {formatTime(
                                                                                        playbackTimes[index] || 0
                                                                                    )}
                                                                                    /{voice.duration}
                                                                                </div>
                                                                            </div>
                                                                            <span className={`${darkMode ? "text-white" : "text-gray-600"} text-[10px]`}>
                                                                                {voice.labels.accent},{voice.category},
                                                                                {voice.labels.gender},{voice.labels.age}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="box shadow-md p-3">
                                                <div className="flex mt-4 mb-2 justify-content-between p-1">
                                                    <label htmlFor="" className={`block text-sm font-medium ${darkMode ? "text-white" : "text-[#467ff7]"}`}>
                                                        <h1 className="font-bold text-[16px] pt-1">
                                                            Pick Your Parameter
                                                        </h1>
                                                    </label>
                                                </div>

                                                <div className="flex space-x-4 mt-2">
                                                    <button
                                                        className={`px-4 py-2 rounded-md border border-solid 
    ${selectedButton === "stockVideos"
                                                                ? darkMode
                                                                    ? "bg-blue-600 text-white"
                                                                    : "bg-blue-500 text-white"
                                                                : darkMode
                                                                    ? "bg-black text-white hover:bg-blue-600 hover:text-white"
                                                                    : "bg-white text-black hover:bg-blue-600 hover:text-white"}
  `}
                                                        type="button"
                                                        onClick={() => handleButtonClick("stockVideos")}
                                                    >
                                                        Select Stock Videos
                                                    </button>

                                                    <button
                                                        className={`px-4 py-2 rounded-md border border-solid 
    ${selectedButton === "Select AI Images"
                                                                ? darkMode
                                                                    ? "bg-blue-600 text-white"
                                                                    : "bg-blue-500 text-white"
                                                                : darkMode
                                                                    ? "bg-black text-white hover:bg-blue-600 hover:text-white"
                                                                    : "bg-white text-black hover:bg-blue-600 hover:text-white"}
  `}
                                                        type="button"
                                                        onClick={() => handleButtonClick("Select AI Images")}
                                                    >
                                                        Select AI Images
                                                    </button>

                                                </div>
                                                {selectedButton === 'Select AI Images' && <div className="sidescroll flex gap-1 overflow-x-auto">
                                                    {images.map((image, index) => {
                                                        return (
                                                            <div key={index} className="flex gap-4 p-2">
                                                                <div
                                                                    className={`w-[150px] h-[180px] relative group flex flex-col gap-2 hover:cursor-pointer`}
                                                                    onClick={() => setPictureType(image.name)}
                                                                >
                                                                    <div className="text-gray-400 text-xs font-semibold">
                                                                        {image.name}
                                                                    </div>
                                                                    <div className="relative inline-block">
                                                                        <img
                                                                            loading="lazy"
                                                                            width="150"
                                                                            height="150"
                                                                            decoding="async"
                                                                            data-nimg="1"
                                                                            className={`h-full rounded-lg object-cover shadow-md outline-2 ${pictureType === image.name
                                                                                ? "outline outline-blue-500"
                                                                                : ""
                                                                                }`}
                                                                            srcSet={image.imageLink}
                                                                            src={image.imageLink}
                                                                            alt={image.name}
                                                                        />
                                                                        <a
                                                                            onClick={() => setPreview(image.pre)}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                            className="absolute inset-0 flex items-end justify-center text-white font-bold bg-opacity-50 hover:bg-opacity-75 transition-opacity hover:cursor-pointer"
                                                                        >
                                                                            Click to Preview
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>}
                                            </div>
                                            {/* Templates Section */}


                                            <div className={`templates-section p-4 ${darkMode ? "bg-[#1E1E1E]" : "bg-white"} rounded-md shadow-sm mt-2`}>
                                                <div>
                                                    <h2 className={`text-xl font-bold mb-4 ${darkMode ? "text-white" : "text-[#467ff7]"}`}>
                                                        Templates
                                                    </h2>
                                                </div>
                                                <div className="flex overflow-x-auto">
                                                    {Templates.map((template) => (
                                                        <div
                                                            key={template.id}
                                                            className="flex flex-col items-center"
                                                        >
                                                            <div
                                                                className="template-card ml-2 w-[250px]  cursor-pointer"
                                                                onClick={() =>
                                                                    handleTemplateClick(template.id)
                                                                }
                                                            >
                                                                <img
                                                                    src={template.previews.previewGif}
                                                                    alt="Template Preview"
                                                                    className={`rounded-lg  object-cover shadow-md outline-2 ${selectedTemplateId === template.id
                                                                        ? "outline outline-blue-500"
                                                                        : ""
                                                                        }`}
                                                                />

                                                            </div>

                                                        </div>
                                                    ))}
                                                </div>


                                            </div>

                                            <div className="box shadow-md p-3">
                                                <label className={`block font-semibold ${darkMode ? "text-white" : "text-[#467ff7]"} text-lg mb-2`}>
                                                    <div className="flex items-center gap-2">
                                                        Aspect Ratio
                                                    </div>
                                                </label>

                                                <div className="flex grid grid-cols-3 divide-x border-1 border-gray-300">
                                                    <button
                                                        className={`aspect-ratio-button p-4 w-[100%]${darkMode ? "bg-gray-500" : "bg-white"} ${selectedAspectRatio === 'portrait' ? 'bg-blue-600 text-white' : ' text-blue-600'}`}
                                                        onClick={() => {
                                                            setSelectedAspectRatio('portrait');
                                                            setAiAspectRatio('portrait_16_9')
                                                        }}
                                                        type="button"
                                                    >
                                                        <div className="flex flex-col items-center">
                                                            <div className={`w-6 h-10 ${darkMode ? "bg-gray-300" : "bg-white"} border-3 border-blue-500 rounded-sm`} />
                                                            <span>Vertical (9:16)</span>
                                                        </div>
                                                    </button>

                                                    <button
                                                        className={` ${darkMode ? "bg-gray-500" : "bg-white"}aspect-ratio-button p-4 w-[100%] ${selectedAspectRatio === 'landscape' ? 'bg-blue-600 text-white' : ' text-blue-600'}`}
                                                        onClick={() => {
                                                            setSelectedAspectRatio('landscape');
                                                            setAiAspectRatio('landscape_16_9')
                                                        }}
                                                        type="button"
                                                    >
                                                        <div className="flex flex-col items-center">
                                                            <div className={`w-10 h-6 ${darkMode ? "bg-gray-300" : "bg-white"} border-3 border-blue-500 rounded-sm`} />
                                                            <span className="mt-3">Horizontal (16:9)</span>
                                                        </div>
                                                    </button>

                                                    <button
                                                        className={` ${darkMode ? "bg-gray-500" : "bg-white"}aspect-ratio-button p-4 w-[100%] ${selectedAspectRatio === 'square' ? 'bg-blue-600 text-white' : ' text-blue-600'}`}
                                                        onClick={() => {
                                                            setSelectedAspectRatio('square');
                                                            setAiAspectRatio('square')
                                                        }}
                                                        type="button"
                                                    >
                                                        <div className="flex flex-col items-center">
                                                            <div className={`w-7 h-7 ${darkMode ? "bg-gray-300" : "bg-white"} border-3 border-blue-500 rounded-sm`} />
                                                            <span className="mt-3">Square (1:1)</span>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>

                                            {showModal ? (
                                                <>
                                                    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-hidden">
                                                        <div className="relative w-full max-w-3xl bg-white rounded-lg shadow-lg">
                                                            {/* Modal Content */}
                                                            <div className="p-6">
                                                                <h1 className="text-lg font-bold">{modalMessage}</h1>
                                                            </div>

                                                            {/* Modal Footer */}
                                                            <div className="flex items-center justify-end p-4">
                                                                <button
                                                                    className="px-4 py-2 text-sm font-bold text-black transition-all duration-150 ease-linear bg-transparent rounded focus:outline-none"
                                                                    type="button"
                                                                    onClick={() => setShowModal(false)}
                                                                >
                                                                    Close
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </>
                                            ) : null}
                                            <div className="text-black pt-6">
                                                {/* <h6 className=" w-max border-1 text-[10px] text-blue-700 border-violet-600 pt-[3px] pl-[10px] pb-[3px] pr-[10px] rounded-xl" >Step5</h6> */}
                                                <button type="submit"

                                                    className="flex justify-center items-center rounded-md w-full bg-[#467ff7] p-2 text-white"
                                                >
                                                    Generate Video
                                                </button>
                                            </div>
                                            {selectedButton === 'Select AI Images' ?
                                                (<div className="video-main-div p-2 hidden md:block h-full w-full">
                                                    <div className="video-re p-2 hidden md:block  w-full">
                                                        <div className=" max-h-screen" >

                                                            <div>
                                                                <h5 className="text-[#899493]">Output Example</h5>
                                                            </div>
                                                            <div className="h-[100%] flex flex-col justify-between items-center">
                                                                {loading === true && (
                                                                    <>
                                                                    </>
                                                                )}

                                                                <video
                                                                    key={preview}
                                                                    className="  rounded-md"
                                                                    autoPlay
                                                                    controls
                                                                    loop
                                                                    muted
                                                                    preload={"auto"}
                                                                    onCanPlayThrough={() => {
                                                                        console.log("video loaded");
                                                                        setLoading(false);
                                                                    }}
                                                                >
                                                                    <source
                                                                        src={`${server_App_URl}/media/defaults/${preview}`}
                                                                    />
                                                                </video>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>) :
                                                (<div className="video-main-div p-2 hidden md:block h-full w-full">
                                                    <div className="video-re p-2 hidden md:block  w-full">
                                                        <div className=" max-h-screen" >

                                                            <div>
                                                                <h5 className="text-[#899493]">Output Example</h5>
                                                            </div>
                                                            <div className="h-[100%] flex flex-col justify-between items-center">
                                                                {loading === true && (
                                                                    <>
                                                                    </>
                                                                )}

                                                                <video
                                                                    className="  rounded-md"
                                                                    autoPlay
                                                                    controls
                                                                    loop
                                                                    muted
                                                                    preload={"auto"}
                                                                    onCanPlayThrough={() => {
                                                                        console.log("video loaded");
                                                                        setLoading(false);
                                                                    }}
                                                                >
                                                                    <source
                                                                        src={`${server_App_URl}/media/stock/output.mp4`}
                                                                    />
                                                                </video>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>)}

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default TwitterCreate;
