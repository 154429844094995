import * as React from 'react';
import { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import BasicModal from './videoComp';
import { listVideo } from './api/Service';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import ReactLoading from 'react-loading';
import DashboardNavbar from './navbar/DashboardNavbar';
import NavTab from './navtab/NavTab';
import Footer from './Footer/Footer';
import { useModal } from '../context/ModalContext';
import { useDarkMode } from '../context/DarkModeContext';

function ListComponent() {
    const themefile = localStorage.getItem("theme");
    const { isModalOpen } = useModal();
    const [videoStatus, setVideoStatus] = useState(false);
    const [videos, setVideos] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [showVideoPreview, setShowVideoPreview] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const navigate = useNavigate();
    const { darkMode } = useDarkMode();

    const handleVideos = async () => {
        try {
            const response = await listVideo();
            if (response.response.length >= 1) {
                setVideoStatus(true);
            }
            setVideos(response.response);
        } catch (error) {
            console.error('Error fetching videos:', error);
        }
    };

    useEffect(() => {
        handleVideos();
    }, []);

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedVideo(null);
    };

    const handleclick = () => {
        navigate("/create");
    };

    return (
        <>
            <div className=''>
                <div>
                    <DashboardNavbar />
                    <div className="flex md:h-[calc(70vh-4rem)] h-auto 2xl:h-[calc(82vh-4rem)]">
                        <div className="w-100 max-h-screen min-h-screen text-sm">
                            {!showVideoPreview && videoStatus ? (
                                <TableContainer component={Paper} sx={{ margin: "0px", overflowX: 'auto', backgroundColor: themefile === "dark" ? "black" : "#fff" }}>
                                    <Table sx={{ minWidth: 650, border: 1, borderColor: "gray" }} aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className={`${themefile === "dark" ?'text-white':'text-black'}`} align="center" sx={{ minWidth: 120 }}>Topic</TableCell>
                                                <TableCell className={`${themefile === "dark" ?'text-white':'text-black'}`} align="center" sx={{ minWidth: 150 }}>CreatedAt</TableCell>
                                                <TableCell className={`${themefile === "dark" ?'text-white':'text-black'}`} align="center" sx={{ minWidth: 200 }}>Status</TableCell>
                                                <TableCell className={`${themefile === "dark" ?'text-white':'text-black'}`} align="center" sx={{ minWidth: 150 }}>Preview</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {videos.map((video, index) => (
                                                <TableRow
                                                    key={index}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, backgroundColor: themefile === "dark"  ? "black" : "#fff" }}
                                                >
                                                    <TableCell className={`${themefile === "dark" ?'text-white':'text-black'}`} align="center" sx={{ minWidth: 120 }}>{video.contentType}</TableCell>
                                                    <TableCell className={`${themefile === "dark" ?'text-white':'text-black'}`} align="center" sx={{ minWidth: 150 }}>{moment(video.createdAt).format('YYYY-MM-DD')}</TableCell>
                                                    <TableCell className={`${themefile === "dark" ?'text-white':'text-black'}`} align="center" sx={{ minWidth: 200 }}>
                                                        {video.status === 0 && <p>Generating Script</p>}
                                                        {video.status === 1 && <p>Parsing script and generating Images</p>}
                                                        {video.status === 2 && <p>Generating Text to Speech</p>}
                                                        {video.status === 3 && <p>Preparing video</p>}
                                                        {video.status === 4 && <p>Completed</p>}
                                                        {video.isfailed === 1 && <p>Failed!!</p>}
                                                    </TableCell>
                                                    <TableCell
                                                        onClick={() => {
                                                            setSelectedVideo(video);
                                                            setShowVideoPreview(true);
                                                            navigate("/app/preview", {
                                                                state: {
                                                                    videoUrl: `/${video.userId}/${video.id}/output.mp4`,
                                                                    path: `/${video.userId}/${video.id}/images`,
                                                                    scriptPath: `/${video.userId}/${video.id}/scriptAndPrompt/generated_scripts.txt`,
                                                                    videoid: video.id,
                                                                    type: video.imageSize,
                                                                    userid: video.userId
                                                                },
                                                            });
                                                        }}
                                                        className={`${themefile === "dark" ?'text-white':'text-black'}`}
                                                        sx={{ textAlign: '-webkit-center', minWidth: 150 }}
                                                    >
                                                        {video.status === 4 ? (
                                                            <BasicModal
                                                                open={openDialog}
                                                                onClose={handleCloseDialog}
                                                                video={video}
                                                            />
                                                        ) : video.isfailed === 1 ? (
                                                            <div className="flex justify-center">
                                                                <p>--</p>
                                                            </div>
                                                        ) : (
                                                            <div className="flex justify-center">
                                                                <ReactLoading type="spin" color="#3498db" height={20} width={20} />
                                                            </div>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                ) : (
                                    <div className="min-h-screen flex items-center justify-center p-4">
                                    <div className="bg-[#ffffff]/5 backdrop-blur-lg rounded-2xl p-12 max-w-lg w-full border border-[#ffffff]/10">
                                      <div className="mb-10 relative">
                                        <div className="w-24 h-24 mx-auto bg-gradient-to-r from-[#2ecc71] to-[#32e010] rounded-xl flex items-center justify-center animate-float">
                                          <i className="fas fa-film text-5xl text-white"></i>
                                        </div>
                                      </div>
                              
                                      <h1 className="text-3xl font-roboto font-bold text-center mb-4">
                                        No Videos Created Yet!
                                      </h1>
                              
                                      <p className="text-[#a3a3a3] text-center mb-10 font-roboto">
                                        Ready to bring your ideas to life? Create your first AI-powered video
                                        and start your creative journey.
                                      </p>
                              
                                      <button onClick={handleclick} className="w-full bg-gradient-to-r from-[#2ecc71] to-[#32e010] hover:from-[#27ae60] hover:to-[#2bc050] text-white font-roboto py-4 px-8 rounded-xl transition-all duration-300 transform hover:scale-[1.02] flex items-center justify-center gap-3">
                                        <i className="fas fa-magic"></i>
                                        Create Your First Video
                                      </button>
                                    </div>
                              
                                    <style jsx global>{`
                                      @keyframes float {
                                        0%, 100% { transform: translateY(0); }
                                        50% { transform: translateY(-10px); }
                                      }
                                      .animate-float {
                                        animation: float 3s ease-in-out infinite;
                                      }
                                    `}</style>
                                  </div>
                            )}
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default ListComponent;
