"use client";
import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import "../../../index.css"
import { ImOffice } from "react-icons/im";
import loho from "../../../loho.png"
import LandingNavbar from "../navbar/Navbar";
import LandingFooter from "../navbar/Footer";

function FAQ() {
    const [openIndex, setOpenIndex] = useState(null);
    const [isOpen, setIsOpen] = useState(false)

    const faqs = [
        {
            question: "Can I create videos in any niche?",
            answer:
                "You bet! You can create a series for nearly any topic or niche you want. Either choose from our preset list or use a custom prompt to describe your own.",
        },
        {
            question: "Are the videos unique?",
            answer:
                "Unlike other services that re-use the same video over and over, we create a new video for each series using generative AI. This means that each video is unique and will not be the same as any other video. Even if two videos have the same topic, the script and images will be completely different due to generative AI.",
        },
        {
            question: "How many videos can i creete per day?",
            answer:
                `For a one time plan, you an create maximum 5 videos per day. For the unlimited plan, this daily limit is exceeded till 10 videos per day.`,
        },
        {
            question: "How many videos can I create per day?",
            answer:
                ` "The number of videos created by each series can be seen on our pricing page. Remember, AutoTube doesn't focus on individual video creation. Instead, you set up a Series that automatically generates videos on a schedule.

               Features that render a new video such as making script edits, or changing your series do not count against your plan."`,
        },
        {
            question: "How do I create a video?",
            answer:
                "You simply choose your preferred video type, then choose existing topic, or enter your custom topic, choose your video preset and other settings like the Audio, Video duration, its orientation and just hit generate. We give you all freedom to customise your video beforehand, you are free to choose any options",
        },
        {
            question: "Do I own the videos?",
            answer:
                "Yes, the videos are yours to do with as you please. You can download them and use them on other platforms, or even sell them to clients."
        },
        {
            question: "Does the platform support multiple languages?",
            answer:
                "Yes, we currently support the following languages: English, Bulgarian, Czech, Danish, Dutch, Estonian, Finnish, French, German, Greek, Hindi, Indonesian, Italian, Japanese, Korean, Malay, Norwegian, Polish, Portuguese, Russian, Spanish, Swedish, Turkish, Ukrainian."
        }
    ];
    const faq = [
        {
            question: "Is there a free trial?",
            answer:
                "You bet your sweet bippy there is. Simply create an account and you can create your first series for free to test a video. No credit card required.",
        },
        {
            question: "Can I cancel at anytime?",
            answer:
                "Absolutely. We hate services that purposefully make it difficult to cancel. You can cancel at the click of a button from the dashboard's billing page.",
        },
        {
            question: "How does the membership work?",
            answer:
                "Beyond the free plan, we offer different tiers of paid memberships. The paid plans remove the watermark and allow you to post more frequently.",
        },
        {
            question: "Can I get a refund?",
            answer:
                "Unfortunately, we cannot offer refunds as costs incurred for creating AI videos and generating AI photos are extremely high. In turn, our upstream providers do not let us ask for refunds for the GPU processing time used to create your AI videos. This would make it a loss making endeavor for us. During sign up you agree to withhold your right to refund for this reason. You can cancel any time though and your subscription ends."
        },
        {
            question: "Can I upgrade or downgrade my subscription?",
            answer:
                "Yes, you can upgrade or downgrade your subscription at any time. Go to the billing tab and select the plan you want to upgrade / downgrade to. If you move to a plan with less series than you currently have, the extra series will be automatically disabled.",
        }
    ];

    return (
        <div className="bg-black text-white">

        <LandingNavbar />    
     
            <div className="min-h-screen items-center justify-center flex ">
                <div className="max-w-4xl rounded-md mx-auto bg-gradient-to-br px-5 pt-3 pb-5 sm:px-6 lg:px-8 from-[#1a1a1a] to-[#2d2d2d]">
                    <div className="text-center mb-16">
                        <h1 className="text-5xl font-bold bg-gradient-to-r from-green-400 to-green-600 inline-block text-transparent bg-clip-text font-roboto">
                            Frequently Asked Questions
                        </h1>
                        <div className="mt-4 h-1 w-24 bg-gradient-to-r from-green-400 to-green-600 mx-auto rounded-full"></div>
                    </div>

                    <div className="space-y-4">
                        {faqs.map((faq, index) => (
                            <div
                                key={index}
                                className="bg-[#333333] rounded-xl  hover:border-[#666666] transition-colors duration-300"
                            >
                                <button
                                    className="w-full px-3 py-4 text-left flex justify-between items-center hover:bg-[#3d3d3d] rounded-xl transition-colors duration-200"
                                    onClick={() => setOpenIndex(openIndex === index ? null : index)}
                                >
                                    <span className="text-lg font-medium text-white font-roboto">
                                        {faq.question}
                                    </span>
                                    <i
                                        className={`fas ${openIndex === index ? "fa-minus" : "fa-plus"} text-green-400`}
                                    ></i>
                                </button>

                                <div
                                    className={`px-6 overflow-hidden transition-all duration-300 ease-in-out ${openIndex === index ? "max-h-48 py-4" : "max-h-0"}`}
                                >
                                    <p className="text-gray-300 leading-relaxed font-roboto">
                                        {faq.answer}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="min-h-screen items-center justify-center pt-2 flex ">
                <div className="max-w-4xl rounded-md mx-auto bg-gradient-to-br px-5 pt-3 pb-5 sm:px-6 lg:px-8 from-[#1a1a1a] to-[#2d2d2d]">
                    <div className="text-center mb-16">
                        <h1 className="text-5xl font-bold bg-gradient-to-r p-3 from-green-400 to-green-600 inline-block text-transparent bg-clip-text font-roboto">
                            Billing
                        </h1>
                        <div className="mt-4 h-1 w-24 bg-gradient-to-r from-green-400 to-green-600 mx-auto rounded-full"></div>
                    </div>

                    <div className="space-y-4">
                        {faq.map((faq, index) => (
                            <div
                                key={index}
                                className="bg-[#333333] rounded-xl  hover:border-[#666666] transition-colors duration-300"
                            >
                                <button
                                    className="w-full px-3 py-4 text-left flex justify-between items-center hover:bg-[#3d3d3d] rounded-xl transition-colors duration-200"
                                    onClick={() => setOpenIndex(openIndex === index ? null : index)}
                                >
                                    <span className="text-lg font-medium text-white font-roboto">
                                        {faq.question}
                                    </span>
                                    <i
                                        className={`fas ${openIndex === index ? "fa-minus" : "fa-plus"} text-green-400`}
                                    ></i>
                                </button>

                                <div
                                    className={`px-6 overflow-hidden transition-all duration-300 ease-in-out ${openIndex === index ? "max-h-48 py-4" : "max-h-0"}`}
                                >
                                    <p className="text-gray-300 leading-relaxed font-roboto">
                                        {faq.answer}
                                    </p>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <LandingFooter/>
        </div>
    );
}

export default FAQ;