import React, { useEffect, useState } from 'react';
import DashboardNavbar from '../navbar/DashboardNavbar';
import axios from 'axios';
import NavTab from '../navtab/NavTab';
import Footer from '../Footer/Footer';
import '../style.css';
import { useLocation, useNavigate } from 'react-router-dom';
import image1 from './loading.gif'
import ProgressBar from 'react-bootstrap/ProgressBar';
import { CheckAiVideo, CheckTwitter, CheckVideo } from '../api/Service';
import { useModal } from '../../context/ModalContext';
import { useDarkMode } from '../../context/DarkModeContext';



const AiVideoPreview = () => {
    const themefile = localStorage.getItem("theme");
    const plan = localStorage.getItem('plan');
    const credit = localStorage.getItem('credit');
    const navigate = useNavigate()
    const { darkMode } = useDarkMode();
    const { isModalOpen } = useModal()
    const location = useLocation();
    const [scriptCreated, setScriptCreated] = useState(false)
    const { videoUrl, videoid, userid, scriptPath } = location.state;
    const [loading, setLoading] = useState(true)
    const [videoCreated, setvideoCreated] = useState(null)
    const [completed, setCompleted] = useState(0);
    const [images, setImages] = useState([]);
    const [script, setScript] = useState('')
    const server_App_URl = process.env.REACT_APP_API_URL
    const [showModal, setShowModal] = React.useState(false);
    const [type, setType] = useState('')
    const [outputPath, setOutputPath] = useState(null)
    const [s3, setS3] = useState(null)

    useEffect(() => {
        console.log('start interval');
        fetchData();
    }, []);




    useEffect(() => {

        const savedProgress = localStorage.getItem('progress');
        if (savedProgress) {
            setCompleted(parseFloat(savedProgress));
        }

        const duration = 900;
        const increment = 100 / duration;
        const interval = setInterval(() => {
            setCompleted(prev => {
                if (prev + increment >= 100) {
                    clearInterval(interval);
                    localStorage.removeItem('progress')
                    return 100;
                }
                const newProgress = prev + increment;
                localStorage.setItem('progress', newProgress);
                return newProgress;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    function sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }


    const fetchData = async () => {

 
       
        try {
            const Response = await CheckAiVideo({
                videoId: videoid
            });

            console.log(Response.outputPath)

            setOutputPath(Response.outputPath)
            setImages(Response.imagesLength);
            if (Response.status >= 1 && !script) {
                setScriptCreated(true);
            }

            setvideoCreated(Response.status);

            // setvideoCreated(imageResponse.response.isVideoCreated);
            if (Response.isfailed === 1) {
                setShowModal(true)
            } else {
                setShowModal(false)
            }
            await checkVideoCreation();

            //console.log("newwww", imageResponse.data);
            if (Response.status >= 1) {
                console.log('helooo enteretd' ,plan)
                let scriptResponse ;
                if (plan === "Free") {
                    console.log("enter in free plain")
                    scriptResponse = await axios.get(`https://autotube.in/backend/media/default/AiVideo/generated_scripts.txt`);
                }else{
                    scriptResponse = await axios.get(`${server_App_URl}/media/AiVideo${scriptPath}`);
                    
                }
                    console.log(scriptResponse, 'scripttt')
                    const cleanedContent = scriptResponse.data
                    .split('\n')
                    .map(line => line.replace(/^Script: /, '').trim())
                    .filter(line => line.length > 0);

                setScript(cleanedContent);
                    
            }

            async function checkVideoCreation() {
                if (Response.status !== 4 && Response.outputPath === null) {
                    await sleep(25000);
                    fetchData();
                } else {
                    localStorage.removeItem('progress');
                    return false;
                }
            }


        } catch (error) {
            console.error('Error fetching data', error);
            // setError(error.message);
        }
        // } finally {
        //     setLoading(false);
        // }
        
    };

    const handleDownload = async () => {
        try {
            console.log('downloadd check')
            const response = await axios.get(videoLink, {
                responseType: 'blob',
            });

            const blobUrl = window.URL.createObjectURL(new Blob([response.data]));

            const newWindow = window.open('');

            const link = newWindow.document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', videoUrl.split('/').pop());
            newWindow.document.body.appendChild(link);

            link.click();

            newWindow.document.body.removeChild(link);
            newWindow.close();
        } catch (error) {
            console.error('Error downloading video', error);
        }
    };

    let videoLink;
    if (plan === "Free") {
        videoLink = `https://autotube.in/backend/media/default/AiVideo/output.mp4`
    }
    else{
        if (s3=== 1) {
            
            videoLink = `https://shortsmedia.s3.us-east-2.amazonaws.com/AiVideo/${userid}/${videoid}/${outputPath}.mp4`
        }else{
            videoLink=`${server_App_URl}/media/AiVideo/${userid}/${videoid}/${outputPath}.mp4`
        }
    }

    return (
        <>
            <DashboardNavbar />
            <div className="flex md:h-max h-max">
                <div className={`w-full p-[2.5rem] min-h-screen overflow-x-hidden font-montserrat h-max lg:h-full space-y-6 flex justify-center pt-12 pb-20 flex-col  `}>
                    {videoCreated !== 4 && outputPath === null ? (
                        <>
                            <div className="App">
                                {completed < 100 && (
                                    <ProgressBar
                                        now={completed}
                                        label={`${Math.round(completed)}%`}
                                    />
                                )}
                            </div>
                        </>
                    ) : null}

                    <div className='video-h flex flex-col items-center justify-center mb-2 p-2 h-[450px]' style={{ margin: '0 auto' }}>
                        <div>
                            <h1 className={`font-bold text-[16px]   pt-1 pb-2`}>Output</h1>
                        </div>
                        <div className='down-btn h-[100%]'>

                            {videoCreated === 4 ? (
                                <>
                                    <video
                                        className={!videoCreated ? 'd-none' : 'h-[92%] w-[100%] rounded-md'}
                                        autoPlay
                                        controls
                                        loop
                                        muted
                                        preload="auto"
                                        onCanPlayThrough={() => {
                                            console.log('video loaded');
                                            setLoading(false);
                                        }}
                                    >
                                        <source src={videoLink} />
                                        {/* {outputPath?(<source src={`https://shortsmedia.s3.us-east-2.amazonaws.com/Twitter_x/${userid}/${videoid}/${outputPath}.mp4`}/>):(<source src={`${server_App_URl}/media/twitter_x${videoUrl}`}/>)} */}
                                    </video>
                                    <div className='flex justify-center mt-[10px] mb-[50px]'>
                                        <button
                                            onClick={handleDownload}
                                            className={`mb-2 px-4 py-2 cursor-pointer rounded hover:bg-green-300 bg-[#00C805]`}
                                        >
                                            Download Video
                                        </button>
                                    </div>
                                </>

                            ) : (<><p className=' '>Loading Video....</p><img className='h-[100%]' src={image1} alt="" /></>)}
                        </div>
                    </div>
                
                        <div className={`margen-set md:p-12 p-[12px] h-max md:w-[80%] lg:p-6 mx-auto shadow border rounded-md shadow-md space-y-4`}>
                            <h1 className={`font-bold text-[16px]   pt-1 pb-2`}>Scripts</h1>
                            <div className='h-max w-full mb-5 mt-[18px] border-gray-100'>
                                {scriptCreated === false ? (
                                    <>
                                        <div className="spinner-border  " role="status">
                                            <span className="visually-hidden  ">Loading...</span>
                                        </div>
                                        <p className=' '>Loading Scripts....</p>
                                    </>
                                ) : (

                                    <p className={`mb-2   cursor-context-menu`}>{script}</p>

                                )}

                            </div>
                           

                        </div>

                        {showModal ? (
                            <>
                                <div className="fixed inset-0 z-50 flex items-center justify-center overflow-hidden">
                                    <div className="relative w-full max-w-3xl bg-white rounded-lg shadow-lg">
                                        {/* Modal Content */}
                                        <div className="p-6">
                                            <h1 className="text-lg font-bold">Video Creation is failed Please Try Again</h1>
                                        </div>

                                        {/* Modal Footer */}
                                        <div className="flex items-center justify-end p-4">
                                            <button
                                                className="px-4 py-2 text-sm font-bold text-black transition-all duration-150 ease-linear bg-transparent rounded focus:outline-none"
                                                type="button"
                                                onClick={() => {
                                                    navigate('/view/aivideo');
                                                    setShowModal(false)
                                                }}
                                            >
                                                Okay
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </>
                        ) : null}

                    
                </div>


            </div>
            <Footer style={{ width: '100vw;' }} />
        </>
    )
}

export default AiVideoPreview