import React from 'react';
import { GiArtificialIntelligence } from 'react-icons/gi';
import { ImFacebook2 } from "react-icons/im";
import { AiFillYoutube } from "react-icons/ai";
import { FiInstagram } from "react-icons/fi";
import { Link } from 'react-router-dom';
import '../style.css'
import logo from '../icon.png'
import loho from "../../loho.png"


const Footer = () => {
  return (
    <footer className="shadow py-8">
      <div className="flex flex-col sm:flex-row items-center justify-between max-w-7xl mx-auto px-4 sm:px-10 space-y-6 sm:space-y-0">
        {/* Logo */}
        {/* <div className="text-center">
          <Link to="/dashboard" className="flex items-center justify-center tracking-wider hover:scale-105 transition-transform cursor-pointer">
            <span className="text-xl sm:text-2xl md:text-3xl font-bold">Auto</span>
            <span className="text-xl sm:text-2xl md:text-3xl font-bold text-red-600">Tube</span>
          </Link>
        </div> */}
        <div className="flex items-center">
          <Link
            to="/dashboard" // Sign in ke liye path ko update karein
            className="flex items-center justify-center tracking-wider hover:scale-105 transition-transform cursor-pointer"
          >
            <img src={loho} alt="Logo" width={40} className="mr-2" />
            <span className="text-5xl md:text-3xl font-bold">Auto</span>
            <span className="text-red-600 text-5xl md:text-3xl font-bold">Tube</span>
          </Link>
        </div>

        {/* Description */}
        <div className="  text-sm text-center max-w-md px-4 sm:px-10">
          AutoTube AI automatically creates, schedules, and posts engaging Faceless videos for you on auto-pilot. Each video is tailored and uniquely crafted to match your specific topic.
        </div>

        {/* Copyright */}
        <div className="text-center   text-sm">
          <p>© 2024 AutoTube. All rights reserved.</p>
        </div>
      </div>
    </footer>

  );
};

export default Footer;
