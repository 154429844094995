import * as React from 'react';
import { useState } from 'react';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import { MdOutlineSlowMotionVideo } from 'react-icons/md';
import { MdDelete } from "react-icons/md";
import MediaCover from './videoPlayer';
import '../components/style.css'
import Preview from './preview/Preview';

export default function BasicModal(props) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    //const [video,setvideo]=useState(props.video)
    //     console.log("video")
    // console.log(props.video)



    return (
        <React.Fragment>
            <MdOutlineSlowMotionVideo
                className='h-8 w-8 cursor-pointer'
                onClick={handleOpen}
            />

            <Modal
                open={open}
                onClose={handleClose}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet
                    variant="outlined"
                    sx={{
                        maxWidth: 500,
                        borderRadius: 'md',
                        p: 3,
                        boxShadow: 'lg',
                        height: 500
                    }}
                >
                    <ModalClose variant="plain" sx={{ m: 1 }} onClick={handleClose} />

                    <Typography id="modal-desc" textColor="text.tertiary">
                        <MediaCover video={props.video}  />
                    </Typography>
                </Sheet>
            </Modal>
        </React.Fragment>
    );
}
     