import React from "react";
import DashboardNavbar from "../navbar/DashboardNavbar";
import NavTab from "./NavTab";
import Footer from "../Footer/Footer";
import billingimg from "../images/billing.png";
import Card from "../card/Card";
import "../style.css";
import { Plans, SubcriptionCancel } from "../api/Service";
import { useModal } from "../../context/ModalContext";
import { useDarkMode } from "../../context/DarkModeContext";

const Guide = () => {
  const { isModalOpen } = useModal();
  const { darkMode } = useDarkMode();

  return (
    <>
      <div className={darkMode ? "bg-black text-white" : "bg-white text-black"}>
        <DashboardNavbar />
        <div className="flex min-h-screen">
          {!isModalOpen ? (
            <div className={`absolute md:relative md:w-[23%] w-[21%] ${darkMode ? "bg-black" : "bg-white"} text-white`}>
              <NavTab />
            </div>
          ) : (
            <div className={`absolute md:relative md:w-[23%] w-[21%] ${darkMode ? "bg-black bg-opacity-50" : "bg-white bg-opacity-50"}`}>
            </div>
          )}
          <div className={`flex justify-evenly w-[79%] min-h-screen ${darkMode ? "bg-black" : "bg-gray-100"} main-w-768 gap-3 items-start`}>
            <div className="w-full flex min-h-screen justify-content-center align-items-center">
              <div className="video-main-cls w-[95%]">
                <div className="border-b-2 w-[120px] border-[#000000] font-bold text-lg space-y-6">
                  <h3 className="pb-2 text-center">VIDEO GUIDE</h3>
                </div>
                <hr />
                <div className={`rounded align-content-center shadow-sm mt-3 p-2 ${darkMode ? "bg-black" : "bg-white"}`}>
                  <div className="p-4 w-full place-items-center video-grid-cal grid grid-cols-2 gap-5">
                    <div>
                      <div className="p-1">
                        <div className="border-8 video-w w-[250px] rounded-lg border-[#467ff7]">
                          <iframe
                            className=" w-full h-full"
                            src="https://www.youtube.com/embed/x651qYWe9es"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        </div>
                      </div>
                      <div className={`w-[250px] lg:w-[350px] font-bold pl-2  ${darkMode ? "text-white" : "text-[#082667]"}`}>
                        <h3 className="text-bold lg:text-xl">How to Create a Faceless YouTube Channel in 2025 (with AI)</h3>
                      </div>
                    </div>
                    <div>
                      <div className="p-1">
                        <div className="border-8 video-w w-[250px] rounded-lg border-[#467ff7]">
                          <iframe
                            className=" w-full h-full"
                            src="https://www.youtube.com/embed/wsyZs6GvE9g"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        </div>
                      </div>
                      <div className={`w-[250px] lg:w-[350px] font-bold pl-2  ${darkMode ? "text-white" : "text-[#082667]"}`}>
                        <h3 className="text-bold lg:text-xl">How to Make $5000 With Viral AI Generated History Shorts</h3>
                      </div>
                    </div>
                    <div>
                      <div className="p-1">
                        <div className="border-8 video-w w-[250px] rounded-lg border-[#467ff7]">
                          <iframe
                            className=" w-full h-full"
                            src="https://www.youtube.com/embed/PA8KCe5KxII"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        </div>
                      </div>
                      <div className={`w-[250px] lg:w-[350px] font-bold pl-2 mt-4  ${darkMode ? "text-white" : "text-[#082667]"}`}>
                        <h3 className="text-bold lg:text-xl">Create Faceless Videos</h3>
                      </div>
                    </div>
                    <div>
                      <div className="p-1">
                        <div className="border-8 video-w rounded-lg w-[250px] border-[#467ff7]">
                          <iframe
                            className=" w-full h-full "
                            src="https://www.youtube.com/embed/4h9tsOmfOMs"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        </div>
                      </div>
                      <div className={`w-[250px] lg:w-[350px] pl-2 font-bold ${darkMode ? "text-white" : "text-[#082667]"}`}>
                        <h3 className="text-bold lg:text-xl">Best Niche REVEALED for 2024! <br></br> TikTok and YouTube Automation</h3>
                      </div>
                    </div>
                    {/* <div>
                      <div className="p-1">
                        <div className="border-8 video-w w-[250px] rounded-lg border-[#467ff7]">
                          <iframe
                            className=" w-full h-full"

                            src="https://www.youtube.com/embed/wp56A33ctKg"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        </div>
                      </div>
                      <div className={`w-[250px] lg:w-[350px]  pl-2 font-bold ${darkMode ? "text-white" : "text-[#082667]"}`}>
                        <h3 className="text-bold lg:text-xl">This New AI Faceless Emoji Reels Generator Automatically Creates YouTube Shorts & TikTok Videos</h3>
                      </div>
                    </div> */}
                    <div>
                      <div className="p-1">
                        <div className="border-8 video-w w-[250px] rounded-lg border-[#467ff7]">
                          <iframe
                            className=" w-full h-full"

                            src="https://www.youtube.com/embed/_UNaV4cbvPo"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        </div>
                      </div>
                      <div className={`w-[250px] lg:w-[350px] pl-2 font-bold mt-4 ${darkMode ? "text-white" : "text-[#082667]"}`}>
                        <h3 className="text-bold lg:text-xl">How I Made 100 Virals TikTok Videos in Just 3 Minutes</h3>
                      </div>
                    </div>
                    <div>
                      <div className="p-1">
                        <div className="border-8 video-w w-[250px] rounded-lg border-[#467ff7]">
                          <iframe
                            className="w-full h-full"
                            src="https://www.youtube.com/embed/PfwIytlve7g"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        </div>
                      </div>
                      <div className={`w-[250px] lg:w-[350px] pl-2 font-bold ${darkMode ? "text-white" : "text-[#082667]"}`}>
                        <h3 className="text-bold lg:text-xl">Turn Tweets into Engaging Visuals <br /> Tweet to Video</h3>
                      </div>
                    </div>
                    <div>
                      <div className="p-1">
                        <div className="border-8 video-w w-[250px] rounded-lg border-[#467ff7]">
                          <iframe
                            className="w-full h-full"
                            src="https://www.youtube.com/embed/AjgZEfI2UPc"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        </div>
                      </div>
                      <div className={`w-[250px] lg:w-[350px] pl-2 font-bold ${darkMode ? "text-white" : "text-[#082667]"}`}>
                        <h3 className="text-bold lg:text-xl">How To Make FACELESS KIDS ANIMATION Channel Videos</h3>
                      </div>
                    </div>
                    <div>
                      <div className="p-1">
                        <div className="border-8 video-w w-[250px] rounded-lg border-[#467ff7]">
                          <iframe
                            className="w-full h-full"
                            src="https://www.youtube.com/embed/kP7s0C-vb-U"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        </div>
                      </div>
                      <div className={`w-[250px] lg:w-[400px] pl-2 font-bold ${darkMode ? "text-white" : "text-[#082667]"}`}>
                        <h3 className="text-bold lg:text-xl">Make $10,000 per Month with AI-Generated LEGO Shorts Using ChatGPT</h3>
                      </div>
                    </div>
                    {/* <div>
                      <div className="p-1">
                        <div className="border-8 video-w w-[250px] rounded-lg border-[#467ff7]">
                          <iframe
                            className="w-full h-full"
                            src="https://www.youtube.com/embed/VGy2vHXv31s"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        </div>
                      </div>
                      <div className={`w-[250px] lg:w-[350px] pl-2 font-bold ${darkMode?"text-white":"text-[#082667]"}`}>
                        <h3 className="text-bold lg:text-xl">How To Create Viral History Shorts<br />Step By Step Guide</h3>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Guide;
