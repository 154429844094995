"use client";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function Myplan() {
//   const [selectedVideos, setSelectedVideos] = useState(5);
//   const basePrice = 349;
//   const pricePerVideo = 119.8;

//   const calculateCustomPrice = () => {
//     return (selectedVideos * pricePerVideo) - basePrice ;
//   };

const videoPricing = {
  5: 599,
  10: 849,
15: 1099,
20: 1499
};

const [selectedVideos, setSelectedVideos] = useState(5);

const calculateCustomPrice = () => {
  return videoPricing[selectedVideos];
};

  return (
    <div className="py-12 mb-12 px-4">
            <div className="max-w-7xl mx-auto">
                <div className="text-center">
                    <h2 className="text-3xl font-bold text-white  font-roboto mb-4">
                        Choose Your Perfect Plan
                    </h2>
                    <p className="text-xl text-gray-300 font-roboto">
                        Select the plan that best suits your needs
                    </p>
                </div>

                <div className="mt-16 grid grid-cols-1 md:grid-cols-3 gap-8">
                    <div className="rounded-lg shadow-lg border border-gray-800 p-8 transform hover:scale-105 transition duration-300 flex flex-col justify-between">
                        <div>
                            <h3 className="text-2xl text-white font-bold  mb-4">
                                Free Plan
                            </h3>
                            <p className="text-4xl text-white font-bold mb-6 font-roboto">
                                ₹0<span className="text-gray-300 text-sm">/month</span>
                            </p>
                            <ul className="space-y-4 mb-8">
                                <li className="flex text-white items-center">
                                    <i className="fas fa-check text-green-500 mr-2"></i>
                                    <span>1 Basic Video</span>
                                </li>
                                <li className="flex text-white items-center">
                                    <i className="fas fa-check text-green-500 mr-2"></i>
                                    <span>Limited Features</span>
                                </li>
                                <li className="flex text-white items-center">
                                    <i className="fas fa-check text-green-500 mr-2"></i>
                                    <span>Basic Support</span>
                                </li>
                            </ul>
                        </div>
                        <Link to="/login">
                            <button className="w-full bg-[#2ecc71] text-black py-3 rounded-lg hover:bg-[#3fcf6f] mt-auto">
                                Get Started
                            </button>
                        </Link>
                    </div>

                    <div className="rounded-lg shadow-lg border-1 border-green-500  p-8 transform hover:scale-105 transition duration-300 flex flex-col justify-between">
                        <span className="absolute -top-2 right-3 bg-green-500 text-white text-center text-xs font-semibold px-3 py-1 rounded-full">
                            Most Popular
                        </span>
                        <div>
                            <h3 className="text-2xl text-white font-bold  mb-4">
                                Custom Plan
                            </h3>
                            <p className="text-4xl text-white font-bold mb-6 font-roboto">
                                ₹{calculateCustomPrice()}
                                <span className="text-gray-300 text-sm">/One Time Payment</span>
                            </p>
                            <div className="mb-6">
                                <label className="block text-white text-gray-400 mb-2">
                                    Select Videos:
                                </label>
                                <select
                                    className="w-full p-2 border text-white border-gray-500 bg-transparent rounded-lg"
                                    value={selectedVideos}
                                    onChange={(e) => setSelectedVideos(Number(e.target.value))}
                                >
                                    <option value="5" className="text-white bg-black">5 Videos</option>
                                    <option value="10" className="text-white bg-black">10 Videos</option>
                                    <option value="15" className="text-white bg-black">15 Videos</option>
                                    <option value="20" className="text-white bg-black">20 Videos</option>
                                </select>
                            </div>
                            <ul className="space-y-4 mb-8">
                                <li className="flex text-white items-center">
                                    <i className="fas fa-check text-green-500 mr-2"></i>
                                    <span>{selectedVideos} Custom Videos</span>
                                </li>
                                <li className="flex text-white items-center">
                                    <i className="fas fa-check text-green-500 mr-2"></i>
                                    <span>Advanced Features</span>
                                </li>
                                <li className="flex text-white items-center">
                                    <i className="fas fa-check text-green-500 mr-2"></i>
                                    <span>Priority Support</span>
                                </li>
                            </ul>
                        </div>
                        <Link to="/login">
                            <button className="w-full bg-[#2ecc71] text-black py-3 rounded-lg hover:bg-[#3fcf6f] mt-auto">
                                Get Started
                            </button>
                        </Link>
                    </div>

                    <div className="rounded-lg shadow-lg border border-gray-800  p-8 transform hover:scale-105 transition duration-300 flex flex-col justify-between">
                        <div>
                            <h3 className="text-2xl text-white font-bold font-roboto mb-4">
                                Premium Plan
                            </h3>
                            <p className="text-4xl font-bold text-white mb-6 font-roboto">
                                ₹3999<span className="text-gray-200 text-sm">/month</span>
                            </p>
                            <ul className="space-y-4 mb-8 text-white">
                                <li className="flex items-center">
                                    <i className="fas fa-check mr-2"></i>
                                    <span>Unlimited Videos</span>
                                </li>
                                <li className="flex items-center">
                                    <i className="fas fa-check mr-2"></i>
                                    <span>All Premium Features</span>
                                </li>
                                <li className="flex items-center">
                                    <i className="fas fa-check mr-2"></i>
                                    <span>24/7 Premium Support</span>
                                </li>
                                <li className="flex items-center">
                                    <i className="fas fa-check mr-2"></i>
                                    <span>Exclusive Content</span>
                                </li>
                            </ul>
                        </div>
                        <Link to="/login">
                            <button className="w-full bg-[#2ecc71] text-black py-3 rounded-lg hover:bg-[#3fcf6f] mt-auto">
                                Get Started
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
  );
}

export default Myplan;
