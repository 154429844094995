import React, { useState } from "react";
import { Link } from "react-router-dom";
import { GiArtificialIntelligence } from "react-icons/gi";
import { VscMenu } from "react-icons/vsc";
import loho from "../../loho.png"
// import logo from '../icon.png'

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className="h-16 font-montserrat z-10 sm:p-4 md:gap-80 flex md:justify-around justify-around   bg-[#0a0a1f]  relative">
      <div className="flex items-center md:items-start">
        <p className="flex items-center text-xl gap-1 font-bold text-white">
          <div className="flex items-center">
            <Link
              to="/" // Sign in ke liye path ko update karein
              className="flex items-center"
            >
              <img src={loho} alt="Logo" width={40} className="mr-2" />
              <span className="text-white text-5xl md:text-3xl font-bold">Auto</span>
              <span className="text-red-600 text-5xl md:text-3xl font-bold">Tube</span>
            </Link>
          </div>
        </p>
      </div>
      <div className="flex gap-6">
        {/* Mobile Menu (Visible on small screens) */}
        <div className="relative sm:hidden flex justify-center items-center">
          <button
            onClick={handleToggle}
            className="text-lg pl-4 pr-4 pt-1 border pb-1 cursor-pointer rounded-sm flex items-center"
          >
            <VscMenu className="text-white text-sm font-bold" />
          </button>

          {/* Dropdown Menu */}
          <div
            className={`absolute right-0 top-12 w-32 h-auto bg-white border rounded-md shadow-md z-20 transition-transform duration-300 ${isOpen
                ? "scale-100 opacity-100 visible"
                : "scale-95 opacity-0 invisible"
              }`}
          >
            <Link
              to="/login"
              className="block py-2 px-4 hover:bg-violet-100 transition-colors duration-300"
              onClick={() => setIsOpen(false)}
            >
              Login
            </Link>
            <Link
              to="/signup"
              className="block py-2 px-4 hover:bg-violet-100 transition-colors duration-300"
              onClick={() => setIsOpen(false)}
            >
              Sign Up
            </Link>
          </div>
        </div>

        {/* Desktop Menu (Hidden on small screens) */}
        <div className="hidden sm:flex md:text-sm font-bold items-center justify-center gap-4">
          <Link
            to="/login"
            className="text-white hover:opacity-[0.8] bg-transparent"
          >
            Login
          </Link>
          <Link
            to="/signup"
            className="text-white hover:opacity-[0.8] bg-transparent border md:text-sm border-white rounded-md flex justify-center items-center p-2 px-3"
          >
            Sign Up
          </Link>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
