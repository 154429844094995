import React from 'react';
import { Link } from 'react-router-dom';
import loho from "../../../loho.png";

const LandingNavbar = () => {
  return (
    <nav className="flex flex-wrap justify-between mb-2 items-center px-4 sm:px-6 py-4 border-b border-gray-800">
      <div className="flex flex-col items-center space-y-6">
        <div className="flex items-center">
          <div className="text-5xl md:text-3xl font-bold">
            <div className="flex items-center">
              <Link
                to="/" // Sign in ke liye path ko update karein
                className="flex items-center"
              >
                <img src={loho} alt="Logo" width={40} className="mr-2" />
                <span className="text-white text-5xl md:text-3xl font-bold">Auto</span>
                <span className="text-red-600 text-5xl md:text-3xl font-bold">Tube</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="hidden md:flex space-x-4 text-lg lg:space-x-8">
        <a href="/" className="hover:text-gray-300">
          Home
        </a>
        <a href="/blog" className="hover:text-gray-300">
          Blog
        </a>
      </div>
      <div className="flex  items-center space-x-4">
      {localStorage.getItem('isAuthenticated') === 'true' ? (
            <Link
              to="/dashboard" // Sign in ke liye path ko update karein
              className="font-medium hiddenbtn"
            >
              <button className="bg-[#2ecc71] text-black px-4 sm:px-6 py-2 rounded-full hover:bg-[#27ae60 text-sm sm:text-base">
                Dashboard
              </button>
            </Link>
          ) : (
            <Link
              to="/login" // Sign in ke liye path ko update karein
              className="font-medium hiddenbtn"
            >
              <button className="bg-[#2ecc71] text-black px-4 sm:px-6 py-2 rounded-full hover:bg-[#27ae60 text-sm sm:text-base">
                Get Started
              </button>
            </Link>
          )}
      </div>
    </nav>
  );
};

export default LandingNavbar;