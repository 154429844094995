import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { youtube } from '../api/Service';

const CLIENT_ID = '316195347494-ns6lmv81p0nseuqe70l6i63mnrsffto4.apps.googleusercontent.com';
const CLIENT_SECRET = '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';
const REDIRECT_URI = 'https://www.shortsgenerator.ai/app/socialmedia';
//const REDIRECT_URI = 'http://localhost:3000/app/socialmedia'
const SCOPES = 'https://www.googleapis.com/auth/youtube.upload https://www.googleapis.com/auth/userinfo.profile';
const google_Auth_Url = `https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&prompt=consent&scope=${encodeURIComponent(SCOPES)}&response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&include_granted_scopes=true`;

const Youtube = () => {
  const [error, setError] = useState(null);
  const [isConnected, setIsConnected] = useState(false);
  const [redirect, setRedirect] = useState(false)
  const navigate = useNavigate();

  const handleLogin = async (code) => {
    if (code) {
      navigate('/app/socialmedia')
    }
    try {
      const dataToSend = { code };
      const responseData = await youtube(dataToSend);
      console.log('Response Data:', responseData);

      if (responseData) {
        setIsConnected(true);
        navigate('/app/socialmedia');
        setTimeout(() => {
          window.location.reload();
        }, 500)
      }
    } catch (error) {
      console.error('Error during YouTube API call:', error);
      setError('Please try again.');
    }
  };

  const handleCallback = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('code');
    if (code) {
      await handleLogin(code);
    }
  };

  useEffect(() => {
    handleCallback();
  }, []);

  return (
    // <div> 
    //   {!isConnected ? (
    //     <a href={google_Auth_Url}>Connect</a>
    //   ) : (
    //     <div>
    //       <button onClick={() => setIsConnected(true)}> Connect to YouTube</button>
    //     </div>
    //   )}
    //   {error && <div style={{ color: 'red', marginTop: '10px' }}>{error}</div>}
    // </div>
    <div>

      {!isConnected ? (
        <div className="text-center text-[#64748b] font-poppins text-sm">
          <a className="text-xl font-bold w-full text-[#FF0000]  " href={google_Auth_Url}>Connect Youtube</a>
          {/* <Link to={google_Auth_Url}>


            <span className="text-xl font-bold w-full text-[#FF0000] ">Connect YouTube</span>
            

          </Link> */}
        </div>
      ) : (
        <div>
          <button onClick={() => setIsConnected(true)}>
            Connect to YouTube
          </button>
        </div>
      )}
      {error && <div style={{ color: 'red', marginTop: '10px' }}>{error}</div>}
    </div>
  );
};

export default Youtube;
