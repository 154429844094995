
import React, { createContext, useState, useContext } from 'react';

const ModalContext = createContext();


export const useModal = () => useContext(ModalContext);


export const ModalProvider = ({ children }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => setIsModalOpen(true);
    const hideModal = () => setIsModalOpen(false);

    return (
        <ModalContext.Provider value={{ isModalOpen, showModal, hideModal }}>
            {children}
        </ModalContext.Provider>
    );
};
