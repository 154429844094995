import React, { useRef, useEffect, useState } from "react";
import DashboardNavbar from "../navbar/DashboardNavbar";
import NavTab from "./NavTab";
import Footer from "../Footer/Footer";
// import Modal from "../modal/modalpopup";

import { MdOutlineAlternateEmail } from "react-icons/md";
// import Youtube from "../navtab/YouTube"
import {
  createVedio,
  generateVideo,
  avatargenerateVideo,
  listVideo,
  Plans,
} from "../api/Service";
import { useNavigate } from "react-router-dom";
import "reactjs-popup/dist/index.css";
import { BiBookOpen } from "react-icons/bi";
import { AiOutlineRobot } from 'react-icons/ai';
import { MdPlayArrow, MdPause, MdInfoOutline, MdLock, MdHelpOutline } from "react-icons/md";
import { DropVoices } from "../../voices/voice";
import { useCookies } from "react-cookie";
import "../style.css?v1";

import Anthonypic from "../../Creators/Anthony-1.png";
import Erica from "../../Creators/Erica.png";
import Kate from "../../Creators/Kate.png";
import Mia from "../../Creators/Mia-1.png";
import Peter from "../../Creators/Peter.png";
import Ava from "../../Creators/vlcsnap-2024-10-03-10h54m02s012.png";
import Evan from "../../Creators/Evan.png";
import George from "../../Creators/George.png";
import Joson from "../../Creators/Joson.png";
import Kira from "../../Creators/Kira.png";
import Ben from "../../Creators/Ben.png";
import Brooke from "../../Creators/brooke.png";
import Elle from "../../Creators/elle.png";
import Grant from "../../Creators/grant-1.png";
import Grant3 from "../../Creators/grant-2.png";
import Norah from "../../Creators/norah.png";
import Rachel from "../../Creators/rachel.png";
import Riley from "../../Creators/riley.png";
import Todd from "../../Creators/todd.png";
import Anthony4 from "../../Creators/Anthony-3.png";
import Loader from "../spinner/Spinner";
import { useModal } from "../../context/ModalContext";
import { useDarkMode } from "../../context/DarkModeContext";
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';

const AvatarCreate = () => {
  const [cookies, setCookie, removeCookie] = useCookies([
    "https://accounts.google.com",
  ]);
  const { darkMode } = useDarkMode();
  const server_App_URl = process.env.REACT_APP_API_URL;
  const { isModalOpen } = useModal()
  const [loading, setLoading] = useState(true);
  const [spinnerLoading, setSpinnerLoading] = useState(false);
  const [series, setSeries] = useState("");
  const [plan, setPlan] = useState("");
  const [script, setScript] = useState("");
  const [avatarscript, setavatarscript] = useState("");
  const [creatorPic, setCreatorPic] = useState("Erica");
  const [preview, setPreview] = useState("Erica.mp4");
  const [isVisible, setIsVisible] = useState(true);
  const [modalMessage, setModalMessage] = useState('')
  const [selectedTemplateId, setSelectedTemplateId] = useState(
    "e7e758de-4eb4-460f-aeca-b2801ac7f8cc"
  );
  const [createLoading, setCreateLoading] = useState(false)

  const [socialmedia, setSocialmedia] = useState('')
  const [postInDay, setPostInDay] = useState('')
  const [isToggled, setIsToggled] = useState(false);
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState('')

  const handleToggle = () => {
    setIsToggled((prev) => !prev);
    // Add any additional actions you want here
  }
  const navigate = useNavigate();

  const Creators = [
    {
      frontName: "Erica",
      name: "Erica",
      image: Erica,
      pre: "Erica.mp4",
      // gifSrc: erica,
      // staticSrc: Erica,
    },

    {
      frontName: "Anthony",
      name: "Anthony-1",
      image: Anthonypic,
      pre: 'Anthony_1.mp4', //"Anthony_1.mp4"
      // gifSrc: Anthony,
      // staticSrc: Anthonypic,
    },
    {
      frontName: "Anthony 2",
      name: "Anthony-3",
      image: Anthony4,
      pre: 'Anthony_3.mp4', //"Anthony_1.mp4"
      // gifSrc: Anthony3,
      // staticSrc: Anthony4,
    },

    {
      frontName: "Kate",
      name: "Kate",
      image: Kate,
      pre: "Kate.mp4",
      // gifSrc: Kate1,
      // staticSrc: Kate,
    },
    {
      frontName: "Mia",
      name: "Mia-1",
      image: Mia,
      pre: "Mia.mp4",
      // gifSrc: Mia1,
      // staticSrc: Mia,
    },
    {
      frontName: "Peter",
      name: "Peter",
      image: Peter,
      pre: "Peter.mp4",
      // gifSrc: Peter1,
      // staticSrc: Peter,
    },
    {
      frontName: "Ava",
      name: "Ava-1",
      image: Ava,
      pre: "Ava.mp4",
      // gifSrc: Ava1,
      // staticSrc: Ava,
    },
    {
      frontName: "Evan",
      name: "Evan-1",
      image: Evan,
      pre: "Evan.mp4",
      // gifSrc: Evan1,
      // staticSrc: Evan,
    },
    {
      frontName: "George",
      name: "George",
      image: George,
      pre: "George.mp4",
      // gifSrc: George1,
      // staticSrc: George,
    },
    {
      frontName: "Jason",
      name: "Jason",
      image: Joson,
      pre: "Joson.mp4",
      // gifSrc: Joson1,
      // staticSrc: Joson,
    },
    {
      frontName: "Kira",
      name: "Kira",
      image: Kira,
      pre: "Kira.mp4",
      // gifSrc: Kira1,
      // staticSrc: Kira,
    },

    // ------> new videos<-------

    {
      frontName: "Ben",
      name: "Ben-1",
      image: Ben,
      pre: "Ben.mp4",
      // gifSrc: Ben1,
      // staticSrc: Ben,
    },
    {
      frontName: "Brooke",
      name: "Brooke-1",
      image: Brooke,
      pre: "Brooke.mp4",
      // gifSrc: Brooke1,
      // staticSrc: Brooke,
    },
    {
      frontName: "Elle",
      name: "Elle",
      image: Elle,
      pre: "Elle.mp4",
      // gifSrc: Elle1,
      // staticSrc: Elle,
    },
    {
      frontName: "Grant",
      name: "Grant-1",
      image: Grant,
      pre: "Grant_1.mp4",
      // gifSrc: Grant1,
      // staticSrc: Grant,
    },
    {
      frontName: "Grant 2",
      name: "Grant-2",
      image: Grant3,
      pre: "Grant_2.mp4",
      // gifSrc: Grant2,
      // staticSrc: Grant3,
    },
    {
      frontName: "Norah",
      name: "Norah-1",
      image: Norah,
      pre: "Norah.mp4",
      // gifSrc: Norah1,
      // staticSrc: Norah,
    },
    {
      frontName: "Rachel",
      name: "Rachel-1",
      image: Rachel,
      pre: "Rachel.mp4",
      // gifSrc: Rachel1,
      // staticSrc: Rachel,
    },
    {
      frontName: "Riley",
      name: "Riley",
      image: Riley,
      pre: "Riley.mp4",
      // gifSrc: Riley1,
      // staticSrc: Riley,
    },
    {
      frontName: "Todd",
      name: "Todd-1",
      image: Todd,
      pre: "Todd.mp4",
      // gifSrc: Todd1,
      // staticSrc: Todd,
    },
  ];

  const planResponse = async () => {
    try {
      const response = await Plans();

      setSeries(response.credit);
      setPlan(response.PlanTypeName);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (loading) {
      planResponse();
    }
  }, [loading]);
  const handlePostInDay = (e) => {
    console.log(e)
    setPostInDay(e)
  }

  const handleGenerate = async () => {
    const contentType = {
      script: script,
      voiceName: creatorPic,
      templateId: selectedTemplateId,
    };
    if (script.length < 10) {
      alert("Script can not be less than 10 charactes!");
      return;
    }
    if (!series) {
      navigate("/app/billing");
      return;
    } else {
      setCreateLoading(true)
      try {
        const response = await generateVideo(contentType);
        localStorage.removeItem('progress')
        console.log(response, "Generate Response");
        if (response) {
          const videoId = response.data.data.response.insertId;
          const userId = response.data.data.UserId;
          navigate("/app/video-preview", {
            state: {
              videoUrl: `/${userId}/${videoId}/output.mp4`,
              videoid: videoId,
              userid: userId
            },
          });
          setCreateLoading(false)
        }
      } catch (error) {
        setCreateLoading(false)
        console.log(error);
        if (
          (error.response && error.response.data) ||
          error.response.data.message === "Un-authorized"
        ) {
          if (error.response.data.message === "Un-authorized") {
            localStorage.clear();
            navigate("/app");
            window.location.reload();

            setModalMessage(
              ` ${error.response.data.message || "Something went wrong"}`
            );
            setShowModal(true)
          }
          else {

            setModalMessage(
              ` ${error.response.data.message || "Something went wrong"}`
            );
            setShowModal(true)

          }
        } else {
          setModalMessage("An unexpected error occurred");
          setShowModal(true)
        }
      }
    }
  };
  const handleGenerateavatar = async () => {

    const contentType = {
      topic: avatarscript,
      style: avatarbtn,
    };
    if (avatarscript.length < 5) {
      alert("Script can not be less than 5 charactes!");
      return;
    }
    if (!series) {
      navigate("/app/billing");
      return;
    } else {
      //hide generate button and show loader button
      setIsVisible(false);
      setSpinnerLoading(true);
      try {
        const response = await avatargenerateVideo(contentType);
        console.log(response, "Generate Response");
        if (response) {
          //show button hide loader
          //get data in and put in script
          //hide ai popup
          //empty script
          setIsVisible(true);
          setSpinnerLoading(false);
          setScript(response.data.data);
          setscriptmodal(false);
          setavatarscript("");

          // navigate("/app/video-preview", {
          //   state: {
          //     videoUrl: `/${userId}/${videoId}/output.mp4`,
          //     videoid: videoId,
          //   },
          // });
        }
      } catch (error) {
        //show button hide loader
        setIsVisible(true);
        setSpinnerLoading(false);

        console.log(error);
        if (
          (error.response && error.response.data) ||
          error.response.data.message === "Un-authorized"
        ) {
          if (error.response.data.message === "Un-authorized") {
            localStorage.clear();
            navigate("/app");
            window.location.reload();

            setModalMessage(
              ` ${error.response.data.message || "Something went wrong"}`
            );
            setShowModal(true)
          }
          else {

            setModalMessage(
              ` ${error.response.data.message || "Something went wrong"}`
            );
            setShowModal(true)

          }
        } else {
          setModalMessage("An unexpected error occurred");
          setShowModal(true)
        }
      }
    }
  };
  const [showModal, setShowModal] = React.useState(false);
  const [scriptmodal, setscriptmodal] = React.useState(false);

  const scriptmodalhandle = (event) => {
    event.preventDefault();
    setscriptmodal(true);
  };

  const handleScriptChange = (value) => {
    if (value.length <= 780) {
      setScript(value);
    }
  };

  const [avatarbtn, setAvatarbtn] = useState("Storytelling");

  const avatarBtnClick = (e) => {
    setAvatarbtn(e);
  };

  const handleScriptChangeavatar = (value) => {
    if (value.length <= 780) {
      setavatarscript(value);
    }
  };

  const [hoveredIndex, setHoveredIndex] = useState(null); // Track hover for each image

  const handleMouseEnter = (index) => {
    setHoveredIndex(index); // Set hovered index when mouse enters
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null); // Reset hover state when mouse leaves
  };
  const Templates = [
    {
      id: "e7e758de-4eb4-460f-aeca-b2801ac7f8cc",
      categories: ["animated", "highlighted"],
      pre: "1.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/e7e758de-4eb4-460f-aeca-b2801ac7f8cc.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/e7e758de-4eb4-460f-aeca-b2801ac7f8cc.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/e7e758de-4eb4-460f-aeca-b2801ac7f8cc.gif",
      },
    },
    {
      id: "21327a45-df89-46bc-8d56-34b8d29d3a0e",
      categories: ["basic"],
      pre: "2.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/21327a45-df89-46bc-8d56-34b8d29d3a0e.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/21327a45-df89-46bc-8d56-34b8d29d3a0e.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/21327a45-df89-46bc-8d56-34b8d29d3a0e.gif",
      },
    },
    {
      id: "a6760d82-72c1-4190-bfdb-7d9c908732f1",
      categories: ["animated"],
      pre: "3.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/a6760d82-72c1-4190-bfdb-7d9c908732f1.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/a6760d82-72c1-4190-bfdb-7d9c908732f1.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/a6760d82-72c1-4190-bfdb-7d9c908732f1.gif",
      },
    },
    {
      id: "982ad276-a76f-4d80-a4e2-b8fae0038464",
      categories: ["basic", "highlighted"],
      pre: "4.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/982ad276-a76f-4d80-a4e2-b8fae0038464.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/982ad276-a76f-4d80-a4e2-b8fae0038464.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/982ad276-a76f-4d80-a4e2-b8fae0038464.gif",
      },
    },
    {
      id: "07ffd4b8-4e1a-4ee3-8921-d58802953bcd",
      categories: ["basic", "highlighted"],
      pre: "5.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/07ffd4b8-4e1a-4ee3-8921-d58802953bcd.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/07ffd4b8-4e1a-4ee3-8921-d58802953bcd.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/07ffd4b8-4e1a-4ee3-8921-d58802953bcd.gif",
      },
    },
    {
      id: "7b946549-ae16-4085-9dd3-c20c82504daa",
      categories: ["basic", "highlighted"],
      pre: "6.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/7b946549-ae16-4085-9dd3-c20c82504daa.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/7b946549-ae16-4085-9dd3-c20c82504daa.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/7b946549-ae16-4085-9dd3-c20c82504daa.gif",
      },
    },
    {
      id: "14bcd077-3f98-465b-b788-1b628951c340",
      categories: ["basic", "highlighted"],
      pre: "7.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/14bcd077-3f98-465b-b788-1b628951c340.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/14bcd077-3f98-465b-b788-1b628951c340.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/14bcd077-3f98-465b-b788-1b628951c340.gif",
      },
    },
    {
      id: "d46bb0da-cce0-4507-909d-fa8904fb8ed7",
      categories: ["animated"],
      pre: "8.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/d46bb0da-cce0-4507-909d-fa8904fb8ed7.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/d46bb0da-cce0-4507-909d-fa8904fb8ed7.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/d46bb0da-cce0-4507-909d-fa8904fb8ed7.gif",
      },
    },
    {
      id: "decf5309-2094-4257-a646-cabe1f1ba89a",
      categories: ["animated"],
      pre: "9.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/decf5309-2094-4257-a646-cabe1f1ba89a.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/decf5309-2094-4257-a646-cabe1f1ba89a.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/decf5309-2094-4257-a646-cabe1f1ba89a.gif",
      },
    },
    {
      id: "dfe027d9-bd9d-4e55-a94f-d57ed368a060",
      categories: ["animated", "highlighted"],
      pre: "10.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/dfe027d9-bd9d-4e55-a94f-d57ed368a060.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/dfe027d9-bd9d-4e55-a94f-d57ed368a060.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/dfe027d9-bd9d-4e55-a94f-d57ed368a060.gif",
      },
    },
    {
      id: "1bb3b68b-6a93-453a-afd7-a774b62cdab8",
      categories: [],
      pre: "11.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/1bb3b68b-6a93-453a-afd7-a774b62cdab8.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/1bb3b68b-6a93-453a-afd7-a774b62cdab8.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/1bb3b68b-6a93-453a-afd7-a774b62cdab8.gif",
      },
    },
    {
      id: "46d20d67-255c-4c6a-b971-31fddcfea7f0",
      categories: ["animated", "highlighted"],
      pre: "12.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/46d20d67-255c-4c6a-b971-31fddcfea7f0.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/46d20d67-255c-4c6a-b971-31fddcfea7f0.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/46d20d67-255c-4c6a-b971-31fddcfea7f0.gif",
      },
    },
    {
      id: "c88bff11-7f03-4066-94cd-88f71f9ecc68",
      categories: ["basic"],
      pre: "13.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/c88bff11-7f03-4066-94cd-88f71f9ecc68.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/c88bff11-7f03-4066-94cd-88f71f9ecc68.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/c88bff11-7f03-4066-94cd-88f71f9ecc68.gif",
      },
    },
    {
      id: "a104df87-5b1a-4490-8cca-62e504a84615",
      categories: ["highlighted"],
      pre: "14.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/a104df87-5b1a-4490-8cca-62e504a84615.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/a104df87-5b1a-4490-8cca-62e504a84615.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/a104df87-5b1a-4490-8cca-62e504a84615.gif",
      },
    },
    {
      id: "ca050348-e2d0-49a7-9c75-7a5e8335c67d",
      categories: ["animated"],
      pre: "15.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/ca050348-e2d0-49a7-9c75-7a5e8335c67d.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/ca050348-e2d0-49a7-9c75-7a5e8335c67d.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/ca050348-e2d0-49a7-9c75-7a5e8335c67d.gif",
      },
    },
    {
      id: "eb5de878-2997-41fe-858a-726e9e3712df",
      categories: ["basic"],
      pre: "16.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/eb5de878-2997-41fe-858a-726e9e3712df.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/eb5de878-2997-41fe-858a-726e9e3712df.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/eb5de878-2997-41fe-858a-726e9e3712df.gif",
      },
    },
    {
      id: "a51c5222-47a7-4c37-b052-7b9853d66bf6",
      categories: ["animated", "highlighted"],
      pre: "17.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/a51c5222-47a7-4c37-b052-7b9853d66bf6.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/a51c5222-47a7-4c37-b052-7b9853d66bf6.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/a51c5222-47a7-4c37-b052-7b9853d66bf6.gif",
      },
    },
    {
      id: "5de632e7-0b02-4d15-8137-e004871e861b",
      categories: ["animated"],
      pre: "18.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/5de632e7-0b02-4d15-8137-e004871e861b.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/5de632e7-0b02-4d15-8137-e004871e861b.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/5de632e7-0b02-4d15-8137-e004871e861b.gif",
      },
    },
    {
      id: "55267be2-9eec-4d06-aff8-edcb401b112e",
      categories: ["highlighted"],
      pre: "19.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/55267be2-9eec-4d06-aff8-edcb401b112e.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/55267be2-9eec-4d06-aff8-edcb401b112e.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/55267be2-9eec-4d06-aff8-edcb401b112e.gif",
      },
    },
    {
      id: "50cdfac1-0a7a-48dd-af14-4d24971e213a",
      categories: ["highlighted"],
      pre: "20.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/50cdfac1-0a7a-48dd-af14-4d24971e213a.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/50cdfac1-0a7a-48dd-af14-4d24971e213a.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/50cdfac1-0a7a-48dd-af14-4d24971e213a.gif",
      },
    },
    {
      id: "cfa6a20f-cacc-4fb6-b1d0-464a81fed6cf",
      categories: ["highlighted"],
      pre: "21.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/cfa6a20f-cacc-4fb6-b1d0-464a81fed6cf.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/cfa6a20f-cacc-4fb6-b1d0-464a81fed6cf.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/cfa6a20f-cacc-4fb6-b1d0-464a81fed6cf.gif",
      },
    },
  ];
  const handleTemplateClick = (id) => {
    setSelectedTemplateId(id);
    console.log("Selected Template ID:", id);
  };


  return (
    <>
      <div className={darkMode ? "bg-black text-white" : "bg-white text-black"}>
        <DashboardNavbar />
        <div className="flex  min-h-screen">
          {!isModalOpen ? (
            <div className="absolute md:relative md:w-[23%] w-[21%] bg text-white">
              <NavTab />
            </div>
          ) : (<div className="absolute md:relative md:w-[23%] w-[21%]  bg-white bg-opacity-50">

          </div>)}
          <div className="flex justify-evenly w-[79%] avatar-main-768 gap-3 items-start">
            <div className={`maindiv  pt-0 flex lg:p-[30px] overflow-x-hidden font-montserrat h-max lg:h-full space-y-6  justify-center  align-items-center pb-20  ${darkMode ? "bg-black" : "bg-gray-100"} text-[#467ff7]`}>
              <div className="main-avatar w-full">
                <div className="flex-col ">
                  <h1 className="createh1 text-center font-bold text-[2.2rem] space-y-6 ">
                    AI Avatar Shorts
                  </h1>
                  <p className="pragcreate text-center">
                    Start generating videos with our AI Avatar Shorts.
                  </p>
                </div>
                <div className={`pt-5 padding-class`}>
                  <div className="flex w-full avatar-video-h flex-col  md:w-100% mx-auto space-y-4 md:space-y-0 md:space-x-4">
                    {/* Form Section */}
                    <div className={`${darkMode ? "bg-black" : "bg-gray-100"} layout-cantent  w-full flex rounded-md`}>
                      <div className={`name ${darkMode ? "bg-black" : "bg-gray-100"} w-[80%] p-[12px] lg:p-6 space-y-4`}>
                        <form action="" className="mx-auto form-w">
                          <div className="mx-auto">
                            <div className="flex mb-2 justify-content-between p-1">
                              <label
                                htmlFor="message"
                                className="mb-2 text-sm font-medium text-gray-900 dark:text-white"
                              >
                                Enter Your Script
                              </label>
                              <button
                                onClick={(e) => scriptmodalhandle(e)}
                                className="flex justify-center items-center font-bold hover:bg-[#467ff7] rounded-lg bg-[#aec5f777] border-3 border-[#467ff7] hover:text-white text-[#3872f1]"
                              >
                                <div className="flex p-1 text-sm hover:text-white text-[#467ff7]">
                                  <AiOutlineRobot className=" mt-1 mr-1 " />
                                  <p>
                                    AI script writer
                                  </p>
                                </div>
                              </button>

                              {scriptmodal ? (
                                <div className="justify-center fixed inset-0 bg-black bg-opacity-50 z-50 items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                                  <div className="relative avatar-popup-responsive my-6 mx-auto">
                                    {/*content*/}
                                    <div className={`${darkMode ? "border-2 border-gray-400" : ""} shadow-unsubscription-modal avatar-w pr-7 pb-3 pl-7 pt-[15px] rounded-3xl shadow-lg relative flex flex-col w-full ${darkMode ? "bg-black" : "bg-gray-100"} outline-none focus:outline-none`}>
                                      <div className="flex justify-between  pb-2 pt-0 rounded-t">

                                        {/*header*/}
                                        <div className="flex w-full justify-start items-center">
                                          <p className={`${darkMode ? "text-white" : "text-black"} font-medium text-[16px] leading-[20px] font-sans`}>
                                            Let AI generate script for you
                                          </p>
                                          <MdHelpOutline className="text-[#467ff7] ml-1" />
                                        </div>
                                        <button
                                          className={`ml-auto ${darkMode ? "text-white" : "text-black"}  opacity-2 float-right`}
                                          type="button" // Prevents default behavior
                                          onClick={() => setscriptmodal(false)} // Close modal on click
                                        >
                                          <span
                                            style={{ fontSize: "35px" }}

                                          >
                                            ×
                                          </span>
                                        </button>
                                      </div>
                                      {/*body*/}
                                      <div className="w-full">
                                        <div className="flex justify-center items-center ">
                                          <div className="rounded w-full h-full justify-center">
                                            <div className="rounded  w-full justify-center ">
                                              <textarea
                                                id="message"
                                                rows="10"
                                                className="p-2.5 w-full h-[200px] text-sm text-gray-900 bg-gray-50 rounded-2xl border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-black dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                placeholder="Enter your prompt here... (e.g. Write a script about Tesla Model X)"
                                                value={avatarscript}
                                                maxLength={780}
                                                onChange={(e) =>
                                                  handleScriptChangeavatar(
                                                    e.target.value
                                                  )
                                                }
                                              ></textarea>
                                            </div>
                                            <div className="text-start justify-content-center">
                                              <div className={`${darkMode ? "text-white" : "text-black"} mt-1 mb-3 font-bold font-sans`}>
                                                <p>Script style</p>
                                              </div>
                                              <div className="flex avatar-buttons justify-content-between">
                                                <button
                                                  onClick={(e) =>
                                                    avatarBtnClick(
                                                      e.target.innerText
                                                    )
                                                  }
                                                  className={`m-1 w-[140px] pt-[11px] pb-[11px]  rounded-md font-normal text-xs text-white font-sans ${avatarbtn === null ? "bg-[#467ff7] " : (
                                                    avatarbtn === "Storytelling"
                                                      ? "bg-[#467ff7] " // Gradient for active button
                                                      : "bg-gray-400 hover:bg-gray-500") // Blue for inactive button
                                                    }`}
                                                  type="button"
                                                >
                                                  Storytelling
                                                </button>

                                                <button
                                                  onClick={(e) =>
                                                    avatarBtnClick(
                                                      e.target.innerText
                                                    )
                                                  }
                                                  className={`m-1 w-[140px] pt-[11px] pb-[11px] rounded-md text-md text-xs text-white font-sans font-normal ${avatarbtn === "Promotional"
                                                    ? "bg-[#467ff7]"
                                                    : "bg-gray-400 hover:bg-gray-500"
                                                    }`}
                                                  type="button"
                                                >
                                                  Promotional
                                                </button>

                                                <button
                                                  onClick={(e) =>
                                                    avatarBtnClick(
                                                      e.target.innerText
                                                    )
                                                  }
                                                  className={`m-1 w-[140px] pt-[11px] pb-[11px] rounded-md text-md text-white font-normal text-xs  font-sans ${avatarbtn === "Exploration"
                                                    ? "bg-[#467ff7]"
                                                    : "bg-gray-400 hover:bg-gray-500"
                                                    }`}
                                                  type="button"
                                                >
                                                  Exploration
                                                </button>

                                                <button
                                                  onClick={(e) =>
                                                    avatarBtnClick(
                                                      e.target.innerText
                                                    )
                                                  }
                                                  className={`m-1 w-[140px]  text-xs pt-[11px] pb-[11px] font-sans rounded-md text-md text-white font-normal ${avatarbtn === "Motivational"
                                                    ? "bg-[#467ff7]"
                                                    : "bg-gray-400 hover:bg-gray-500"
                                                    }`}
                                                  type="button"
                                                >
                                                  Motivational
                                                </button>
                                              </div>
                                            </div>
                                            <div className="mt-6 flex  items-center justify-center space-x-10 p-2">
                                              {isVisible && (
                                                <button
                                                  onClick={() =>
                                                    handleGenerateavatar()
                                                  }
                                                  className="m-1 w-75 h-[44px] p-1 rounded-md	bg-[#467ff7]  text-md text-white font-normal"
                                                  type="button"
                                                >
                                                  Generate
                                                </button>
                                              )}

                                              {spinnerLoading && (
                                                <div className="text-center py-4">
                                                  <Loader />
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                    </div>
                                    {/*footer*/}
                                  </div>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>

                            <textarea
                              id="message"
                              rows="10"
                              className="p-2.5 w-full h-[320px] text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-black dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder="Enter your script..."
                              value={script}
                              maxLength={780}
                              onChange={(e) =>
                                handleScriptChange(e.target.value)
                              }
                            ></textarea>
                            <div>
                              <p className="text-gray-500 text-sm mt-2">
                                Note: Your script must not exceed 800
                                characters.
                              </p>
                            </div>

                            {/* <div className="mt-4">
                            <label
                              htmlFor="Select Voice"
                              className="text-sm font-medium text-gray-700"
                            > */}
                            {/* <h1 className="font-bold text-[16px] text-[#467ff7] pt-1">
                                Choose Creator
                              </h1> */}
                            {/* </label> */}
                            {plan === "Free" && series === 1 ?
                              (<div className="flex gap-1 overflow-x-auto">
                                {Creators.map((creator, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="flex gap-4 p-2 mb-5"
                                    >
                                      <div
                                        className={`w-[150px] h-[180px] relative group flex-col gap-2 hover:cursor-pointer`}
                                        onClick={() => {
                                          if (creator.name === 'Erica') {
                                            setCreatorPic(creator.name)
                                          }
                                        }
                                        }
                                      >
                                        <div className={`flex items-center ${darkMode ? "text-white" : "text-gray-400"} text-xs font-semibold`}>
                                          <span>{creator.name}</span>
                                          {creator.name !== "Erica" && <MdLock className="text-gray-700 text-2xl ml-1" />}
                                        </div>

                                        <div className="relative h-[100%]">
                                          <img
                                            loading="lazy"
                                            width="150"
                                            height="150"
                                            decoding="async"
                                            className={`h-full rounded-lg object-cover shadow-md outline-2 ${creatorPic === creator.name
                                              ? "outline outline-blue-500"
                                              : ""
                                              }${creator.name !== "Erica" ? "pointer-events-none" : ""}`}
                                            srcSet={creator.image}
                                            src={creator.image}
                                            alt={creator.name}
                                          />
                                          {/* {creator.name !== "Erica" && (
                                            <div className="absolute inset-0 flex items-center justify-center">
                                              <MdLock className="text-gray-700 text-2xl" />
                                            </div>
                                          )} */}
                                        </div>
                                        <a
                                          onClick={() => setPreview(creator.pre)}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="absolute inset-0 flex items-end justify-center text-white font-bold bg-opacity-50 hover:bg-opacity-75 transition-opacity hover:cursor-pointer"
                                        >
                                          Click to Preview
                                        </a>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>) : (<div className="flex gap-1 overflow-x-auto">
                                {Creators.map((creator, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="flex gap-4 p-2 mb-5"
                                    >
                                      <div
                                        className={`w-[150px] h-[180px] relative group flex-col gap-2 hover:cursor-pointer`}
                                        onClick={() =>
                                          setCreatorPic(creator.name)
                                        }
                                      >
                                        <div className={`flex items-center ${darkMode ? "text-white" : "text-gray-400"} text-xs font-semibold`}>
                                          {creator.name}
                                        </div>
                                        <img
                                          loading="lazy"
                                          width="150"
                                          height="150"
                                          decoding="async"
                                          className={`h-full rounded-lg object-cover shadow-md outline-2 ${creatorPic === creator.name
                                            ? "outline outline-blue-500"
                                            : ""
                                            }`}
                                          srcSet={creator.image}
                                          src={creator.image}
                                          alt={creator.name}
                                        />
                                        <a
                                          onClick={() => setPreview(creator.pre)}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                          className="absolute inset-0 flex items-end justify-center text-white font-bold bg-opacity-50 hover:bg-opacity-75 transition-opacity hover:cursor-pointer"
                                        >
                                          Click to Preview
                                        </a>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>)}

                            <div className={`templates-section p-4 ${darkMode ? "bg-black" : "bg-white"} rounded-md shadow-sm mt-2`}>
                              <div>
                                <h2 className="text-xl font-bold mb-4">
                                  Templates
                                </h2>
                              </div>
                              <div className="flex overflow-x-auto">
                                {Templates.map((template) => (
                                  <div
                                    key={template.id}
                                    className="flex flex-col items-center"
                                  >
                                    <div
                                      className="template-card ml-2 w-[250px]  cursor-pointer"
                                      onClick={() =>
                                        handleTemplateClick(template.id)
                                      }
                                    >
                                      <img
                                        src={template.previews.previewGif}
                                        alt="Template Preview"
                                        className={`rounded-lg  object-cover shadow-md outline-2 ${selectedTemplateId === template.id
                                          ? "outline outline-blue-500"
                                          : ""
                                          }`}
                                      />

                                    </div>

                                  </div>
                                ))}
                              </div>


                            </div>
                            
                          </div>
                        </form>

                        {/* {showModal ? (
                          <>
                            <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                              <div className="relative w-auto my-6 mx-auto max-w-3xl"> */}
                        {/*content*/}

                        {/* <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                  <div className="flex justify-end">
                                    <button
                                      className="ml-auto text-black fixed opacity-2 float-right  "
                                      onClick={() => setShowModal(false)}
                                    >
                                      <span
                                        style={{ fontSize: "35px" }}
                                        className="text-black pr-5"
                                      >
                                        ×
                                      </span>
                                    </button>
                                  </div> */}
                        {/*header*/}
                        {/* <div className="flex items-center  flex-col justify-between p-5 pb-2 pt-0 rounded-t">
                                    <div className="w-40">
                                      <img
                                        src={image2}
                                        alt="warnig img"
                                        className="w-full"
                                      />
                                    </div>
                                    <div className="flex w-full justify-center items-center ">
                                      <h3 className="text-3xl font-bold text-black">
                                        Oops!
                                      </h3>
                                    </div>
                                  </div> */}
                        {/*body*/}
                        {/* <div className="w-full">
                                    <div className="flex justify-center items-center pt-17">
                                      <div className="rounded w-75 h-full justify-center bg-red-100">
                                        <div className="rounded p-2 flex justify-center bg-red-100">
                                          <div className="pt-0">
                                            <MdInfoOutline
                                              className="mr-1 text-black "
                                              size={35}
                                            ></MdInfoOutline>
                                          </div>

                                          <p className="flex text-md font-bold  leading-relaxed text-bold text-black">
                                            Video creation is no longer
                                            available on the free plan due to
                                            increased demand. We are
                                            prioritizing paid members. You can
                                            check the preview of each video on
                                            the right-hand side.
                                          </p>
                                        </div>
                                        <div className="text-center flex justify-content-center">
                                          <button className="w-[400px] p-2 text-md font-bold">
                                            <a
                                              className="text-black text-md text-blue-500"
                                              href="https://www.shortsgenerator.ai/step/unlimited-plan-2/"
                                              target="_blank"
                                            >
                                              <span className="hover:underline-2 text-md  hover:underline-blue-500 hover:underline">
                                                GET OUR UNLIMITED VIDEO PLAN ON
                                                A DISCOUNT - OFFER EXPIRES TODAY
                                              </span>
                                            </a>
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="flex items-center justify-end p-6 rounded-b">
                                    <button
                                      className="text-black background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                      type="button"
                                      onClick={() => setShowModal(false)}
                                    >
                                      Close
                                    </button>
                                  </div>
                                </div> */}
                        {/*footer*/}
                        {/* </div>
                            </div>
                            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                          </>
                        ) : null} */}

                        <button
                          onClick={() => handleGenerate()}
                          className="flex justify-center items-center rounded-md w-full bg-[#467ff7] p-2 text-white"
                        >{createLoading ? "Generating..." : "Generate Video"}

                        </button>
                      </div>
                      {showModal ? (
                        <>
                          <div className="fixed inset-0 z-50 flex items-center justify-center overflow-hidden">
                            <div className="relative w-full max-w-3xl bg-white rounded-lg shadow-lg">
                              {/* Modal Content */}
                              <div className="p-6">
                                <h1 className="text-lg font-bold">{modalMessage}</h1>
                              </div>

                              {/* Modal Footer */}
                              <div className="flex items-center justify-end p-4">
                                <button
                                  className="px-4 py-2 text-sm font-bold text-black transition-all duration-150 ease-linear bg-transparent rounded focus:outline-none"
                                  type="button"
                                  onClick={() => setShowModal(false)}
                                >
                                  Close
                                </button>
                              </div>
                            </div>
                          </div>

                        </>
                      ) : null}
                      {/* <div className="name w-50 p-[12px] lg:p-6 overflow-y-scroll space-y-4">
                        <div className="flex flex-cols-respo grid grid-cols-3">
                          {Creators.map((creator, index) => {
                            return (
                              <div>

                              </div>
                              // <div
                              //   key={index}
                              //   onClick={() => setCreatorPic(creator.name)}
                              //   className="flex gap-4 margin-set p-2"
                              // >
                              //   <div
                              //     onMouseEnter={() => handleMouseEnter(index)} // Pass the index here
                              //     onMouseLeave={handleMouseLeave}
                              //     className="w-[150px] h-[180px] relative group flex-col gap-2 hover:cursor-pointer"
                              //   >
                              //     <img
                              //       loading="lazy"
                              //       width="150"
                              //       height="150"
                              //       decoding="async"
                              //       className={`h-full rounded-lg object-cover shadow-md outline-2 ${
                              //         creatorPic === creator.name
                              //           ? "outline outline-blue-500"
                              //           : ""
                              //       }`}
                              //       // Show the GIF only for the hovered index
                              //       srcSet={
                              //         hoveredIndex === index
                              //           ? creator.gifSrc
                              //           : creator.staticSrc
                              //       }
                              //       alt={creator.name}
                              //     />
                              //     <a
                              //       onClick={() => setPreview(creator.pre)}
                              //       target="_blank"
                              //       rel="noopener noreferrer"
                              //       className="absolute inset-0 flex items-end justify-start text-white font-bold bg-opacity-50 hover:bg-opacity-75 transition-opacity hover:cursor-pointer"
                              //     >
                              //       {creator.frontName}
                              //     </a>
                              //   </div>
                              // </div>
                            );
                          })}
                        </div>
                      </div> */}
                      {/* Video Section */}
                      <div className="h-[410px] w-[20%] mt-[55px]  example-video justify-between">
                        <div className="example-video-1">
                          <div className="">
                            <h5 className="text-[#899493]">
                              Output Example
                            </h5>
                          </div>
                          <div className="h-full  ">
                            <video
                              key={preview}
                              className=" rounded-md"
                              autoPlay
                              controls
                              loop
                              muted
                              preload={"auto"}
                            >
                              <source
                                className="rounded-md"
                                src={`${server_App_URl}/media/creatordefaults/${preview}`}
                              />
                            </video>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default AvatarCreate;
