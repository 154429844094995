import React, { useState, useEffect } from "react";
import { GiArtificialIntelligence } from "react-icons/gi";
import { FaUserCircle, FaLock } from "react-icons/fa";
import { RiErrorWarningLine } from "react-icons/ri";
import { GoogleForm, SignUpForm, handleOtp } from "./api/Service";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "./navbar/Navbar";
import { gapi } from "gapi-script";
import Loader from "./spinner/Spinner";
import { GoogleLogin, googleLogout } from "@react-oauth/google";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import logo from '../components/icon.png'
import loho from "../loho.png"

const SignUp = ({ setIsAuthenticated }) => {
  const clientid = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  // console.log(clientid)

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  // const [Googleerror, setGoogleError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState(false);
  const [messageError, setMessageError] = useState(null);
  const [focusedField, setFocusedField] = useState(null);
  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState(null);
  const [sentOtp, setSentOtp] = useState(false);
  const [otpError, setOtpError] = useState(null);
  const [userError, setUserError] = useState(null);
  const [otpLoading, setOtpLoading] = useState(false)
  const [ref, setRef] = useState(null)
  const navigate = useNavigate();

  const validateEmail = (email) => {
    return email.includes("@");
  };
  const handleCallback = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('ref');
    if (code) {
      setRef(code)
    } else {
      setRef(null)
    }
  };

  useEffect(() => {
    handleCallback();
  }, []);

  const submitOtp = async () => {
    setOtpLoading(true)
    try {
      if (!email || !validateEmail(email)) {
        setEmailError("Please enter your email correctly");
        return false;
      } else {
        setEmailError(null);
        const res = await handleOtp(email);
        console.log("-------", email);

        console.log("Response", res.data.data);
        if (res.data.data.success === true) {
          setOtpLoading(false)
          setSentOtp(true);
          setUserError(null);
        } else if (res.data.data.success === false) {
          setSentOtp(false);
        }
      }
    } catch (error) {
      if (error) {
        setOtpLoading(false)
        setUserError(error.response.data.message);
        console.log(error);
      } else {
        //onsole.log('erororororoor', userError)
        setUserError(null);
      }
    }
  };
  const handleOtpChange = (e) => {
    console.log(e.target.value, "--------");
    setOtp(e.target.value);
    const isValid = /^\d{6}$/.test(e.target.value);
    if (!isValid && otp !== "") {
      setOtpError("Invalid OTP");
    } else {
      setOtpError(null);
    }
  };

  // ... existing code ...

  const handleSubmit = async (event) => {
    event.preventDefault();
    // ... existing validation code ...

    setLoading(true);

    try {
      let payload = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
        otp: otp,
        affiliate_id: ref
      };

      const response = await SignUpForm(payload);
      if (response && response.data) {
        console.log('Response', response);

        // Set authentication state and token
        setIsAuthenticated(true);
        localStorage.setItem("isAuthenticated", "true");
        localStorage.setItem("token", response.data.token); // Add this if your API returns a token
        localStorage.setItem("user", JSON.stringify(response.data)); // Store user data if needed

        // Navigate to dashboard after successful signup
        navigate("/dashboard");
      }
    } catch (err) {
      console.error("Signup Error:", err);
      setMessageError(err.response?.data?.message || "Signup failed. Please try again.");
      setLoading(false);
    }
  };

  useEffect(() => {
    function start() {
      gapi.client.init({
        clientId: clientid,
        scope: "",
      });
    }
    gapi.load("client:auth2", start);
  }, [clientid]);

  return (
    <>
      <div className="">

      </div>
      <div className="min-h-screen bg-[#0a0a1f] relative overflow-hidden">
        <div className="fixed top-20 right-4 z-50 w-[380px]">
          {(otpError || emailError || userError || passwordError || confirmPasswordError || messageError) && (
            <div className="flex flex-col gap-2">
              {/* OTP Error */}
              {otpError && (
                <div className="flex items-center gap-3 p-4 bg-[#1a1a1a] border-l-4 border-[#ff3b3b] rounded-lg shadow-xl animate-slideLeft backdrop-blur-lg bg-opacity-95">
                  <div className="flex-shrink-0">
                    <div className="w-9 h-9 rounded-full bg-[#ff3b3b]/10 flex items-center justify-center">
                      <RiErrorWarningLine className="text-[#ff3b3b] text-xl" />
                    </div>
                  </div>
                  <div className="flex-1 min-w-0">
                    <div className="font-roboto text-[14px] text-white/90 font-medium">
                    {otpError}
                    </div>
                  </div>
                  <button
                    onClick={() => setOtpError(null)}
                    className="self-start p-1 hover:text-white/90 text-white/50 transition-colors"
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              )}

              {/* Email Error */}
              {emailError && (
                <div className="flex items-center gap-3 p-4 bg-[#1a1a1a] border-l-4 border-[#ff3b3b] rounded-lg shadow-xl animate-slideLeft backdrop-blur-lg bg-opacity-95">
                  <div className="flex-shrink-0">
                    <div className="w-9 h-9 rounded-full bg-[#ff3b3b]/10 flex items-center justify-center">
                      <RiErrorWarningLine className="text-[#ff3b3b] text-xl" />
                    </div>
                  </div>
                  <div className="flex-1 min-w-0">
                    <div className="font-roboto text-[14px] text-white/90 font-medium">
                      Email Error
                    </div>
                    <div className="font-roboto text-[13px] text-white/70 mt-0.5">
                      {emailError}
                    </div>
                  </div>
                  <button
                    onClick={() => setEmailError(null)}
                    className="self-start p-1 hover:text-white/90 text-white/50 transition-colors"
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              )}

              {/* User Error */}
              {userError && (
                <div className="flex items-center gap-3 p-4 bg-[#1a1a1a] border-l-4 border-[#ff3b3b] rounded-lg shadow-xl animate-slideLeft backdrop-blur-lg bg-opacity-95">
                  <div className="flex-shrink-0">
                    <div className="w-9 h-9 rounded-full bg-[#ff3b3b]/10 flex items-center justify-center">
                      <RiErrorWarningLine className="text-[#ff3b3b] text-xl" />
                    </div>
                  </div>
                  <div className="flex-1 min-w-0">
                    <div className="font-roboto text-[14px] text-white/90 font-medium">
                      Error
                    </div>
                    <div className="font-roboto text-[13px] text-white/70 mt-0.5">
                      {userError}
                    </div>
                  </div>
                  <button
                    onClick={() => setUserError(null)}
                    className="self-start p-1 hover:text-white/90 text-white/50 transition-colors"
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              )}

              {/* Password Error */}
              {passwordError && (
                <div className="flex items-center gap-3 p-4 bg-[#1a1a1a] border-l-4 border-[#ff3b3b] rounded-lg shadow-xl animate-slideLeft backdrop-blur-lg bg-opacity-95">
                  <div className="flex-shrink-0">
                    <div className="w-9 h-9 rounded-full bg-[#ff3b3b]/10 flex items-center justify-center">
                      <RiErrorWarningLine className="text-[#ff3b3b] text-xl" />
                    </div>
                  </div>
                  <div className="flex-1 min-w-0">
                    <div className="font-roboto text-[14px] text-white/90 font-medium">
                      Password Error
                    </div>
                    <div className="font-roboto text-[13px] text-white/70 mt-0.5">
                      {passwordError}
                    </div>
                  </div>
                  <button
                    onClick={() => setPasswordError("")}
                    className="self-start p-1 hover:text-white/90 text-white/50 transition-colors"
                  >
                    <i className="fas fa-times"></i>
                  </button>
                </div>
              )}

              {/* Other error messages follow the same pattern */}
            </div>
          )}

          <style jsx global>{`
        @keyframes slideLeft {
          from {
            transform: translateX(100%);
            opacity: 0;
          }
          to {
            transform: translateX(0);
            opacity: 1;
          }
        }
        .animate-slideLeft {
          animation: slideLeft 0.3s cubic-bezier(0.16, 1, 0.3, 1) forwards;
        }
      `}</style>
        </div>

        <div className="absolute inset-0 bg-[#0a0a1f] bg-center [mask-image:linear-gradient(180deg,white,rgba(255,255,255,0))]"></div>

        <div className="relative z-10 flex flex-col items-center justify-center min-h-screen px-4">

          <div className="flex items-center gap-1">
            <Link to="/" className="flex items-center gap-1">
              <div className="h-12 w-12 rounded-xl flex items-center justify-center">
                <img src={loho} alt="Logo" width={50} className="fas fa-robot text-2xl text-white animate-pulse" />
              </div>
              <span className="text-white text-7xl md:text-5xl font-bold">Auto</span>
              <span className="text-red-600 text-7xl md:text-5xl font-bold">Tube</span>
            </Link>
          </div>
          <div class="text text-xl font-light text-[#6fee95] my-3">Welcome! 👋</div>
          <div className="max-w-[400px] w-full backdrop-blur-xl bg-[#1a1a2e] p-8 rounded-2xl shadow-2xl border border-white/20 animate-slideIn">
            <div className="flex justify-center mb-8">
              {/* <div className="flex items-center gap-1">
              <div className="h-12 w-12 rounded-xl flex items-center justify-center">
              <img src={loho} alt="Logo" width={40} className=" fas fa-robot text-2xl text-white animate-pulse" />
              </div>
              <h1 className="text-3xl font-bold p-2 bg-gradient-to-r from-white to-purple-200 bg-clip-text text-transparent">
                Sign Up
              </h1>
            </div> */}
            </div>
            <form className="space-y-6" onSubmit={handleSubmit}>
              {/* <div className="grid grid-cols-2 gap-4">
              <div
                className="relative group animate-fadeIn"
                style={{ animationDelay: "0.2s" }}
              >
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  onFocus={() => setFocusedField("firstName")}
                  onBlur={() => setFocusedField(null)}
                  className="w-full px-4 py-3 bg-white/5 border border-white/10 rounded-lg focus:outline-none focus:border-indigo-500 text-white transition-all hover:bg-white/10"
                  placeholder="First Name"
                />
              </div>

              <div
                className="relative group animate-fadeIn"
                style={{ animationDelay: "0.3s" }}
              >
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  onFocus={() => setFocusedField("lastName")}
                  onBlur={() => setFocusedField(null)}
                  className="w-full px-4 py-3 bg-white/5 border border-white/10 rounded-lg focus:outline-none focus:border-indigo-500 text-white transition-all hover:bg-white/10"
                  placeholder="Last Name"
                />
              </div>
            </div> */}

              <div className="relative group">
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onFocus={() => setFocusedField("email")}
                  onBlur={() => setFocusedField(null)}
                  className="w-full px-4 py-3 bg-white/5 border border-white/10 rounded-lg focus:outline-none focus:border-indigo-500 text-white transition-all"
                  placeholder="Email"
                />
              </div>


              <div className="relative group">
                <input
                  type="text"
                  maxLength="6"
                  // onChange={(e) => setOtp(e.target.value)}
                  id="otp"
                  name="otp"
                  value={otp}
                  onChange={handleOtpChange}
                  onFocus={() => setFocusedField("otp")}
                  onBlur={() => setFocusedField(null)}
                  className="w-full px-4 py-3 bg-white/5 border border-white/10 rounded-lg focus:outline-none focus:border-indigo-500 text-white transition-all"
                  placeholder="OTP"
                />
                <button
                  type="button"
                  onClick={() => !otpLoading && submitOtp()}
                  className="absolute right-3 top-1/2 -translate-y-1/2 text-sm text-indigo-400 hover:text-[#2ecc71]"
                >
                  {otpLoading ? "Sending..." : "Get OTP"}
                </button>
              </div>
              <div className="relative group">
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  onFocus={() => setFocusedField("password")}
                  onBlur={() => setFocusedField(null)}
                  className="w-full px-4 py-3 bg-white/5 border border-white/10 rounded-lg focus:outline-none focus:border-indigo-500 text-white transition-all"
                  placeholder="Password"
                />
              </div>
              {/* <div className="relative group">
              <input
                type="password"
                id="confirm-password"
                name="confirm-password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                onFocus={() => setFocusedField("confirm-password")}
                onBlur={() => setFocusedField(null)}
                className="w-full px-4 py-3 bg-white/5 border border-white/10 rounded-lg focus:outline-none focus:border-indigo-500 text-white transition-all"
                placeholder="Confirm Password"
              />
            </div> */}

              <button
                type="submit"
                disabled={loading}
                className="w-full py-3 px-4 bg-gradient-to-r from-[#2ecc71] to-[#06b6d4] text-white rounded-lg font-medium hover:opacity-90 transition-all transform hover:scale-[1.02] active:scale-[0.98] disabled:opacity-50"
              >
                {loading ? "Creating Account..." : "Create Account"}
              </button>
            </form>

            <div className="mt-6 text-center text-white/60">
              Already have an account?
              <Link to="/login">
                <span className="text-[#2ecc71] hover:text-[#06b6d4] font-medium ml-1 transition-colors duration-300"> Sign In</span>
              </Link>
            </div>
          </div>
        </div>
        <style jsx global>{`
        @keyframes fadeIn {
          from { opacity: 0; transform: translateY(20px); }
          to { opacity: 1; transform: translateY(0); }
        }
        @keyframes float {
          0% { transform: translateY(0px) rotate(0deg); }
          50% { transform: translateY(-10px) rotate(5deg); }
          100% { transform: translateY(0px) rotate(0deg); }
        }
        @keyframes slideIn {
          0% { opacity: 0; transform: translateX(-100px); }
          100% { opacity: 1; transform: translateX(0); }
        }
        @keyframes pulse {
          0% { transform: scale(1); }
          50% { transform: scale(1.05); }
          100% { transform: scale(1); }
        }
        .animate-fadeIn {
          animation: fadeIn 0.6s ease-out forwards;
        }
        .animate-float {
          animation: float 3s ease-in-out infinite;
        }
        .animate-slideIn {
          animation: slideIn 0.8s ease-out forwards;
        }
        input:focus {
          animation: pulse 0.5s ease-out;
        }
      `}</style>
      </div>
    </>
  );
};
export default SignUp;
