import React, { useEffect, useState } from "react";
import DashboardNavbar from "../navbar/DashboardNavbar";
import NavTab from "./NavTab";
import Footer from "../Footer/Footer";
import billingimg from "../images/billing.png";
import Card from "../card/Card";
import "../style.css";
import { Plans, ReactivateSubscription, SubcriptionCancel } from "../api/Service";
import { useModal } from "../../context/ModalContext";
import { useDarkMode } from "../../context/DarkModeContext";

const Billing = () => {
  const themefile = localStorage.getItem('theme');
  console.log(themefile ,"theme files");
  
  const [plan, setPlan] = useState("");
  const { isModalOpen } = useModal();
  const [series, setSeries] = useState("");
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState("");
  const [generated, setGenerated] = useState("");
  const [today, setToday] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [popup, setpopup] = useState(false);
  const email = localStorage.getItem("email");
  const [subscription, setsubscription] = useState("");
  const [status, setstatus] = useState(false);
  const { darkMode } = useDarkMode();
  const [reactivateModal, setReactivateModal] = useState(false)

  const planResponse = async () => {
    try {
      const response = await Plans();
      setPlan(response.PlanTypeName);
      setSeries(response.creditPoints);
      setTotal(response.credit);
      setGenerated(response.totalvideoGenerated);
      setToday(response.todayGenerateVideoCount);
      setStartDate(response.startPlanformatDate);
      setEndDate(response.plainEpiryFormatDate);
      setsubscription(response.subscriptionStatus);
      console.log(subscription ,"subscription cancel ");
      if (response.subscriptionStatus === 'wc-cancelled') {
        setReactivateModal(true)
      } else {
        setReactivateModal(false)
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (loading) {

      planResponse();

      const intervalId = setInterval(() => {
        planResponse();
      }, 30000);

      return () => clearInterval(intervalId);
    }
  }, [loading]);


  const handleCancelClick = () => {
    setpopup(true);
  };

  const onConfirm = async () => {
    try {
      let payload = {
        email: email,
      };
      const res = await SubcriptionCancel(payload);
      if (res.status === 200) {
        setstatus(true);
      }
      console.log(res, "api res");
      setpopup(false);
      localStorage.setItem("Subscription", "");
    } catch (error) {
      console.log(error, "Error in canceling subscription");
    }
  };
  const MembershipReactivate = async () => {
    const data = {
      email: email,
    }
    try {
      const res = await ReactivateSubscription(data)
      if (res.status) {
        setReactivateModal(false)
      }
    } catch (error) {
      console.log(error)
    }

  }

  return (
    <div className={`min-h-screen`}>
      <DashboardNavbar />
      <div className="flex md:h-max h-[max] mx-auto justify-center items-center">
        {popup && (
          <div className={`fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50`}>
            <div className={`  p-6 rounded-md shadow-md   ${themefile === "dark" ? "bg-black border " : "bg-white"}`}>
              <h2 className="text-lg font-bold mb-4">Confirm Cancellation</h2>
              <p>Are you sure you want to cancel your subscription?</p>
              <div className="flex justify-end mt-4 space-x-2">
                <button
                  onClick={() => setpopup(false)}
                  className={`  px-4 py-2 rounded-md`}
                >
                  No
                </button>
                <button
                  onClick={() => onConfirm()}
                  className="px-4 py-2 bg-red-500   rounded-md"
                >
                  Yes, Cancel
                </button>
              </div>
            </div>
          </div>
        )}
        {reactivateModal &&
          (<div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="  rounded-lg shadow-lg max-w-md w-full px-6 py-8 relative">

              <button
                onClick={() => { setReactivateModal(false) }}
                className="absolute top-3 right-3 text-gray-500 hover:text-gray-800"
                aria-label="Close Modal"
              >
                ✕
              </button>

              <div className={`text-center  `}>
                <h1 className="font-poppins text-2xl mb-4 text-gray-800">
                  No worries! Your videos and progress are saved.
                </h1>
                <p className="font-poppins text-gray-600 mb-8">
                  Just reactivate your membership to keep creating videos.
                </p>
                <button onClick={() => MembershipReactivate()} className="bg-blue-600 hover:bg-blue-700   font-poppins py-3 px-8 rounded-lg transition-colors duration-200">
                  Reactivate Membership
                </button>
              </div>
            </div>
          </div>)}

        <div
          className=" w-full">
          <div className={`space-y-6 min-h-screen w-full md:p-12 p-[0.5rem] text-sm  overflow-auto`}>

            <h5 className="font-bold text-md md:text-lg pb-2   border-b-2 border-gray-500 capitalize w-max">
              CURRENT PLAN
            </h5>

            <div className={`flex align-content-center p-3 space-y-1 rounded-lg border-2 border-gray-500 shadow-md`}>
              <div className="grid grid-cols-3 gap-4">
                <div>
                  <h1 className={`font-bold    mb-2`}>
                    Current Plan:{" "}
                    <span className={`font-normal  `}>{plan}</span>
                  </h1>
                </div>

                <div>
                  <h1 className={`font-bold   mb-2`}>
                    Available Credits:{" "}
                    <span className={`font-normal  `}>{total || 0}</span>
                  </h1>
                </div>
                
                <div className="m-0">
                  <p className="text-center font-semibold">
                    {subscription === "active" &&  plan !== "Free" &&
                      (status ? (
                        <p className={` `}>
                          Subscription Canceled Successfully!!
                        </p>
                      ) : (
                        <a
                          onClick={handleCancelClick}
                          className="cancel-btn bg-green-500   font-bold py-2 sm:py-3 px-3 sm:px-4 rounded-full hover:bg-green-600 transition duration-300 ease-in-out transform hover:scale-105 cursor-pointer"
                        >
                          Cancel Subscription
                        </a>
                      ))
                    }
                  </p>
                </div>
              </div>

            </div>
            <h5 className="font-bold text-md md:text-lg pb-2 border-b-2 border-gray-z500 capitalize w-max">
              CHANGE PLAN
            </h5>

            <Card  name={total} plan={plan} />

            {/* Video Section */}

          </div>
        </div>
      </div>

      <Footer />
    </div>
  );


};

export default Billing;
