import React, { useState, useEffect } from "react";
import DashboardNavbar from "../navbar/DashboardNavbar";
import NavTab from "./NavTab";
import Footer from "../Footer/Footer";
import "../style.css?v1";
import "reactjs-popup/dist/index.css";
import { GenerateCaptions, Plans } from "../api/Service";
import { useNavigate } from "react-router-dom";
import { MdCheck } from "react-icons/md";
import { useModal } from "../../context/ModalContext";
import { useDarkMode } from "../../context/DarkModeContext";
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';

const CreateCaptions = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [dragActive, setDragActive] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState(
    "e7e758de-4eb4-460f-aeca-b2801ac7f8cc"
  );
  const { darkMode } = useDarkMode();
  const { isModalOpen } = useModal()
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(true);
  const [plan, setPlan] = useState("");
  const [series, setSeries] = useState('')
  const [modalMessage, setModalMessage] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [preview, setPreview] = useState("1.mp4");
  const navigate = useNavigate();
  const [checkbox, setCheckbox] = useState(false)
  const [socialmedia, setSocialmedia] = useState('')
  const [postInDay, setPostInDay] = useState('')
  const [value, onChange] = useState('');
  const [isToggled, setIsToggled] = useState(false);
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState('')

  const handleToggle = () => {
    setIsToggled((prev) => !prev);
    // Add any additional actions you want here
  }

  const server_App_URl = process.env.REACT_APP_API_URL;

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  const handlePostInDay = (e) => {
    console.log(e)
    setPostInDay(e)
  }
  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      console.log(e.target.files, "objectttt");
      setSelectedFile(e.dataTransfer.files[0]);
      // Process file here (e.g., call handleVideoChange)
      // handleVideoChange(e.dataTransfer.files[0]);
    }
  };

  const handleFileSelect = (e) => {
    if (e.target.files && e.target.files[0]) {
      console.log(e.target.files[0], "objectttt");
      setSelectedFile(e.target.files[0]);
      // Process file here (e.g., call handleVideoChange)
      // handleVideoChange(e.target.files[0]);
    }
  };

  const handleVideoUpload = async () => {
    if (!plan || series === "Free") {
      navigate("/app/billing");
      return;
    } else {
      setLoading(true);
      if (selectedFile || selectedTemplateId) {
        var formData = new FormData();
        formData.append("video_url", selectedFile);
        formData.append("templateId", selectedTemplateId);
        try {
          // let data = {
          //     video_url: formData,
          //     templateId: selectedTemplateId
          // }

          const res = await GenerateCaptions(formData);
          localStorage.removeItem('progress')

          if (res) {
            const videoId = res.data.data.response.insertId;
            const userId = res.data.data.UserId;
            navigate("/app/caption-preview", {
              state: {
                videoUrl: `/${userId}/${videoId}/output.mp4`,
                videoid: videoId,
                userid: userId
              },
            });
            setLoading(false);
          }
        } catch (err) {
          setLoading(false);
          console.log(err);
          if (err.response || err.response.data.message === "Un-authorized") {
            if (err.response.data.message === "Un-authorized") {
              localStorage.clear();
              navigate("/app");
              window.location.reload();

              setModalMessage(
                ` ${err.response.data.message || "Something went wrong"}`
              );
              setShowModal(true);
            } else {
              setModalMessage(
                ` ${err.response.data.message || "Something went wrong"}`
              );
              setShowModal(true);
            }
          } else {
            setModalMessage("An unexpected error occurred");
            setShowModal(true);
          }
        }

        console.log("Video uploaded:", selectedFile);
      } else {
        alert("Please select a video first!");
      }
    }
  };
  const handleClick = () => {
    document.getElementById("video-upload").click();
  };

  const handleTemplateClick = (id) => {
    setSelectedTemplateId(id);
    console.log("Selected Template ID:", id);
  };

  const Templates = [
    {
      id: "e7e758de-4eb4-460f-aeca-b2801ac7f8cc",
      categories: ["animated", "highlighted"],
      pre: "1.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/e7e758de-4eb4-460f-aeca-b2801ac7f8cc.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/e7e758de-4eb4-460f-aeca-b2801ac7f8cc.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/e7e758de-4eb4-460f-aeca-b2801ac7f8cc.gif",
      },
    },
    {
      id: "21327a45-df89-46bc-8d56-34b8d29d3a0e",
      categories: ["basic"],
      pre: "2.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/21327a45-df89-46bc-8d56-34b8d29d3a0e.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/21327a45-df89-46bc-8d56-34b8d29d3a0e.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/21327a45-df89-46bc-8d56-34b8d29d3a0e.gif",
      },
    },
    {
      id: "a6760d82-72c1-4190-bfdb-7d9c908732f1",
      categories: ["animated"],
      pre: "3.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/a6760d82-72c1-4190-bfdb-7d9c908732f1.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/a6760d82-72c1-4190-bfdb-7d9c908732f1.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/a6760d82-72c1-4190-bfdb-7d9c908732f1.gif",
      },
    },
    {
      id: "982ad276-a76f-4d80-a4e2-b8fae0038464",
      categories: ["basic", "highlighted"],
      pre: "4.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/982ad276-a76f-4d80-a4e2-b8fae0038464.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/982ad276-a76f-4d80-a4e2-b8fae0038464.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/982ad276-a76f-4d80-a4e2-b8fae0038464.gif",
      },
    },
    {
      id: "07ffd4b8-4e1a-4ee3-8921-d58802953bcd",
      categories: ["basic", "highlighted"],
      pre: "5.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/07ffd4b8-4e1a-4ee3-8921-d58802953bcd.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/07ffd4b8-4e1a-4ee3-8921-d58802953bcd.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/07ffd4b8-4e1a-4ee3-8921-d58802953bcd.gif",
      },
    },
    {
      id: "7b946549-ae16-4085-9dd3-c20c82504daa",
      categories: ["basic", "highlighted"],
      pre: "6.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/7b946549-ae16-4085-9dd3-c20c82504daa.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/7b946549-ae16-4085-9dd3-c20c82504daa.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/7b946549-ae16-4085-9dd3-c20c82504daa.gif",
      },
    },
    {
      id: "14bcd077-3f98-465b-b788-1b628951c340",
      categories: ["basic", "highlighted"],
      pre: "7.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/14bcd077-3f98-465b-b788-1b628951c340.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/14bcd077-3f98-465b-b788-1b628951c340.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/14bcd077-3f98-465b-b788-1b628951c340.gif",
      },
    },
    {
      id: "d46bb0da-cce0-4507-909d-fa8904fb8ed7",
      categories: ["animated"],
      pre: "8.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/d46bb0da-cce0-4507-909d-fa8904fb8ed7.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/d46bb0da-cce0-4507-909d-fa8904fb8ed7.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/d46bb0da-cce0-4507-909d-fa8904fb8ed7.gif",
      },
    },
    {
      id: "decf5309-2094-4257-a646-cabe1f1ba89a",
      categories: ["animated"],
      pre: "9.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/decf5309-2094-4257-a646-cabe1f1ba89a.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/decf5309-2094-4257-a646-cabe1f1ba89a.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/decf5309-2094-4257-a646-cabe1f1ba89a.gif",
      },
    },
    {
      id: "dfe027d9-bd9d-4e55-a94f-d57ed368a060",
      categories: ["animated", "highlighted"],
      pre: "10.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/dfe027d9-bd9d-4e55-a94f-d57ed368a060.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/dfe027d9-bd9d-4e55-a94f-d57ed368a060.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/dfe027d9-bd9d-4e55-a94f-d57ed368a060.gif",
      },
    },
    {
      id: "1bb3b68b-6a93-453a-afd7-a774b62cdab8",
      categories: [],
      pre: "11.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/1bb3b68b-6a93-453a-afd7-a774b62cdab8.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/1bb3b68b-6a93-453a-afd7-a774b62cdab8.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/1bb3b68b-6a93-453a-afd7-a774b62cdab8.gif",
      },
    },
    {
      id: "46d20d67-255c-4c6a-b971-31fddcfea7f0",
      categories: ["animated", "highlighted"],
      pre: "12.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/46d20d67-255c-4c6a-b971-31fddcfea7f0.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/46d20d67-255c-4c6a-b971-31fddcfea7f0.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/46d20d67-255c-4c6a-b971-31fddcfea7f0.gif",
      },
    },
    {
      id: "c88bff11-7f03-4066-94cd-88f71f9ecc68",
      categories: ["basic"],
      pre: "13.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/c88bff11-7f03-4066-94cd-88f71f9ecc68.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/c88bff11-7f03-4066-94cd-88f71f9ecc68.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/c88bff11-7f03-4066-94cd-88f71f9ecc68.gif",
      },
    },
    {
      id: "a104df87-5b1a-4490-8cca-62e504a84615",
      categories: ["highlighted"],
      pre: "14.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/a104df87-5b1a-4490-8cca-62e504a84615.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/a104df87-5b1a-4490-8cca-62e504a84615.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/a104df87-5b1a-4490-8cca-62e504a84615.gif",
      },
    },
    {
      id: "ca050348-e2d0-49a7-9c75-7a5e8335c67d",
      categories: ["animated"],
      pre: "15.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/ca050348-e2d0-49a7-9c75-7a5e8335c67d.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/ca050348-e2d0-49a7-9c75-7a5e8335c67d.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/ca050348-e2d0-49a7-9c75-7a5e8335c67d.gif",
      },
    },
    {
      id: "eb5de878-2997-41fe-858a-726e9e3712df",
      categories: ["basic"],
      pre: "16.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/eb5de878-2997-41fe-858a-726e9e3712df.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/eb5de878-2997-41fe-858a-726e9e3712df.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/eb5de878-2997-41fe-858a-726e9e3712df.gif",
      },
    },
    {
      id: "a51c5222-47a7-4c37-b052-7b9853d66bf6",
      categories: ["animated", "highlighted"],
      pre: "17.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/a51c5222-47a7-4c37-b052-7b9853d66bf6.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/a51c5222-47a7-4c37-b052-7b9853d66bf6.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/a51c5222-47a7-4c37-b052-7b9853d66bf6.gif",
      },
    },
    {
      id: "5de632e7-0b02-4d15-8137-e004871e861b",
      categories: ["animated"],
      pre: "18.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/5de632e7-0b02-4d15-8137-e004871e861b.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/5de632e7-0b02-4d15-8137-e004871e861b.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/5de632e7-0b02-4d15-8137-e004871e861b.gif",
      },
    },
    {
      id: "55267be2-9eec-4d06-aff8-edcb401b112e",
      categories: ["highlighted"],
      pre: "19.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/55267be2-9eec-4d06-aff8-edcb401b112e.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/55267be2-9eec-4d06-aff8-edcb401b112e.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/55267be2-9eec-4d06-aff8-edcb401b112e.gif",
      },
    },
    {
      id: "50cdfac1-0a7a-48dd-af14-4d24971e213a",
      categories: ["highlighted"],
      pre: "20.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/50cdfac1-0a7a-48dd-af14-4d24971e213a.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/50cdfac1-0a7a-48dd-af14-4d24971e213a.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/50cdfac1-0a7a-48dd-af14-4d24971e213a.gif",
      },
    },
    {
      id: "cfa6a20f-cacc-4fb6-b1d0-464a81fed6cf",
      categories: ["highlighted"],
      pre: "21.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/cfa6a20f-cacc-4fb6-b1d0-464a81fed6cf.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/cfa6a20f-cacc-4fb6-b1d0-464a81fed6cf.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/cfa6a20f-cacc-4fb6-b1d0-464a81fed6cf.gif",
      },
    },
  ];
  const planResponse = async () => {
    try {
      const response = await Plans();
      setSeries(response.PlanTypeName)
      setPlan(response.credit);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (loading1) {
      planResponse();
    }
  }, [loading1]);

  return (
    <>
      <div className={darkMode ? "bg-black text-white" : "bg-white text-black"}>
        <DashboardNavbar />
        <div className="flex min-h-screen">
          {/* Sidebar */}
          {!isModalOpen ? (
            <div className="absolute md:relative md:w-[23%] w-[21%] bg text-white">
              <NavTab />
            </div>
          ) : (<div className="absolute md:relative md:w-[23%] w-[21%]  bg-white bg-opacity-50">

          </div>)}

          {/* Main Content */}
          <div className="flex flex-col md:flex-row justify-evenly w-[79%] avatar-main-768 gap-3 items-start">
            <div className={`maindiv pt-0 w-full flex flex-col lg:p-[30px] p-4 overflow-x-hidden font-montserrat h-max lg:h-full space-y-6 justify-center items-center pb-20 ${darkMode ? "bg-black" : "bg-gray-100"} text-[#467ff7]`}>
              <div className="main-avatar w-full">
                <div className="flex-col">
                  <h1 className="createh1 text-center font-bold text-[2.2rem] space-y-6">
                    AI Captions
                  </h1>
                  <p className="pragcreate text-center">
                    Start generating captions with AI.
                  </p>
                </div>
                <div className="pt-5">
                  <div className="flex flex-col w-full mx-auto space-y-4">
                    {/* Form Section */}
                    <div className="flex layout-cantent-1 ">
                      <div className={`${darkMode ? "bg-black" : "bg-gray-100"}  flex-col form-w w-[80%] lg:flex-row rounded-md`}>
                        {/* Video Upload Field */}
                        <div className="p-4 lg:p-6 space-y-4 ">
                          <div
                            className={`flex flex-col items-center justify-center h-[464px] p-4 border-dashed border-2 rounded-md transition-colors cursor-pointer 
                              ${dragActive ? "border-blue-500 bg-blue-50" : darkMode ? "bg-black border-gray-500" : "bg-white border-gray-300"}
                            `}
                            onClick={handleClick}
                            onDragEnter={handleDrag}
                            onDragOver={handleDrag}
                            onDragLeave={handleDrag}
                            onDrop={handleDrop}
                          >
                            <input
                              type="file"
                              accept="video/*"
                              id="video-upload"
                              className="hidden"
                              onChange={handleFileSelect}
                            />

                            <label
                              htmlFor="video-upload"
                              className="block text-gray-900 font-bold mb-2 cursor-pointer"
                            >
                              {selectedFile ? (
                                <>
                                  <div className="flex justify-center">
                                    <MdCheck
                                      style={{
                                        color: "green",
                                        fontSize: "50px",
                                      }}
                                    />
                                  </div>{" "}
                                  {/* Checkmark icon next to the file name */}
                                  {`Selected Video: ${selectedFile.name}`}
                                </>
                              ) : (
                                "Upload or Drag & Drop Video"
                              )}
                            </label>

                            <div className="flex flex-col items-center justify-center p-4 w-full">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="h-10 w-10 text-blue-500"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M7 16v-1a4 4 0 014-4h.01a4 4 0 014 4v1m4 0v-1a4 4 0 00-3-3.87M16 4v4m-8-4v4m-4 4v8a2 2 0 002 2h12a2 2 0 002-2v-8a2 2 0 00-2-2h-3.28a4 4 0 01-7.44 0H6a2 2 0 00-2 2z"
                                />
                              </svg>
                              <p className="text-gray-500 text-sm mt-2">
                                Drag & Drop video file here
                              </p>
                              <p className="text-gray-400 text-xs mt-1">
                                or click to browse
                              </p>
                            </div>

                            <span className="text-sm mt-2">
                              Note: Your video needs to be under 10 minutes
                            </span>
                          </div>
                        </div>

                        {/* Templates Section */}
                        <div className={`templates-section p-4 ${darkMode ? "bg-[#1E1E1E]" : "bg-white"} rounded-md shadow-sm`} >
                          <div>
                            <h2 className="text-xl font-bold mb-4 text-center">
                              Templates
                            </h2>
                          </div>
                          <div className="flex overflow-x-auto">
                            {Templates.map((template) => (
                              <div
                                key={template.id}
                                className="flex flex-col items-center"
                              >
                                <div
                                  className="template-card ml-2 w-[250px]  cursor-pointer"
                                  onClick={() =>
                                    handleTemplateClick(template.id)
                                  }
                                >
                                  <img
                                    src={template.previews.previewGif}
                                    onClick={() => setPreview(template.pre)}
                                    alt="Template Preview"
                                    className={`rounded-lg  object-cover shadow-md outline-2 ${selectedTemplateId === template.id
                                      ? "outline outline-blue-500"
                                      : ""
                                      }`}
                                  />
                                  {/* <a
                                        onClick={() => setPreview(template.pre)}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className="absolute inset-0 flex items-end justify-center text-white font-bold bg-opacity-50 hover:bg-opacity-75 transition-opacity hover:cursor-pointer"
                                      >
                                        Click to Preview
                                      </a> */}
                                </div>
                                {/* <div className="text-center mt-2 bg-gray-200 rounded w-[120px] sm:w-[150px]">
                                                                {template.categories.join(", ")}
                                                            </div> */}
                              </div>
                            ))}
                          </div>
                        </div>
                        
                      </div>
                      <div className="h-[410px] w-[20%] pl-2 mt-2 example-video justify-between">
                        <div className="">
                          <div className="">
                            <h5 className="text-[#899493]">Output Example</h5>
                          </div>
                          <div className="h-full ">
                            <video
                              key={preview}
                              className=" rounded-md video-h"
                              autoPlay
                              controls
                              loop
                              muted
                              preload={"auto"}
                            >
                              <source
                                className="rounded-md "
                                src={`${server_App_URl}/media/template/${preview}`}
                              />
                            </video>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Upload Button */}
                    <div className="pt-4 flex justify-center mb-3">
                      <button
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded w-[200px]"
                        onClick={handleVideoUpload}
                      >
                        {loading ? "Generating..." : "Generate Video"}
                      </button>
                    </div>
                    {showModal ? (
                      <>
                        <div className="fixed inset-0 z-50 flex items-center justify-center overflow-hidden">
                          <div className="relative w-full max-w-3xl bg-white rounded-lg shadow-lg">
                            {/* Modal Content */}
                            <div className="p-6">
                              <h1 className="text-lg font-bold">
                                {modalMessage}
                              </h1>
                            </div>

                            {/* Modal Footer */}
                            <div className="flex items-center justify-end p-4">
                              <button
                                className="px-4 py-2 text-sm font-bold text-black transition-all duration-150 ease-linear bg-transparent rounded focus:outline-none"
                                type="button"
                                onClick={() => setShowModal(false)}
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default CreateCaptions;
