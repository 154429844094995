import React from 'react';
import { Link } from 'react-router-dom';
import loho from "../../../loho.png";
import "../../../index.css"

const LandingFooter = () => {
    return (
        <footer className="bg-[#0d0d0d] mt-2 text-white">
            <div className="max-w-5xl mx-auto py-2 px-4 sm:px-2">
                <div className="grid grid-cols-1  md:grid-cols-3 gap-4">
                    <div className="flex items-center  justify-center">
                        <div className="space-y-4 justifyend">
                            <div className="flex items-center">
                                <Link
                                    to="/" // Sign in ke liye path ko update karein
                                    className="flex items-center"
                                >
                                    <img src={loho} alt="Logo" width={40} className="mr-2" />
                                    <span className="text-white text-5xl md:text-3xl font-bold">Auto</span>
                                    <span className="text-red-600 text-5xl md:text-3xl font-bold">Tube</span>
                                </Link>
                            </div>
                            <p className="text-gray-400 justifyend">
                                AutoTube AI automatically creates, schedules, and posts engaging
                                Faceless videos for you on auto-pilot.
                            </p>
                        </div>
                    </div>

                    <div className="flex items-center justifyend  justify-end">
                        <div className="space-y-4">
                            <h3 className="text-lg font-semibold">Support</h3>
                            <ul className="space-y-2">
                                <li>
                                    <a
                                        href="/faq"
                                        className="text-gray-400 hover:text-white transition-colors"
                                    >
                                        FAQ
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/term"
                                        className="text-gray-400 hover:text-white transition-colors"
                                    >
                                        Terms & Conditions
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/privarypolicy"
                                        className="text-gray-400 hover:text-white transition-colors"
                                    >
                                        Privacy Policy
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/google-api-disclosure"
                                        className="text-gray-400 hover:text-white transition-colors"
                                    >
                                        Google API Disclosure
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="/contact"
                                        className="text-gray-400 hover:text-white transition-colors"
                                    >
                                        Contact Us
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="flex justifyend justify-end">
                        <div className="space-y-4">
                            <h3 className="text-lg font-semibold">Follow Us</h3>
                            <div className="space-y-3">
                                <a
                                    href="https://www.instagram.com/autotube.in"
                                    className="text-gray-400 hover:text-white transition-colors flex items-center"
                                >
                                    <i className="fab fa-instagram text-xl mr-2"></i>
                                    Instagram
                                </a>
                                <a
                                    href="https://www.youtube.com/@autotube.in-automation"
                                    className="text-gray-400 hover:text-white transition-colors flex items-center"
                                >
                                    <i className="fab fa-youtube text-xl mr-2"></i>
                                    YouTube
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="border-t border-gray-800 mt-2 pt-2 text-center text-gray-400">
                    <p>© 2024 AutoTube. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
}

export default LandingFooter;