"use client";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import image1 from "../../../components/autotube/media/image/Capture.jpg"
import image2 from "../../../components/autotube/media/image/Leonardo_Kino.jpg"
import image3 from "../../../components/autotube/media/image/cat.jpg"
import image4 from "../../../components/autotube/media/image/enchanted.jpg"



function BlogList() {

    const [isOpen, setIsOpen] = useState(false);
    const [blogs, setBlogs] = useState([
        {
            id: 1,
            title: "AI-Powered Video Creation: The Future of Content",
            excerpt:
                "Discover how artificial intelligence is revolutionizing video content creation and making it accessible to everyone.",
            author: "Sarah Parker",
            date: "March 15, 2024",
            image: image1,
            readTime: "5 min read",
            category: "AI Technology",
        },
        {
            id: 2,
            title: "Top 10 Features of AutoTube AI You Need to Know",
            excerpt:
                "Explore the groundbreaking features that make AutoTube AI the leading choice for automated video creation.",
            author: "Mike Johnson",
            date: "March 12, 2024",
            image: image2,
            readTime: "7 min read",
            category: "Tutorial",
        },
        {
            id: 3,
            title: "How to Create Viral Videos with AI Tools",
            excerpt:
                "Learn the secrets of creating engaging video content that captures audience attention using AI technology.",
            author: "Emily Chen",
            date: "March 10, 2024",
            image: image3,
            readTime: "6 min read",
            category: "Tips & Tricks",
        },
        {
            id: 4,
            title: "The Impact of AI on Content Creation Industry",
            excerpt:
                "Explore how artificial intelligence is transforming the landscape of digital content creation and distribution.",
            author: "Alex Thompson",
            date: "March 8, 2024",
            image: image4,
            readTime: "8 min read",
            category: "Industry Insights",
        },
    ]);

    return (
        <div className="bg-black w-full text-white min-h-screen">
            <nav className="flex flex-wrap justify-between items-center px-4 sm:px-6 py-4 border-b border-gray-800">
                <div className="flex flex-col items-center space-y-6">
                    <div className="flex items-center">
                        <div className="text-5xl md:text-3xl font-bold">
                            <Link
                                to="/" // Sign in ke liye path ko update karein
                                className="font-medium"
                            >
                                <span className="text-white">Auto</span>
                                <span className="text-red-600">Tube</span>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="hidden md:flex space-x-4 lg:space-x-8">
                    <a href="/" className="hover:text-gray-300">
                        Home
                    </a>
                    <a href="#features" className="hover:text-gray-300">
                        Features
                    </a>
                    <a href="#pricing" className="hover:text-gray-300">
                        Pricing
                    </a>
                    <a href="#how-it-works" className="hover:text-gray-300">
                        How It Works
                    </a>
                    <a href="/blog" className="hover:text-gray-300">
                        Blog
                    </a>
                </div>
                <div className="flex items-center space-x-4">
                    <Link
                        to="/login" // Sign in ke liye path ko update karein
                        className="font-medium"
                    >
                        <button className="bg-[#2ecc71] text-black px-4 sm:px-6 py-2 rounded-full hover:bg-[#27ae60 text-sm sm:text-base">
                            Get Started
                        </button>
                    </Link>
                    <button
                        onClick={() => setIsOpen(!isOpen)}
                        className="md:hidden bg-black"
                    >
                        <i className={`fas ${isOpen ? "fa-times" : "fa-bars"}`}></i>
                    </button>
                </div>
            </nav>
            <div className="container mx-auto px-4 py-16">
            <h1 className="text-4xl md:text-6xl font-bold text-center mb-16 font-roboto bg-gradient-to-r from-blue-500 via-green-500 to-red-500 text-transparent bg-clip-text pt-10 pb-10 ">
                    Latest Blog Posts
                </h1>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                    {blogs.map((blog) => (
                        <button
                            key={blog.id}
                            className="w-full text-left cursor-pointer"
                            onClick={() => (window.location.href = `#`)}
                        >
                            <div className="group bg-[#1e293b] rounded-xl overflow-hidden hover:scale-[1.02] transition-all duration-300 border border-[#334155] hover:border-blue-500 h-full">
                                <div className="relative h-48">
                                    <img
                                        src={blog.image}
                                        alt={blog.title}
                                        className="w-full h-full object-cover"
                                    />
                                    <div className="absolute top-4 left-4">
                                        <span className="text-sm bg-[#2ecc71]/60 text-white rounded-full px-4 py-1 backdrop-blur-sm">
                                            {blog.category}
                                        </span>
                                    </div>
                                </div>

                                <div className="p-6">
                                    <div className="flex items-center gap-2 mb-3">
                                        <i className="fas fa-clock text-blue-400"></i>
                                        <span className="text-gray-400 text-sm">
                                            {blog.readTime}
                                        </span>
                                    </div>
                                    <h2 className="text-xl font-bold text-white mb-3 line-clamp-2 group-hover:text-blue-400 transition-colors">
                                        {blog.title}
                                    </h2>
                                    <p className="text-gray-400 text-sm mb-4 line-clamp-2">
                                        {blog.excerpt}
                                    </p>
                                    <div className="flex items-center justify-between pt-4 border-t border-[#334155]">
                                        <div className="flex items-center gap-3">
                                            <div className="w-8 h-8 rounded-full bg-gradient-to-r from-blue-500 to-purple-600 flex items-center justify-center">
                                                <i className="fas fa-user text-white text-sm"></i>
                                            </div>
                                            <p className="text-white text-sm">{blog.author}</p>
                                        </div>
                                        <span className="text-gray-400 text-sm">{blog.date}</span>
                                    </div>
                                </div>
                            </div>
                        </button>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default BlogList;