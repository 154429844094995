import React, { useEffect, useState  } from 'react';
// import { HashRouter, Route, Routes } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SignUp from './components/SignUp';
import Dashboard from './components/Dashboard';
import Create from './components/navtab/Create';
import Affilates from './components/navbar/Affilates';
import Billing from './components/navtab/Billing';
import PrivateRoute from './components/PrivateComponent';
import Account from './components/navtab/Account';
import SocialMedia from './components/navtab/SocialMedia';
import ComingComp from './components/comingsoon/ComingComp';
import Preview from './components/preview/Preview';
import VideoPreview from './components/preview/VideoPreview';
import ListComponent from './components/List';
import CreatorList from './components/CreatorList';
import AvatarCreate from './components/navtab/AvatarCreate';
import CreateCaptions from './components/navtab/CreateCaptions';
import CaptionList from './components/CaptionsList';
import CaptionPreview from './components/preview/CaptionPreview';
import TwitterCreate from './components/navtab/TwitterCreate';
import TwitterList from './components/TwitterList';
import TwitterPreview from './components/preview/TwitterPreview';
import Guide from './components/navtab/Guide';
import EnterEmail from './components/Forget Password/EnterEmail';
import ResetPassword from './components/Forget Password/ResetPassword';
import VideoCreate from './components/navtab/AivideosCreate';
import AIVideoPreview from './components/preview/AiVideoPreview';
import VideoListComponent from './components/AiVideoList';
import { ModalProvider } from './context/ModalContext';
import { DarkModeProvider } from './context/DarkModeContext';
import AutoPostQueue from './components/navtab/AutoPostQueue';
import AffilateListing from './components/navbar/AffiliateListing';
import CommingSoon from './components/navtab/CommingSoon';
import Login from './components/Login';
import PrivaryPolicy from './components/autotube/policys/PrivecyPolicy'
import Term from './components/autotube/policys/Term'
import MainComponentPage from './components/autotube/landing page/Page'
import BlogList from './components/autotube/Blog/BlogList'
import { Suspense , lazy } from "react";
import "../src/index.css"
import FAQ from './components/autotube/FAQ/FAQ';
import AipDesclosure from './components/autotube/apiDisclosure/apiDisclosure';
import ContactUs from './components/autotube/contact/Contact';
import Thanks from './components/autotube/thanks/Thanks';
// import { GoogleOAuthProvider } from "@react-oauth/google";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return localStorage.getItem('isAuthenticated') === 'true';
  });
  // const clientid = "806958723882-6950od6rdd0qbtl2c11fiqk5l383clsj.apps.googleusercontent.com";

  useEffect(() => {
    localStorage.setItem('isAuthenticated', isAuthenticated);
  }, [isAuthenticated]);

  const auth = localStorage.getItem('isAuthenticated')
  const token = localStorage.getItem('Token')
  const email = localStorage.getItem('email')


  return (
    <div className="min-h-screen bg-[var(--bg-color)] text-[var(--text-color)]">
      <DarkModeProvider>
        <ModalProvider>
          <Routes>
            {!auth || auth === false || !token ? (<>
              <Route path='/app/reset-password-email' element={<EnterEmail />} />
              <Route path='/app/reset-password' element={<ResetPassword />} />
              <Route path="/" element={<MainComponentPage setIsAuthenticated={setIsAuthenticated} />} />
              <Route path='/app/reset-password-email' element={<EnterEmail />} />
              <Route path='/app/reset-password' element={<ResetPassword />} />
              <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
              <Route path="/signup" element={<SignUp setIsAuthenticated={setIsAuthenticated} />} />
            </>) : (
              <Route element={<PrivateRoute isAuthenticated={isAuthenticated} />}>
                <Route path="*" element={<Dashboard />} />
                {/* <Route path='/app/comingcomp' element={<ComingComp />} /> */}
                <Route path='/dashboard' element={<Dashboard />} />
                <Route path='/create' element={<Create />} />
                <Route path='/avatarCreate' element={<AvatarCreate />} />
                <Route path='/app/captionCreate' element={<CreateCaptions />} />
                <Route path='/app/twitterCreate' element={<TwitterCreate />} />
                <Route path='/videoCreate' element={<VideoCreate />} />
                <Route path='/view/aishorts' element={<ListComponent />} />
                <Route path='/app/view/aicreator' element={<CreatorList />} />
                <Route path='/app/view/aicaption' element={<CaptionList />} />
                <Route path='/app/view/aitwitter' element={<TwitterList />} />
                <Route path='/view/aivideo' element={<VideoListComponent />} />
                {/* {(email === 'contacteffectsclub@gmail.com' || email === 'bansalvishal317@gmail.com') && (
                  <Route path='/app/affiliate_list' element={<AffilateListing />} />
                )} */}

                <Route path='/app/comming-soon' element={<CommingSoon />} />
                <Route path='/app/affilates' element={<Affilates />} />
                <Route path='/billing' element={<Billing />} />
                <Route path='/app/account' element={<Account />} />
                <Route path='/socialmedia' element={<SocialMedia />} />
                <Route path='/app/preview' element={<Preview />} />
                <Route path='/app/video-preview' element={<VideoPreview />} />
                <Route path='/app/caption-preview' element={<CaptionPreview />} />
                <Route path='/app/twitter-preview' element={<TwitterPreview />} />
                <Route path='/app/ai-video-preview' element={<AIVideoPreview />} />
                <Route path='/app/guide' element={<Guide />} />
                <Route path='/app/autopost' element={<AutoPostQueue />} />
                

              </Route>)}
              <Route path='/privarypolicy' element={<PrivaryPolicy />} />
                <Route path='/term' element={<Term />} />
                <Route path='/blog' element={<BlogList />} />
                <Route path='/faq' element={<FAQ/>} />
                <Route path='/google-api-disclosure' element={<AipDesclosure/>} />
                <Route path='/contact' element={<ContactUs/>} />
                <Route path='/thanks' element={<Thanks/>} />
          </Routes>
        </ModalProvider>
      </DarkModeProvider>
    </div>
  );
}
export default App;