import React, { useState, useEffect } from 'react';
import Youtube from './YouTube';
import img from '../images/YouTube-Symbol.png';
import tiktokimg from '../images/tiktok.webp';
import instaimg from '../images/instagramimg.avif';
import { DisconnectYoutube, socialmedia } from '../api/Service';
import { Plans } from '../api/Service';
import { useDarkMode } from '../../context/DarkModeContext';
import { useNavigate } from 'react-router-dom';
import '../style.css';
import logo from '../icon.png'
import loho from "../../loho.png"

const Tabs = () => {
    const [username, setusername] = useState('');
    const [userimg, setuserimg] = useState(img);
    const [plan, setPlan] = useState('');
    const { darkMode } = useDarkMode();
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate()

    const youtubeInfo = async () => {
        try {
            const response = await socialmedia();
            setusername(response.data.name);
            setuserimg(response.data.picture);
        } catch (error) {
            console.error('Error fetching videos:', error);
        }
    };

    useEffect(() => {
        if (loading) {
            youtubeInfo();

        }
    }, [loading]);

    const planResponse = async () => {
        try {
            const response = await Plans();
            if (response) {
                setPlan(response.PlanTypeName);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        planResponse();
        setLoading(false);
    }, []);

    const handleClick = () => {
        alert('To Connect with YouTube Please Take Subscription');
    };
    const handleDisconnect = async () => {
        const data = localStorage.getItem('Token')
        try {
            const response = await DisconnectYoutube(data)
            console.log(response)
            if (response.status) {
                window.location.reload()
            }
        } catch (error) {
            console.log(error)
        }
    }
    const userId = parseInt(localStorage.getItem('userId'), 10);

    return (
       <div
            className={`min-h-screen  flex flex-col items-center justify-center p-4`}
        >
            <div
                className={`rounded-3xl shadow-lg  border-2 border-gray-500  p-12 max-w-lg w-full space-y-10 `}
            >
                <div
                    className={`space-y-8 `}
                >
                    <h1 className="text-4xl font-poppins font-bold text-center  ">
                        Connect Your Accounts
                    </h1>
                    <div className="flex justify-center">
                    <div className="flex justify-center">
                        <div className="w-20 h-20 rounded-2xl flex items-center justify-center transform hover:rotate-6 transition-transform duration-300">
                        <img src={loho} alt="Logo" width={40} className="mr-2" />
                        </div>
                    </div>
                    </div>
                    <p className="text-center   font-poppins text-lg">
                        Choose your platform to connect
                    </p>
                </div>

                <div className="space-y-6  w-full h-16">
                    {plan === 'Free' ? (
                        <button onClick={() => navigate('/billing')} className="w-full h-16  border-2 border-[#FF0000] text-[#FF0000] py-4 px-8 rounded-2xl hover:bg-[#FF0000] hover:  transition-colors duration-300 font-poppins flex items-center justify-between group">
                            <span className="text-xl font-bold ">Connect YouTube</span>
                            <i className="fas fa-arrow-right transition-transform duration-300 group-hover:translate-x-2"></i>
                        </button>
                        // <button className="bg-[#467ff7] px-4 py-1 rounded-md z-10 transition-all duration-500 cursor-not-allowed" disabled >YouTube</button>
                    ) : (<>
                    {username?(<button onClick={handleDisconnect} className="w-full h-16  border-2 border-[#FF0000] text-[#FF0000] py-4 px-8 rounded-2xl hover:bg-[#FF0000] hover:  transition-colors duration-300 font-poppins flex items-center justify-between group">
                            <span className="text-xl font-bold ">{username}/ Disconnect</span>

                        </button>):(<button className="w-full h-16 text-3xl border-2 border-[#FF0000] text-[#FF0000] py-4 px-8 rounded-2xl transition-colors duration-300 font-poppins flex items-center justify-between group">
                            <span className="text-xl font-bold ">{ <Youtube />}</span>
                            <i className="fas fa-arrow-right text-[#FF0000]  transition-transform duration-300 group-hover:translate-x-2"></i>

                        </button>)}
                        
                       </>
                    )}

                </div>

                {/* <div className="text-center   font-poppins text-sm">
                    <button className="w-[100%] h-16  border-2 border-gray-500   py-4 px-8 rounded-2xl hover:bg-black hover:  transition-colors duration-300 font-poppins flex items-center justify-between group" disabled>
                        <span className="text-xl font-semibold">Connect TikTok</span>

                        <i className="fas text-2xl   fa-arrow-right transition-transform duration-300 "></i>

                    </button>
                </div> */}
                Connect to start generating amazing shorts
            </div>
        </div>
    );
};

export default Tabs;
