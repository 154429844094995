import * as React from "react";
import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import BasicModal from "./videoComp";
import { CheckTwitter } from "./api/Service";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import ReactLoading from "react-loading";
import DashboardNavbar from "./navbar/DashboardNavbar";
import NavTab from "./navtab/NavTab";
import Footer from "./Footer/Footer";
import { useModal } from "../context/ModalContext";
import { useDarkMode } from "../context/DarkModeContext";

function TwitterList() {
  const { isModalOpen } = useModal();
  const [videoStatus, setVideoStatus] = useState(false);
  const [videos, setVideos] = useState([]);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [showVideoPreview, setShowVideoPreview] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [length, setLength] = useState("");
  const navigate = useNavigate();
  const { darkMode } = useDarkMode();

  const handleVideos = async () => {
    try {
      const response = await CheckTwitter();
      console.log(response, "Responseee");
      setLength(response.length);
      setVideos(response);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    handleVideos();
  }, []);

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedVideo(null);
  };

  const handleclick = () => {
    navigate("/app/create");
  };

  return (
    <>
      <div className={darkMode ? 'bg-black text-white' : 'bg-white text-black'}>
        <div>
          <DashboardNavbar />
          <div className="flex md:h-[calc(70vh-4rem)] h-auto 2xl:h-[calc(82vh-4rem)]">
            {!isModalOpen ? (
              <div className="absolute md:relative md:w-[23%] w-[21%] bg text-white">
                <NavTab />
              </div>
            ) : (
              <div className="absolute md:relative md:w-[23%] w-[21%] bg-white bg-opacity-50"></div>
            )}
            <div className="w-full max-h-screen min-h-screen text-sm overflow-hidden">
              {length !== 0 ? (
                <>
                  <TableContainer
                    component={Paper}
                    sx={{ margin: "25px", overflowX: "auto", backgroundColor: darkMode ? 'black' : '#fff', }}
                  >
                    <Table
                      sx={{ minWidth: 650, border: 1, borderColor: "gray" }}
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell className={`${darkMode?'text-white':'text-black'}`} align="center" sx={{ minWidth: 120 }}>
                            Parameter Type
                          </TableCell>
                          <TableCell className={`${darkMode?'text-white':'text-black'}`} align="center" sx={{ minWidth: 150 }}>
                            CreatedAt
                          </TableCell>
                          <TableCell className={`${darkMode?'text-white':'text-black'}`} align="center" sx={{ minWidth: 200 }}>
                            Status
                          </TableCell>
                          <TableCell className={`${darkMode?'text-white':'text-black'}`} align="center" sx={{ minWidth: 150 }}>
                            Preview
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Array.isArray(videos) &&
                          videos.map((video, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                                backgroundColor: darkMode ? "black" : "#fff"
                              }}
                            >
                              <TableCell className={`${darkMode?'text-white':'text-black'}`} align="center" sx={{ minWidth: 120 }}>
                                {video.parameterType}
                              </TableCell>
                              <TableCell className={`${darkMode?'text-white':'text-black'}`} align="center" sx={{ minWidth: 150 }}>
                                {moment(video.createdAt).format("YYYY-MM-DD")}
                              </TableCell>
                              <TableCell className={`${darkMode?'text-white':'text-black'}`} align="center" sx={{ minWidth: 200 }}>
                                {video.isfailed === 1 ? (
                                  <p>Failed!!</p>
                                ) : video.status === 2 ? (
                                  <p>Processing...</p>
                                ) : video.status === 8 ? (
                                  <p>Completed</p>
                                ) : null}
                              </TableCell>

                              <TableCell
                                onClick={() => {
                                  setSelectedVideo(video);
                                  setShowVideoPreview(true);
                                  navigate("/app/twitter-preview", {
                                    state: {
                                      videoUrl: `/${video.userId}/${video.id}/output.mp4`,
                                      videoid: video.id,
                                      userid: video.userId,
                                    },
                                  });
                                }}
                                sx={{
                                  textAlign: "-webkit-center",
                                  minWidth: 150,
                                }}
                                className={`${darkMode?'text-white':'text-black'}`}
                              >
                                {video.status === 8 ? (
                                  <BasicModal
                                    open={openDialog}
                                    onClose={handleCloseDialog}
                                    video={video}
                                  />
                                ) : video.isfailed === 1 ? (
                                  <div className="flex justify-center">
                                    <p>--</p>
                                  </div>
                                ) : (
                                  <div className="flex justify-center">
                                    <ReactLoading
                                      type="spin"
                                      color="#3498db"
                                      height={20}
                                      width={20}
                                    />
                                  </div>
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              ) : (
                <>
                  <div
                  className={`h-max-[100vh] h-min-[100vh] ${darkMode ? 'bg-black text-white' : 'bg-gray-50'}`}
                  style={{ minHeight: "100vh" }}
                >
                  <div className="p-6 text-center lg:h-[400px] flex justify-center gap-4 items-center md:justify-center flex-col md:items-center">
                    <div className={` shadow-lg h-30 w-100 p-4 rounded-md ${darkMode?'border-2 border-gray-400':''} `}>
                      <h1 className={`text-center text-2xl ${darkMode?'text-white':'text-black'} `}>No Avatar Video Created Yet!!</h1>
                      <button
                        className="p-2 text-white rounded-md bg-[#467ff7] mt-5"
                        onClick={() => navigate("/app/twitterCreate")}
                      >
                        Create a video
                      </button>
                    </div>
                  </div>
                </div>
                </>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default TwitterList;
