import React, { useEffect, useState } from "react";
import { GiArtificialIntelligence } from "react-icons/gi";
import { FaUserCircle, FaLock } from "react-icons/fa";
import { RiErrorWarningLine } from "react-icons/ri";
import { GoogleLogin, googleLogout } from "@react-oauth/google";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { gapi } from "gapi-script";
import Navbar from "./navbar/Navbar";
import { Link, useNavigate } from "react-router-dom";
import { GoogleForm, LoginUpForm, Plans } from "./api/Service";
import Loader from "./spinner/Spinner";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import logo from "../components/icon.png";
import loho from "../loho.png"

function Login({ setIsAuthenticated }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [Googleerror, setGoogleError] = useState(false);
  const [passwordnotmatch, setpasswordnotmatch] = useState(null);
  const [focusedField, setFocusedField] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const clientid = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  // console.log(clientid,'iddddd')

  // localStorage.removeItem('user_id')

  const handleSubmit = async (event) => {
    event.preventDefault();
    setEmailError(false);
    setPasswordError(false);


    if (!email) {
      setEmailError(true);
    } else if (!password) {
      setPasswordError(true);
    } else {
      setLoading(true);
      calltoapi();
      // wordpress();
    }

    async function calltoapi() {
      try {
        let payload = { email: email, password: password };
        const response = await LoginUpForm(payload);
        if (response) {
          setIsAuthenticated(true);
          localStorage.setItem("isAuthenticated", "true ");
          navigate("/dashboard");
        }
      } catch (err) {
        setpasswordnotmatch(err.response.data.message);
        setLoading(false);
      }
    }

    async function wordpress() {
      console.log("wordpress");
      try {
        const response = await axios.post(
          "http://localhost:3001/wp-json/custom/v1/login",
          {
            username: email,
            password: password,
          },
          {
            withCredentials: true, // This enables cookies to be sent and received
          }
        );
      } catch (error) {
        console.error("Error logging into WordPress:", error);
      }
    }
  };

  const handleGoogleLoginSuccess = async (credentialResponse) => {
    const decoded = jwtDecode(credentialResponse.credential);
    console.log(decoded, "decodedddd");
    let payload = {
      user_email: decoded.email,
      display_name: decoded.name,
      firstName: decoded.given_name,
      lastName: decoded.family_name,
      google_id: decoded.sub,
    };
    try {
      const response = await GoogleForm(payload);
      const decoded = jwtDecode(response.data.token);
      const GEmail = decoded.email;
      if (response) {
        setIsAuthenticated(true);
        localStorage.setItem("isAuthenticated", "true ");
        const Response = await axios.post(
          "http://localhost:3001/wp-json/custom/v1/login",
          {
            username: GEmail,
            // password: password,
          },
          {
            withCredentials: true, // This enables cookies to be sent and received
          }
        );
        console.log("Wordpress", Response);
        const res = await Plans();
        console.log(res);
        navigate("/dashboard");
      }
    } catch (error) {
      if (error) {
        setGoogleError(true);
        console.error("Error logging in with Google", error);
      } else {
        setGoogleError(false);
      }
    }
  };

  const handleGoogleLoginFailure = () => {
    console.log("Google login failed");
  };

  useEffect(() => {
    function start() {
      gapi.client
        .init({
          clientId: clientid,
          scope: "https://www.googleapis.com/auth/userinfo.profile",
        })
        .then(() => {
          console.log("Google API client initialized");
        })
        .catch((error) => {
          console.error("Error initializing Google API client", error);
        });
    }

    if (window.gapi) {
      gapi.load("client:auth2", start);
    } else {
      console.error("gapi is not available");
    }
  }, [clientid]);

  return (
    <>
      <div className=" bg-[#0a0a1f] relative z-10 flex flex-col items-center w-full justify-center min-h-screen px-4">
        
          <div className="flex items-center justify-center gap-1">
            <Link to="/" className="flex items-center gap-1">
              <div className="h-12 w-12 rounded-xl flex items-center justify-center">
                <img src={loho} alt="Logo" width={50} className="fas fa-robot text-2xl text-white animate-pulse" />
              </div>
              <span className="text-white text-3xl md:text-5xl font-bold">Auto</span>
              <span className="text-red-600 text-3xl md:text-5xl font-bold">Tube</span>
            </Link>
          </div>
          <div class="text text-xl text-center font-light text-[#6fee95] my-3">Welcome! 👋</div>
          <div className="flex items-center max-w-[400px] w-full  justify-center">
            <div className="bg-[#1a1a2e] w-full rounded-3xl p-8 animate-slideIn shadow-2xl border border-[#2a2a3e]">
              {(emailError || passwordError || passwordnotmatch || Googleerror) && (
                <div className="mb-4 p-4 bg-red-100 border-l-4 border-red-500 rounded">
                  <p className="text-red-700 text-sm flex items-center">
                    <i className="fas fa-exclamation-circle mr-2"></i>
                    {emailError
                      ? "Please enter an email"
                      : passwordError
                        ? "Please enter a password"
                        : passwordnotmatch
                          ? passwordnotmatch
                          : "Please try again"}
                  </p>
                </div>
              )}

              <form onSubmit={handleSubmit} className="space-y-6 pt-4">
                <div className="relative group">

                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    onFocus={() => setFocusedField("email")}
                    onBlur={() => setFocusedField(null)}
                    className="w-full px-4 py-3 bg-[#2a2a3e] border border-[#3a3a4e] rounded-lg text-white placeholder-gray-400 focus:border-[#4f46e5] focus:ring-2 focus:ring-[#4f46e5] focus:ring-opacity-50 transition-all duration-300"
                    placeholder="Email Address"
                  />
                  <i className="fas fa-envelope absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 group-hover:text-[#2ecc71] transition-colors duration-300"></i>
                </div>

                <div className="relative group">
                  <input
                    type="password"
                    id="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onFocus={() => setFocusedField("password")}
                    onBlur={() => setFocusedField(null)}
                    className="w-full px-4 py-3 bg-[#2a2a3e] border border-[#3a3a4e] rounded-lg text-white placeholder-gray-400 focus:border-[#4f46e5] focus:ring-2 focus:ring-[#4f46e5] focus:ring-opacity-50 transition-all duration-300"
                    placeholder="Password"
                  />
                  <i className="fas fa-lock absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 group-hover:text-[#2ecc71] transition-colors duration-300"></i>
                </div>

                <div className="flex justify-end">
                  <a onClick={() => navigate("/reset-password-email")} className="text-sm text-[#2ecc71] cursor-pointer hover:text-[#06b6d4] font-medium transition-colors duration-300">
                    Forgot Password?
                  </a>
                </div>
                <button
                  type="submit"
                  className="w-full py-3 bg-gradient-to-r from-[#2ecc71] to-[#06b6d4] text-white rounded-lg hover:from-[#2ecc71] hover:to-[#0891b2] transition-all duration-300 font-semibold shadow-lg transform hover:scale-[1.02]"
                >
                  {loading ? <i className="fas fa-spinner fa-spin"></i> : "Sign In"}
                </button>
              </form>

              <div className="mt-6 text-center">
                <p className="text-gray-400">
                  Don't have an account?

                  <Link to="/signup">
                    <span className="text-[#2ecc71] hover:text-[#06b6d4] font-medium ml-1 transition-colors duration-300"> Sign up</span>
                  </Link>
                </p>
              </div>
            </div>
          </div>
          <style jsx global>{`
        @keyframes fadeIn {
          from { opacity: 0; transform: translateY(20px); }
          to { opacity: 1; transform: translateY(0); }
        }
        @keyframes float {
          0% { transform: translateY(0px) rotate(0deg); }
          50% { transform: translateY(-10px) rotate(5deg); }
          100% { transform: translateY(0px) rotate(0deg); }
        }
        @keyframes slideIn {
          0% { opacity: 0; transform: translateX(-100px); }
          100% { opacity: 1; transform: translateX(0); }
        }
        @keyframes pulse {
          0% { transform: scale(1); }
          50% { transform: scale(1.05); }
          100% { transform: scale(1); }
        }
        .animate-fadeIn {
          animation: fadeIn 0.6s ease-out forwards;
        }
        .animate-float {
          animation: float 3s ease-in-out infinite;
        }
        .animate-slideIn {
          animation: slideIn 0.8s ease-out forwards;
        }
        input:focus {
          animation: pulse 0.5s ease-out;
        }
      `}</style>
        </div>
    </>
  );
}

export default Login;