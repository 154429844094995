"use client";
import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../Footer/Footer";
import PrivacyPDF from "../../../components/autotube/policys/PDF/Privacy.pdf";
import { useState } from "react";
import "../../../index.css"
import loho from "../../../loho.png"
import LandingNavbar from "../navbar/Navbar";
import LandingFooter from "../navbar/Footer";


function PrivaryPolicy() {
  const [currentPage, setCurrentPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="bg-black text-white">
    <LandingNavbar />
      <div className="min-h-screen bg-black">
        <div className="max-w-4xl mx-auto px-6 py-16 bg-[#1c1c1c] shadow-lg rounded-lg">
          <h1 className="text-4xl font-bold text-white mb-12 text-center font-roboto text-white">
            Privacy Policy for AutoTube.in
          </h1>
          <div className="space-y-8">
            <p className="text-white">
              At <span className="font-bold text-white">AutoTube.in</span>, your privacy is our priority. This Privacy Policy explains how we collect, use, and safeguard your personal information, including our practices for accessing, using, storing, and sharing Google user data. By using our website and services, you agree to the terms outlined below.
            </p>
            <h2 className="text-2xl font-bold mb-4 text-white">About AutoTube.in</h2>
            <p className="text-white">
              AutoTube.in operates the website <a href="https://autotube.in" className="underline text-[#9292fd]">https://autotube.in</a>, which provides tools and services for automated video creation and management (referred to as the "Service").
            </p>

            <p className="text-white">
              This policy is designed to inform users about how we handle the collection, use, and disclosure of personal information and Google user data. By using our Service, you consent to the practices described in this Privacy Policy.
            </p>

            <div>
              <h2 className="text-2xl font-bold text-white mb-4 text-white">
                Information We Collect
              </h2>
              <p className="text-white">
                To enhance your experience, we may require certain personal information, including but not limited to your name, email address, and phone number. If you integrate your Google account with our Service, we may collect Google user data based on your explicit consent during the authorization process.
              </p>
              <p className="text-white">The information collected is used to identify and contact you, provide the Service, and improve its functionality.</p>
            </div>

            <div>
              <h2 className="text-2xl font-bold text-white mb-4 text-white">
                Third-Party Services
              </h2>
              <ul className="list-disc pl-5 space-y-2">
                <li className="text-white"><a href="https://www.youtube.com/t/terms" target="_blank" className="underline text-[#9292fd]">YouTube Terms of Service</a></li>
                <li className="text-white"><a href="https://policies.google.com/privacy" target="_blank" className="underline text-[#9292fd]">Google Privacy Policy</a></li>
                <li className="text-white"><a href="https://developers.google.com/terms/api-services-user-data-policy" target="_blank" className="underline text-[#9292fd]">Google API Services User Data Policy</a></li>
                <li className="text-white"><a href="https://www.tiktok.com/en/privacy-policy" target="_blank" className="underline text-[#9292fd]">TikTok Terms of Service</a></li>
                <li className="text-white"><a href="https://developers.google.com/terms/api-services-user-data-policy#limited-use-disclosure" target="_blank" className="underline text-[#9292fd]">Google API Limited Use Disclosure</a></li>
              </ul>
              <p className="text-white">
                We may integrate with third-party platforms and services to enhance your experience. Below are links to the privacy policies of the third-party services we use:
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-bold text-white mb-4 text-white">
                YouTube Integration
              </h2>
              <p className="text-white">
                AutoTube.in uses Google APIs to enable features such as signing in with Google and uploading videos to YouTube. We strictly adhere to the Google API Services User Data Policy, including Limited Use requirements. Users can revoke our access to their data via their Google account settings.
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-bold text-white mb-4 text-white">
                Data Storage and Security
              </h2>
              <p className="text-white">
                All personal and Google user data is securely stored and protected using industry-standard encryption. Data is retained only as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law.
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-bold text-white mb-4 text-white">
                Sharing of Data
              </h2>
              <p className="text-white">
                We do not share your personal or Google user data with third parties except to provide the Service, comply with legal obligations, or protect our rights. If third parties process data on our behalf, they are bound by confidentiality agreements and must comply with applicable privacy laws.
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-bold text-white mb-4 text-white">
                Cookies and Log Data
              </h2>
              <p className="text-white">
                We collect log data and use cookies to enhance your browsing experience. This information may include your IP address, browser type, and activity on our website. You can manage cookie preferences through your browser settings.
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-bold text-white mb-4 text-white">
                Security
              </h2>
              <p className="text-white">
                We use commercially acceptable measures to protect your information. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-bold text-white mb-4 text-white">
                Links to Other Websites
              </h2>
              <p className="text-white">
                Our Service may include links to external websites. AutoTube.in is not responsible for the privacy practices or content of third-party websites. We encourage you to review the privacy policies of these sites.
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-bold text-white mb-4 text-white">
                Children's Privacy
              </h2>
              <p className="text-white">
                AutoTube.in does not knowingly collect personal information from children under the age of 13. If we become aware that a child under 13 has provided us with personal information, we will delete it immediately. Parents or guardians can contact us for assistance.
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-bold text-white mb-4 text-white">
                Refund Policy
              </h2>
              <p className="text-white">
                Unfortunately, we do not offer refunds for any payments made for our Service. However, users may cancel their subscriptions at any time. Once canceled, no further charges will be incurred.
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-bold text-white mb-4 text-white">
                Shipping Policy
              </h2>
              <p className="text-white">
                As AutoTube.in provides software-based services, there are no physical goods to ship. Users receive immediate access to our Service upon successful payment.
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-bold text-white mb-4 text-white">
                Changes to This Privacy Policy
              </h2>
              <p className="text-white">
                We may update this Privacy Policy periodically. Any changes will be posted on this page and take effect immediately. We encourage users to review the Privacy Policy regularly to stay informed.
              </p>
            </div>

            <div>
              <h2 className="text-2xl font-bold text-white mb-4 text-white">
                Contact Us
              </h2>
              <p className="text-white">
                If you have any questions, concerns, or suggestions about our Privacy Policy, do not hesitate to <a href="/contact" className="underline text-[#9292fd]">contact us</a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <LandingFooter/>
    </div>
  );
}

export default PrivaryPolicy;