import React from 'react';
import ReactLoading from 'react-loading';

const Loader = () => {
  return (
    <div className="flex justify-center ">
      <ReactLoading type="spin" color="#3498db" height={40} width={40} />
    </div>
  );
};

export default Loader;