import React, { useEffect, useState } from 'react';
import DashboardNavbar from '../navbar/DashboardNavbar';
import { useCallback } from 'react';
import NavTab from './NavTab';
import Footer from '../Footer/Footer';
import Tabs from './SocialMediaCard';
import { useModal } from '../../context/ModalContext';
import { useDarkMode } from '../../context/DarkModeContext';
import { DeleteAllPost, RegenerateDiscription, RegenerateTittle, socialMediaList, YoutubeContentUpdate } from '../api/Service';
import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';
import { GiNunFace } from 'react-icons/gi';

const AutoPostQueue = () => {
    const { isModalOpen } = useModal();
    const { darkMode } = useDarkMode();
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(true)
    const [animalsData, setAnimalsData] = useState(
        [
        ])
    const [nolist, setNolist] = useState(false)
    const [cancel, setCancel] = useState(1)
    const [id, setId] = useState(null)
    const [cancelType, setCancelType] = useState(null)
    const [regeneratedTitle, setRegeneratedTitle] = useState(null)
    const [regeneratedDiscription, setRegeneratedDiscription] = useState(null)
    const [titleLoading, setTitleLoading] = useState(false)
    const [descriptionLoading, setDescriptionLoading] = useState(false)
    const [DeleteModal, setDeleteModal] = useState(false)
    const navigate = useNavigate()

    const openModal = (id, type) => { setId(id); setCancelType(type); setShowModal(true); setCancel(2) };

    const closeModal = () => setShowModal(false);

    const handleConfirmCancel = () => {
        setCancel(2)
        CancelPost();
        closeModal();
        window.location.reload()
    };
    const DeleteAll = async () => {
        try {
            const res = await DeleteAllPost()
            if (res.status) {
                subscriptionList()
                setDeleteModal(false)
            }
            console.log(res)
        } catch (err) {
            console.log(err)
        }

    }

    const subscriptionList = async () => {
        try {
            const res = await socialMediaList()
            console.log(res.data, 'hhhhhh')
            setAnimalsData(res.data)
            if (res.data.length === 0) {
                setNolist(true)
            } else {
                setNolist(false)
            }
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        subscriptionList();
    }, [loading]);
    const handleEditToggle = useCallback(
        (id) => {
            setAnimalsData((prevData) =>
                prevData.map((animal) =>
                    animal.id === id ? { ...animal, isEditing: !animal.isEditing } : animal
                )
            );
        },
        [setAnimalsData]
    );

    const handleUpdate = (id, updatedTitle, type, updatedDescription, updatedDate, updatedTime, updatedTimezone) => {
        setAnimalsData((prevData) =>
            prevData.map((animal) =>
                animal.id === id
                    ? { ...animal, youtube_title: updatedTitle, youtube_description: updatedDescription, schedule_date: updatedDate, schedule_time: updatedTime, time_zone: updatedTimezone, isEditing: false }
                    : animal
            )
        );
        const data = {
            id: id,
            type: type,
            youtube_title: updatedTitle,
            youtube_description: updatedDescription,
            schedule_date: updatedDate,
            schedule_time: updatedTime,
            time_zone: updatedTimezone
        }
        try {
            const response = YoutubeContentUpdate(data)
            if (response) {
                setRegeneratedDiscription(null)
                setRegeneratedTitle(null)
            }
            console.log(response)
        } catch (error) {
            console.log(error)
        }
        console.log(animalsData)
    };
    const CancelPost = async () => {
        const data = {
            id: id,
            type: cancelType,
            youtubeUpload: cancel
        }
        try {
            const response = await YoutubeContentUpdate(data)
            console.log(response)
        } catch (error) {
            console.log(error)
        }
    }
    const handleChange = (id, updatedTitle, updatedDescription, updatedDate, updatedTime, updatedTimezone) => {
        setAnimalsData((prevData) =>
            prevData.map((animal) =>
                animal.id === id
                    ? { ...animal, youtube_title: updatedTitle, youtube_description: updatedDescription, schedule_date: updatedDate, schedule_time: updatedTime, time_zone: updatedTimezone, }
                    : animal
            )
        );
    };
    const TitleRegenerate = async (description, title, topic) => {
        setTitleLoading(true)
        const data = {
            description: description,
            title: title,
            topic: topic
        }
        try {
            const res = await RegenerateTittle(data)

            setRegeneratedTitle(res.data.data.title)
            if (res.status) {
                setTitleLoading(false)
            }

        } catch (error) {
            setTitleLoading(false)
            console.log(error)

        }
    }
    const DescriptionRegenerate = async (description, title, topic) => {
        setDescriptionLoading(true)
        const data = {
            title: title,
            description: description,
            topic: topic
        }
        try {
            const res = await RegenerateDiscription(data)

            setRegeneratedDiscription(res.data.data.description)
            if (res.status) {
                setDescriptionLoading(false)
            }
        } catch (error) {
            console.log(error)
            setDescriptionLoading(false)
        }
    }


    return (<>
        <div className={darkMode ? "bg-black text-white" : "bg-gray-100 text-black"}>

            <DashboardNavbar />
            <div className="flex md:h-max h-max  ">
                {(!showModal && !DeleteModal) ? (
                    <div className="absolute md:relative md:w-[23%] w-[21%] bg text-white">
                        <NavTab />
                    </div>
                ) : (<div className="absolute md:relative md:w-[23%] w-[21%] bg-opacity-50">

                </div>)}
                <div className={`maindiv w-full pt-4 lg:p-[44px] overflow-x-hidden font-montserrat h-max lg:h-full space-y-6 flex justify-center pb-20 flex-col  ${darkMode ? "bg-black" : "bg-gray-100"} text-[#467ff7] overflow-auto`}>
                    {!nolist ? (<> <div className="flex flex-col ">
                        <div className="flex flex-col md:flex-row md:items-center md:justify-between space-y-4 md:space-y-0">
                            <h1 className="createh1 text-center font-bold text-[2.2rem]">
                                Auto Posted Content
                            </h1>
                            <button
                                className="bg-red-600 text-white px-6 py-2 rounded hover:bg-red-800"
                                onClick={() => setDeleteModal(true)}
                            >
                                <span>Delete All</span>
                            </button>
                        </div>



                    </div>
                        <div className="space-y-4 h-[800px]">
                            {animalsData.map((animal) => (
                                <div key={animal.id} className={`${darkMode ? "bg-black text-white border-1 border-gray-500 " : "bg-white text-black"} rounded-lg shadow-md p-6`}>
                                    <div className="flex flex-col md:flex-row justify-between items-start mb-4">
                                        <div className="flex-1">
                                            <div className="space-y-4">
                                                {animal.isEditing ? (

                                                    <>
                                                        <div className="flex items-center gap-2">
                                                            <input
                                                                className="w-full p-2 border rounded"
                                                                type="text"
                                                                value={regeneratedTitle ? regeneratedTitle : animal.youtube_title}
                                                                onChange={(e) => {
                                                                    if (regeneratedTitle) {
                                                                        handleChange(
                                                                            animal.id,
                                                                            regeneratedTitle,
                                                                            animal.youtube_description,
                                                                            animal.schedule_date,
                                                                            animal.schedule_time,
                                                                            animal.time_zone
                                                                        )
                                                                    } else {
                                                                        handleChange(
                                                                            animal.id,
                                                                            e.target.value,
                                                                            animal.youtube_description,
                                                                            animal.schedule_date,
                                                                            animal.schedule_time,
                                                                            animal.time_zone
                                                                        )
                                                                    }
                                                                }
                                                                }
                                                            />
                                                            <button className="bg-gray-100 p-2 rounded hover:bg-gray-200" onClick={() => TitleRegenerate(animal.youtube_description, animal.youtube_title, animal.topic)}>
                                                                <i className={` ${titleLoading ? "fas fa-sync-alt fa-spin" : "fas fa-sync-alt"}`}></i>
                                                            </button>
                                                        </div>

                                                        <div className="flex items-center gap-2">
                                                            <textarea
                                                                name="description"
                                                                className="w-full p-2 border rounded"
                                                                rows="3"
                                                                value={regeneratedDiscription ? regeneratedDiscription : animal.youtube_description}
                                                                onChange={(e) => {
                                                                    if (regeneratedDiscription) {
                                                                        handleChange(
                                                                            animal.id,
                                                                            animal.youtube_title,
                                                                            regeneratedDiscription,
                                                                            animal.schedule_date,
                                                                            animal.schedule_time,
                                                                            animal.time_zone
                                                                        )
                                                                    } else {
                                                                        handleChange(
                                                                            animal.id,
                                                                            animal.youtube_title,
                                                                            e.target.value,
                                                                            animal.schedule_date,
                                                                            animal.schedule_time,
                                                                            animal.time_zone
                                                                        )
                                                                    }
                                                                }}
                                                            />
                                                            <button className="bg-gray-100 p-2 rounded hover:bg-gray-200" onClick={() => DescriptionRegenerate(animal.youtube_description, animal.youtube_title, animal.topic)}>
                                                                <i className={` ${descriptionLoading ? "fas fa-sync-alt fa-spin" : "fas fa-sync-alt"}`}></i>
                                                            </button>
                                                        </div>

                                                        <div className={`${darkMode ? "text-white" : "text-blue-700"}`}>
                                                            <div className="flex space-x-2">
                                                                <input
                                                                    className={`w-1/2 p-2 border rounded ${darkMode ? "bg-black" : "bg-white"} ${darkMode ? "text-white" : "text-black"}`}
                                                                    value={animal.schedule_date ? moment(animal.schedule_date).format('YYYY-MM-DD') : ""}
                                                                    onChange={(e) => {
                                                                        if (e.target.value > '2024-11-19') {
                                                                            handleChange(
                                                                                animal.id,
                                                                                animal.youtube_title,
                                                                                animal.youtube_description,
                                                                                e.target.value,
                                                                                animal.schedule_time,
                                                                                animal.time_zone
                                                                            )
                                                                        } else {
                                                                            return false;
                                                                        }
                                                                    }

                                                                    }
                                                                    min='2024-11-19'
                                                                    type="date"
                                                                    name="Date"
                                                                />
                                                                <input
                                                                    className={`w-1/2 p-2 border rounded ${darkMode ? "bg-black" : "bg-white"} ${darkMode ? "text-white" : "text-black"}`}
                                                                    value={animal.schedule_time || ""}
                                                                    onChange={(e) =>
                                                                        handleChange(
                                                                            animal.id,
                                                                            animal.youtube_title,
                                                                            animal.youtube_description,
                                                                            animal.schedule_date,
                                                                            e.target.value,
                                                                            animal.time_zone
                                                                        )
                                                                    }
                                                                    type="time"
                                                                    name="time"
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="mb-4">
                                                            <select
                                                                value={animal.time_zone || ""}
                                                                onChange={(e) =>
                                                                    handleChange(
                                                                        animal.id,
                                                                        animal.youtube_title,
                                                                        animal.youtube_description,
                                                                        animal.schedule_date,
                                                                        animal.schedule_time,
                                                                        e.target.value
                                                                    )
                                                                }
                                                                className={`w-full p-2 ${darkMode ? "bg-black" : "bg-white"} ${darkMode ? "text-white" : "text-black"} border rounded`}
                                                                name="timezone"
                                                            >
                                                                <option value="GMT-10:00 (Honolulu)">GMT-10:00 (Honolulu)</option>
                                                                <option value="GMT-09:00 (Anchorage)">GMT-09:00 (Anchorage)</option>
                                                                <option value="GMT-08:00 (Los Angeles)">GMT-08:00 (Los Angeles)</option>
                                                                <option value="GMT-07:00 (Denver, Phoenix)">GMT-07:00 (Denver, Phoenix)</option>
                                                                <option value="GMT-06:00 (Chicago, Mexico City)">GMT-06:00 (Chicago, Mexico City)</option>
                                                                <option value="GMT-05:00 (New York, Toronto)">GMT-05:00 (New York, Toronto)</option>
                                                                <option value="GMT-04:00 (Halifax)">GMT-04:00 (Halifax)</option>
                                                                <option value="GMT-03:00 (São Paulo)">GMT-03:00 (São Paulo)</option>
                                                                <option value="GMT+00:00 (London)">GMT+00:00 (London)</option>
                                                                <option value="GMT+01:00 (Paris, Berlin)">GMT+01:00 (Paris, Berlin)</option>
                                                                <option value="GMT+02:00 (Cairo)">GMT+02:00 (Cairo)</option>
                                                                <option value="GMT+03:00 (Moscow)">GMT+03:00 (Moscow)</option>
                                                                <option value="GMT+05:30 (Mumbai)">GMT+05:30 (Mumbai)</option>
                                                                <option value="GMT+08:00 (Singapore, Beijing)">GMT+08:00 (Singapore, Beijing)</option>
                                                                <option value="GMT+09:00 (Tokyo)">GMT+09:00 (Tokyo)</option>
                                                                <option value="GMT+10:00 (Sydney)">GMT+10:00 (Sydney)</option>
                                                            </select>
                                                        </div>
                                                    </>

                                                ) : (

                                                    <>
                                                        <h2 className="text-xl font-semibold mb-2">{animal.youtube_title}</h2>
                                                        <p className="text-gray-600 mb-2">{animal.youtube_description}</p>
                                                        <p className="text-gray-600 mb-2 flex items-center">
                                                            Content Type: {animal.topic}
                                                            <div className="bg-red-500 text-white w-[30px] h-[35px] p-1.5 rounded-full transition-transform hover:scale-110 ml-2 inline-flex items-center justify-center">
                                                                <i className="fab fa-youtube"></i>
                                                            </div>
                                                        </p>

                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <div className="ml-4 flex flex-col items-end">
                                            <div className="text-sm text-gray-500 mb-2">
                                                <span>Scheduled: {moment(animal.schedule_date).format('YYYY-MM-DD')}</span>
                                                <br />
                                                <span>{moment(animal.schedule_time, 'HH:mm:ss').format('hh:mm A')} ({animal.time_zone})</span>
                                            </div>

                                            <div className="flex items-center space-x-2">
                                                {animal.isEditing ? (
                                                    <>
                                                        <button
                                                            className="bg-green-500 text-white px-6 py-2 rounded hover:bg-green-600"
                                                            onClick={() => {
                                                                if (regeneratedDiscription && regeneratedTitle) { handleUpdate(animal.id, regeneratedTitle, animal.type, regeneratedDiscription, animal.schedule_date, animal.schedule_time, animal.time_zone) }
                                                                else if (regeneratedTitle && !regeneratedDiscription) { handleUpdate(animal.id, regeneratedTitle, animal.type, animal.youtube_description, animal.schedule_date, animal.schedule_time, animal.time_zone) }
                                                                else if (regeneratedDiscription && !regeneratedTitle) { handleUpdate(animal.id, animal.youtube_title, animal.type, regeneratedDiscription, animal.schedule_date, animal.schedule_time, animal.time_zone) }
                                                                else { handleUpdate(animal.id, animal.youtube_title, animal.type, animal.youtube_description, animal.schedule_date, animal.schedule_time, animal.time_zone) }
                                                            }}
                                                        >
                                                            Update
                                                        </button>

                                                    </>
                                                ) : (<>
                                                    {animal.is_posted === 1 ? (
                                                        <button
                                                            className="flex items-center space-x-2 bg-blue-500 text-white px-6 py-2 rounded hover:bg-blue-600"

                                                            onClick={() => {
                                                                if (animal.type === 'Ai Image') {
                                                                    navigate("/app/preview", {
                                                                        state: {
                                                                            videoUrl: `/${animal.userId}/${animal.video_id}/output.mp4`,
                                                                            path: `/${animal.userId}/${animal.video_id}/images`,
                                                                            scriptPath: `/${animal.userId}/${animal.video_id}/scriptAndPrompt/generated_scripts.txt`,
                                                                            videoid: animal.video_id,
                                                                            type: animal.imageSize,
                                                                            userid: animal.userId
                                                                        },
                                                                    });
                                                                } else if (animal.type === 'Ai Video') {
                                                                    navigate("/app/ai-video-preview", {
                                                                        state: {
                                                                            videoUrl: `/${animal.userId}/${animal.video_id}/output.mp4`,
                                                                            path: `/${animal.userId}/${animal.video_id}/images`,
                                                                            scriptPath: `/${animal.userId}/${animal.video_id}/scriptAndPrompt/generated_scripts.txt`,
                                                                            videoid: animal.video_id,
                                                                            type: animal.imageSize,
                                                                            userid: animal.userId
                                                                        },
                                                                    });
                                                                }

                                                            }}
                                                        >

                                                            <span>View</span>
                                                        </button>) : (
                                                        <>
                                                            <button
                                                                className="flex items-center space-x-2 bg-red-600 text-white px-6 py-2 rounded hover:bg-red-800"
                                                                // onClick={() => CancelPost(animal.id, animal.type)}
                                                                onClick={() => openModal(animal.id, animal.type)}
                                                            >

                                                                <span>Cancel</span>
                                                            </button>
                                                            {animal.youtubeUpload === 1 && <button
                                                                className="flex items-center space-x-2 bg-blue-500 text-white px-6 py-2 rounded hover:bg-blue-600"
                                                                onClick={() => handleEditToggle(animal.id)}
                                                            >
                                                                <i className="fas fa-edit"></i>
                                                                <span>Edit</span>
                                                            </button>}

                                                        </>)}


                                                </>)}
                                            </div>
                                        </div>

                                    </div>
                                    <div className="flex items-center text-sm">
                                        {animal.youtubeUpload === 2 ?
                                            (<span className='px-3 py-1.5 rounded-full transition-colors duration-300 bg-red-500/20 text-red-400'>Canceled<i class="fas fa-question-circle ml-2"></i></span>)
                                            : (<span className={`${animal.is_posted === 0 ? 'px-3 py-1.5 rounded-full transition-colors duration-300 bg-yellow-500/20 text-yellow-400' : 'px-3 py-1.5 rounded-full transition-colors duration-300 bg-green-500/20 text-green-400'}`}>{animal.is_posted === 0 ? 'Pending' : 'Completed'}<i class="fas fa-question-circle ml-2"></i></span>)}

                                    </div>
                                </div>
                            ))}
                        </div></>) : (
                        <div className={`h-max-[100vh] h-min-[100vh] ${darkMode ? 'bg-black text-white ' : 'bg-gray-50'}`} style={{ minHeight: '100vh' }}>
                            <div className='p-6 text-center lg:h-[400px] flex justify-center gap-4 items-center md:justify-center flex-col md:items-center'>
                                <div className={` shadow-lg h-30 w-100 p-4 rounded-md ${darkMode ? 'border-2 border-gray-400' : ''} `}>
                                    <h1 className={`text-center text-2xl ${darkMode ? 'text-white' : 'text-black'} `}>No Video is Scheduled To Post on Social Media Yet !!!</h1>

                                </div>
                            </div>
                        </div>)}


                </div>
            </div>


            <Footer />
        </div>
        {/* Confirmation Modal */}
        {showModal && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <div className={`bg-white p-6 rounded-lg shadow-lg w-80 ${darkMode ? "bg-gray-800 text-white" : "bg-white text-black"}`}>
                    <h2 className="text-lg font-bold mb-4">Confirm Cancellation</h2>
                    <p className="mb-4">Are you sure you want to cancel this post?</p>
                    <div className="flex justify-end space-x-2">
                        <button
                            onClick={closeModal}
                            className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400 text-black"
                        >
                            No
                        </button>
                        <button
                            onClick={handleConfirmCancel}
                            className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
                        >
                            Yes, Cancel
                        </button>
                    </div>
                </div>
            </div>
        )}
        {DeleteModal &&
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                <div className={`bg-white p-6 rounded-lg shadow-lg w-80 ${darkMode ? "bg-gray-800 text-white" : "bg-white text-black"}`}>
                    <h2 className="text-lg font-bold mb-4">Confirm Delete</h2>
                    <p className="mb-4">Are you sure you want to Delete All the scheduled video posts?</p>
                    <div className="flex justify-end space-x-2">
                        <button
                            onClick={() => setDeleteModal(false)}
                            className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400 text-black"
                        >
                            No
                        </button>
                        <button
                            onClick={() => DeleteAll()}
                            className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
                        >
                            Yes, Delete
                        </button>
                    </div>
                </div>
            </div>}
    </>)
}

export default AutoPostQueue