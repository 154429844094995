import React, { useState, useEffect } from 'react';
import DashboardNavbar from './DashboardNavbar';
import NavTab from '../navtab/NavTab';
import Footer from '../Footer/Footer';
import img1 from '../images/img1.png';
import { useDarkMode } from '../../context/DarkModeContext';
import { AffiliateCode, AffiliateList, PaypalEmail } from '../api/Service';
import { CompressOutlined } from '@mui/icons-material';
import { faLeaf, faSleigh } from '@fortawesome/free-solid-svg-icons';

function Affilates() {
    const [focusedField, setFocusedField] = useState(null);
    const [inputValue, setInputValue] = useState(null);
    const [show, setShow] = useState(false)
    const { darkMode } = useDarkMode()
    const [error, setError] = useState(false)
    const [errorValue, setErrorValue] = useState(null)
    const [loading, setLoading] = useState(true)
    const [earning, setEarning] = useState('')
    const [NumberOfSignup, setNumberSignup] = useState('')
    const [url, seturl] = useState('')
    const [buttonLoading, setButtonLoading] = useState(false)
    const [updateLoading, setUpdateLoading] = useState(false)
    const [paypalEmail, setPaypalEmail] = useState(null)
    const [paypalError, setPaypalError] = useState(false)
    const [confirm, setConfirm] = useState(false)

    const handleChange = (event) => {
        setInputValue(event.target.value);
    };
    const handlePaypalEmailChange = (e) => {
        setPaypalEmail(e.target.value);
    }

    useEffect(() => {
        if (loading) {
            AffiliateData();
        }
    }, [loading]);

    const AffiliateData = async () => {
        try {
            const response = await AffiliateList()
        
            if (response.status === 200) {
                setShow(true)
                setEarning(response.data.result.earnings)
                setNumberSignup(response.data.result.no_of_signups)
                setPaypalEmail(response.data.result.paypal_id)
                seturl(response.data.result.affiliate_url)
            } else {
                setShow(false)
            }
            console.log(response.data, 'listt')
        } catch (err) {
            console.log(err)

        }
    }
    const PaypalSubmit = async (event) => {
        setConfirm(false)
        setPaypalError(false)
        setUpdateLoading(true)
        event.preventDefault()
        const data = {
            paypal_id: paypalEmail,
        }
        if(paypalEmail===null){
            setPaypalError(true)
        }else{
            setPaypalError(false)
            try {
                const res = await PaypalEmail(data)
                if (res.status === 200) {
                    setConfirm(true)
                    setUpdateLoading(false)
                }
            } catch (error) {
                setPaypalError(true)
                setUpdateLoading(false)
                console.log(error)
            }
        }
        
    }
    const AffiliateSubmit = async (event) => {

        setButtonLoading(true)
        event.preventDefault();
        console.log('entered')
        const data = {
            affiliate_id: inputValue
        }
        if(inputValue===null){
            setError(true)
            setErrorValue('Please Enter the Affiliate Id')
        }else{
            setError(false)
            try {
                console.log('in tryy', data)
                const response = await AffiliateCode(data)
                console.log(response)
                if (response.status === 200) {
                    // setLoading(false)
                    setButtonLoading(false)
                    AffiliateData()
                    setShow(true)
                } else {
                    setShow(false)
                }
            } catch (error) {
                setButtonLoading(false)
                setErrorValue(error)
                setError(true)
                console.log(error)
            }
        }
       
    }

    return (
        <>
            <DashboardNavbar />
            <div className={`${darkMode ? 'bg-black' : 'bg-gray-100'} flex min-h-screen`}>
                <div className="md:w-1/4 w-1/5">
                    <NavTab />
                </div>
                <div className={`flex w-full p-4 ${darkMode ? 'bg-black' : 'bg-white'} overflow-auto`}>
                    {!show ? (
                        <div className={`flex flex-col items-center mx-auto p-6 h-[80%] ${darkMode ? 'bg-black border-2 border-gray-100' : 'bg-white'} shadow-md rounded-lg md:w-[570px] w-[300px]`}>
                            <div className="h-36 w-36 rounded-full overflow-hidden mb-4">
                                <img src={img1} alt="Affiliate" className="h-full w-full object-cover" />
                            </div>
                            <h1 className="text-blue-900 font-semibold text-xl mb-4">Affiliate Signup</h1>
                            <form className="space-y-4 w-full">
                                <div className="relative">
                                    <label
                                        htmlFor="url"
                                        className={`absolute top-[-10px] left-4 text-xs font-medium bg-white px-1 transition-all ${focusedField === 'url' ? 'text-blue-500' : 'text-gray-500'}`}
                                    >
                                        Create Your URL
                                    </label>
                                    <div className="flex items-center border border-gray-300 rounded shadow-sm focus-within:border-blue-500 focus-within:ring-2 focus-within:ring-blue-500">
                                        <span className="text-sm text-gray-500 px-3 py-2 bg-white">https://{window.location.host}/app/signup?ref=</span>
                                        <input
                                            type="text"
                                            id="ref"
                                            name="ref"
                                            value={inputValue}
                                            onChange={handleChange}
                                            // onFocus={() => setFocusedField('url')}
                                            onBlur={() => setFocusedField(null)}
                                            className="w-[38%] border-2 border-gray-400 p-3 focus:outline-none"
                                        />
                                    </div>
                                    {error && <p className="text-red-600 text-sm mt-2">
                                        {errorValue}
                                    </p>}
                                    <button
                                        type="submit"
                                        onClick={AffiliateSubmit}
                                        className="w-full mt-6 bg-blue-600 text-white font-semibold py-2 rounded hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    >{buttonLoading ? "Creating..." : "CREATE"}
                                    </button>
                                </div>
                            </form>
                        </div>) : (
                        <div className="w-full max-w-4xl mx-auto space-y-8 mt-8">
                            {/* <div className="h-36 w-36 flex justify-center rounded-full overflow-hidden mb-4">
                                <img src={img1} alt="Affiliate" className="h-full  w-full object-cover" />
                            </div> */}
                            <section>

                                <div className=" shadow-md rounded-lg p-6 mt-4">
                                    <h2 className="text-lg text-blue-700 border-b-2 border-blue-700 w-[90px] font-semibold mb-4">Your Stats</h2>
                                    <div className="grid grid-cols-5 gap-4">
                                        {[
                                            {
                                                name: 'Sign Ups',
                                                value: NumberOfSignup || '0',
                                            },
                                            {
                                                name: 'Earnings',
                                                value: earning || '0',
                                            },
                                        ].map((item, index) => (
                                            <div key={index} className="flex flex-col items-center border-r border-gray-400 text-center">
                                                <div className="text-2xl font-bold">{item.name === 'Earnings' && '$'}{item.value}</div>
                                                <div className="text-gray-500">{item.name}</div>
                                            </div>
                                        ))}
                                    </div>

                                </div>
                            </section>

                            <section>
                                <div className=" shadow-md rounded-lg p-6">
                                    <h2 className="text-lg text-blue-700 border-b-2 border-blue-700 w-[150px] font-semibold mb-4">Your Affiliate URL</h2>
                                    <div className="bg-gray-100 p-2 rounded text-center">
                                        <span> https://{window.location.host}/app/signup?ref={url}</span>
                                    </div>
                                </div>
                            </section>

                            <section>
                                <div className=" shadow-md rounded-lg p-6">
                                    <h2 className="text-lg text-blue-700 border-b-2 border-blue-700 w-[120px] font-semibold mb-4">PayPal Id</h2>
                                    <div className="space-y-4">
                                        {paypalError && <p className="text-red-600 text-sm mt-2">
                                            Please Enter Paypal Id
                                        </p>
                                        }
                                        <input
                                            value={paypalEmail}
                                            onChange={handlePaypalEmailChange}
                                            type="text"
                                            placeholder="Type your PayPal id here"
                                            className="w-full border border-gray-300 rounded px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        />
                                        <button onClick={PaypalSubmit}
                                            className="w-full bg-blue-600 text-white font-semibold py-2 rounded hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500"
                                        >{updateLoading ? 'Updating...' : 'Update'}

                                        </button>
                                        {confirm&& <p className="text-green-600 text-sm mt-2">
                                            Paypal Id Updated Successfully!!
                                        </p>
                                        }
                                        <p className="text-gray-500 text-sm">Note: This is where your commission payouts will be sent</p>
                                    </div>
                                </div>
                            </section>

                            {/* <section>
                                <div className=" shadow-md rounded-lg p-6">
                                    <h2 className="text-lg text-blue-700 border-b-2 border-blue-700 w-[150px] font-semibold mb-4">Marketing Assets</h2>
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                                        {[
                                            { title: 'Cover Photo', src: 'https://video-cdn.autoshorts.ai/assets/FacebookCover2.png' },
                                            { title: 'Logo', src: 'https://video-cdn.autoshorts.ai/assets/LogoGradient.png' },
                                            { title: 'Marketing #1', src: 'https://video-cdn.autoshorts.ai/assets/Interesting%201920x1200.png' },
                                            { title: 'Marketing #2', src: 'https://video-cdn.autoshorts.ai/assets/Interesting9x16.png' },
                                            { title: 'Promo Video #1', src: 'https://video-cdn.autoshorts.ai/assets/FinalHeroDemoCompressed.mov#t=0.2', video: true },
                                            { title: 'Vertical Video', src: 'https://video-cdn.autoshorts.ai/assets/9x16 Demo(Compressed).mov#t=0.2', video: true },
                                        ].map((asset, index) => (
                                            <div key={index} className="text-center">
                                                <h3 className="font-semibold mb-2">{asset.title}</h3>
                                                {asset.video ? (
                                                    <video controls className="mx-auto mb-2 w-full max-w-xs rounded">
                                                        <source src={asset.src} type="video/mp4" />
                                                    </video>
                                                ) : (
                                                    <img src={asset.src} alt={asset.title} className="mx-auto mb-2 w-full max-w-xs rounded" />
                                                )}
                                                <a href={asset.src} className="text-blue-500 underline" download>
                                                    Download
                                                </a>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </section> */}

                            <section>
                                <div className=" shadow-md rounded-lg p-6">
                                    <h2 className="text-lg text-blue-700 border-b-2 border-blue-700 w-[150px] font-semibold mb-4">Frequently Asked</h2>
                                    <div className="space-y-4">
                                        {[
                                            { question: 'How does this work?', answer: "We give you a unique affiliate URL to share. If someone registers an account and purchases a membership within 30 days after clicking the link, we'll give you a cut of the sale!" },
                                            { question: 'What commissions do I get?', answer: 'Our affiliate program offers a 30% recurring commission. Our recurring model means great passive income for you.' },
                                            { question: 'When are payouts sent out?', answer: 'Payouts will be sent on the 1st of every month through PayPal.' },
                                            { question: 'Is there a minimum payout requirement?', answer: 'Yes. You need to have a minimum "unpaid commissions" of $30 before receiving your payout in order to keep transaction fees low.' },
                                            { question: 'Do you support payout methods other than PayPal?', answer: 'No, we only offer payouts through PayPal at the moment. Requests for other payout methods will be denied.' },
                                            { question: 'How does affiliate tracking work?', answer: 'If someone clicks on your link, a 30-day cookie is attached in their browser. This tracks them so when they register an account they are locked-in to be attributed to you forever.' },
                                            { question: 'Can I run ads to promote Shortsgenerator.ai?', answer: 'In general, you can. However, we DO NOT allow promotion through Google Search ads. You can run ads on other platforms like Facebook, Instagram, TikTok, Reddit, YouTube, etc.' },
                                        ].map((faq, index) => (
                                            <div key={index} className="space-y-2">
                                                <h3 className="text-blue-900 font-semibold">{faq.question}</h3>
                                                <p className="text-gray-500">{faq.answer}</p>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </section>
                        </div>)}



                </div>
            </div>
            <Footer />
        </>

    );
}

export default Affilates;
