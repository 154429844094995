"use client";
import React from "react";
import { useState, useEffect } from "react";
import "font-awesome/css/font-awesome.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
// import "../index.css";
// import Terrifying from "../media/video/Terrifying.mp4";
// import video from "../media/video/video4.mp4";
// import Wrong from "../media/video/Wrong.mp4";
// import Enchanted from "../media/video/Enchanted.mp4";
// import Discoveries from "../media/video/Discoveries.mp4";
import Capture from "../media/image/Capture.jpg";
import cat from "../media/image/cat.jpg";
import enchanted from "../media/image/enchanted.jpg";
import Leonardo from "../media/image/Leonardo_Kino.jpg";
import terrifying from "../media/image/terrifying.jpg";
import { Link } from "react-router-dom";
import Myplan from "../myplan/Myplan";
// import ThemeToggle from "./Themefile";
import "../../../index.css"
import loho from "../../../loho.png"
import LandingFooter from "../navbar/Footer";



function MainComponentPage() {
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredBtn, setHoveredBtn] = useState(false);
  const [openIndex, setOpenIndex] = useState(null);
  const [playingVideo, setPlayingVideo] = useState(null);
  const [isPaused, setIsPaused] = useState(false);

  const [activeSlide, setActiveSlide] = useState(0);

  const slides = [
    {
      title: "Stream to Short",
      videos: 'https://autotube.in/defaults/Childrens.mp4',
      image: terrifying,
    },
    {
      title: "Comment Silent Video",
      videos: 'https://autotube.in/defaults/DisneyToon.mp4',
      image: enchanted,
    },
    {
      title: "Faceless Videos",
      videos: 'https://autotube.in/defaults/n7pk1hz19z.mp4',
      image: Capture,
    },
    {
      title: "Faceless Videos",
      videos: 'https://autotube.in/defaults/f007dsau4wk.mp4',
      image: Capture,
    },
    {
      title: "Faceless Videos",
      videos: 'https://autotube.in/defaults/FoodAutoshortsV2.mp4',
      image: Capture,
    },
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setActiveSlide((prev) => (prev + 1) % slides.length);
    }, 3000);
    return () => clearInterval(timer);
  }, []);

  const faqs = [
    {
      question: "Can I create videos in any niche?",
      answer:
        "You bet! You can create a series for nearly any topic or niche you want. Either choose from our preset list or use a custom prompt to describe your own.",
    },
    {
      question: "Are the videos unique?",
      answer:
        "Unlike other services that re-use the same video over and over, we create a new video for each series using generative AI. This means that each video is unique and will not be the same as any other video. Even if two videos have the same topic, the script and images will be completely different due to generative AI.",
    },
    {
      question: "How many videos can i creete per day?",
      answer:
        `For a one time plan, you an create maximum 5 videos per day. For the unlimited plan, this daily limit is exceeded till 10 videos per day.`,
    },
    {
      question: "How many videos can I create per day?",
      answer:
        ` "The number of videos created by each series can be seen on our pricing page. Remember, AutoTube doesn't focus on individual video creation. Instead, you set up a Series that automatically generates videos on a schedule.

           Features that render a new video such as making script edits, or changing your series do not count against your plan."`,
    },
    {
      question: "How do I create a video?",
      answer:
        "You simply choose your preferred video type, then choose existing topic, or enter your custom topic, choose your video preset and other settings like the Audio, Video duration, its orientation and just hit generate. We give you all freedom to customise your video beforehand, you are free to choose any options",
    },
    {
      question: "Do I own the videos?",
      answer:
        "Yes, the videos are yours to do with as you please. You can download them and use them on other platforms, or even sell them to clients."
    },
    {
      question: "Does the platform support multiple languages?",
      answer:
        "Yes, we currently support the following languages: English, Bulgarian, Czech, Danish, Dutch, Estonian, Finnish, French, German, Greek, Hindi, Indonesian, Italian, Japanese, Korean, Malay, Norwegian, Polish, Portuguese, Russian, Spanish, Swedish, Turkish, Ukrainian."
    }
  ];

  const planarrya = [
    {
      name: "Rookie Plan",
      price: 29,
    },
    {
      name: "Starter Plan",
      price: 49,
    },
    {
      name: "Business Plan",
      price: 149,
    },
  ];

  const [plans, setPlans] = useState(planarrya);

  return (
    <div className="bg-black w-full text-white min-h-screen">
      <nav className="flex flex-wrap justify-between items-center px-4 sm:px-6 py-4 border-b border-gray-800">
        <div className="flex flex-col items-center space-y-6">
          <div className="flex items-center">
            <div className="flex items-center">
              <Link
                to="/" // Sign in ke liye path ko update karein
                className="flex items-center"
              >
                <img src={loho} alt="Logo" width={40} className="mr-2" />
                <span className="text-white text-5xl md:text-3xl font-bold">Auto</span>
                <span className="text-red-600 text-5xl md:text-3xl font-bold">Tube</span>
              </Link>
            </div>
          </div>
        </div>
        <div className="hidden md:flex space-x-4  text-lg lg:space-x-8">
          <a href="#features" className="hover:text-gray-300">
            Features
          </a>
          <a href="#pricing" className="hover:text-gray-300">
            Pricing
          </a>
          <a href="#how-it-works" className="hover:text-gray-300">
            How It Works
          </a>
          <a href="/blog" className="hover:text-gray-300">
            Blog
          </a>
        </div>
        <div className="flex  items-center space-x-4">
          {localStorage.getItem('isAuthenticated') === 'true' ? (
            <Link
              to="/dashboard" // Sign in ke liye path ko update karein
              className="font-medium hiddenbtn"
            >
              <button className="bg-[#2ecc71] text-black px-4 sm:px-6 py-2 rounded-full hover:bg-[#27ae60 text-sm sm:text-base">
                Dashboard
              </button>
            </Link>
          ) : (
            <Link
              to="/login" // Sign in ke liye path ko update karein
              className="font-medium hiddenbtn"
            >
              <button className="bg-[#2ecc71] text-black px-4 sm:px-6 py-2 rounded-full hover:bg-[#27ae60 text-sm sm:text-base">
                Get Started
              </button>
            </Link>
          )}
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="md:hidden bg-black"
          >
            <i className={`fas ${isOpen ? "fa-times" : "fa-bars"}`}></i>
          </button>
        </div>
      </nav>

      <div className={`md:hidden ${isOpen ? "block" : "hidden"} bg-black py-4`}>
        <div className="flex flex-col items-center space-y-4">
          <a href="#features" className="hover:text-gray-300">
            Features
          </a>
          <a href="#pricing" className="hover:text-gray-300">
            Pricing
          </a>
          <a href="#how-it-works" className="hover:text-gray-300">
            How It Works
          </a>
          <Link
            to="/login" // Sign in ke liye path ko update karein
            className="font-medium"
          >
            <button className="bg-[#2ecc71] text-black px-4 sm:px-6 py-2 rounded-full hover:bg-[#27ae60 text-sm sm:text-base">
              Get Started
            </button>
          </Link>
        </div>
      </div>

      <main className=" mx-auto px-4 sm:px-6">
        <section className="py-12 md:py-2 ">
        <article className="rounded-3xl px-4 sm:px-6 md:px-8 ">
          <div className="w-full relative border-x border-[#ffffff10] md:px-8 py-[7.5%] grid lg:grid-cols-2 gap-12 lg:gap-4 xl:gap-8 items-center">
            <section className="space-y-8 mt-12 lg:mt-0 flex-2 flex justify-center flex-col gap-0">
              <div className="space-y-4">
                <h1 className="text-4xl sm:text-[48px] lg:text-[42px] xl:text-5xl 2xl:text-6xl lg:text-left leading-[50px] text-start font-[&quot;Euclid_Circular_A_Regular&quot;] text-white font-medium text-balance ">
                  Auto-Pilot Faceless Videos in <span className="font-medium from-[#45EC82] from-[0.16%] via-[#70f3e6] via-[47.81%] to-[#75CEFC] to-100% bg-gradient-to-r bg-clip-text text-transparent">Minutes</span>
                </h1>
                <p className="text-md text-neutral-dark 2xl:text-base text-start lg:text-left mx-auto lg:mx-0 leading-normal font-[&quot;Inter&quot;]">
                  Our powerful AI video creation platform allows you to fully automate
                  a faceless channel. Create engaging content without ever showing
                  your face.
                </p>
              </div>
              <div className="flex justify-center lg:justify-start">
                <div className="flex  flex-col gap-2 items-start justify-start">
                  <Link to="/login" >
                  <button
                    onMouseEnter={() => setHoveredBtn(true)}
                    onMouseLeave={() => setHoveredBtn(false)}
                    className={`px-6 sm:px-8 py-3 sm:py-4 bg-gradient-to-r from-[#05ce4f] to-[#34D399] text-white text-base sm:text-lg font-bold rounded-full transform transition-all duration-300 hover:shadow-[0_0_30px_rgba(96,165,250,0.5)] ${hoveredBtn ? "scale-105" : ""}`}
                  >
                    Try AutoTube AI For Free
                  </button>
                  </Link>
                  <p className="mt-4 text-[#94A3B8] text-sm sm:text-base font-light">
                    ✨ No credit card required
                  </p>
                </div>
              </div>
            </section>
            <div className="max-h-[600px] flex items-end justify-center relative">
              <div className="bg-white/10 border-white/90 hidden border p-1 w-[120px] sm:w-1/2 sm:max-w-[240px] sm:-rotate-12 sm:absolute z-20  sm:block sm:-left-1/4 sm:right-1/4 mx-auto rounded-xl object-cover">
                <video autoPlay className="content-visibility-auto bg-white shadow-2xl rounded-[10px] object-cover" muted playsInline loop src="https://cdn.tfrv.xyz/static/Queen%20Liana%20and%20the%20Festival%20of%20Blossoms.mp4" poster="https://cdn.tfrv.xyz/static/Flower%20Princess.webp" preload="none" loading="lazy" width="100%" height="100%"></video>
              </div>
              <div className="bg-white/10 border-white/90 border p-1 w-11/12 sm:w-5/6 md:w-3/4 sm:max-w-[250px] relative z-30 mx-auto  rounded-xl object-cover">
                <video autoPlay className="content-visibility-auto bg-white shadow-2xl rounded-[10px] object-cover" muted playsInline loop src="https://cdn.tfrv.xyz/static/Lily_s%20Dutch%20Adventure.mp4" poster="https://cdn.tfrv.xyz/static/Netherland%20Girl.webp" preload="none" loading="lazy" width="100%" height="100%"></video>
              </div>
              <div className="bg-white/10 border-white/90 hidden border p-1 w-[120px] sm:w-1/2 sm:max-w-[240px] sm:rotate-12 sm:absolute  sm:block sm:-right-1/4 sm:left-1/4 z-20 mx-auto rounded-xl object-cover">
                <video autoPlay className="content-visibility-auto bg-white shadow-2xl rounded-[10px] object-cover" muted playsInline loop src="https://cdn.tfrv.xyz/static/Eldenvale_%20A%20Tapestry%20of%20Life.mp4" poster="https://cdn.tfrv.xyz/static/Eldenvale.webp" preload="none" loading="lazy" width="100%" height="100%"></video>
              </div>
              <div className="absolute bg-gradient-to-b from-[#BEFFD6] to-[#92DAFF] blur-[55px] w-[40%] h-[85%] mb-6 z-0"></div>
            </div>
          </div>
        </article>

          <div className=" bg-[#1a1a1a] text-white py-12">
            <div className="container mx-auto px-4">
              <div className="text-center mb-8 sm:mb-10">
                <h1 className="text-3xl sm:text-4xl md:text-6xl font-bold mb-4">
                  <span className="text-[#4ade80]">2909+</span> short videos
                  made by over <span className="text-[#4ade80]">758</span>{" "}
                  creators
                </h1>
              </div>

              <div
                className="relative max-w-6xl mx-auto overflow-hidden"
                onMouseEnter={() => setIsPaused(true)}
                onMouseLeave={() => {
                  if (!playingVideo) {
                    setIsPaused(false);
                  }
                }}
              >
                <div
                  className={`flex animate-scroll  ${isPaused ? "pause" : ""
                    } gap-2 sm:gap-3`}
                >
                  {[...slides, ...slides].map((slide, index) => (
                    <div
                      key={index}
                      className="w-[70%] sm:w-[50%] md:w-[25%] flex-shrink-0 px-1 sm:px-2"
                    >
                      <div className="aspect-[9/16] bg-gray-800 rounded-lg relative overflow-hidden">
                        <video
                          key={slide.videos}
                          src={slide.videos}
                          className="w-full h-full object-cover hover:scale-105 transition-transform duration-300"
                          autoPlay
                          loop
                          muted
                          onEnded={() => {
                            if (!isPaused) {
                              setIsPaused(false);
                            }
                          }}
                        >
                          <div className="absolute inset-0 bg-black bg-opacity-40 flex items-center justify-center">
                          </div>
                          <div className="absolute bottom-0 left-0 right-0 p-2 sm:p-3 bg-gradient-to-t from-black">
                            <h3 className="text-xs sm:text-sm font-bold">
                              {slide.title}
                            </h3>
                          </div>
                        </video>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <style jsx global>{`
              @keyframes scroll {
                0% {
                  transform: translateX(0);
                }
                100% {
                  transform: translateX(-100%);
                }
              }
              .animate-scroll {
                animation: scroll 30s linear infinite;
              }
              .pause {
                animation-play-state: paused;
              }
            `}</style>
          </div>
        </section>
        

        <section id="features" className="py-16 px-4 bg-[#000000]">
          <div className="mx-auto p-8 ">
            <div className="text-center mb-12">
              <span className="bg-[#1a1a1a] text-[#2ecc71] px-4 py-1 rounded-full text-sm">
                Tools
              </span>
              <h2 className="text-4xl font-bold mt-4 mb-6">Features</h2>
              <p className="text-gray-400">
                Pick the right tool, provides your input, and you'll create a
                video in no time – customize it however you want.
              </p>
            </div>

            <div className="mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="bg-[#1a1a1a] p-6 rounded-xl">
                <i className="fas fa-robot text-[#2ecc71] text-4xl mb-4"></i>
                <h3 className="text-xl font-bold mb-2">AI-Powered Creation</h3>
                <p className="text-gray-400">
                  Generate engaging content using advanced AI technology
                </p>
              </div>
              <div className="bg-[#1a1a1a] p-6 rounded-xl">
                <i className="fas fa-clock text-[#2ecc71] text-4xl mb-4"></i>
                <h3 className="text-xl font-bold mb-2">Auto-Scheduling</h3>
                <p className="text-gray-400">
                  Schedule uploads across multiple platforms automatically
                </p>
              </div>
              <div className="bg-[#1a1a1a] p-6 rounded-xl">
                <i className="fas fa-share-nodes text-[#2ecc71] text-4xl mb-4"></i>
                <h3 className="text-xl font-bold mb-2">Multi-Platform</h3>
                <p className="text-gray-400">
                  Share to YouTube with one click
                </p>
              </div>
            </div>
          </div>
        </section>

        <section
          id="how-it-works"
          className="py-12 md:py-20 border-t border-gray-800"
        >
          <h2 className="text-3xl md:text-4xl font-bold text-center mb-8 md:mb-12">
            How It Works
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
            <div className="flex-1 text-center">
              <div className="text-3xl font-bold mb-4">1</div>
              <h3 className="text-xl font-bold mb-2">Choose Your Plan</h3>
              <p className="text-gray-400">
                Select a subscription that fits your needs
              </p>
            </div>
            <div className="flex-1 text-center">
              <div className="text-3xl font-bold mb-4">2</div>
              <h3 className="text-xl font-bold mb-2">Configure Settings</h3>
              <p className="text-gray-400">
                Set your preferences and content style
              </p>
            </div>
            <div className="flex-1 text-center">
              <div className="text-3xl font-bold mb-4">3</div>
              <h3 className="text-xl font-bold mb-2">Connect YouTube</h3>
              <p className="text-gray-400">
                Link your channel and we'll handle the rest
              </p>
            </div>
          </div>
        </section>

        <section id="pricing" className="bg-[#1a1a1a]">
          <Myplan />
        </section>
      </main>

      <div className="min-h-screen items-center justify-center flex ">
        <div className="max-w-4xl rounded-md mx-auto  px-5 pt-3 pb-5 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h1 className="text-5xl font-bold bg-gradient-to-r from-green-400 to-green-600 inline-block text-transparent bg-clip-text font-roboto">
              Frequently Asked Questions
            </h1>
            <div className="mt-4 h-1 w-24 bg-gradient-to-r from-green-400 to-green-600 mx-auto rounded-full"></div>
          </div>

          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <div
                key={index}
                className="bg-[#333333] rounded-xl  hover:border-[#666666] transition-colors duration-300"
              >
                <button
                  className="w-full px-3 py-4 text-left flex justify-between items-center hover:bg-[#3d3d3d] rounded-xl transition-colors duration-200"
                  onClick={() => setOpenIndex(openIndex === index ? null : index)}
                >
                  <span className="text-lg font-medium text-white font-roboto">
                    {faq.question}
                  </span>
                  <i
                    className={`fas ${openIndex === index ? "fa-minus" : "fa-plus"} text-green-400`}
                  ></i>
                </button>

                <div
                  className={`px-6 overflow-hidden transition-all duration-300 ease-in-out ${openIndex === index ? "max-h-48 py-4" : "max-h-0"}`}
                >
                  <p className="text-gray-300 leading-relaxed font-roboto">
                    {faq.answer}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <LandingFooter />
    </div>

  );
}

export default MainComponentPage;
