import React from 'react';
import ReactDOM from 'react-dom/client';
// import { __SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED } from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, HashRouter } from 'react-router-dom';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    {/* <HashRouter> */}
    <App />
    {/* </HashRouter> */}
     </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
