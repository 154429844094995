import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import "../../../index.css"

export default function ThemeToggle() {
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light');

    useEffect(() => {
        document.documentElement.setAttribute('data-theme', theme);
        localStorage.setItem('theme', theme);
    }, [theme]);

    const toggleTheme = () => {
        setTheme(theme === 'light' ? 'dark' : 'light');
    };
    return (
        <button onClick={toggleTheme} className="p-2 ml-1 border rounded-full">
            <FontAwesomeIcon 
                icon={theme === 'light' ? faMoon : faSun} 
                style={{ color: 'var(--primary-icon-color)' }}
            />
        </button>
    );
}
