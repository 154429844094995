import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = ({ isAuthenticated }) => {
  return isAuthenticated ? <Outlet /> : <Navigate to="/app/login" />;
};

export default PrivateRoute;





// import React from 'react';
// import { Navigate, Outlet } from 'react-router-dom';

// const PrivateRoute = () => {
//   const isAuthenticated= localStorage.getItem('isAuthenticated')
//   {console.log('isAuthenticated - ' + isAuthenticated)} 
//   return isAuthenticated === 'true' ? <Navigate to="/app/dashboard" /> : <Navigate to="/app/login" />;
// };

// export default PrivateRoute;


