import React, { useState, useEffect } from "react";
import DashboardNavbar from "../components/navbar/DashboardNavbar";
import Footer from "../components/Footer/Footer";
import NavTab from "./navtab/NavTab";
import { generatePath, Link } from "react-router-dom";
import ListComponent from "./List";
import { jwtDecode } from "jwt-decode";
import { useCookies } from "react-cookie";
import "./style.css";
import { useNavigate } from "react-router-dom";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { MdPlayArrow, MdInfoOutline } from "react-icons/md";
import CreatorList from "./CreatorList";
import { listVideo, ReviewPopup } from "./api/Service";
import { Plans } from "./api/Service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faImage,
  faUserCircle,
  faClosedCaptioning,
  faVideo,
  faBolt,
  faArrowRight,
  faMagic,
  faRobot,
  faSparkles,
} from "@fortawesome/free-solid-svg-icons";
import { useModal } from "../context/ModalContext";
import { useDarkMode } from "../context/DarkModeContext";

const Dashboard = () => {
  const [cookies, setCookie, removeCookie] = useCookies(["https://accounts.google.com"]);
  const { isModalOpen } = useModal();
  const [selectedTopic, setSelectedTopic] = useState("Shorts Generator");
  const navigate = useNavigate();
  const popup = localStorage.getItem('popup')
  const [modal, setModal] = useState(false)
  const [generted, setGenerated] = useState('')
  const [loading, setLoading] = useState(true)

  const planResponse = async () => {
    try {
      const response = await Plans();
      setGenerated(response.totalvideoGenerated);

    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (loading) {
      planResponse();
    }
  }, [loading]);

  useEffect(() => {
    if (popup === "0" && generted >= 4) {
      setModal(true)
      const token = localStorage.getItem('Token')
      try {
        const res = ReviewPopup(token)
        console.log(res, 'popup res')
      } catch (error) {
        console.log(error)
      }

    } else {
      setModal(false)
    }
  })
  const { darkMode } = useDarkMode();

  const handleclick = () => {
    navigate("/videoCreate");
  };
  const handleclickavatar = () => {
    navigate("/create");
  };

  return (
    <div className=''>
      <DashboardNavbar />
      <div className={`flex md:h-max h-[max] min-h-screen   `}>
        <div className={`flex justify-evenly w-full     main-w-768 gap-3 items-start`}>
          <div className={`space-y-6 w-full text-sm mt-20 flex justify-content-center align-content-center   `}>
            <div className=" w-full  flex justify-content-center align-content-center flex-col">
              <div className="fadeIn flex flex-col items-center mb-20 -top-20">
                <h1 className={`text-2xl sm:text-3xl md:text-4xl lg:text-5xl -top-8  font-bold    text-center  font-poppins`}>
                  Welcome to{" "}
                  <span className="text-green-500">
                    AutoTube
                  </span>
                </h1>
                <h2 className={`text-lg md:text-md    text-center mt-3 text-gray-400 font-inter`}>
                  What type of video would you like to create today?
                </h2>
              </div>
              <div className="flex justify-center p-1 w-full">
                <div className="grid grid-cols-1  md:grid-cols-2 gap-12 w-[80%]">
                  <div onClick={handleclick}
                    className="group relative backdrop-blur p-6 rounded-2xl border border-green-500/10 hover:bg-green-100 hover:bg-opacity-20  transition-all cursor-pointer overflow-hidden"
                  >
                    <div className="absolute inset-0 bg-gradient-to-br from-green-500/10 to-emerald-500/10 opacity-0 group-hover:opacity-100 transition-opacity"></div>
                    <div className="relative">
                      <div className="w-14 h-14 bg-gradient-to-br from-green-500 to-emerald-500 rounded-xl flex items-center justify-center mb-4">
                        <i
                          className={`fas fa-video     text-2xl`}
                        ></i>
                      </div>
                      <h3 className="text-xl font-semibold     mb-2">
                        Moving Shorts
                      </h3>
                      <p className="text-gray-400">AI Powered</p>
                      <span className="absolute top-4 right-4 bg-gradient-to-r from-green-500 to-emerald-500     text-sm px-3 py-1 rounded-full">
                        Popular
                      </span>
                    </div>
                  </div>
                  <div
                    onClick={handleclickavatar}
                    className="group relative backdrop-blur p-6 rounded-2xl border border-green-500/10 hover:bg-green-100 hover:bg-opacity-20 transition-all cursor-pointer overflow-hidden"
                  >
                    <div className="absolute inset-0 bg-gradient-to-br from-green-500/10 to-emerald-500/10 opacity-0 group-hover:opacity-100 transition-opacity"></div>
                    <div className="relative">
                      <div className="w-14 h-14 bg-gradient-to-br from-green-500 to-emerald-500 rounded-xl flex items-center justify-center mb-4">
                        <i
                          className={`fas fa-image     text-2xl`}
                        ></i>
                      </div>
                      <h3 className="text-xl font-semibold     mb-2">
                        Image Shorts
                      </h3>
                      <p className="text-gray-400">AI Powered</p>
                    </div>
                  </div>
                  {/*                   
                  {modal && <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full mx-4 animate-popup">
                      <div className="text-center">
                        <p className="text-2xl font-roboto mb-6 animate-fadeIn">Congrats on rendering your first few videos!</p>
                        <p className="text-lg font-roboto mb-8 animate-fadeIn animation-delay-200">
                          We would like to give you an additional 5 video credits. All you need to do is leave a review.
                        </p>
                        <a
                          href="https://www.shortsgenerator.ai/review/"
                          onClick={() => {
                            setModal(false)
                            localStorage.setItem('popup', '1')
                          }}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="inline-block bg-blue-600 hover:bg-blue-700     font-roboto font-bold py-3 px-8 rounded-lg transition-all duration-300 transform hover:scale-105 animate-fadeIn animation-delay-400"
                        >
                          Leave A Review
                        </a>
                        <button
                          onClick={() => {
                            setModal(false)
                            localStorage.setItem('popup', '1')
                          }}
                          className="block w-full mt-6 text-gray-500 hover:text-gray-700 font-roboto animate-fadeIn animation-delay-600"
                        >
                          No Thanks
                        </button>
                      </div>
                    </div> */}
                  <style>
                    {`
        @keyframes popup {
          from {
            opacity: 0;
            transform: scale(0.8);
          }
          to {
            opacity: 1;
            transform: scale(1);
          }
        }

        @keyframes fadeIn {
          from {
            opacity: 0;
            transform: translateY(20px);
          }
          to {
            opacity: 1;
            transform: translateY(0);
          }
        }

        .animate-popup {
          animation: popup 0.5s ease-out;
        }

        .animate-fadeIn {
          animation: fadeIn 0.5s ease-out forwards;
        }

        .animation-delay-200 {
          animation-delay: 200ms;
        }

        .animation-delay-400 {
          animation-delay: 400ms;
        }

        .animation-delay-600 {
          animation-delay: 600ms;
        }
      `}
                  </style>
                </div>
              </div>
            </div>
          </div>
        </div >
      </div>
      <Footer />
    </div>
  );
};


export default Dashboard;
