import React, { useState, useEffect } from 'react';

import { TiTick } from "react-icons/ti";
import '../style.css';
import { useDarkMode } from '../../context/DarkModeContext';
import { Link } from 'react-router-dom';
import { payment } from '../api/Service';
import { PaymentVerify } from '../api/Service';
import { paymentsubscription } from '../api/Service';
import { PaymentVerifySubscription } from '../api/Service';
import { useNavigate } from 'react-router-dom';




const Card = (props) => {
    const {name , plan} = props ;
    const [code, setCode] = useState('');
    const [loading, setLoading] = useState(false);
    const [load , setLoad] = useState(true);
    const navigate = useNavigate()
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        };
    }, []);
const handlePayment = async (amount, plan, order_id) => {
    const options = {
        key: "rzp_test_dfagW6jI4zk7cN",
        amount: amount * 100, // Amount in subunits (50000 paise = 500 INR)
        currency: "INR",
        name: "AutoTube",
        description: "Test Transaction",
        order_id: order_id, // Replace with your generated order ID
        callback_url: "http://localhost:3001/billing",
        notes: {
            address: "Razorpay Corporate Office"
        },
        theme: {
            color: "#3399cc"
        },
        handler: async (response) => {
            console.log("Payment Successful:", response);
            if (response) {
                try {
                    const Response = await PaymentVerify({
                            razorpay_order_id: response.razorpay_order_id,
                            razorpay_payment_id: response.razorpay_payment_id,
                            razorpay_signature: response.razorpay_signature
                    });
                    if (Response.data.status === true) {
                        navigate('/thanks');
                    }else{
                       
                    }
                    console.log(Response, "data payment");
                } catch (error) {
                    console.error('Error verifying payment:', error);
                }
            } else {
                console.log("Payment Failed");
            }
        },

    };
    const rzp = new window.Razorpay(options);
    rzp.open();
}

    const videoPricing = {
        5: 599,
        10: 849,
        15: 1099,
        20: 1499
    };

    const [selectedVideos, setSelectedVideos] = useState(5);
    const calculateCustomPrice = () => {
        return videoPricing[selectedVideos];
    };
    useEffect(() => {
        function generateNineDigitCode() {
            return Math.floor(100000000 + Math.random() * 900000000).toString();
        }
        const code = generateNineDigitCode();
        setCode(code);
        console.log(code);
    }, []);

    const SubmitPayment = async (amount, plan) => {
        setLoading(true);
        try {
            const response = await payment({
                amount: amount * 100,
                currency: "INR",
                receipt: "receipt" + code,
                notes: {
                    plan: plan
                }
            });
            if (response) {
                handlePayment(amount, plan, response.data.data.order_id);
            }
            console.log(response, "------------------ ======================");
        } catch (error) {
            console.error('Error submitting payment:', error);
        }
        setLoading(false);
    }


    const premiumSubscription = async ( amount , plan ) => {
        setLoading(true);
        try{
            const response = await paymentsubscription({
                amount: amount * 100,
                currency: "INR",
                plan_id:"plan_PVSVGabgDdeFGU",
                planname: plan
                
            });
            if (response) {
                handleSubscription(amount, plan, response.data.data.subscription_id);
            }
            console.log(response , "subscription response");

        }catch (error){
            console.error('Error submitting payment:', error);
        }
        setLoading(false);
    }


    const handleSubscription = async (amount , plan, subscription_id) => {
        const options = {
            key: "rzp_test_dfagW6jI4zk7cN", // Replace with your Razorpay key // Razorpay subscription ID
            currency: "INR",
            subscription_id:subscription_id,
            amount:amount,
            name: "AutoTube", // Change to your business name
            description: "Subscription Payment", // Subscription description
            notes: {
                address: "Razorpay Corporate Office" // Additional notes
            },
            theme: {
                color: "#3399cc" // Theme color
            },
            handler: async (response) => {
                console.log("Subscription Payment Successful:", response);
                if (response) {
                    try {
                        const verifyResponse = await PaymentVerifySubscription({
                            razorpay_subscription_id: subscription_id,
                            razorpay_payment_id: response.razorpay_payment_id,
                            razorpay_signature: response.razorpay_signature
                        });
                        console.log(verifyResponse , "---------------=");
                        
                        if (verifyResponse.data.status === true) {
                            navigate('/thanks');
                        }else{
                           
                        }
                    } catch (error) {
                        console.error("Error verifying subscription payment:", error);
                    }
                    console.log(subscription_id , "subscription faild")
                } else {
                    console.log("Payment Failed");
                }
            },
        };
    
        const rzp = new window.Razorpay(options);
        rzp.open();
    };
    

    return (
        <div className="min-h-screen py-12 mb-12 px-4">
            <div className="max-w-7xl mx-auto">
                <div className="text-center">
                    <h2 className="text-3xl font-bold    font-roboto mb-4">
                        Choose Your Perfect Plan
                    </h2>
                    <p className="text-xl   font-roboto">
                        Select the plan that best suits your needs
                    </p>
                </div>

                <div className="mt-16 grid grid-cols-1 md:grid-cols-3 gap-8">
                    <div className="rounded-lg shadow-lg border border-gray-800 p-8 transform hover:scale-105 transition duration-300 flex flex-col justify-between">
                        <div>
                            <h3 className="text-2xl   font-bold  mb-4">
                                Free Plan
                            </h3>
                            <p className="text-4xl   font-bold mb-6 font-roboto">
                                ₹0<span className="  text-sm">/month</span>
                            </p>
                            <ul className="space-y-4 mb-8">
                                <li className="flex   items-center">
                                    <i className="fas fa-check text-green-500 mr-2"></i>
                                    <span>1 Basic Video</span>
                                </li>
                                <li className="flex   items-center">
                                    <i className="fas fa-check text-green-500 mr-2"></i>
                                    <span>Limited Features</span>
                                </li>
                                <li className="flex   items-center">
                                    <i className="fas fa-check text-green-500 mr-2"></i>
                                    <span>Basic Support</span>
                                </li>
                            </ul>
                        </div>
                        <Link to="#">
                            <button className="hidden w-full bg-[#2ecc71] text-black py-3 rounded-lg hover:bg-[#3fcf6f] mt-auto">
                                Get Started
                            </button>
                        </Link>
                    </div>

                    <div className="rounded-lg shadow-lg border-1 border-green-500  p-8 transform hover:scale-105 transition duration-300 flex flex-col justify-between">
                        <span className="absolute -top-2 right-3 bg-green-500   text-center text-xs font-semibold px-3 py-1 rounded-full">
                            Most Popular
                        </span>
                        <div>
                            <h3 className="text-2xl   font-bold  mb-4">
                                Custom Plan
                            </h3>
                            <p className="text-4xl   font-bold mb-6 font-roboto">
                                ₹{calculateCustomPrice()}
                                <span className="  text-sm">/One Time Payment</span>
                            </p>
                            <div className="mb-6">
                                <label className="block text-gray-400 mb-2">
                                    Select Videos:
                                </label>
                                <select
                                    className="w-full p-2 border rounded-lg dropdown"
                                    value={selectedVideos}
                                    onChange={(e) => setSelectedVideos(Number(e.target.value))}
                                >
                                    <option value="5"  >5 Videos</option>
                                    <option value="10" >10 Videos</option>
                                    <option value="15" >15 Videos</option>
                                    <option value="20" >20 Videos</option>
                                </select>

                            </div>
                            <ul className="space-y-4 mb-8">
                                <li className="flex   items-center">
                                    <i className="fas fa-check text-green-500 mr-2"></i>
                                    <span>{selectedVideos} Custom Videos</span>
                                </li>
                                <li className="flex   items-center">
                                    <i className="fas fa-check text-green-500 mr-2"></i>
                                    <span>Advanced Features</span>
                                </li>
                                <li className="flex   items-center">
                                    <i className="fas fa-check text-green-500 mr-2"></i>
                                    <span>Priority Support</span>
                                </li>
                            </ul>
                        </div>
                        <Link to="#">
                            <button onClick={() => SubmitPayment(calculateCustomPrice(), 'Custom')} disabled={plan === "Premium Plan"} className={`w-full bg-[#2ecc71] text-black py-3 ${plan === "Premium Plan" ? "cursor-not-allowed" :"cursor-pointer" } rounded-lg hover:bg-[#3fcf6f] mt-auto`}>
                                Get Started
                            </button>
                        </Link>
                    </div>

                    <div className="rounded-lg shadow-lg border border-gray-800  p-8 transform hover:scale-105 transition duration-300 flex flex-col justify-between">
                        <div>
                            <h3 className="text-2xl   font-bold font-roboto mb-4">
                                Premium Plan
                            </h3>
                            <p className="text-4xl font-bold   mb-6 font-roboto">
                                ₹3999<span className=" text-sm">/month</span>
                            </p>
                            <ul className="space-y-4 mb-8 ">
                                <li className="flex items-center">
                                    <i className="fas fa-check mr-2"></i>
                                    <span>Unlimited Videos</span>
                                </li>
                                <li className="flex items-center">
                                    <i className="fas fa-check mr-2"></i>
                                    <span>All Premium Features</span>
                                </li>
                                <li className="flex items-center">
                                    <i className="fas fa-check mr-2"></i>
                                    <span>24/7 Premium Support</span>
                                </li>
                                <li className="flex items-center">
                                    <i className="fas fa-check mr-2"></i>
                                    <span>Exclusive Content</span>
                                </li>
                            </ul>
                        </div>
                        <Link to="#">
                            <button onClick={() => premiumSubscription(3999, 'Premium Plan')}  disabled={plan === "Premium Plan"} className={`w-full bg-[#2ecc71] text-black py-3 ${plan === "Premium Plan" ? "cursor-not-allowed" :"cursor-pointer" } rounded-lg hover:bg-[#3fcf6f] mt-auto`}>
                                Get Started
                            </button>
                        </Link>
                    </div>
                </div>
            </div>
            {loading && (
                <div className="fixed inset-0 bg-[#000000]/80 backdrop-blur-sm flex flex-col items-center justify-center z-50">
                    <div className="relative w-[120px] h-[120px] flex items-center justify-center">
                        <div className="absolute w-full h-full border-[3px] border-t-[#00ff88] border-r-[#00ff88] border-b-transparent border-l-transparent rounded-full animate-spin"></div>
                        <div className="absolute w-[80px] h-[80px] border-[3px] border-t-[#00ffea] border-r-[#00ffea] border-b-transparent border-l-transparent rounded-full animate-spin-reverse"></div>
                        <div className="w-[40px] h-[40px] bg-[#00ff88] rounded-full animate-pulse"></div>
                    </div>
                    <div className="mt-4 text-[#00ff88] font-roboto text-xl animate-pulse">
                        Payment Processing...
                    </div>
                </div>
            )}
            <style jsx global>{`
        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
        @keyframes spin-reverse {
          0% { transform: rotate(360deg); }
          100% { transform: rotate(0deg); }
        }
        .animate-spin {
          animation: spin 2s linear infinite;
        }
        .animate-spin-reverse {
          animation: spin-reverse 1.5s linear infinite;
        }
        .animate-pulse {
          animation: pulse 1.5s ease-in-out infinite;
        }
        @keyframes pulse {
          0%, 100% { opacity: 1; }
          50% { opacity: 0.5; }
        }
      `}</style>
        </div >
    );

};

export default Card;
