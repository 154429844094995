import React, { useEffect, useState } from "react";
import { GiArtificialIntelligence } from "react-icons/gi";
import { FaUserCircle, FaLock } from "react-icons/fa";
import { RiErrorWarningLine } from "react-icons/ri";
import Navbar from "../navbar/Navbar";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../spinner/Spinner";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SendEmail } from "../api/Service";

const EnterEmail = () => {
    const [loading, setLoading] = useState(false);
    const [emailError, setEmailError] = useState(null)
    const [email, setEmail] = useState('')
    const [focusedField, setFocusedField] = useState(null);

    const notifySuccess = (message) => {
        toast.success(message, {
          autoClose: 5000,
          style: {
            background: "#90EE90",
            color: "#000",
            fontWeight: "bold",
            boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.2)",
            borderRadius: "4px",
          }
        })
      }
      const handleSubmit = async(e) => {
        e.preventDefault();
        setLoading(true)
        try {
            let data = {
                email: email
            };
            console.log(data,'email2')

            const res = await SendEmail (data);
            if (res.status === 200) {
                setEmailError(null);
                setLoading(false);
                notifySuccess("Email sent successfully Please Check Your Inbox");
            }
        } catch (err) {
            if(err){
                setLoading(false);
                console.log(err,'bhjfebferjk')
                setEmailError(err.response.data.message);
            }else{
                setEmailError(null)
            }
            
        }
    }

    return (
        <>
            <div
                className="h-max-[100vh] h-min-[100vh] bg-[#532ff4]"
                style={{ minHeight: "100vh" }}
            >
                <div className="bg-[#532ff4]">
                    <div className="h-[70px]">
                        <Navbar />
                    </div>
                    <div className="flex justify-center items-center h-auto min-h-[calc(100vh-70px)] sm:h-[630px]">
                        <div className="w-full max-w-sm sm:w-[300px] md:w-[346px] md:py-3 px-4 sm:px-0">
                            <div className="w-full p-6 sm:p-8 md:p-7 space-y-4 bg-white shadow-lg rounded-lg">
                                {loading && (
                                    <div className="text-center py-4">
                                        <Loader />
                                    </div>
                                )}
                                <div className="flex justify-center text-[#467ff7]">
                                    <GiArtificialIntelligence className="h-6 w-6" />
                                </div>
                                <h1 className="text-2xl font-semibold pb-2 text-center font-montserrat text-blue-950">
                                    Please Enter Your Email
                                </h1>

                                {emailError && (
                                    <p className="text-sm mt-1 flex justify-start gap-[8px] pl-3 items-center bg-pink-100 h-10">
                                        <RiErrorWarningLine className="text-red-500 size-5" />
                                        Please enter an email
                                    </p>
                                )}
                                <form onSubmit={handleSubmit} className="space-y-6">
                                    <div className="relative group py-4">
                                        <label
                                            htmlFor="email"
                                            className={`absolute top-[14px] left-3 text-[11px] font-sm bg-white px-1 z-10 ${focusedField === "email"
                                                ? "text-blue-500"
                                                : "text-gray-700"
                                                }`}
                                        >
                                            Email*
                                        </label>
                                        <div className="relative group-hover:border-black group-hover:cursor-pointer">
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                value={email}
                                                onChange={(e) => setEmail(e.target.value)}
                                                onFocus={() => setFocusedField("email")}
                                                onBlur={() => setFocusedField(null)}
                                                className={`w-full pl-10 h-12 border rounded-sm focus:outline-blue-700 ${focusedField === "email"
                                                    ? "border-blue-500 focus:ring-blue-500"
                                                    : "border-gray-300"
                                                    }`}
                                            />
                                            <FaUserCircle className="absolute top-1/2 left-3 transform -translate-y-1/2 w-5 h-5 text-gray-500" />
                                        </div>
                                    </div>
                                    <div>
                                        <button
                                            type="submit"
                                            className="w-full text-[11px] py-2 px-4 bg-blue-500 text-white font-montserrat font-semibold rounded-sm shadow-sm hover:bg-blue-700 focus:outline focus:ring-2 focus:ring-blue-500"
                                        >
                                            Send Link
                                        </button>
                                    </div>
                                </form>
                                <div className="mt-6 text-center text-sm">
                                    <a href="#" className="text-gray-600 hover:underline">
                                        Remember Password? <Link to="/app">Login</Link>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer />
            </div>
        </>
    )
}

export default EnterEmail