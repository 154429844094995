"use client";
import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../Footer/Footer";
import PrivacyPDF from "../../../components/autotube/policys/PDF/Privacy.pdf";
import { useState } from "react";
import "../../../index.css"
import loho from "../../../loho.png"
import LandingNavbar from "../navbar/Navbar";
import LandingFooter from "../navbar/Footer";


function ContactUs() {
  const [currentPage, setCurrentPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div className="bg-black text-white">
      <LandingNavbar/>
      <div className="min-h-screen items-center justify-center flex bg-black">
        <div className="max-w-4xl mx-auto px-6 py-16 bg-[#1c1c1c] shadow-lg rounded-lg">
          <h1 className="text-4xl font-bold text-white mb-12 text-center font-roboto text-white">
            Contact Us
          </h1>
          <div className="space-y-8">
            <p className="text-white">
              We’re here to help! If you have any questions, feedback, or need assistance, feel free to reach out to us. Our team is always ready to provide the support you need.
            </p>
            <h2 className="text-2xl font-bold text-white">Get in Touch</h2>
            <p className="text-white">Email: support@autotube.in <br />Phone No.: +91 9416286595</p>
            <p className="text-white">
              Whether it’s a query about our services, troubleshooting, or general feedback, don’t hesitate to contact us. We strive to respond promptly and ensure your experience with AutoTube.in is smooth and hassle-free.
            </p>
          </div>
        </div>
      </div>
     <LandingFooter/>
    </div>
  );
}

export default ContactUs;