import React, { useState } from 'react';
import DashboardNavbar from '../navbar/DashboardNavbar';
import NavTab from './NavTab';
import Footer from '../Footer/Footer';
import accountimg from '../images/img2.png';
import '../style.css';
import { useModal } from '../../context/ModalContext';
import { useDarkMode } from '../../context/DarkModeContext';

const Account = () => {
    const { isModalOpen } = useModal();
    const [email, setEmail] = useState('');
    const [emailFocused, setEmailFocused] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [emailSuccessMessage, setEmailSuccessMessage] = useState('');
    const { darkMode } = useDarkMode();

    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [passwordSuccessMessage, setPasswordSuccessMessage] = useState('');

    const validateEmail = (email) => /\S+@\S+\.\S+/.test(email);
    const validatePassword = (password) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d]{8,}$/.test(password);

    const handleEmailSave = () => {
        if (validateEmail(email)) {
            setEmailSuccessMessage('Email is saved');
            setEmailError('');
        } else {
            setEmailError('Please enter a valid Email');
            setEmailSuccessMessage('');
            setEmail(''); // Clear email field if invalid
        }
    };

    const handlePasswordSave = () => {
        if (password !== confirmPassword) {
            setPasswordError('Passwords do not match');
            setPasswordSuccessMessage('');
            return;
        }
        if (validatePassword(password)) {
            setPasswordSuccessMessage('Password is updated successfully');
            setPasswordError('');
        } else {
            setPasswordError('Password must be at least 8 characters long and include an uppercase letter, a lowercase letter, and a number');
            setPasswordSuccessMessage('');
        }
    };

    return (
        <>
            <DashboardNavbar />
            <div className={`flex h-max font-montserrat ${darkMode ? 'bg-black' : 'bg-gray-50'}`}>
                {!isModalOpen ? (
                    <div className={`absolute md:relative md:w-[23%] w-[21%] ${darkMode ? 'bg-black' : 'bg-white'} text-white`}>
                        <NavTab />
                    </div>
                ) : (
                    <div className={`absolute md:relative md:w-[23%] w-[21%] ${darkMode ? 'bg-black' : 'bg-white'} bg-opacity-50`}>
                    </div>
                )}

                <div className={`w-full flex justify-center items-center flex-col h-max text-sm overflow-auto ${darkMode ? 'text-white' : 'text-black'}`}>
                    <div className="md:w-[600px] w-[100%] flex-col flex justify-center items-center">
                        <div className="md:h-[300px] h-[180px] flex justify-center items-center">
                            <img src={accountimg} height="200px" width="250px" alt="Account" />
                        </div>
                    </div>

                    <section className="formset flex flex-col justify-start items-start flex-wrap">
                        {/* Email Update Section */}
                        <div className="update-email pb-8 md:w-[650px] w-[100%] p-2 md:p-0 flex justify-start items-start">
                            <h5 className={`text-start font-bold text-md md:text-lg pb-2 text-[#467ff7] border-b-2 ${darkMode ? 'border-blue-400' : 'border-[#467ff7]'} capitalize w-max`}>
                                UPDATE EMAIL
                            </h5>
                        </div>
                        <div className={`update-form flex items-center flex-wrap h-max md:h-[110px] w-[230px] md:w-[600px] shadow-md rounded-md p-4 ${darkMode ? 'bg-black' : 'bg-white'}`}>
                            <form className="flex flex-col w-full">
                                <div className="flex items-center md:space-x-2 space-x-0 mb-2">
                                    <div className="relative w-[92%] ">
                                        <input
                                            type="email"
                                            id="email"
                                            name="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            onFocus={() => setEmailFocused(true)}
                                            onBlur={() => setEmailFocused(false)}
                                            className={`md:w-full md:h-11 w-[80%] h-10 text-md border rounded-sm pl-3 ${emailError ? 'outline-red-500' : (emailFocused || email ? 'outline-blue-500' : 'border-gray-300')} transition-all duration-300`}
                                        />
                                        <label
                                            htmlFor="email"
                                            className={`absolute left-3 top-2 text-lg bg-white px-1 z-10 transform transition-transform duration-300 ease-in-out ${emailFocused || email ? (emailError ? 'scale-75 text-red-500' : 'scale-75 text-blue-500') : (emailError ? 'top-1/2 text-red-500 border-red-500' : 'top-1/2 text-gray-500')}`}
                                        >
                                            Email
                                        </label>
                                    </div>
                                    <button
                                        type="button"
                                        onClick={handleEmailSave}
                                        className="md:w-[13%] md:h-11 h-10 w-[30%] bg-blue-500 text-white rounded-md hover:bg-blue-600"
                                    >
                                        Save
                                    </button>
                                </div>
                                {emailError && <p className="text-red-500 mb-2">{emailError}</p>}
                                {emailSuccessMessage && <p className="text-green-500 mb-2">{emailSuccessMessage}</p>}
                            </form>
                        </div>
                        <p className='text-center pt-2 pb-2 text-[#467ff7]'>Note: This will be your login email. Make sure to remember it!</p>

                        {/* Password Update Section */}
                        <div className="update-email pb-8 md:w-[650px] w-[100%] p-2 md:p-0 flex justify-start items-start mt-6">
                            <h5 className={`text-start font-bold text-md md:text-lg pb-2 text-[#467ff7] border-b-2 ${darkMode ? 'border-blue-400' : 'border-[#467ff7]'} capitalize w-max`}>
                                CHANGE PASSWORD
                            </h5>
                        </div>
                        <div className={`update-form flex items-center flex-wrap h-max md:h-max w-[230px] md:w-[600px] shadow-md rounded-md p-4 ${darkMode ? 'bg-black' : 'bg-white'}`}>
                            <form className="flex flex-col w-full">
                                <div className="mb-2">
                                    <label htmlFor="password" className="block text-gray-700 mb-1">New Password</label>
                                    <input
                                        type="password"
                                        id="password"
                                        name="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        className={`w-full h-10 text-md border rounded-sm pl-3 ${passwordError ? 'outline-red-500 text-black' : 'border-gray-300'} transition-all duration-300`}
                                    />
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="confirmPassword" className="block text-gray-700 mb-1">Confirm Password</label>
                                    <input
                                        type="password"
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        className={`w-full h-10 text-md border rounded-sm pl-3 ${passwordError ? 'outline-red-500 text-black' : 'border-gray-300'} transition-all duration-300`}
                                    />
                                </div>
                                <button
                                    type="button"
                                    onClick={handlePasswordSave}
                                    className="w-full h-10 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                                >
                                    Save
                                </button>
                                {passwordError && <p className="text-red-500 mt-2">{passwordError}</p>}
                                {passwordSuccessMessage && <p className="text-green-500 mt-2">{passwordSuccessMessage}</p>}
                            </form>
                        </div>
                        <p className='text-center pt-2 pb-2 text-[#467ff7]'>Note: Make sure your new password meets all the requirements.</p>
                    </section>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Account;
