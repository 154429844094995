import React, { useState, useEffect } from 'react';
import DashboardNavbar from '../navbar/DashboardNavbar';
import NavTab from '../navtab/NavTab';
import Footer from '../Footer/Footer';
import { useDarkMode } from '../../context/DarkModeContext';

const CommingSoon = () => {

    const darkMode = useDarkMode()

    return (
        <>
            <DashboardNavbar />
            <div className={`bg-gray-100 flex min-h-screen`}>
                <div className="md:w-1/4 w-1/5">
                    <NavTab />
                </div>

                <div className="text-gray-700 min-h-[84vh] w-full flex justify-center items-center">
                    <div className="flex flex-col items-center space-y-4">
                        <h1 className="font-montserrat text-3xl">
                            AI Video Shorts is under Update...
                        </h1>
                        <h2 className="font-montserrat text-xl bg-black text-white px-4 py-2 rounded">
                            We are rolling out our new model, so thank you for the patience!
                        </h2>
                    </div>
                </div>
            </div>
            <Footer />
        </>

    );


}

export default CommingSoon