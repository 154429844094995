import { Co2Sharp } from "@mui/icons-material";
import Http from "../api/Http"
import Transformer from "../api/Transformer"
import { jwtDecode } from "jwt-decode";



/**
 * Logs in with shorts generated credentials.
 *
 * @param {object} data 
 * @returns {Promise} 
 */

export const LoginUpForm = async (data) => {
  try {
    console.log(data);
    localStorage.clear();
    const response = await Http.post('/Registration/logIn', (data));
    localStorage.setItem('Token', response.data.data.token);
    const decode = jwtDecode(response.data.data.token)
    localStorage.setItem('popup', decode.popup)
    localStorage.setItem('email', decode.email);
    localStorage.setItem('firstName', decode.firstName);
    localStorage.setItem('lastName', decode.lastName);
    localStorage.setItem('userId', decode.userId);

    return response.data;
  } catch (err) {
    console.error("Error in LoginUpForm:", err);
    throw err;
  }
};

export const SignUpForm = async (data) => {
  try {
    // ExitToApp();
    localStorage.clear();
    const response = await Http.post('/Registration/signUp', (data));
    console.log(response.data);
    localStorage.setItem('Token', response.data.data.token);
    // const decode= decode(response.data.data.token);
    const decode = jwtDecode(response.data.data.token)
    console.log(decode.popup, 'popupp')
    localStorage.setItem('popup', decode.popup)
    localStorage.setItem('email', decode.email);
    localStorage.setItem('firstName', decode.firstName);
    localStorage.setItem('lastName', decode.lastName);
    localStorage.setItem('userId', decode.userId);
    return response.data;
  } catch (err) {
    throw err;
  }
};


export const SubcriptionCancel = async (data) => {
  try {
    const res = await Http.post('/payments/cancel-subscription', data)
    return res;
  } catch (error) {
    console.error(error, 'Subscription not cancled')
  }
}
export const SubcriptionActive = async (data) => {
  try {
    const res = await Http.post('/Registration/activesubscription', data)
    return res;

  } catch (error) {
    console.error(error, 'Subscription not Activet')
  }
}
export const ReviewPopup = async (data) => {
  try {
    const res = await Http.post('/Registration/updatepopup', data)
    return res;

  } catch (error) {
    console.error(error, 'Subscription not cancled')
  }
}

export const GoogleForm = async (data) => {
  try {
    const response = await Http.post('/Registration/loginWithGoogle', (data));
    console.log(response.data.data, 'google response')
    localStorage.setItem('Token', response.data.data.token);
    const decode = jwtDecode(response.data.data.token)
    localStorage.setItem('email', decode.email);
    localStorage.setItem('firstName', decode.firstName);
    localStorage.setItem('lastName', decode.lastName);
    localStorage.setItem('userId', decode.userId);
    return response.data;
  } catch (err) {
    throw err;
  }
};
export const AffiliateCode = async(data) =>{
  try{
    const response = await Http.post('/Registration/createAffiliate',data)
    return response
  }catch(err){
    console.error(err)
    throw err;
  }
}

export const AffiliateList = async() =>{
  try{
    const response = await Http.get('/Registration/affiliateDetails',)
    return response
  }catch(err){
    console.error(err)
    throw err
  }
}

export const AllAffiliateList = async() =>{
  try{
    const response = await Http.get('Registration/allAffiliateDetails',)
    return response
  }catch(err){
    console.error(err)
    throw err
  }
}
export const UpdatePassword = async (data) => {
  try {
    const response = await Http.post('/Registration/passwordUpdate', data)
    return response
  } catch (err) {
    console.error(err)
  }
}
export const PaypalEmail = async(data) =>{
  try{
    const response = await Http.post('/Registration/paypalEmailId',data)
    return response
  }catch(err){
    console.error(err)
  }
}
export const SendEmail = async(data) =>{
  console.log(data,'email')
  try{
    const response = await Http.post('/Registration/forgotPassword', data);
    return response
  } catch (err) {
    console.log("Error in SendEmail:", err);
    throw err
  }
}

export const ReactivateSubscription = async(data) =>{
  try{
    const response = await Http.post('/Registration/reactivate', data);
    return response
  } catch (err) {
    console.log("Error in SendEmail:", err);
    throw err
  }
} 

export const listVideo = async (data) => {
  try {
    const response = await Http.post('/contentCreater/userVideos', (data));
    return response.data.data;
  }
  catch (err) {
    console.error('Error fetching videos:', err);
    throw new Error('Failed to fetch videos. Please try again later.');
  }
};
export const listVideoFree = async (data) => {
  try {
    const response = await Http.post('/contentCreater/freePlanUserVideo', (data));
    return response;
  }
  catch (err) {
    console.error('Error fetching videos:', err);
    throw new Error('Failed to fetch videos. Please try again later.');
  }
};

export const payment = async (data) => {
  try {
    const response = await Http.post('/payments/create-order', (data));
    return response;
  }
  catch (err) {
    console.error('Error fetching videos:', err);
    throw new Error('Failed to fetch videos. Please try again later.');
  }
};
export const paymentsubscription = async (data) => {
  try {
    const response = await Http.post('/payments/create-subscription', (data));
    return response;
  }
  catch (err) {
    console.error('Error fetching videos:', err);
    throw new Error('Failed to fetch videos. Please try again later.');
  }
};

export const PaymentVerify = async (data) => {
  try {
    const response = await Http.post('/payments/verify-payment', (data));
    return response;
  }
  catch (err) {
    console.error('Error fetching videos:', err);
    throw new Error('Failed to fetch videos. Please try again later.');
  }
};
export const PaymentVerifySubscription = async (data) => {
  try {
    const response = await Http.post('/payments/verify-subscription', (data));
    return response;
  }
  catch (err) {
    console.error('Error fetching videos:', err);
    throw new Error('Failed to fetch videos. Please try again later.');
  }
};

export const Plans = async (data) => {
  try {
    const response = await Http.get('/contentCreater/userplan');
    console.log(response.data.data, '----------')
    localStorage.setItem('credit', response.data.data.credit)
    localStorage.setItem('plan', response.data.data.PlanTypeName)
    return response.data.data;

  } catch (error) {
    console.error('Error fetching plans:', error);
  }
}

// export const Preview = async () => {
//   try {
//     const response = await Http.get('');
//     console.log('preview successfully:', response.data.data);
//     return response.data;
//   }
//   catch (err) {
//     console.error('Error fetching videos:', err);
//     throw new Error('Please try again later.');
//   }
// };

export const generateVideo = async (contentType) => {
  try {
    const response = await Http.post('/contentCreater/aicreator', contentType);
    return response

  } catch (error) {
    console.error(error)
    throw error
  }
}

export const socialMediaList = async () => {
  try {
    const response = await Http.get('/schedule/schedulelisting');
    return response.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const RegenerateTittle = async (data) => {
  try {
    const response = await Http.post('/schedule/generate_title', data);
    console.log(response)
    return response

  } catch (err) {
    console.log(err, 'error in Regenerating tittle')
    throw err;
  }
}

export const RegenerateDiscription = async (data) => {
  try {
    const response = await Http.post('/schedule/generate_description', data);
    return response

  } catch (err) {
    console.log(err, 'error in Regenerating Discription')
    throw err;
  }
}
export const DeleteAllPost = async (data) => {
  try {
    const response = await Http.post('/schedule/delete_all_schedule', data);
    return response

  } catch (err) {
    console.log(err, 'error in updating')
    throw err;
  }
}
export const YoutubeContentUpdate = async (data) => {
  try {
    const response = await Http.post('/schedule/youtube_content_update', data);
    return response

  } catch (err) {
    console.log(err, 'error in updating')
    throw err;
  }
}
export const GenerateCaptions = async (data) => {
  try {
    const response = await Http.post('/contentCreater/aicaption', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response

  } catch (err) {
    console.log(err, 'error in generating captions')
    throw err;
  }
}
export const GenerateTwitter = async (data) => {
  try {
    const response = await Http.post('/contentCreater/twitter_x', data);
    return response

  } catch (err) {
    console.log(err, 'error in generating captions')
    throw err;
  }
}
// export const UpdatePassword = async(data) =>{
//   try{
//     const response = await Http.post('/Registration/passwordUpdate',data)
//     return response
//   }catch(err){
//     console.error(err)
//   }
// }
// export const SendEmail = async(data) =>{
//   console.log(data,'email')
//   try{
//     const response = await Http.post('/Registration/forgotPassword', data);
//     return response
//   }catch(err){
//     console.log("Error in SendEmail:", err);
//     throw err
//   }
// }
export const avatargenerateVideo = async (contentType) => {
  try {
    const response = await Http.post('contentCreater/scriptGenerateForAicreator', contentType);
    return response

  } catch (error) {
    console.error(error)
    throw error
  }
}
export const CheckTwitter = async (data) => {
  try {
    const response = await Http.post('/contentCreater/twitter_x_VideoList', data);
    return response.data.data;

  } catch (error) {
    console.error(error)
  }
}
export const CheckCaptions = async (data) => {
  try {
    const response = await Http.post('/contentCreater/aicaptionVideoList', data);
    return response.data.data;

  } catch (error) {
    console.error(error)
  }
}
export const CheckAiVideo = async (data) => {
  try {
    const response = await Http.post('/contentCreater/aivideolist', data);
    return response.data.data;

  } catch (error) {
    console.error(error)
  }
}
export const CheckVideo = async (data) => {
  try {
    const response = await Http.post('/contentCreater/aicreatorVideoList', data);
    return response.data.data;

  } catch (error) {
    console.error(error)
  }
}

export const createVedio = async (contenType) => {
  try {
    const response = await Http.post('/contentCreater/videoContentType', contenType);
    console.log(response)
    return response;
  }

  catch (err) {
    throw err;
  }
};
export const createAiVedio = async (contenType) => {
  try {
    const response = await Http.post('/contentCreater/aivideo', contenType);
    console.log(response)
    return response;
  }

  catch (err) {
    throw err;
  }
};
export const handleOtp = async (data) => {
  console.log(data,'data');
  try {
    let data1 = {
      email: data
    }
    const response = await Http.post('/Registration/sendotp',
      (data1));
    console.log(response)
    return response
  }
  catch (err) {
    throw err;
  }
}


export const youtube = async (data) => {
  try {

    const token = localStorage.getItem('Token');

    if (!token) {
      throw new Error('No token found in localStorage');
    }

    const headers = {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    };


    console.log('Sending data to YouTube API:', data);
    console.log('Headers:', headers);


    const response = await Http.post('/youtubeChannel/verify', data, { headers });
    console.log('Response:', response);


    if (response.data && response.data.token) {
      localStorage.setItem('Token', response.data.token);
    } else {
      console.warn('No token returned in response');
    }

    return response.data;
  } catch (err) {

    console.error('Error during YouTube API call:', err);
    throw err;
  }
};




export const socialmedia = async () => {
  try {
    const response = await Http.get('/youtubeChannel/social_media_detail');
    console.log(' successfully:', response.data.data);
    return response.data;
  }
  catch (err) {
    console.error('Error fetching videos:', err);
    throw new Error('Please try again later.');
  }
};

export const DisconnectYoutube = async (data) => {
  try {
    const response = await Http.post('/youtubeChannel/delete_youtube_account', data);
    return response.data;

  } catch (error) {
    console.error(error)
  }
}
