"use client";
import React from "react";

function Thanks(){
    const themefile = localStorage.getItem("theme")
  return (
    <div className={`min-h-screen ${themefile === "dark" ? "bg-black" : "bg-white "}  flex items-center justify-center  `}>
      <div className={`text-center  p-10 rounded-3xl ${themefile === "dark" ? "bg-black border-gray-100 text-white border-4 border-gray-800" : "bg-white border-4 border-gray-200"}  shadow-2xl max-w-md mx-4 animate-cardFade`}>
        <div className="text-[#22c55e] mb-8 animate-iconBounce relative">
          <i className="fas fa-circle-check text-8xl relative animate-iconPulse"></i>
        </div>

        <h1 className="text-4xl font-poppins mb-4">
        Your subscription has been activated successfully
        </h1>

        <p className=" font-roboto text-sm mb-8">
          Start creating amazing AI-powered videos now
        </p>

        <a
          href="/dashboard"
          className="inline-block bg-gradient-to-r from-[#16a34a] to-[#22c55e] hover:from-[#22c55e] hover:to-[#4ade80] text-white font-poppins text-lg py-4 px-12 rounded-2xl transition-all duration-300 hover:scale-105 hover:shadow-[0_0_30px_rgba(34,197,94,0.5)] transform hover:-translate-y-1 uppercase tracking-wider"
        >
          Go to Dashboard
        </a>
      </div>

      <style jsx global>{`
        @keyframes cardFade {
          0% { opacity: 0; transform: scale(0.95); }
          100% { opacity: 1; transform: scale(1); }
        }
        
        @keyframes iconBounce {
          0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
          40% { transform: translateY(-20px); }
          60% { transform: translateY(-10px); }
        }

        @keyframes iconPulse {
          0% { filter: drop-shadow(0 0 0px #22c55e); }
          50% { filter: drop-shadow(0 0 15px #22c55e); }
          100% { filter: drop-shadow(0 0 0px #22c55e); }
        }

        .animate-cardFade {
          animation: cardFade 0.8s ease-out;
        }

        .animate-iconBounce {
          animation: iconBounce 2s ease-in-out;
        }

        .animate-iconPulse {
          animation: iconPulse 2s ease-in-out infinite;
        }
      `}</style>
    </div>
  );
}

export default Thanks;