import React, { useRef, useEffect, useState, Component } from "react";
import DashboardNavbar from "../navbar/DashboardNavbar";
import NavTab from "./NavTab";
import Footer from "../Footer/Footer";
import TimePicker from 'react-time-picker';
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import ProgressBar from 'react-bootstrap/ProgressBar';
// import Modal from "../modal/modalpopup";
import ReactCountryFlag from 'react-country-flag';
import Select from "react-select"
import { MdOutlineAlternateEmail } from "react-icons/md";
// import Youtube from "../navtab/YouTube"
import { createVedio, generateVideo, listVideo, Plans, socialmedia } from "../api/Service";
import { useNavigate } from "react-router-dom";
import "reactjs-popup/dist/index.css";
import { MdPlayArrow, MdPause, MdInfoOutline, MdLock, MdHelpOutline, MdWhatshot } from "react-icons/md";
import { DropVoices } from "../../voices/voice";
import { useCookies } from "react-cookie";
import "../style.css?v1";
import Anthony from "../../Creators/Anthony-1.png";
import Erica from "../../Creators/Erica.png";
import Kate from "../../Creators/Kate.png";
import Mia from "../../Creators/Mia-1.png";
import Peter from "../../Creators/Peter.png";
import image2 from "./image__22_-removebg-preview.png";
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import PetsIcon from '@mui/icons-material/Pets';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import EmojiEmotionsIcon from '@mui/icons-material/EmojiEmotions';
import SpaIcon from '@mui/icons-material/Spa';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import MovieFilterIcon from '@mui/icons-material/MovieFilter';
import ElderlyWomanIcon from '@mui/icons-material/ElderlyWoman';
import BedtimeIcon from '@mui/icons-material/Bedtime';
import ApartmentIcon from '@mui/icons-material/Apartment';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import { EmojiEmotions } from "@mui/icons-material";
import StarIcon from '@mui/icons-material/Star';
import PsychologyIcon from '@mui/icons-material/Psychology';
import CampaignIcon from '@mui/icons-material/Campaign';
import CatchingPokemonIcon from '@mui/icons-material/CatchingPokemon';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import EmojiNatureIcon from '@mui/icons-material/EmojiNature';
import BedroomBabySharpIcon from '@mui/icons-material/BedroomBabySharp';
import { useModal } from "../../context/ModalContext";
import { useDarkMode } from "../../context/DarkModeContext";
import MyComponent from 'react-fullpage-custom-loader'
import { create, values } from "lodash";
import { listVideoFree } from "../api/Service";

const CLIENT_ID =
  "281909909216-l5qfp3rnt0h0a2fbtjsb52v776663odv.apps.googleusercontent.com";
const REDIRECT_URI = "http://localhost:3000";
const SCOPES = [
  "https://www.googleapis.com/auth/youtube.upload https://www.googleapis.com/auth/userinfo.profile",
];
const google_Auth_Url = `https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&scope=${encodeURIComponent(
  SCOPES.join(" ")
)}&response_type=code&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}`;

const Create = () => {
  const [cookies, setCookie, removeCookie] = useCookies([
    "https://accounts.google.com",
  ]);
  const { isModalOpen } = useModal()
  const themefile = localStorage.getItem("theme");
  console.log(themefile);
  const { darkMode } = useDarkMode();
  const server_App_URl = process.env.REACT_APP_API_URL
  const [selectedTopic, setSelectedTopic] = useState("Shorts Generator");
  const [selectedOption, setSelectedOption] = useState("");
  const [secondaryInputVisible, setSecondaryInputVisible] = useState(false);
  const [additionalFieldsVisible, setAdditionalFieldsVisible] = useState(false);
  const [selectedDropdownOption, setSelectedDropdownOption] = useState("");
  const [selectFontOption, setselectFontOption] = useState("16");
  const [selectPosition, setselectPosition] = useState("125");
  const [activeIndex, setActiveIndex] = useState(null);
  const [dropactiveIndex, setdropactiveIndex] = useState(null);
  const [currentPlayingIndex, setCurrentPlayingIndex] = useState(null);
  const [activeId, setActiveId] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [playingIndex, setPlayingIndex] = useState(null);
  const [playbackTimes, setPlaybackTimes] = useState({});
  const [showCustomTopic, setShowCustomTopic] = useState(false);
  const [customTopic, setCustomTopic] = useState("");
  const [pictureType, setPictureType] = useState("raytraced");
  const [loading, setLoading] = useState(true);
  const [series, setSeries] = useState('');
  const [plan, setPlan] = useState("");
  const [script, setScript] = useState("");
  const [creatorPic, setCreatorPic] = useState("");
  const [preview, setPreview] = useState("raytraced.mp4");
  const [modalMessage, setModalMessage] = useState('')
  const [aiAspectRatio, setAiAspectRatio] = useState('portrait_16_9')
  const [selectedTemplateId, setSelectedTemplateId] = useState(
    "default"
  );
  const [topicModal, setTopicModal] = useState(false)
  const [content, setContent] = useState(null)
  const [selectedLanguage, setSelectedLanguage] = useState('en')
  const [language, setLanguage] = useState("Hindi")
  const [selectedType, setSelectedType] = useState('AI Script');
  const [showScript, setShowScript] = useState(false);
  const [creatLoading, setCreateLoading] = useState(false)
  const [Socialmedia, setSocialmedia] = useState('none')
  const [postInDay, setPostInDay] = useState('1')
  const [timezone, setTimezone] = useState('')
  const [isToggled, setIsToggled] = useState(false);
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState('')
  const [youtubeUpload, setYoutubeUpload] = useState(0)
  const [noConnectedDropdown, setnoConnectedDropdown] = useState(null)
  const [scheduleLoading, setScheduleLoading] = useState(false)
  const [startDateError, setStartDateError] = useState(false)
  const [completed, setCompleted] = useState(0);
  const [datePopup, setDatePopup] = useState(false)

  useEffect(() => {
    const savedProgress = localStorage.getItem('progressCreate');
    if (savedProgress) {
      setCompleted(parseFloat(savedProgress));
    }

    const duration = 300;
    const increment = 100 / duration;
    const interval = setInterval(() => {
      setCompleted(prev => {
        if (prev + increment >= 100) {
          clearInterval(interval);
          localStorage.removeItem('progressCreate');
          return 100;
        }
        const newProgress = prev + increment;
        localStorage.setItem('progressCreate', newProgress);
        return newProgress;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, []);
  const handleToggle = () => {
    setIsToggled((prev) => !prev);
    // Add any additional actions you want here
  }
  useEffect(() => {
    if (isToggled) {
      setisScheduled(1);
    }
  }, [isToggled]);
  const handleClearStorage = () => {
    localStorage.clear();
    localStorage.removeItem("isAuthenticated");
    sessionStorage.clear();
    for (const cookieName in cookies) {
      removeCookie(cookieName);
    }
    navigate("/app/login");
    window.location.reload(false);
  };
  const handleLanguageChange = (event) => {
    const selectedId = event.value;
    setSelectedLanguage(selectedId);

    // Find the selected language object by language_id
    const selectedLangObj = Language.find((lang) => lang.language_id === selectedId);

    // Set the name of the selected language if it exists
    if (selectedLangObj) {
      setLanguage(selectedLangObj.name);
    }
  };

  const audioRefs = useRef([]);
  const [errors, setErrors] = useState({
    destination: "",
    duration: "",
  });

  const toggleContent = () => {
    setIsVisible((prevState) => !prevState);
  };

  const navigate = useNavigate();

  const handleOptionChange = (e) => {
    const value = e.target.value;
    setSelectedOption(value);
    setSecondaryInputVisible(value === "youtube" || "email");
    setAdditionalFieldsVisible(false);
    ;
  };
  const handleCustomtopic = (e) => {
    setCustomTopic(e.target.value);
  };
  const handlePostInDay = (e) => {
    console.log(e)
    setPostInDay(e)
  }
  const handleTimezone = (e) => {
    console.log(e)
    setTimezone(e)
  }
  const handleSecondaryInputChange = (e) => {
    const value = e.target.value;

    setAdditionalFieldsVisible(value !== "");
    setShowCustomTopic(value === "Custom Topic");
    if (showCustomTopic) {
      setSelectedDropdownOption(customTopic)
    } else {
      setSelectedDropdownOption(value);
    }
    // setErrors((prevErrors) => ({
    //   ...prevErrors,
    //   content: value ? "" : "Content is required",
    // }));
  };

  const ClickCreate = async () => {
    const data = {
         type: "Ai Image",
    }
    const response = await listVideoFree(data)
    const userId = response.data.data.userId
    const videoId = response.data.data.response.insertId
    if(response.data.status === true){
        navigate("/app/preview", {
            state: {
                videoUrl: `/${userId}/${videoId}/output.mp4`,
                path: `/${userId}/${videoId}/images`,
                scriptPath: `/${userId}/${videoId}/scriptAndPrompt/generated_scripts.txt`,
                videoid: videoId,
                type: aiAspectRatio,
                userid: userId
            },
        });
    }
  }

  const handleOneclick = (e) => {
    const value = e.target.value;
    if (!value) {
      setselectFontOption('16')
    } else {
      setselectFontOption(value);

    }
  };

  const handleClick = (e) => {
    const value = e.target.value;
    if (!value) {
      setselectPosition("125")
    } else {
      setselectPosition(value);

    }
  };

  function getDifferenceInDays(date1, date2) {

    const d1 = new Date(date1);
    const d2 = new Date(date2);


    const differenceInMilliseconds = Math.abs(d1 - d2);


    const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));

    return differenceInDays;
  }




  const daysDifference = getDifferenceInDays(startDate, endDate);
  const CreditsRequired = daysDifference * postInDay;

  // if (CreditsRequired > series) {
  //   setDatePopup(true);
  // } else {
  //   setDatePopup(false);
  // }

  // console.log(CreditsRequired,'dates difference');  



  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [inputValue1, setInputValue1] = useState("");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleItemClick = (option) => {
    setInputValue(option);
    setIsOpen(false);
  };

  const handleDropdownToggle = () => {
    setIsOpen1(!isOpen1);
  };
  const handleOptionSelect = (option1) => {
    setInputValue1(option1);
    setIsOpen1(false);
  };
  const Creators = [
    {
      name: "Erica",
      image: Erica,
    },

    {
      name: "Anthony-1",
      image: Anthony,
    },

    {
      name: "Kate",
      image: Kate,
    },
    {
      name: "Mia-1",
      image: Mia,
    },
    {
      name: "Peter",
      image: Peter,
    },
  ];
  const images = [
    {
      name: "raytraced",
      imageLink:
        `${server_App_URl}/media/images/RAYTRACED.avif`,
      pre: "raytraced.mp4",
    },
    {
      name: "Default",
      imageLink:
        `${server_App_URl}/media/images/DEFAULT.avif`,
      pre: "default.mp4",
    },
    {
      name: "anime",
      imageLink:
        `${server_App_URl}/media/images/ANIME.avif`,
      pre: "anime.mp4",
    },
    {
      name: "illustration",
      imageLink:
        `${server_App_URl}/media/images/ILLUSTRATION.avif`,
      pre: "illustration.mp4",
    },
    {
      name: "sketch color",
      imageLink:
        `${server_App_URl}/media/images/SKETCH_COLOR.avif`,
      pre: "sketch_color.mp4",
    },
    {
      name: "sketch black and white",
      imageLink:
        `${server_App_URl}/media/images/SKETCH_BLACK_AND_WHITE.avif`,
      pre: "sketch_black_and_white.mp4",
    },
    {
      name: "pixar",
      imageLink:
        `${server_App_URl}/media/images/PIXAR.avif`,
      pre: "pixar.mp4",
    },
    {
      name: "japanese ink",
      imageLink:
        `${server_App_URl}/media/images/INK.avif`,
      pre: "japanese_ink.mp4",
    },
    {
      name: "3D render",
      imageLink:
        `${server_App_URl}/media/images/RENDER_3D.avif`,
      pre: "3D_render.mp4",
    },
    {
      name: "lego",
      imageLink:
        `${server_App_URl}/media/images/LEGO.avif`,
      pre: "lego.mp4",
    },
    {
      name: "Sci-fi",
      imageLink:
        `${server_App_URl}/media/images/SCIFI.avif`,
      pre: "sci_fi.mp4",
    },
    {
      name: "retro cartoon",
      imageLink:
        `${server_App_URl}/media/images/RETRO_CARTOON.avif`,
      pre: "retro_cartoon.mp4",
    },
    {
      name: "pixel art",
      imageLink:
        `${server_App_URl}/media/images/PIXEL_ART.avif`,
      pre: "pixel_art.mp4",
    },
    {
      name: "creative",
      imageLink:
        `${server_App_URl}/media/images/CREATIVE.avif`,
      pre: "creative.mp4",
    },
    {
      name: "photography",
      imageLink:
        `${server_App_URl}/media/images/PHOTOGRAPHY.avif`,
      pre: "photography.mp4",
    },

    {
      name: "environment",
      imageLink:
        `${server_App_URl}/media/images/ENVIRONMENT.avif`,
      pre: "environment.mp4",
    },
    {
      name: "realist",
      imageLink:
        `${server_App_URl}/media/images/REALISM.avif`,
      pre: "realist.mp4",
    },
  ];

  const Templates = [
    // {
    //   id: "default",
    //   categories: ["animated", "highlighted"],
    //   pre: "1.mp4",
    //   previewUrl:
    //     "https://cdn.zapcap.ai/templates/e7e758de-4eb4-460f-aeca-b2801ac7f8cc.mp4",
    //   previews: {
    //     previewMp4:
    //       "https://cdn.zapcap.ai/templates/e7e758de-4eb4-460f-aeca-b2801ac7f8cc.mp4",
    //     previewGif:
    //       `${server_App_URl}/media/defaultGif.gif`,
    //   },
    // },
    {
      id: "e7e758de-4eb4-460f-aeca-b2801ac7f8cc",
      categories: ["animated", "highlighted"],
      pre: "1.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/e7e758de-4eb4-460f-aeca-b2801ac7f8cc.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/e7e758de-4eb4-460f-aeca-b2801ac7f8cc.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/e7e758de-4eb4-460f-aeca-b2801ac7f8cc.gif",
      },
    },
    {
      id: "21327a45-df89-46bc-8d56-34b8d29d3a0e",
      categories: ["basic"],
      pre: "2.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/21327a45-df89-46bc-8d56-34b8d29d3a0e.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/21327a45-df89-46bc-8d56-34b8d29d3a0e.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/21327a45-df89-46bc-8d56-34b8d29d3a0e.gif",
      },
    },
    {
      id: "a6760d82-72c1-4190-bfdb-7d9c908732f1",
      categories: ["animated"],
      pre: "3.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/a6760d82-72c1-4190-bfdb-7d9c908732f1.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/a6760d82-72c1-4190-bfdb-7d9c908732f1.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/a6760d82-72c1-4190-bfdb-7d9c908732f1.gif",
      },
    },
    {
      id: "982ad276-a76f-4d80-a4e2-b8fae0038464",
      categories: ["basic", "highlighted"],
      pre: "4.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/982ad276-a76f-4d80-a4e2-b8fae0038464.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/982ad276-a76f-4d80-a4e2-b8fae0038464.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/982ad276-a76f-4d80-a4e2-b8fae0038464.gif",
      },
    },
    {
      id: "07ffd4b8-4e1a-4ee3-8921-d58802953bcd",
      categories: ["basic", "highlighted"],
      pre: "5.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/07ffd4b8-4e1a-4ee3-8921-d58802953bcd.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/07ffd4b8-4e1a-4ee3-8921-d58802953bcd.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/07ffd4b8-4e1a-4ee3-8921-d58802953bcd.gif",
      },
    },
    {
      id: "7b946549-ae16-4085-9dd3-c20c82504daa",
      categories: ["basic", "highlighted"],
      pre: "6.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/7b946549-ae16-4085-9dd3-c20c82504daa.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/7b946549-ae16-4085-9dd3-c20c82504daa.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/7b946549-ae16-4085-9dd3-c20c82504daa.gif",
      },
    },
    {
      id: "14bcd077-3f98-465b-b788-1b628951c340",
      categories: ["basic", "highlighted"],
      pre: "7.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/14bcd077-3f98-465b-b788-1b628951c340.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/14bcd077-3f98-465b-b788-1b628951c340.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/14bcd077-3f98-465b-b788-1b628951c340.gif",
      },
    },
    {
      id: "d46bb0da-cce0-4507-909d-fa8904fb8ed7",
      categories: ["animated"],
      pre: "8.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/d46bb0da-cce0-4507-909d-fa8904fb8ed7.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/d46bb0da-cce0-4507-909d-fa8904fb8ed7.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/d46bb0da-cce0-4507-909d-fa8904fb8ed7.gif",
      },
    },
    {
      id: "decf5309-2094-4257-a646-cabe1f1ba89a",
      categories: ["animated"],
      pre: "9.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/decf5309-2094-4257-a646-cabe1f1ba89a.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/decf5309-2094-4257-a646-cabe1f1ba89a.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/decf5309-2094-4257-a646-cabe1f1ba89a.gif",
      },
    },
    {
      id: "dfe027d9-bd9d-4e55-a94f-d57ed368a060",
      categories: ["animated", "highlighted"],
      pre: "10.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/dfe027d9-bd9d-4e55-a94f-d57ed368a060.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/dfe027d9-bd9d-4e55-a94f-d57ed368a060.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/dfe027d9-bd9d-4e55-a94f-d57ed368a060.gif",
      },
    },
    {
      id: "1bb3b68b-6a93-453a-afd7-a774b62cdab8",
      categories: [],
      pre: "11.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/1bb3b68b-6a93-453a-afd7-a774b62cdab8.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/1bb3b68b-6a93-453a-afd7-a774b62cdab8.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/1bb3b68b-6a93-453a-afd7-a774b62cdab8.gif",
      },
    },
    {
      id: "46d20d67-255c-4c6a-b971-31fddcfea7f0",
      categories: ["animated", "highlighted"],
      pre: "12.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/46d20d67-255c-4c6a-b971-31fddcfea7f0.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/46d20d67-255c-4c6a-b971-31fddcfea7f0.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/46d20d67-255c-4c6a-b971-31fddcfea7f0.gif",
      },
    },
    {
      id: "c88bff11-7f03-4066-94cd-88f71f9ecc68",
      categories: ["basic"],
      pre: "13.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/c88bff11-7f03-4066-94cd-88f71f9ecc68.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/c88bff11-7f03-4066-94cd-88f71f9ecc68.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/c88bff11-7f03-4066-94cd-88f71f9ecc68.gif",
      },
    },
    {
      id: "a104df87-5b1a-4490-8cca-62e504a84615",
      categories: ["highlighted"],
      pre: "14.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/a104df87-5b1a-4490-8cca-62e504a84615.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/a104df87-5b1a-4490-8cca-62e504a84615.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/a104df87-5b1a-4490-8cca-62e504a84615.gif",
      },
    },
    {
      id: "ca050348-e2d0-49a7-9c75-7a5e8335c67d",
      categories: ["animated"],
      pre: "15.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/ca050348-e2d0-49a7-9c75-7a5e8335c67d.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/ca050348-e2d0-49a7-9c75-7a5e8335c67d.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/ca050348-e2d0-49a7-9c75-7a5e8335c67d.gif",
      },
    },
    {
      id: "eb5de878-2997-41fe-858a-726e9e3712df",
      categories: ["basic"],
      pre: "16.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/eb5de878-2997-41fe-858a-726e9e3712df.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/eb5de878-2997-41fe-858a-726e9e3712df.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/eb5de878-2997-41fe-858a-726e9e3712df.gif",
      },
    },
    {
      id: "a51c5222-47a7-4c37-b052-7b9853d66bf6",
      categories: ["animated", "highlighted"],
      pre: "17.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/a51c5222-47a7-4c37-b052-7b9853d66bf6.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/a51c5222-47a7-4c37-b052-7b9853d66bf6.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/a51c5222-47a7-4c37-b052-7b9853d66bf6.gif",
      },
    },
    {
      id: "5de632e7-0b02-4d15-8137-e004871e861b",
      categories: ["animated"],
      pre: "18.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/5de632e7-0b02-4d15-8137-e004871e861b.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/5de632e7-0b02-4d15-8137-e004871e861b.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/5de632e7-0b02-4d15-8137-e004871e861b.gif",
      },
    },
    {
      id: "55267be2-9eec-4d06-aff8-edcb401b112e",
      categories: ["highlighted"],
      pre: "19.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/55267be2-9eec-4d06-aff8-edcb401b112e.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/55267be2-9eec-4d06-aff8-edcb401b112e.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/55267be2-9eec-4d06-aff8-edcb401b112e.gif",
      },
    },
    {
      id: "50cdfac1-0a7a-48dd-af14-4d24971e213a",
      categories: ["highlighted"],
      pre: "20.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/50cdfac1-0a7a-48dd-af14-4d24971e213a.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/50cdfac1-0a7a-48dd-af14-4d24971e213a.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/50cdfac1-0a7a-48dd-af14-4d24971e213a.gif",
      },
    },
    {
      id: "cfa6a20f-cacc-4fb6-b1d0-464a81fed6cf",
      categories: ["highlighted"],
      pre: "21.mp4",
      previewUrl:
        "https://cdn.zapcap.ai/templates/cfa6a20f-cacc-4fb6-b1d0-464a81fed6cf.mp4",
      previews: {
        previewMp4:
          "https://cdn.zapcap.ai/templates/cfa6a20f-cacc-4fb6-b1d0-464a81fed6cf.mp4",
        previewGif:
          "https://cdn.zapcap.ai/template-gifs/cfa6a20f-cacc-4fb6-b1d0-464a81fed6cf.gif",
      },
    },
  ];
  const Language = [
    { language_id: "hi", name: "Hindi", flagCode: "IN" },
    { language_id: "en", name: "English", flagCode: "US" },
    { language_id: "ja", name: "Japanese", flagCode: "JP" },
    { language_id: "zh", name: "Chinese", flagCode: "CN" },
    { language_id: "de", name: "German", flagCode: "DE" },
    { language_id: "fr", name: "French", flagCode: "FR" },
    { language_id: "ko", name: "Korean", flagCode: "KR" },
    { language_id: "pt", name: "Portuguese", flagCode: "PT" },
    { language_id: "it", name: "Italian", flagCode: "IT" },
    { language_id: "es", name: "Spanish", flagCode: "ES" },
    { language_id: "id", name: "Indonesian", flagCode: "ID" },
    { language_id: "nl", name: "Dutch", flagCode: "NL" },
    { language_id: "tr", name: "Turkish", flagCode: "TR" },
    { language_id: "fil", name: "Filipino", flagCode: "PH" },
    { language_id: "pl", name: "Polish", flagCode: "PL" },
    { language_id: "sv", name: "Swedish", flagCode: "SE" },
    { language_id: "bg", name: "Bulgarian", flagCode: "BG" },
    { language_id: "ro", name: "Romanian", flagCode: "RO" },
    { language_id: "ar", name: "Arabic", flagCode: "SA" },
    { language_id: "cs", name: "Czech", flagCode: "CZ" },
    { language_id: "el", name: "Greek", flagCode: "GR" },
    { language_id: "fi", name: "Finnish", flagCode: "FI" },
    { language_id: "hr", name: "Croatian", flagCode: "HR" },
    { language_id: "ms", name: "Malay", flagCode: "MY" },
    { language_id: "sk", name: "Slovak", flagCode: "SK" },
    { language_id: "da", name: "Danish", flagCode: "DK" },
    { language_id: "ta", name: "Tamil", flagCode: "IN" },
    { language_id: "uk", name: "Ukrainian", flagCode: "UA" },
    { language_id: "ru", name: "Russian", flagCode: "RU" }
  ];

  const handleTemplateClick = (id) => {
    setSelectedTemplateId(id);
    console.log("Selected Template ID:", id);
  };

  const [voicetype, setvoicetype] = useState({});

  const updatevoicetype = (data, index) => {
    setvoicetype(data);
    if (currentPlayingIndex !== null && currentPlayingIndex !== index) {
      if (audioRefs.current[currentPlayingIndex]) {
        audioRefs.current[currentPlayingIndex].pause();
        audioRefs.current[currentPlayingIndex].currentTime = 0;
      }
    }
    setActiveIndex(index);
    setCurrentPlayingIndex(index);
  };

  const activeVoice = (index) => {
    setActiveIndex(index);
    setdropactiveIndex(index);
  };

  const validateForm = () => {
    const errors = {};
    // if (!selectedOption) errors.destination = "Please enter destination";
    // if (!selectedDropdownOption) errors.content = "Please enter content";
    if (isToggled && startDate === '' && endDate === '') errors.date = 'Please Enter Dates';
    if (isToggled && timezone === '') errors.timezone = 'Please Select Timezone';
    if (!activeId) errors.voice = "Please select a voice";
    if (selectedDropdownOption === "Custom Topic" && !customTopic) {
      errors.customTopic = "Please enter a custom topic";
    }
    // if (!duration) errors.duration = 'Duration is required';
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const planResponse = async () => {
    console.log('planResponse')
    try {
      const response = await Plans();

      setSeries(response.credit);
      setPlan(response.PlanTypeName);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (loading) {
      planResponse();
    }
  }, [loading]);


  // const [openmodal , setopenmodal ] = useState(false);

  const handleGenerate = async () => {
    const contentType = {
      script: script,
      voiceName: creatorPic,
    };

    if (series) {
      navigate("/app/billing");
      return;
    } else {
      try {
        const response = await generateVideo(contentType);
        console.log(response, "Generate Response");
        if (response) {
          const videoId = response.data.data.response.insertId;
          const userId = response.data.data.UserId;

          navigate("/app/video-preview", {
            state: {
              videoUrl: `/${userId}/${videoId}/output.mp4`,
              videoid: videoId,

            },
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const [showModal, setShowModal] = React.useState(false);
  const handleScriptChange = (value) => {
    setScript(value);
  };

  const [isScheduled, setisScheduled] = useState(0)

  const handleCreate = async () => {
    if (!series) {
      navigate("/billing");
      return;
    } else if (parseInt(CreditsRequired) > parseInt(series)) {
      console.log('helloo');
      setDatePopup(true);
      setCreateLoading(false)
    }
    else if (!validateForm()) return;
    // const value = document.getElementById("Content").value;
    const destinationDropdownvalue = document.getElementById("dropdown").value;
    const durationDropdown = document.getElementById("dropdown-select").value;
    const fontSizeElement = document.getElementById("font-size");
    const positionElement = document.getElementById("position");
    const fontsize = fontSizeElement && fontSizeElement.value ? fontSizeElement.value : "16"; // Default to "16px"
    const position = positionElement && positionElement.value ? positionElement.value : "125"; // Default
    // console.log(value);
    let contentType;

    if (showCustomTopic) {
      contentType = {
        scriptOrTopic: selectedType,
        script: script,
        contentType: customTopic,
        destination: 'Preview',
        narrationVoice: "siri",
        videoLanguage: "English",
        fontSize: fontsize,
        fontPosition: position,
        durationpreference: durationDropdown,
        audioVoiceId: activeId,
        imageType: pictureType,
        imageSize: aiAspectRatio,
        templateId: selectedTemplateId,
        languageCode: selectedLanguage,
        language: language,
        youtubeUpload: youtubeUpload,
        is_schedule: isScheduled,
        type: 'Ai Image',
        frequency: Number(postInDay),
        time_zone: timezone,
        start_date: startDate,
        end_date: endDate


      };
    } else {
      contentType = {
        scriptOrTopic: selectedType,
        script: script,
        contentType: content,
        destination: 'Preview',
        narrationVoice: "siri",
        videoLanguage: "English",
        fontSize: fontsize,
        fontPosition: position,
        durationpreference: durationDropdown,
        audioVoiceId: activeId,
        imageType: pictureType,
        imageSize: aiAspectRatio,
        templateId: selectedTemplateId,
        languageCode: selectedLanguage,
        language: language,
        youtubeUpload: youtubeUpload,
        is_schedule: isScheduled,
        type: 'Ai Image',
        frequency: Number(postInDay),
        time_zone: timezone,
        start_date: startDate,
        end_date: endDate
      };
    }
    if (!content && selectedType === 'AI Script') {
      setShowModal(true)
      setModalMessage('Please Select the Content Type')
    } else {

      setCreateLoading(true)

      try {
        setShowModal(false)
        localStorage.removeItem('progressCreate');
        // console.log(contentType,'contentt')
        const responseData = await createVedio(contentType);
        if (responseData && isScheduled === 1) {
          navigate('/app/autopost')
          setCreateLoading(false)
        } else if (responseData && isScheduled === 0) {
          localStorage.removeItem('progress')
          const userId = responseData.data.data.userId;
          const videoId = responseData.data.data.response.insertId;
          console.log(userId, 'idddd')
          navigate("/app/preview", {
            state: {
              videoUrl: `/${userId}/${videoId}/output.mp4`,
              path: `/${userId}/${videoId}/images`,
              scriptPath: `/${userId}/${videoId}/scriptAndPrompt/generated_scripts.txt`,
              videoid: videoId,
              type: aiAspectRatio,
              userid: userId
            },
          });
          setCreateLoading(false)
        }
      } catch (error) {
        setCreateLoading(false)
        console.error("Error:", error);
        if (
          (error.response && error.response.data) ||
          error.response.data.message === "Un-authorized"
        ) {
          if (error.response.data.message === "Un-authorized") {
            localStorage.clear();
            navigate("/app");
            window.location.reload();

            setModalMessage(
              ` ${error.response.data.message || "Something went wrong"}`
            );
            setShowModal(true)
          }
          else {

            setModalMessage(
              ` ${error.response.data.message || "Something went wrong"}`
            );
            setShowModal(true)

          }
        } else {
          setModalMessage("An unexpected error occurred");
          setShowModal(true)
        }
      }
    }


  };
  const handleTypeChange = (e) => {
    const value = e.target.value;
    setSelectedType(value);
    // setErrors((prevErrors) => ({
    //   ...prevErrors,
    //   destination: value ? "" : "Destination is required",
    // }));
    if (value === 'Script') {
      setShowScript(true)
    } else {
      setShowScript(false)
    }
  }

  const handleSocialmediaChange = (e) => {
    const value = e.target.value;
    if (value === 'you_tube') {
      setYoutubeUpload(1)
    } else {
      setYoutubeUpload(0)
    }
    setSocialmedia(value)
    // setErrors((prevErrors) => ({
    //   ...prevErrors,
    //   destination: value ? "" : "Destination is required",
    // }));
  }

  const togglePlayback = (index) => {
    if (playingIndex === index) {
      audioRefs.current[index].pause();
      setPlayingIndex(null);
    } else {
      if (playingIndex !== null) {
        audioRefs.current[playingIndex].pause();
      }
      setPlayingIndex(index);
      audioRefs.current[index].play();
    }
  };

  const handleAudioEnded = (index) => {
    setPlayingIndex(null);
  };
  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60)
      .toString()
      .padStart(2, "0");
    return `${minutes}:${seconds}`;
  };
  const [username, setusername] = useState(null)
  const youtubeInfo = async () => {
    try {
      const response = await socialmedia();
      setusername(response.data.name);
    } catch (error) {
      console.error('Error fetching videos:', error);
    }
  };

  useEffect(() => {
    if (loading) {
      youtubeInfo();

    }
  }, [loading]);

  const handleTimeUpdate = (index) => {
    const audioElement = audioRefs.current[index];
    if (audioElement) {
      setPlaybackTimes((prevTimes) => ({
        ...prevTimes,
        [index]: audioElement.currentTime,
      }));
    }
  };

  const options = Language.map((lang) => ({
    value: lang.language_id,
    label: (
      <span>

        {lang.name}
        <ReactCountryFlag
          countryCode={lang.flagCode}
          svg
          style={{
            width: '1em',
            height: '0.75em',
            marginLeft: '0.5em'
          }}
        />
      </span>
    )
  }));
  const handleStartDateChange = (e) => {
    const value = e.target.value;
    const today = new Date().toISOString().split("T")[0];

    setStartDate(value); // Update the start date state
  };

  const userId = localStorage.getItem('userId')



  return (
    <div className="">
      <DashboardNavbar />
      <div className="flex md:h-max h-max">
        <div className="maindiv w-full pt-4 lg:p-[44px] overflow-x-hidden font-montserrat h-max lg:h-full space-y-6 flex justify-center pb-20 flex-col">

          <div className="flex flex-col mt-8">
            <h1 className="createh1 text-2xl sm:text-3xl md:text-4xl lg:text-5xl -top-8   font-bold text-center  font-poppins ">
              CREATE{" "}
              <span className="text-green-500">
                FACELESS VIDEOS
              </span>
            </h1>
            <p className="text-lg md:text-md text-center mt-3 text-gray-400 font-interr">
              Create Faceless Videos and Generate Income
            </p>
          </div>

          {selectedTopic === "Shorts Generator" ? (
            <div className="content-main flex justify-evenly min-h-screen gap-3 items-start w-full">
              <div className={`name md:p-12 p-[12px] h-max md:w-[80%]  lg:p-6 mx-auto  rounded-2xl border border-green-500/10  shadow-md space-y-4`}>
                <div>
                  <label
                    htmlFor="dropdown"
                    className="block text-sm font-medium  "
                  >
                    <h1 className={`font-bold text-[16px]   pt-1 `}>
                      Destination
                    </h1>
                    <h4 className={`text-gray-400 pb-3 pt-1 font-sans`}>
                      The account where your video series will be posted
                    </h4>
                  </label>


                  {plan === 'Free' && series === 1 ? (<select
                    id="dropdown"
                    className={`block w-full px-3  dropdown   py-2  md:py-12 border border-gray-300 rounded-md shadow-sm focus:outline-blue-600 focus:ring-blue-500 focus:border-green-500 sm:text-sm`}
                    onChange={handleSocialmediaChange}
                    value={Socialmedia}
                  >
                    <option value="none">
                      {" "}
                      <MdOutlineAlternateEmail />
                      Email Me Video
                    </option>
                    <option disabled value="you_tube">
                      Post To Youtube

                    </option>
                    <option disabled value="tik_tok">
                      Post To Tiktok
                    </option>

                  </select>) : (
                    <>
                      {username ? (<select
                        id="dropdown"
                        className={`block w-full px-3 py-2 dropdown md:py-12 border border-gray-300 rounded-md shadow-sm focus:outline-blue-600 focus:ring-blue-500 focus:border-green-500 sm:text-sm`}
                        onChange={handleSocialmediaChange}
                        value={Socialmedia}
                      >
                        <option value="none">
                          {" "}
                          <MdOutlineAlternateEmail />
                          Email Me Video
                        </option>
                        <option disabled value="you_tube">
                          Post To Youtube

                        </option>
                        <option disabled value="tik_tok">
                          Post To Tiktok
                        </option>

                      </select>) : (
                        <>
                          <select
                            className={` block w-full px-3 py-2  dropdown    md:py-12 border border-gray-300 rounded-md shadow-sm focus:outline-blue-600 focus:ring-blue-500 focus:border-green-500 sm:text-sm`}
                            onChange={(e) => {

                              setnoConnectedDropdown(e.target.value)
                            }}
                            value={noConnectedDropdown}
                          >
                            <option value="none">
                              Email Me Video
                            </option>
                            <option value="youtube">
                              Post To Youtube
                            </option>
                            <option disabled value="tik_tok">
                              Post to Tiktok
                            </option>

                          </select>

                        </>)}
                    </>
                  )}
                </div>


                <div>
                  <label
                    htmlFor="dropdown"
                    className="block text-sm font-medium  "
                  >

                    <h1 className={`font-bold text-[16px]   pt-1 mb-2 `}>
                      Select Script Type
                    </h1>
                  </label>


                  {plan === 'Free' && series === 1 ? (<select
                    id="dropdown"
                    className={`block w-full px-3 py-2  dropdown md:py-12 border border-gray-300 rounded-md shadow-sm focus:outline-blue-600 focus:ring-blue-500 focus:border-green-500 sm:text-sm`}
                    onChange={handleTypeChange}
                    value={selectedType}
                  >
                    <option value="AI Script">
                      {" "}
                      <MdOutlineAlternateEmail />
                      AI Generated Script
                    </option>
                    <option disabled value="Script">
                      Enter Own Script
                    </option>

                  </select>) : (
                    <select
                      id="dropdown"
                      className={`block w-full px-3 py-2 dropdown md:py-12 border border-gray-300 rounded-md shadow-sm focus:outline-blue-600 focus:ring-blue-500 focus:border-green-500 sm:text-sm`}
                      onChange={handleTypeChange}
                      value={selectedType}
                    >
                      <option value="AI Script">
                        {" "}
                        <MdOutlineAlternateEmail />
                        AI Generated Script
                      </option>
                      <option value="Script">
                        Enter Own Script
                      </option>
                    </select>
                  )}
                </div>
                {showScript ? (<div>
                  <label
                    htmlFor="message"
                    className="mb-2 text-sm font-medium text-gray-900 dark: "
                  >
                    Enter Your Script
                  </label>

                  <textarea
                    id="message"
                    rows="10"
                    className="p-2.5 w-full h-[320px] text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-green-500 dark:  dark:border-gray-600 dark:placeholder-gray-400 dark:  dark:focus:ring-blue-500 dark:focus:border-green-500"
                    placeholder="Enter your script..."
                    value={script}
                    maxLength={780}
                    onChange={(e) =>
                      handleScriptChange(e.target.value)
                    }
                  ></textarea>
                  <div>
                    <p className="text-gray-500 text-sm mt-2">
                      Note: Your script must not exceed 800
                      characters.
                    </p>
                  </div>

                </div>) : (<><div>
                  <label
                    htmlFor="Content"
                    className="block text-sm font-medium  "
                  >
                    {/* <h6 className=" w-max border-1 text-[10px] text-blue-700 border-violet-600 pt-[3px] pl-[10px] pb-[3px] pr-[10px] rounded-xl" >Step2</h6> */}
                    <h1 className={`font-bold text-[16px]   pt-1 `}>
                      Content
                    </h1>

                    <h4 className={`text-gray-400 pb-3 pt-1 font-sans`}>
                      What will your video series be about?
                    </h4>
                  </label>
                  {plan === 'Free' && series === 1 ? ( <>
                      <button
                        onClick={() => setTopicModal(true)}
                        className={` flex justify-center items-center     rounded-md w-full border border-gray-300 shadow-sm p-2`}
                      >{content ? `${content}` : "Choose Content Type"}

                      </button>
                      {topicModal && (
                        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75 h-[100%]">
                          <div className={` max-w-3xl h-[95%]  ${themefile === "dark" ? "bg-black" : "bg-white"} max-h-fit overflow-y-auto mx-auto rounded-xl p-8 shadow-2xl transform hover:scale-[1.02] transition-all duration-300 my-8`}>
                            <div className="mb-8">
                              <h2 className={` text-3xl font-bold flex items-center gap-2 mb-6   hover-glow`}>
                                Trending <span className="text-green-500">Topics</span> <span className="animate-bounce">🔥</span>
                              </h2>
                              <ul className="grid grid-cols-2 md:grid-cols-3 gap-4">
                                {[
                                  {
                                    name: "Custom Topic",
                                    icon: ImportContactsIcon,
                                  },
                                  {
                                    name: "Hybrid Animals",
                                    icon: PetsIcon,
                                  },
                                  {
                                    name: "Baby Animals",
                                    icon: BedroomBabySharpIcon,
                                  },
                                  {
                                    name: "Luxury Motivational",
                                    icon: MonetizationOnIcon,
                                  },
                                  {
                                    name: "AI Cats",
                                    icon: SmartToyIcon,
                                  },
                                  {
                                    name: "Tiny Animals",
                                    icon: EmojiNatureIcon,
                                  },
                                  {
                                    name: "Pokemon Evolution",
                                    icon: CatchingPokemonIcon,
                                  },
                                  {
                                    name: "Emoji Reels",
                                    icon: EmojiEmotionsIcon,
                                  },
                                  {
                                    name: "Nature Reels",
                                    icon: SpaIcon,
                                  },
                                ].map((item, i) => (
                                  <li
                                    key={i}
                                    onClick={() => {
                                      // setContent(item.name);
                                      if (item.name === "Hybrid Animals") {
                                        setContent('Hybrid Animals')
                                    } else {
                                        setContent('')
                                    }
                                      setTopicModal(false);
                                      if (item.name === "Custom Topic") {
                                        setShowCustomTopic(true);
                                      } else {
                                        setShowCustomTopic(false);
                                      }
                                    }}
                                    disabled={item.name !== "Hybrid Animals"}
                                    className={`p-3 text-sm font-semibold rounded-lg w-[105%] ${item.name !== "Hybrid Animals" ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}   border border-gray-300 transition-colors duration-300 flex  shadow-sm hover:shadow-md font-medium transform hover:-translate-y-1`}
                                  >{item.icon && <item.icon className="mr-2" />}
                                    {item.name}
                                    {item.name !== "Hybrid Animals" && <MdLock className="ml-2" />}
                                  </li>
                                ))}
                              </ul>
                            </div>
                            <div>
                              <h2 className={`text-3xl font-bold mb-6   hover-glow`}>
                                More <span className="text-green-500">Topics</span>
                              </h2>
                              <ul className="grid grid-cols-2 md:grid-cols-3 gap-4">
                                {[
                                  {
                                    name: "Interesting History",
                                    icon: AccountBalanceIcon,
                                  },
                                  {
                                    name: "AI Stories",
                                    icon: MovieFilterIcon,
                                  },
                                  {
                                    name: "Scary Stories",
                                    icon: ElderlyWomanIcon,
                                  },
                                  {
                                    name: "Bedtime Stories",
                                    icon: BedtimeIcon,
                                  },
                                  {
                                    name: "Urban Legends",
                                    icon: ApartmentIcon,
                                  },
                                  {
                                    name: "Motivational",
                                    icon: ElectricBoltIcon,
                                  },
                                  {
                                    name: "Fun Facts",
                                    icon: LightbulbIcon,
                                  },
                                  {
                                    name: "Long Form Jokes",
                                    icon: EmojiEmotions,
                                  },
                                  {
                                    name: "Life Pro Tips",
                                    icon: StarIcon,
                                  },
                                  {
                                    name: "Philosophy",
                                    icon: PsychologyIcon,
                                  },
                                  {
                                    name: "Product Marketing",
                                    icon: CampaignIcon,
                                  },
                                ].map((item, i) => (
                                  <li
                                    key={i}
                                    onClick={() => {
                                      // setContent(item.name);
                                      if (item.name === "Hybrid Animals") {
                                        setContent('Hybrid Animals')
                                    } else {
                                        setContent('')
                                    }
                                      setTopicModal(false);
                                      setShowCustomTopic(false)
                                    }}
                                    disabled={item.name !== "Hybrid Animals"}
                                    className={`p-3 font-semibold text-sm ${item.name !== "Hybrid Animals" ? "opacity-50 cursor-not-allowed" : "cursor-pointer"} rounded-lg w-[105%]   border border-gray-300 transition-colors duration-300 flex shadow-sm hover:shadow-md font-medium transform hover:-translate-y-1`}
                                  >{item.icon && <item.icon className="mr-2" />}
                                    {item.name}
                                    {item.name !== "Hybrid Animals" && <MdLock className="ml-2" />}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                          <style jsx global>{`
      .hover-glow:hover {
        text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
      }
    `}</style>
                        </div>
                      )}


                    </>) :
                    <>
                      <button
                        onClick={() => setTopicModal(true)}
                        className={` flex justify-center items-center     rounded-md w-full border border-gray-300 shadow-sm p-2`}
                      >{content ? `${content}` : "Choose Content Type"}

                      </button>
                      {topicModal && (
                        <div className="fixed inset-0 z-50 flex items-center bg-black justify-center bg-opacity-75 h-[100%]">
                          <div className={` max-w-3xl h-[95%] ${themefile === "dark" ? "bg-black" : "bg-white"}  max-h-fit overflow-y-auto mx-auto rounded-xl p-8 shadow-2xl transform hover:scale-[1.02] transition-all duration-300 my-8`}>
                            <div className="mb-8">
                              <h2 className={` text-3xl font-bold flex items-center gap-2 mb-6   hover-glow`}>
                                Trending <span className="text-green-500">Topics</span> <span className="animate-bounce">🔥</span>
                              </h2>
                              <ul className="grid grid-cols-2 md:grid-cols-3 gap-4">
                                {[
                                  {
                                    name: "Custom Topic",
                                    icon: ImportContactsIcon,
                                  },
                                  {
                                    name: "Hybrid Animals",
                                    icon: PetsIcon,
                                  },
                                  {
                                    name: "Baby Animals",
                                    icon: BedroomBabySharpIcon,
                                  },
                                  {
                                    name: "Luxury Motivational",
                                    icon: MonetizationOnIcon,
                                  },
                                  {
                                    name: "AI Cats",
                                    icon: SmartToyIcon,
                                  },
                                  {
                                    name: "Tiny Animals",
                                    icon: EmojiNatureIcon,
                                  },
                                  {
                                    name: "Pokemon Evolution",
                                    icon: CatchingPokemonIcon,
                                  },
                                  {
                                    name: "Emoji Reels",
                                    icon: EmojiEmotionsIcon,
                                  },
                                  {
                                    name: "Nature Reels",
                                    icon: SpaIcon,
                                  },
                                ].map((item, i) => (
                                  <li
                                    key={i}
                                    onClick={() => {
                                      setContent(item.name);
                                      setTopicModal(false);
                                      if (item.name === "Custom Topic") {
                                        setShowCustomTopic(true);
                                      } else {
                                        setShowCustomTopic(false);
                                      }
                                    }}
                                    className={`p-3 text-sm font-semibold rounded-lg w-[105%]   border border-gray-300 transition-colors duration-300 cursor-pointer shadow-sm hover:shadow-md font-medium transform hover:-translate-y-1`}
                                  >{item.icon && <item.icon className="mr-2" />}
                                    {item.name}
                                  </li>
                                ))}
                              </ul>
                            </div>
                            <div>
                              <h2 className={`text-3xl font-bold mb-6   hover-glow`}>
                                More <span className="text-green-500">Topics</span>
                              </h2>
                              <ul className="grid grid-cols-2 md:grid-cols-3 gap-4">
                                {[
                                  {
                                    name: "Interesting History",
                                    icon: AccountBalanceIcon,
                                  },
                                  {
                                    name: "AI Stories",
                                    icon: MovieFilterIcon,
                                  },
                                  {
                                    name: "Scary Stories",
                                    icon: ElderlyWomanIcon,
                                  },
                                  {
                                    name: "Bedtime Stories",
                                    icon: BedtimeIcon,
                                  },
                                  {
                                    name: "Urban Legends",
                                    icon: ApartmentIcon,
                                  },
                                  {
                                    name: "Motivational",
                                    icon: ElectricBoltIcon,
                                  },
                                  {
                                    name: "Fun Facts",
                                    icon: LightbulbIcon,
                                  },
                                  {
                                    name: "Long Form Jokes",
                                    icon: EmojiEmotions,
                                  },
                                  {
                                    name: "Life Pro Tips",
                                    icon: StarIcon,
                                  },
                                  {
                                    name: "Philosophy",
                                    icon: PsychologyIcon,
                                  },
                                  {
                                    name: "Product Marketing",
                                    icon: CampaignIcon,
                                  },
                                ].map((item, i) => (
                                  <li
                                    key={i}
                                    onClick={() => {
                                      setContent(item.name);
                                      setTopicModal(false);
                                      setShowCustomTopic(false)
                                    }}
                                    className={`p-3 font-semibold text-sm rounded-lg w-[105%]   border border-gray-300 transition-colors duration-300 cursor-pointer shadow-sm hover:shadow-md font-medium transform hover:-translate-y-1`}
                                  >{item.icon && <item.icon className="mr-2" />}
                                    {item.name}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                          <style jsx global>{`
      .hover-glow:hover {
        text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
      }
    `}</style>
                        </div>
                      )}


                    </>
                  }



                  {errors.content && (
                    <p className="text-red-600 text-sm mt-2">
                      {errors.content}
                    </p>
                  )}
                </div>
                  {showCustomTopic && (
                    <div>
                      <label
                        htmlFor="custom-topic"
                        className="block text-sm font-medium  "
                      >
                        <h1 className={`font-bold text-[16px] ${darkMode ? " " : " "}  pt-1`}>
                          Custom Topic
                        </h1>
                      </label>
                      <input
                        id="custom-topic"
                        type="text"
                        maxLength="2000"
                        placeholder="Enter Custom Topic"
                        className={`mt-1 block w-full     px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-green-500 sm:text-sm`}
                        value={customTopic}
                        onChange={handleCustomtopic}
                      />
                      {errors.customTopic && (
                        <p className="text-red-600 text-sm mt-2">
                          {errors.customTopic}
                        </p>
                      )}
                    </div>
                  )}</>)
                }
                {/*  */}
                {noConnectedDropdown === 'youtube' &&
                  <div className="box p-1">
                    <div className="mb-8">
                      <h2 className={`${darkMode ? " " : " "} text-lg font-semibold mb-1`}>Connected Social Accounts</h2>
                      <button onClick={() => navigate('/app/socialmedia')} className={`w-full p-2 border text-black border-gray-300 rounded text-left bg-white hover:bg-gray-50`}>
                        Connect YouTube Account
                      </button>
                    </div>

                  </div>}

                {Socialmedia !== 'none' &&
                  <div className="box p-1">
                    <div>
                      <h2 className={`${darkMode ? " " : " "} text-lg font-medium mb-1`}>Connected Social Accounts</h2>
                      <div className={`flex items-center justify-between w-full p-2 border border-gray-300 rounded text-left ${darkMode ? "  hover:bg-gray-900" : "bg-white hover:bg-gray-50"} `}>
                        <p>{username}</p>
                        <img
                          src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/09/YouTube_full-color_icon_%282017%29.svg/2560px-YouTube_full-color_icon_%282017%29.svg.png"
                          alt="YouTube logo"
                          className="w-6 h-6"
                        />
                      </div>
                    </div>

                    {!showScript && <>

                      <div
                        className="flex items-center justify-between mt-4 bg-gradient-to-r from-blue-500 to-purple-500 p-4 rounded-lg   cursor-pointer"
                        onClick={handleToggle}
                      >
                        <div>
                          <h3 className="font-semibold">Auto Post Content</h3>
                          <p className="text-sm opacity-90">Let AI generate and post content automatically</p>
                        </div>
                        <div
                          className={`w-12 h-6 rounded-full p-1 duration-300 ease-in-out ${isToggled ? 'bg-blue-500' : 'bg-gray-400'
                            }`}
                        >
                          <div
                            className={`shadow-md h-4 w-4 bg-white rounded-full transform duration-300 ease-in-out ${isToggled ? 'translate-x-6' : ''
                              }`}
                          ></div>
                        </div>
                      </div>
                      {isToggled && <>
                        <div className={`mt-4 p-4 border rounded-lg ${darkMode ? " " : "bg-gray-50"}`}>

                          <div className="mb-4">
                            <p className={`text-sm font-semibold mb-2  ${darkMode ? " " : "text-blue-700"}`}>Upload Frequency</p>
                            <select value={postInDay}
                              onChange={(e) => handlePostInDay(e.target.value)} className={`w-full p-2 ${darkMode ? " " : "bg-white"} ${darkMode ? " " : "text-black"} border rounded" name="frequency`}>
                              <option value="1">1 time per day</option>
                              <option value="2">2 times per day</option>
                              <option value="3">3 times per day</option>
                              <option value="4">4 times per day</option>
                              <option value="5">5 times per day</option>
                            </select>
                          </div>
                          <div className="mb-4">
                            <p className={`text-sm font-semibold mb-2  ${darkMode ? " " : "text-blue-700"}`}>Time Zone</p>
                            <select value={timezone}
                              onChange={(e) => handleTimezone(e.target.value)} className={`w-full p-2 ${darkMode ? " " : "bg-white"} ${darkMode ? " " : "text-black"} border rounded" name="frequency`}>
                              <option value="GMT-10:00 (Honolulu)">GMT-10:00 (Honolulu)</option><option value="GMT-09:00 (Anchorage)">GMT-09:00 (Anchorage)</option><option value="GMT-08:00 (Los Angeles)">GMT-08:00 (Los Angeles)</option><option value="GMT-07:00 (Denver, Phoenix)">GMT-07:00 (Denver, Phoenix)</option><option value="GMT-06:00 (Chicago, Mexico City)">GMT-06:00 (Chicago, Mexico City)</option><option value="GMT-05:00 (New York, Toronto)">GMT-05:00 (New York, Toronto)</option><option value="GMT-04:00 (Halifax)">GMT-04:00 (Halifax)</option><option value="GMT-03:00 (São Paulo)">GMT-03:00 (São Paulo)</option><option value="GMT+00:00 (London)">GMT+00:00 (London)</option><option value="GMT+01:00 (Paris, Berlin)">GMT+01:00 (Paris, Berlin)</option><option value="GMT+02:00 (Cairo)">GMT+02:00 (Cairo)</option><option value="GMT+03:00 (Moscow)">GMT+03:00 (Moscow)</option><option value="GMT+05:30 (Mumbai)">GMT+05:30 (Mumbai)</option><option value="GMT+08:00 (Singapore, Beijing)">GMT+08:00 (Singapore, Beijing)</option><option value="GMT+09:00 (Tokyo)">GMT+09:00 (Tokyo)</option><option value="GMT+10:00 (Sydney)">GMT+10:00 (Sydney)</option>
                            </select>
                          </div>
                          {errors.timezone && (
                            <p className="text-red-600 text-sm mt-2">
                              {errors.timezone}
                            </p>
                          )}
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                            <div className={` ${darkMode ? " " : "text-blue-700"}`}>
                              <p className="text-sm font-semibold mb-2">Start Date & Time</p>

                              <input
                                className={`w-full p-2 border rounded ${darkMode ? " " : "bg-white"} ${darkMode ? " " : "text-black"}`}
                                value={startDate}
                                onChange={(e) => { if (e.target.value > '2024-11-19') { setStartDate(e.target.value) } else { return false } }}
                                type="datetime-local"
                                name="startDateTime"
                                min={new Date(Date.now() + 86400000).toISOString().slice(0, 16)}
                                max={new Date(Date.now() + 30 * 86400000).toISOString().slice(0, 16)}

                              />


                            </div>
                            <div className={` ${darkMode ? " " : "text-blue-700"}`}>
                              <p className="text-sm font-semibold mb-2">End Date & Time</p>
                              <input className={`w-full p-2 border rounded ${darkMode ? " " : "bg-white"} ${darkMode ? " " : "text-black"}`} value={endDate} onChange={(e) => {
                                if (e.target.value > '2024-11-19') { setEndDate(e.target.value) } else { return false }
                              }} min={new Date(Date.now() + 86400000).toISOString().slice(0, 16)} max={new Date(Date.now() + 30 * 86400000).toISOString().slice(0, 16)
                              } type="datetime-local" name="startDateTime" />

                            </div>
                          </div>
                          {errors.date && (
                            <p className="text-red-600 text-sm mt-2">
                              {errors.date}
                            </p>
                          )}

                          <div className="bg-yellow-50 border border-yellow-200 rounded p-3">
                            <p className="text-sm text-yellow-800">
                              <i className="fas fa-info-circle mr-2"></i>
                              Remember, 1 video = 1 credit. The AI will automatically generate hybrid animal videos for you. We’ll also create titles and descriptions!
                            </p>
                          </div>
                        </div>

                      </>}</>}



                  </div>}

                <div>
                  <div>
                    <label
                      htmlFor="Select Voice"
                      className="block text-sm font-medium  "
                    >
                      <h1 className={`font-bold text-[16px]   pt-1 `}>
                        Select Voice
                      </h1>
                      <h4 className={`pb-3 text-gray-400 pt-1 font-sans`}>
                        Preferences for every video in your series
                      </h4>
                    </label>
                    {/* <h4 className='text-violet-900 pb-3 pt-1 font-sans'>Narration Voice</h4> */}

                    <div className={`    rounded-md w-full flex-[100%]`}>
                      <section className="flex flex-col items-start gap-3">
                        <div className="w-full flex justify-between">
                          <div className="flex gap-2   font-medium w-full items-start"></div>
                        </div>
                        <div className=" w-full flex flex-col gap-4"></div>
                      </section>

                      {errors.voice && (
                        <p className="text-red-600 text-sm mt-2">
                          {errors.voice}
                        </p>
                      )}

                      <div className="pt-2">
                        <div className="sidescroll border w-full border-gray-100 rounded-md shadow-sm overflow-auto h-96 scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100 ">
                          {plan === "Free" && series === 1 ?
                            (<div className="voicecal grid grid-cols-1 p-2 md:grid-cols-2 gap-2">

                              {DropVoices.map((voice, index) => (

                                <div
                                  key={voice.voice_id}
                                  onClick={() => { if (voice.name === 'Sarah') { setActiveId(voice.voice_id) } }}
                                  className={`relative p-2   flex justify-between border rounded-lg${voice.name === 'Sarah' ? 'cursor-pointer hover:cursor-pointer' : 'cursor-not-allowed hover:cursor-not-allowed'}  ${activeId === voice.voice_id
                                    ? "outline outline-[#46f758] outline-2 "
                                    : "border-gray-300"
                                    }`}
                                >{voice.name === "Sarah" ?
                                  (<div className="flex gap-2 w-full    items-center mb-2">
                                    <div className=" ">
                                      <audio className=" "
                                        ref={(el) =>
                                          (audioRefs.current[index] = el)
                                        }
                                        src={voice.preview_url}
                                        onEnded={() => handleAudioEnded(index)}
                                        onTimeUpdate={() =>
                                          handleTimeUpdate(index)
                                        }
                                      />
                                      <button
                                        onClick={() => togglePlayback(index)}
                                        className="h-6 w-6  "
                                      >
                                        {playingIndex === index ? (
                                          <MdPause />
                                        ) : (
                                          <MdPlayArrow />
                                        )}
                                      </button>
                                    </div>
                                    <div className="flex flex-col flex-1">
                                      <div className="flex justify-between items-center">
                                        <div className="font-semibold text-[12px]  ">
                                          {voice.name}
                                        </div>
                                        <div className="text-[10px]">
                                          {formatTime(
                                            playbackTimes[index] || 0
                                          )}
                                          /{voice.duration}
                                        </div>
                                      </div>
                                      <span className="text-[10px]   ">
                                        {voice.labels.accent},{voice.category},
                                        {voice.labels.gender},{voice.labels.age}
                                      </span>
                                    </div>
                                  </div>) :
                                  (<div className="flex gap-2 w-full items-center mb-2 cursor-not-allowed hover:cursor-not-allowed">
                                    <div>
                                      <audio
                                        ref={(el) => (audioRefs.current[index] = el)}
                                        src={voice.preview_url}
                                        onEnded={() => handleAudioEnded(index)}
                                        onTimeUpdate={() => handleTimeUpdate(index)}
                                      />
                                      <button

                                        className="h-6 w-6   cursor-not-allowed"
                                        disabled
                                      >
                                        {playingIndex === index ? <MdPause /> : <MdPlayArrow />}
                                      </button>
                                    </div>
                                    <div className="flex flex-col flex-1">
                                      <div className="flex justify-between items-center">
                                        <div className="font-semibold text-[12px]  ">{voice.name}<MdLock /></div>
                                        <div className="text-[10px]  ">
                                          {formatTime(playbackTimes[index] || 0)}/{voice.duration}
                                        </div>
                                      </div>
                                      <span className="text-[10px]  ">
                                        {voice.labels.accent},{voice.category},{voice.labels.gender},
                                        {voice.labels.age}
                                      </span>
                                    </div>
                                  </div>
                                  )}

                                </div>
                              ))}
                            </div>)
                            : (<div className="voicecal grid grid-cols-1 p-2 md:grid-cols-2 gap-2">

                              {DropVoices.map((voice, index) => (

                                <div
                                  key={voice.voice_id}
                                  onClick={() => setActiveId(voice.voice_id)}
                                  className={`relative p-2 flex justify-between border rounded-lg cursor-pointer ${activeId === voice.voice_id
                                    ? "outline outline-[#467ff7] outline-2 "
                                    : "border-gray-300"
                                    }`}
                                >
                                  <div className="flex gap-2   w-full items-center mb-2">
                                    <div>
                                      <audio
                                        ref={(el) =>
                                          (audioRefs.current[index] = el)
                                        }
                                        src={voice.preview_url}
                                        onEnded={() => handleAudioEnded(index)}
                                        onTimeUpdate={() =>
                                          handleTimeUpdate(index)
                                        }
                                      />
                                      <button
                                        onClick={() => togglePlayback(index)}
                                        className={`h-6 w-6  `}
                                      >
                                        {playingIndex === index ? (
                                          <MdPause />
                                        ) : (
                                          <MdPlayArrow />
                                        )}
                                      </button>
                                    </div>
                                    <div className="flex flex-col flex-1">
                                      <div className="flex justify-between items-center">
                                        <div className={`font-semibold text-[12px]   `}>
                                          {voice.name}
                                        </div>
                                        <div className={`text-[10px]`}>
                                          {formatTime(
                                            playbackTimes[index] || 0
                                          )}
                                          /{voice.duration}
                                        </div>
                                      </div>
                                      <span className={`text-[10px]   `}>
                                        {voice.labels.accent},{voice.category},
                                        {voice.labels.gender},{voice.labels.age}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>)}

                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4">
                    <label
                      htmlFor="Select Voice"
                      className="block text-sm font-medium  "
                    >
                      <h1 className={`font-bold text-[16px]   pt-1 `}>
                        Choose Generation Preset
                      </h1>
                    </label>
                    {plan === 'Free' && series === 1 ?
                      (<div className="sidescroll flex gap-1 overflow-x-auto rounded-2xl ">
                        {images.map((image, index) => {
                          return (
                            <div key={index} className="flex gap-4 p-2 rounded-2xl">
                              <div
                                className={`w-[150px] h-[180px] relative group flex  flex-col gap-2  ${image.name === "raytraced" ? "hover:cursor-pointer" : "hover:cursor-not-allowed"
                                  }`}
                                onClick={() => { if (image.name === 'raytraced') { setPictureType(image.name) } }}
                              >
                                <div className="flex items-center   text-[10px] font-semibold">
                                  <span>{image.name}</span>
                                  {image.name !== "raytraced" && <MdLock className="ml-1" />}
                                </div>

                                <div className="relative inline-block">
                                  <div className="relative">
                                    <img
                                      loading="lazy"
                                      width="150"
                                      height="150"
                                      decoding="async"
                                      data-nimg="1"
                                      className={`h-full rounded-lg object-cover shadow-md outline-2 ${pictureType === image.name ? "outline outline-blue-500" : "rounded-2xl border border-blue-500/10  "
                                        } ${image.name !== "raytraced" ? "pointer-events-none" : ""}`}
                                      srcSet={image.imageLink}
                                      src={image.imageLink}
                                      alt={image.name}
                                    />

                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>) : (<div className="sidescroll flex gap-1 overflow-x-auto">
                        {images.map((image, index) => {
                          return (
                            <div key={index} className="flex gap-4 p-2">
                              <div
                                className={`w-[150px] h-[180px] relative group flex flex-col gap-2 hover:cursor-pointer`}
                                onClick={() => setPictureType(image.name)}
                              >
                                <div className={` text-xs font-semibold  `}>
                                  {image.name}
                                </div>
                                <div className="relative inline-block">
                                  <img
                                    loading="lazy"
                                    width="150"
                                    height="150"
                                    decoding="async"
                                    data-nimg="1"
                                    className={`h-full rounded-lg object-cover    shadow-md outline-2 ${pictureType === image.name
                                      ? "outline outline-green-500"
                                      : "rounded-2xl border border-green-500/10"
                                      }`}
                                    srcSet={image.imageLink}
                                    src={image.imageLink}
                                    alt={image.name}
                                  />

                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>)}

                  </div>
                  <div className="mt-3">
                    <label
                      htmlFor="dropdown"
                      className="block text-sm font-medium  "
                    >

                      <h1 className={`font-bold text-[16px] ${darkMode ? " " : " "} pt-1 `}>
                        Select Language
                      </h1>

                    </label>
                    {plan === 'Free' && series === 1 ? (
                       <Select
                       className=" dropdown "
                       value={{ value: 'English', label: 'English' }} // Set default value to English
                       options={options} // Show all language options
                       isOptionDisabled={(option) => option.value !== 'English'} // Disable all non-English options
                       onChange={(selectedOption) => handleLanguageChange(selectedOption)}
                       styles={{
                           control: (base) => ({
                               ...base,
                               backgroundColor: themefile === "dark" ? "white" : "#1E1E1E",
                               color:  themefile ==="dark" ? "black" : "white",
                           }),
                           singleValue: (base) => ({
                               ...base,
                               color: themefile ==="dark" ? "black" : "white",
                           }),
                           menu: (base) => ({
                               ...base,
                               backgroundColor: themefile ==="dark" ? "#333333" : "#1E1E1E",  // Background color for dropdown menu
                               color: themefile ==="dark" ? "black" : "white",  // Text color for dropdown options
                           }),
                           option: (base, { isFocused, isSelected, isDisabled }) => ({
                               ...base,
                               backgroundColor: isSelected
                                   ? themefile ==="dark"
                                       ? "#555555"  // Selected background color for dark mode
                                       : "#0d0d0d"  // Selected background color for light mode
                                   : isFocused
                                       ? themefile ==="dark"
                                           ? "#444444"  // Focused background color for dark mode
                                           : "#0f0f0f"  // Focused background color for light mode
                                       : themefile ==="dark"
                                           ? "#333333"  // Default background color for dark mode options
                                           : "#1E1E1E",   // Default background color for light mode options
                               color: themefile ==="dark" ? "black" : "white",  // Text color
                               cursor: isDisabled ? 'not-allowed' : 'default',
                               opacity: isDisabled ? 0.5 : 1,
                           }),
                       }}
                   />
                  ) : (
                    <Select
                    options={options}
                    onChange={(selectedOption) => handleLanguageChange(selectedOption)}
                    styles={{
                        control: (base) => ({
                            ...base,
                            backgroundColor: themefile ==="dark" ? "#1E1E1E" : "white",
                            color: themefile ==="dark" ? "white" : "black",
                        }),
                        singleValue: (base) => ({
                            ...base,
                            color: themefile ==="dark" ? "white" : "black",
                        }),
                        menu: (base) => ({
                            ...base,
                            backgroundColor: themefile ==="dark" ? "#333333" : "white",  // Background color for dropdown menu
                            color: themefile ==="dark" ? "white" : "black",  // Text color for dropdown options
                        }),
                        option: (base, { isFocused, isSelected }) => ({
                            ...base,
                            backgroundColor: isSelected
                                ? themefile ==="dark"
                                    ? "#555555"  // Selected background color for dark mode
                                    : "#e6f7ff"  // Selected background color for light mode
                                : isFocused
                                    ? themefile ==="dark"
                                        ? "#444444"  // Focused background color for dark mode
                                        : "#f0f0f0"  // Focused background color for light mode
                                    : themefile ==="dark"
                                        ? "#333333"  // Default background color for dark mode options
                                        : "white",   // Default background color for light mode options
                            color: themefile ==="dark" ? "white" : "black",  // Text color
                        }),
                    }}
                />
                    )}


                  </div>

                  {plan === 'Free' && series === 1 ?
                    (<div className="templates-section p-4   rounded-md shadow-sm mt-2">
                      <div>
                        <h2 className="text-xl   font-bold mb-4">
                          Templates
                        </h2>
                      </div>
                      <div className="flex overflow-x-auto">
                        {Templates.map((template) => (
                          <div
                            key={template.id}
                            className="flex flex-col items-center"
                          ><div className={`flex justify-center ${template.id === "default" ? "mb-4" : ""}`}>{template.id !== "default" ? (<MdLock className="  text-xl ml-1" />) : (<p className=" ">Default Template</p>)}</div>
                            <div
                              className={`template-card ml-2 w-[250px]  cursor-pointer ${template.id !== 'default' ? "cursor-not-allowed hover:cursor-not-allowed" : ""}`}
                              onClick={() =>
                                handleTemplateClick('default')
                              }
                            >
                              <img
                                src={template.previews.previewGif}
                                alt="Template Preview"
                                className={`rounded-lg  object-cover shadow-md outline-2 ${selectedTemplateId === template.id
                                  ? "outline outline-blue-500"
                                  : ""
                                  }${template.id !== "default" ? "cursor-not-allowed hover:cursor-not-allowed" : ""}`}
                              />

                            </div>

                          </div>
                        ))}
                      </div>


                    </div>) : (<div className={`templates-section p-4 rounded-md shadow-sm mt-2`}>
                      <div>
                        <h2 className={`text-xl   font-bold mb-4`}>
                          Templates
                        </h2>
                      </div>
                      <div className="flex overflow-x-auto">
                        {Templates.map((template) => (
                          <div
                            key={template.id}
                            className="flex flex-col items-center"
                          ><div className={`flex justify-center ${template.id !== "default" ? "mb-4" : ""}`}>{template.id !== "default" ? (<></>) : (<p className=" ">Default Template</p>)}</div>
                            <div
                              className="template-card ml-2 w-[250px]  cursor-pointer"
                              onClick={() =>
                                handleTemplateClick(template.id)
                              }
                            >
                              <img
                                src={template.previews.previewGif}
                                alt="Template Preview"
                                className={`rounded-lg  object-cover shadow-md outline-2 ${selectedTemplateId === template.id
                                  ? "outline outline-green-500"
                                  : "rounded-2xl border border-green-500/10"
                                  }`}
                              />

                            </div>

                          </div>
                        ))}
                      </div>


                    </div>)}

                  <div className="relative w-full">
                    <label
                      htmlFor="dropdown-select"
                      className="block text-sm font-medium pt-3 font-sans"
                    >
                      <h1 className={`font-bold text-[16px]      pt-1`}>
                        Duration
                      </h1>
                    </label>
                    {plan === 'Free' && series === 1 ? (<select
                      id="dropdown-select"
                      value={inputValue1}
                      onChange={(e) => handleOptionSelect(e.target.value)}
                      className={`mt-1 dropdown  block w-full   px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-green-500 sm:text-sm`}
                    >
                      <option value="" disabled>
                        Select duration
                      </option>
                      <option value="30 to 60 Seconds">
                        30 to 60 Seconds
                      </option>
                      <option disabled value="60 to 90 Seconds">
                        60 to 90 Seconds
                      </option>
                    </select>) : (<select
                      id="dropdown-select"
                      value={inputValue1}
                      onChange={(e) => handleOptionSelect(e.target.value)}
                      className={`mt-1  block w-full  dropdown   px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-green-500 sm:text-sm`}
                    >
                      <option value="" disabled>
                        Select duration
                      </option>
                      <option value="30 to 60 Seconds">
                        30 to 60 Seconds
                      </option>
                      <option value="60 to 90 Seconds">
                        60 to 90 Seconds
                      </option>
                    </select>)}
                  </div>
                  {selectedTemplateId === 'default' && <>
                    <div>
                      <label
                        htmlFor="font-size"
                        className="block text-sm pt-2 font-medium  "
                      >
                        <h1 className={`font-bold text-[16px]   pt-1`}>
                          Select Font Size
                        </h1>
                      </label>
                      {plan === 'Free' && series === 1 ? (<select
                        id="font-size"
                        className={`mt-1 block w-full  dropdown   px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-green-500 sm:text-sm`}
                        onChange={handleOneclick}
                        value={selectFontOption}
                      >
                        {/* <option value="" disabled>Choose Font Size</option> */}
                        <option value="16">16</option>
                        <option disabled value="18">18</option>
                        <option disabled value="20">20</option>
                        <option disabled value="24">24</option>
                      </select>) : (<select
                        id="font-size"
                        className={`mt-1 block w-full  dropdown   px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-green-500 sm:text-sm`}
                        onChange={handleOneclick}
                        value={selectFontOption}
                      >
                        {/* <option value="" disabled>Choose Font Size</option> */}
                        <option value="16">16</option>
                        <option value="18">18</option>
                        <option value="20">20</option>
                        <option value="24">24</option>
                      </select>)}
                    </div>

                    <div>
                      <label
                        htmlFor="position"
                        className="block text-sm font-medium  "
                      >
                        {/* <h6 className=" w-max border-1 text-[10px] text-blue-700 border-violet-600 pt-[3px] pl-[10px] pb-[3px] pr-[10px] rounded-xl" >Step2</h6> */}
                        <h1 className={`font-bold text-[16px]   pt-1`}>
                          Select Positions{" "}
                        </h1>
                      </label>
                      {plan === 'Free' && series === 1 ? (<select
                        id="position"
                        className={`mt-1 block w-full   dropdown  px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-green-500 sm:text-sm`}
                        onChange={handleClick}
                        value={selectPosition}
                      >
                        {/* <option value="" disabled>Choose Positions</option> */}
                        <option value="125">Center</option>
                        <option disabled value="250">Top</option>
                        <option disabled value="15">Bottom</option>
                      </select>) : (<select
                        id="position"
                        className={`mt-1 block w-full  dropdown   px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-green-500 sm:text-sm`}
                        onChange={handleClick}
                        value={selectPosition}
                      >
                        <option value="125">Center</option>
                        <option value="250">Top</option>
                        <option value="15">Bottom</option>
                      </select>)}
                    </div>
                  </>}


                  <div className="box shadow-md p-3">
                    <label className={`block font-semibold   text-lg mb-2`}>
                      <div className="flex items-center gap-2">
                        Aspect Ratio
                      </div>
                    </label>
                    {plan === 'Free' && series === 1 ?
                      (<div className="flex grid grid-cols-3 divide-x border-1 border-gray-300">
                        <button
                          className={`aspect-ratio-button p-4 w-[100%] ${aiAspectRatio === 'portrait_16_9' ? 'green-500 text-green-500' : 'bg-white  '}`}
                          onClick={() => {

                            setAiAspectRatio('portrait_16_9')
                          }}
                          type="button"
                        >
                          <div className="flex flex-col items-center">
                            <div className="w-6 h-10  border-3 border-green-500 text-green-500 rounded-sm" />
                            <span>Vertical (9:16)</span>
                          </div>
                        </button>

                        <button
                          className={`aspect-ratio-button p-4 hover:cursor-not-allowed w-[100%] ${aiAspectRatio === 'landscape_16_9' ? 'green-500 text-green-500' : '   '}`}
                          onClick={() => {

                            setAiAspectRatio('')
                          }}
                          type="button"
                        >
                          <div className="flex flex-col items-center"><MdLock />
                            <div className="w-10 h-6 border-3 border-green-500 rounded-sm" />
                            <span className="mt-3">Horizontal (16:9)</span>
                          </div>
                        </button>

                        <button
                          className={`aspect-ratio-button p-4 hover:cursor-not-allowed w-[100%] ${aiAspectRatio === 'square' ? 'green-500 text-green-500' : '   '}`}
                          onClick={() => {


                            setAiAspectRatio('')
                          }}
                          type="button"
                        >
                          <div className="flex flex-col items-center"><MdLock />
                            <div className="w-7 h-7 border-3 border-green-500 rounded-sm" />
                            <span className="mt-3">Square (1:1)</span>
                          </div>
                        </button>
                      </div>) :
                      (<div className="flex grid grid-cols-3 divide-x border-1 border-gray-300">
                        <button
                          className={` aspect-ratio-button p-4 w-[100%] ${aiAspectRatio === 'portrait_16_9' ? 'green-500 text-green-500' : '  '}`}
                          onClick={() => {

                            setAiAspectRatio('portrait_16_9')
                          }}
                          type="button"
                        >
                          <div className="flex flex-col items-center">
                            <div className={`w-6 h-10   border-3 border-green-500 rounded-sm`} />
                            <span>Vertical (9:16)</span>
                          </div>
                        </button>

                        <button
                          className={` aspect-ratio-button p-4  w-[100%] ${aiAspectRatio === 'landscape_16_9' ? 'green-500 text-green-500' : '  '}`}
                          onClick={() => {

                            setAiAspectRatio('landscape_16_9')
                          }}
                          type="button"
                        >
                          <div className="flex flex-col items-center">
                            <div className={`w-10 h-6   border-3 border-green-500 rounded-sm`} />
                            <span className="mt-3">Horizontal (16:9)</span>
                          </div>
                        </button>

                        <button
                          className={` aspect-ratio-button p-4 w-[100%] ${aiAspectRatio === 'square' ? 'green-500 text-green-500' : ' '}`}
                          onClick={() => {


                            setAiAspectRatio('square')
                          }}
                          type="button"
                        >
                          <div className="flex flex-col items-center">
                            <div className={`w-7 h-7   border-3 border-green-500 rounded-sm`} />
                            <span className="mt-3">Square (1:1)</span>
                          </div>
                        </button>
                      </div>)}

                  </div>
                  {showModal ? (
                    <>
                      <div className="fixed inset-0 z-50 flex items-center justify-center overflow-hidden">
                        <div className="relative w-full max-w-3xl bg-white rounded-lg shadow-lg">
                          {/* Modal Content */}
                          <div className="p-6">
                            <h1 className="text-lg font-bold">{modalMessage}</h1>
                          </div>

                          {/* Modal Footer */}
                          <div className="flex items-center justify-end p-4">
                            <button
                              className="px-4 py-2 text-sm font-bold text-black transition-all duration-150 ease-linear bg-transparent rounded focus:outline-none"
                              type="button"
                              onClick={() => setShowModal(false)}
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      </div>

                    </>
                  ) : null}
                  <div className="text-black pt-6">
                    <h1 className={`font-bold text-[16px]   pt-1 `}>
                      Create
                    </h1>
                    <h4 className={`${darkMode ? " " : " "} pb-3 pt-1 font-sans`}>
                      You will be able to preview your upcoming videos before
                      posting
                    </h4>
                    <button
                      onClick={(plan === "Free" && series === 1 ) ? ClickCreate : handleCreate}
                      className="flex justify-center items-center rounded-md w-full bg-green-500 p-2  "
                    >{creatLoading ? "Creating..." : "Create Video"}

                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : null
          }
        </div >
        {datePopup && (
          <div className="fixed inset-0 flex items-center justify-center   bg-opacity-50 z-50">
            <div className="bg-white rounded-lg shadow-lg max-w-md w-full px-6 py-8 relative">

              <button
                onClick={() => { setDatePopup(false) }}
                className="absolute top-3 right-3 text-gray-500 hover:text-gray-800"
                aria-label="Close Modal"
              >
                ✕
              </button>
              <div className="text-center">
                <h1 className="font-poppins text-2xl mb-4 text-gray-800">
                  You Don't have enough credits for this schedule.
                </h1>
                <p className="font-poppins text-gray-600 mb-8">
                  Buy more credits to Schedule more.
                </p>
                <button onClick={() => navigate('/billing')} className="green-500 hover:bg-blue-700   font-poppins py-3 px-8 rounded-lg transition-colors duration-200">
                  Buy Credits
                </button>
              </div>
            </div>
          </div>
        )}
        {creatLoading && (
          <>
            <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-40">
              <div className="bg-white rounded-lg p-6 flex flex-col items-center">
                <div className="loader w-12 h-12 border-4 border-green-500 border-t-transparent rounded-full animate-spin"></div>
                <p className="mt-4   font-medium">Loading... Do not exit page</p>
                <div className="w-full">
                  <ProgressBar
                    now={completed}
                    label={`${Math.round(completed)}%`}
                    style={{
                      height: '1.5rem',
                    }}
                  />
                </div>
              </div>

            </div>
          </>
        )}
      </div >
      <Footer />
    </div >

  );
};

export default Create;
