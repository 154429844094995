import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { GiArtificialIntelligence } from 'react-icons/gi';
import { useCookies } from 'react-cookie';
import { VscMenu } from 'react-icons/vsc';
import { RiVerifiedBadgeFill } from 'react-icons/ri';
import { Plans, SubcriptionActive } from '../api/Service';
import "../style.css";
import { useModal } from '../../context/ModalContext';
import { useDarkMode } from '../../context/DarkModeContext';
import ThemeToggle from '../autotube/theme/Themefile';
import loho from "../../loho.png"
// import logo from '../icon.png'

function DashboardNavbar() {
    const location = useLocation();
    const themefile = localStorage.getItem("theme");
    const [series, setSeries] = useState('');
    const [loading, setLoading] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [cookies, setCookie, removeCookie] = useCookies(['https://accounts.google.com']);
    const navigate = useNavigate();
    const { isModalOpen, showModal, hideModal } = useModal();
    const handleShowModal = () => showModal();
    const handleCloseModal = () => hideModal();
    const [plan, setPlan] = useState('')
    const [subscription, setsubscription] = useState(false);
    const { darkMode, toggleDarkMode } = useDarkMode();
    const [status, setstatus] = useState(false);
    const [createOpen, setCreateOpen] = useState(false);
    const [completedOpen, setCompletedOpen] = useState(false);
    const [profileOpen, setProfileOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const SubActive = async () => {
        const email = localStorage.getItem('email')
        try {
            let payload = {
                email: email,
            };
            const response = await SubcriptionActive(payload);
            if (response.status === 200) {
                setstatus(true);

                setTimeout(() => {
                    setstatus(false);
                    setsubscription(false)
                }, 5000);
            } else {
                setstatus(false);
            }
        } catch (error) {
            console.log(error);
        }
    };
    const credit = localStorage.getItem('credit')

    const planResponse = async () => {
        try {
            const response = await Plans();
            setPlan(response.PlanTypeName);
            setSeries(response.credit);
            if (response.subscriptionStatus === 'wc-pending-cancel') {
                setsubscription(true);
            } else {
                setsubscription(false)
            }

        } catch (error) {
            console.log(error);
        }
    };

    // Load user plan when component mounts
    useEffect(() => {
        if (loading) {
            planResponse();
            setLoading(false);
        }
    }, [loading]);


    // Clear cookies, localStorage, and sessionStorage
    const handleClearStorage = () => {
        localStorage.clear();
        localStorage.removeItem('isAuthenticated');
        sessionStorage.clear();
        for (const cookieName in cookies) {
            removeCookie(cookieName);
        }
        navigate('/');
        window.location.reload();
    };
    const Myaccount = () => {
        navigate('/socialmedia');
    }
    const email = localStorage.getItem('email')

    return (
        <>
            {subscription &&
                (status ? (
                    <div className="w-full bg-[#d5f3d3] border-b border-[#44ef7d]">
                        <div className="max-w-7xl mx-auto px-4 py-3">
                            <div className="flex items-center justify-between">
                                <i className="fas fa-exclamation-circle text-[#3bf583] mr-3"></i>
                                <p className="font-roboto text-[#38f88e]">
                                    Subscription Activated Successfully
                                </p>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="w-full bg-[#fee2e2] border-b border-[#ef4444]">
                        <div className="max-w-7xl mx-auto px-4 py-3">
                            <div className="flex items-center justify-between">
                                <i className="fas fa-exclamation-circle text-[#dc2626] mr-3"></i>
                                <p className="font-roboto text-[#dc2626]">
                                    Your account will be canceled once your subscription ends. We will erase all videos, scheduled posts, and data from your account.{" "}
                                    <a onClick={SubActive} className="cursor-pointer underline ml-1 font-semibold">
                                        Please reactivate here
                                    </a>{" "}
                                    or ignore this message.
                                </p>
                            </div>
                        </div>
                    </div>
                ))
            }

            <nav className={`px-4 py-3 w-full top-0 shadow-md`}>
                <div className="max-w-7xl mx-auto flex justify-between items-center">
                    <div className="flex items-center">
                        <div className="flex items-center">
                            <Link
                                to="/" // Sign in ke liye path ko update karein
                                className="flex items-center"
                            >
                                <img src={loho} alt="Logo" width={40} className="mr-2" />
                                <span className="text-5xl md:text-3xl font-bold">Auto</span>
                                <span className="text-red-600 text-5xl md:text-3xl font-bold">Tube</span>
                            </Link>
                        </div>
                        <div className="">
                            <ThemeToggle />
                        </div>
                    </div>
                    <div className="hidden md:flex space-x-6">
                        <Link to="/dashboard"
                            className={`    hover:   tracking-wider hover:scale-105 transition-transform cursor-pointer font-medium relative
                            ${location?.pathname === '/dashboard' ? 'after:content-[""] after:absolute after:left-0 after:bottom-[-6px] after:w-full after:h-[2px] after:bg-red-600' : ''} 
                             `}>
                            Dashboard
                        </Link>
                        <div className="relative group">
                            <div
                                className={` tracking-wider hover:scale-105 transition-transform cursor-pointer font-medium relative
                                    ${location?.pathname === '/videoCreate' || location?.pathname === '/create' ? 'after:content-[""] after:absolute after:left-0 after:bottom-[-6px] after:w-full after:h-[2px] after:bg-red-600' : ''} 
                                     `}
                                onClick={() => setCreateOpen(!createOpen)}
                            >
                                Create <i className="fas fa-chevron-down ml-2 text-xs"></i>
                            </div>
                            {createOpen && (
                                <div
                                    className={`absolute top-full  left-0 mt-2 z-10 w-48 rounded-md shadow-lg py-2 border border-gray-700 ${themefile === "dark" ? "bg-black" : "bg-white"} bg-black `}
                                    onMouseLeave={() => setCreateOpen(false)}
                                >
                                    <div
                                        className="px-4 py-2  hover:bg-[#a2a2a284] cursor-pointer"
                                        onClick={() => {
                                            navigate('/videoCreate');
                                            setCreateOpen(false);
                                        }}
                                    >
                                        Moving Shorts
                                    </div>
                                    <div
                                        className="px-4 py-2 hover:bg-[#a2a2a284] cursor-pointer"
                                        onClick={() => {
                                            navigate('/create');
                                            setCreateOpen(false);
                                        }}
                                    >
                                        Image Shorts
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="relative group">
                            <div
                                className={` tracking-wider hover:scale-105 transition-transform cursor-pointer font-medium relative
                                ${location?.pathname === '/view/aivideo' || location?.pathname === '/view/aishorts' ? 'after:content-[""] after:absolute after:left-0 after:bottom-[-6px] after:w-full after:h-[2px] after:bg-red-600' : ''} 
                                 `}
                                onClick={() => setCompletedOpen(!completedOpen)}
                            >
                                My Videos <i className="fas fa-chevron-down ml-2 text-xs"></i>
                            </div>
                            {completedOpen && (
                                <div
                                    className={`absolute top-full ${themefile === "dark" ? "bg-black" : "bg-white"} bg-black left-0 mt-2 w-48 z-10 rounded-md shadow-lg py-2 border border-gray-700`}
                                    onMouseLeave={() => setCompletedOpen(false)}
                                >
                                    <div
                                        className="px-4 py-2     hover:   hover:bg-[#a2a2a284] cursor-pointer"
                                        onClick={() => {
                                            navigate('/view/aivideo');
                                            setCompletedOpen(false);
                                        }}
                                    >
                                        Moving Shorts
                                    </div>
                                    <div
                                        className="px-4 py-2     hover:   hover:bg-[#a2a2a284] cursor-pointer"
                                        onClick={() => {
                                            navigate('/view/aishorts');
                                            setCompletedOpen(false);
                                        }}
                                    >
                                        Image Shorts
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>


                    {/* Credits & Navigation - Desktop */}
                    <div className="hidden md:flex items-center space-x-6">

                        <div className={`px-3 tracking-wider hover:scale-105 transition-transform cursor-pointer py-1.5 rounded-lg  `}>
                            <span className={`    hover:   transition-all hover:translate-y-[-2px]  `}>
                                Credits: <span className="font-semibold">{credit || 0}</span>
                            </span>
                        </div>




                        {/* {(email === 'contacteffectsclub@gmail.com' || email === 'bansalvishal317@gmail.com') && (
                            <Link to="/app/affiliate_list"
                                className={`font-medium ${darkMode ? "   hover:text-[#467ff7]" : "text-[#3c77f7] hover:text-blue-700"}`}>
                                All Affiliates
                            </Link>
                        )} */}

                        <Link to="/billing"
                            className="bg-[#00C805]    px-4 py-1.5 rounded-md font-medium hover:bg-[#00A804] tracking-wider hover:scale-105 transition-transform cursor-pointer flex items-center gap-2">
                            <RiVerifiedBadgeFill className="text-lg" />
                            Upgrade
                        </Link>

                        <div className="relative">
                            <div
                                className={`   tracking-wider hover:scale-105 transition-transform cursor-pointer font-medium relative p-2 rounded-full
                                `}
                                onClick={() => setProfileOpen(!profileOpen)}
                            >
                                <i className={`fas fa-user-circle text-2xl ${location?.pathname === '/socialmedia' ? 'text-red-600' : ''}`}></i>
                            </div>
                            {profileOpen && (
                                <div
                                    className="absolute top-full right-0 mt-3 w-48 z-10 rounded-md shadow-lg py-2 border border-gray-700"
                                    onMouseLeave={() => setProfileOpen(false)}
                                >
                                    <div onClick={Myaccount} className="px-4 py-2   hover:bg-[#939393] cursor-pointer">
                                        My Account
                                    </div>
                                    <div onClick={handleClearStorage} className='px-4 py-2  hover:bg-[#939393] cursor-pointer'>
                                        Logout
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>


                    {/* Mobile Menu Button */}
                    <div className="md:hidden">
                        <button onClick={handleToggle}
                            className="p-2 rounded-md border border-gray-600 hover:border-blue-500">
                            <VscMenu className="  " />
                        </button>
                    </div>
                </div>

                {/* Mobile Menu Dropdown */}
                {isOpen && (
                    <div className={`md:hidden bg-black absolute z-50 top-16 left-0 right-0 px-4 py-2    border-b border-gray-700`}>
                        <div className="flex flex-col space-y-4">
                            <div className="border-b border-gray-700 pb-2">
                                <div
                                    className="    font-medium mb-2 flex items-center justify-between"
                                    onClick={() => setCreateOpen(!createOpen)}
                                >
                                    Create
                                    <i className={`fas fa-chevron-down text-xs transition-transform ${createOpen ? 'rotate-180' : ''}`}></i>
                                </div>
                                {createOpen && (
                                    <div className="flex flex-col space-y-2 pl-4">
                                        <div
                                            className="    hover:   py-1 cursor-pointer"
                                            onClick={() => {
                                                navigate('/videoCreate');
                                                setCreateOpen(false);
                                            }}
                                        >
                                            Moving Shorts
                                        </div>
                                        <div
                                            className="    hover:   py-1 cursor-pointer"
                                            onClick={() => {
                                                navigate('/create');
                                                setCreateOpen(false);
                                            }}
                                        >
                                            Image Shorts
                                        </div>
                                    </div>
                                )}
                            </div>

                            {/* Add Completed Menu */}
                            <div className="border-b border-gray-700 pb-2">
                                <div
                                    className="    font-medium mb-2 flex items-center justify-between"
                                    onClick={() => setCompletedOpen(!completedOpen)}
                                >
                                    My Videos
                                    <i className={`fas fa-chevron-down text-xs transition-transform ${completedOpen ? 'rotate-180' : ''}`}></i>
                                </div>
                                {completedOpen && (
                                    <div className="flex flex-col space-y-2 pl-4">
                                        <div
                                            className="    hover:   py-1 cursor-pointer"
                                            onClick={() => {
                                                navigate('/view/aivideo');
                                                setCompletedOpen(false);
                                            }}
                                        >
                                            Moving Shorts
                                        </div>
                                        <div
                                            className="    hover:   py-1 cursor-pointer"
                                            onClick={() => {
                                                navigate('/view/aishorts');
                                                setCompletedOpen(false);
                                            }}
                                        >
                                            Image Shorts
                                        </div>
                                    </div>
                                )}
                            </div>

                            <div className={`px-3 py-1.5     rounded-lg  `}>
                                <span className={` `}>
                                    Credits: <span className="font-semibold    ">{credit || 0}</span>
                                </span>
                            </div>

                            <Link to="/"
                                className={`font-medium    `}>
                                Dashboard
                            </Link>

                            {/* {(email === 'contacteffectsclub@gmail.com' || email === 'bansalvishal317@gmail.com') && (
                                <Link to="/app/affiliate_list"
                                    className={`font-medium ${darkMode ? "  " : "text-[#3c77f7]"}`}>
                                    All Affiliates
                                </Link>
                            )} */}

                            <Link to="/billing"
                                className="bg-[#00C805]    px-4 py-1.5 rounded-md font-medium hover:bg-[#00A804] transition-all duration-300 flex items-center gap-2 justify-center">
                                <RiVerifiedBadgeFill className="text-lg" />
                                Upgrade
                            </Link>

                            <div className="relative">
                                <div
                                    className="    hover:   transition-all cursor-pointer"
                                    onClick={() => setProfileOpen(!profileOpen)}
                                >
                                    <i className="fas fa-user-circle text-2xl"></i>
                                </div>
                                {profileOpen && (
                                    <div
                                        className="absolute top-full right-0 mt-2 w-48  rounded-md shadow-lg py-2 border border-gray-700"
                                        onMouseLeave={() => setProfileOpen(false)}
                                    >
                                        <div className="px-4 py-2 hover:bg-[#222222] cursor-pointer">
                                            My Account
                                        </div>
                                        <div onClick={handleClearStorage} className='px-4 py-2 hover:bg-[#222222] cursor-pointer'>
                                            Logout
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                <style jsx global>{`
        @keyframes slideDown {
          from { transform: translateY(-100%); }
          to { transform: translateY(0); }
        }
        @keyframes glow {
          0%, 100% { text-shadow: 0 0 5px rgba(59, 130, 246, 0.5); }
          50% { text-shadow: 0 0 20px rgba(59, 130, 246, 0.8); }
        }
        nav {
          animation: slideDown 0.5s ease-out;
        }
        nav span {
          animation: glow 3s infinite;
        }
      `}</style>
            </nav >
        </>
    );

}

export default DashboardNavbar;

