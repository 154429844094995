// import * as React from 'react';
import React, { useState, useEffect } from 'react';
import Box from '@mui/joy/Box';
import Card from '@mui/joy/Card';
import CardCover from '@mui/joy/CardCover';
import CardContent from '@mui/joy/CardContent';
import Typography from '@mui/joy/Typography';
import ReactLoading from 'react-loading';
import Preview from './preview/Preview';





export default function MediaCover(props) {


  const videoUrl = `https://www.shortsgenerator.ai/app/backend/media/${props.video.userId}/${props.video.id}/output.mp4`;

  // const videoUrl = `http://18.117.241.140/app/backend/media/${props.video.userId}/${props.video.id}/output.mp4`;
  // const videoUrl = `http://192.168.29.3:3001/media/${props.video.userId}/${props.video.id}/output.mp4`






  return (


    <Box
      component="ul"
      sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', p: 0, m: 0 }}
    >
      <Card component="li" sx={{ minWidth: 300, flexGrow: 1 }}>
        <div className=" flex justify-center">
          <ReactLoading type="spin" color="#3498db" height={20} width={20} />
        </div>
        <CardCover>

          <video
            Style="height:240%"

            autoPlay
            loop
            poster={videoUrl}
          >
            <source
              src={videoUrl}
              type="video/mp4"
            />
          </video>
        </CardCover>
        <CardContent>
          <Typography
            level="body-lg"
            fontWeight="lg"
            textColor="#fff"
            mt={{ xs: 12, sm: 18 }}
          >
            {/* Add any additional content here */}
          </Typography>
          {/* <Preview videoUrl={props.videoUrl} /> */}
        </CardContent>
      </Card>
    </Box>
  );
}
