"use client";
import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../Footer/Footer";
import TermsPDF from "../../autotube/policys/PDF/Terms.pdf";
import "../../../index.css"
import { useState } from "react";
import loho from "../../../loho.png"
import LandingNavbar from "../navbar/Navbar";
import LandingFooter from "../navbar/Footer";

function Term() {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className=" bg-black text-white">
      <LandingNavbar />
      <div className="min-h-screen bg-black">
        <div className="max-w-4xl mx-auto px-6 py-16 bg-[#1c1c1c] shadow-lg rounded-lg">
          <h1 className="text-4xl font-bold mb-12 text-center font-roboto text-white">
            Terms and Conditions for AutoTube.in
          </h1>
          <div className="space-y-8">
            <div>
              <p className="mb-4 text-white">
                Welcome to AutoTube.in!
              </p>
              <p className="mb-4 text-white">
                These terms and conditions outline the rules and regulations for the use of AutoTube.in's website, located at <a href="https://autotube.in" className="underline text-[#9292fd]">https://autotube.in</a> .
              </p>
              <p className="mb-4 text-white">
                By accessing this website, we assume you accept these terms and conditions. Do not continue to use AutoTube.in if you do not agree to all the terms and conditions stated on this page.

              </p>
              <h2 className="text-2xl font-bold mb-4 text-white">General Usage Policy</h2>
              <p className="mb-4 text-white">
                By using AutoTube.in, you acknowledge and agree to abide by these terms. The website is designed to provide tools and services for creating and managing video content. AutoTube.in reserves the right to modify, suspend, or discontinue any aspect of the website at any time without prior notice.
              </p>
              <h2 className="text-2xl font-bold mb-4 text-white">User-Generated Content</h2>
              <p className="mb-4 text-white">
                Any videos or other content created using AutoTube.in remain the intellectual property of the user. AutoTube.in does not claim ownership of user-generated videos. Users are responsible for ensuring that their content complies with all applicable laws and does not infringe upon the rights of any third parties.
              </p>

              <h2 className="text-2xl font-bold mb-4 text-white">Integration with Third-Party Services</h2>
              <p className="mb-4 text-white">
                AutoTube.in utilizes YouTube API Services to enable users to connect their YouTube accounts. By using this feature, you agree to adhere to YouTube's Terms of Service <a href="https://www.youtube.com/t/terms" className="underline text-[#9292fd]">(https://www.youtube.com/t/terms)</a> and Google's Privacy Policy <a href="https://www.google.com/policies/privacy" className="underline text-[#9292fd]">(https://www.google.com/policies/privacy)</a>.
              </p>
              <h2 className="text-2xl font-bold mb-4 text-white">Disclaimer of Warranties </h2>
              <p className="mb-4 text-white">
                AutoTube.in is provided "as is" and "as available" without any express or implied warranties, including but not limited to implied warranties of merchantability, fitness for a particular purpose, or non-infringement. While we strive for accuracy and reliability, we do not guarantee that the website will be error-free, uninterrupted, or free from harmful components.
              </p>

              <h2 className="text-2xl font-bold mb-4 text-white">Limitation of Liability  </h2>
              <p className="mb-4 text-white">
                To the maximum extent permitted by law, AutoTube.in shall not be held liable for any damages, including but not limited to direct, indirect, incidental, special, or consequential damages, arising from the use or inability to use the website or its services. Users agree to use AutoTube.in at their own risk.
              </p>
              <h2 className="text-2xl font-bold mb-4 text-white">Cookies </h2>
              <p className="mb-4 text-white">
                AutoTube.in employs the use of cookies to enhance user experience and provide personalized services. By using the website, you consent to the use of cookies in accordance with our Privacy Policy. Third-party advertisers or affiliates may also use cookies on our website.
              </p>
              <h2 className="text-2xl font-bold mb-4 text-white">Content and Linking Policies</h2>
              <p className="mb-4 text-white">
                Content Responsibility: AutoTube.in is not responsible for any content uploaded, created, or shared by users, including any claims or disputes arising from such content. Links to Third-Party Websites: AutoTube.in may contain links to external websites. We do not guarantee the accuracy, relevance, or security of these third-party websites and disclaim all liability related to them. Restrictions on Embedding: Framing or embedding AutoTube.in's content without prior written consent is prohibited.
              </p>
              <p className="mb-4 text-white">
                User Responsibility Users are solely responsible for ensuring that their content complies with all applicable laws, including intellectual property rights, data privacy regulations, and any applicable platform policies. AutoTube.in reserves the right to suspend or terminate access for users who violate these terms.
              </p>
              <h2 className="text-2xl font-bold mb-4 text-white">Reservation of Rights</h2>
              <p className="mb-4 text-white">
                We reserve the right to update these terms and conditions at any time. Continued use of the website constitutes acceptance of any changes. We also reserve the right to request the removal of any link or content connected to AutoTube.in that violates these terms.
              </p>
              <h2 className="text-2xl font-bold mb-4 text-white">Removal of Content or Links</h2>
              <p className="mb-4 text-white">
                If you encounter content or links on AutoTube.in that you find objectionable, please contact us. While we are under no obligation to remove content, we will review and address such requests as appropriate.
              </p>
              <h2 className="text-2xl font-bold mb-4 text-white">Governing Law and Jurisdiction</h2>
              <p className="mb-4 text-white">
                These terms are governed by and construed in accordance with the laws of [your jurisdiction], without regard to its conflict of law provisions. Any disputes arising from the use of AutoTube.in shall be resolved exclusively in the courts of [your jurisdiction].
              </p>
              <h2 className="text-2xl font-bold mb-4 text-white">Indemnification</h2>
              <p className="mb-4 text-white">
                By using AutoTube.in, you agree to indemnify and hold harmless AutoTube.in, its owners, employees, and affiliates from any claims, damages, or liabilities arising out of your use of the website or violation of these terms.
              </p>
              <h2 className="text-2xl font-bold mb-4 text-white">Contact Us</h2>
              <p className="mb-4 text-white">
                If you have any questions, concerns, or suggestions about our Privacy Policy, do not hesitate to <a href="/contact" className="underline text-[#9292fd]">contact us</a>
              </p>
            </div>
          </div>
        </div>
      </div>
     <LandingFooter/>
    </div>
  );
}

export default Term;
