import axios from "axios";


const server_App_URl = process.env.REACT_APP_API_URL

const apiClient = axios.create({
  baseURL: server_App_URl,
  headers: {
    Accept: "application/json",
  },
});


apiClient.interceptors.request.use(
  (config) => {

    const token = localStorage.getItem('Token');

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const { config, response } = error;

    if (response && response.status === 401 && !config._retry) {
      config._retry = true;

      try {
        // Implement your token refresh logic here
        // Example: await refreshToken(); 

        // Retry the original request with the new token
        return apiClient(config);
      } catch (refreshError) {
        // Handle the error appropriately (e.g., redirect to login)
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default apiClient;
