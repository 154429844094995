import React from 'react';
import DashboardNavbar from '../navbar/DashboardNavbar';
import NavTab from './NavTab';
import Footer from '../Footer/Footer';
import Tabs from './SocialMediaCard';
import { useModal } from '../../context/ModalContext';
import { useDarkMode } from '../../context/DarkModeContext';

const SocialMedia = () => {
    const { isModalOpen } = useModal();
    const { darkMode } = useDarkMode();

    return (
        <>
            <DashboardNavbar />
            <div className={`flex max-h-screen min-h-screen `}>
                <div className={`w-full flex justify-center text-sm`}>
                    <Tabs />
                </div>
            </div>
            <Footer />
        </>
    );
}

export default SocialMedia;
