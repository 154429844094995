import React, { useState, useEffect } from "react";
import { useLocation, Link } from "react-router-dom";
import { MdArrowBack, MdFace, MdOutlineSlowMotionVideo } from "react-icons/md";
import { IoMdMenu } from "react-icons/io";
import { BiHome, BiBookOpen, BiWallet, BiListCheck } from "react-icons/bi";
import { TiSocialAtCircular } from "react-icons/ti";
import { GoPlusCircle } from "react-icons/go";
import { useModal } from "../../context/ModalContext";
import { useDarkMode } from "../../context/DarkModeContext";
import "../NavTab.css";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

function NavTab() {
  const location = useLocation();
  const currentPath = location.pathname;
  const [menuOpen, setMenuOpen] = useState(false);
  const [createDropdownOpen, setCreateDropdownOpen] = useState(false);
  const [seriesDropdownOpen, setSeriesDropdownOpen] = useState(false);
  const { isModalOpen } = useModal();
  const { darkMode } = useDarkMode();

  const toggleMenu = () => setMenuOpen(!menuOpen);
  const toggleCreateDropdown = () => setCreateDropdownOpen(!createDropdownOpen);
  const toggleSeriesDropdown = () => setSeriesDropdownOpen(!seriesDropdownOpen);

  useEffect(() => {
    if (
      ["/app/create", "/app/avatarCreate", "/app/captionCreate", "/app/twitterCreate", "/app/videoCreate"].includes(currentPath)
    ) {
      setCreateDropdownOpen(true);
    }
  }, [currentPath]);

  useEffect(() => {
    if (
      ["/app/view/aishorts", "/app/view/aicreator", "/app/view/aicaption", "/app/view/aitwitter", "/app/view/aivideo"].includes(currentPath)
    ) {
      setSeriesDropdownOpen(true);
    }
  }, [currentPath]);
  const userId = localStorage.getItem('userId')


  return (
    <>
      <div className="flex h-full">
        <div className="position md:hidden">
          <button onClick={toggleMenu} className="text-[#467ff7] text-3xl">
            {menuOpen ? null : <IoMdMenu />}
          </button>
        </div>

        <div
          className={`w-[100%] flex flex-col transform ${menuOpen ? "translate-x-0" : "-translate-x-full"} md:translate-x-0 transition-transform duration-300 ease-in-out fixed md:relative h-full z-50 ${darkMode ? "bg-black text-white" : "bg-white text-black"}`}
        >
          <div className="flex justify-end md:hidden">
            <button onClick={toggleMenu} className="text-white">
              <MdArrowBack className="text-blue-500 text-3xl" />
            </button>
          </div>

          <div className={`min-h-screen p-4 ${darkMode ? "bg-[#1E1E1E]" : "bg-gradient-to-br from-[#f8fafc] to-[#e2e8f0]"}`}>
            <div className="space-y-4 max-w-md mx-auto">
              <div className="border-b border-gray-300">
                <Link
                  to={'/dashboard'}
                  className={`flex items-center space-x-2 py-3 px-4 ${currentPath === '/app/dashboard' ? "text-blue-600 bg-gray-200" : `${darkMode ? "text-gray-300" : "text-black"} ${darkMode ? "bg-[#1E1E1E]" : "bg-white"}`} hover:bg-blue-100 hover:text-blue-600`}
                >
                  <BiHome className="text-[#3b82f6]" />
                  <span className="font-poppins text-sm font-semibold">Dashboard</span>
                </Link>
              </div>
              <div className="border-b border-gray-300 relative">
                {/* NEW Badge */}
                <span className="absolute top-0 right-0 bg-green-500 text-white text-xs font-bold px-2 py-1 rounded-br-md">
                  NEW
                </span>

                <Link
                  to={'/app/autopost'}
                  className={`flex items-center space-x-2 py-3 px-4 ${currentPath === '/app/autopost' ? "text-blue-600 bg-gray-200" : `${darkMode ? "text-gray-300" : "text-black"} ${darkMode ? "bg-[#1E1E1E]" : "bg-white"}`} hover:bg-blue-100 hover:text-blue-600`}
                >
                  <BiListCheck className="text-[#3b82f6]" />
                  <span className="font-poppins text-sm font-semibold">Auto Post Queue</span>
                </Link>
              </div>


              {/* SERIES Dropdown */}
              <div>
                <button onClick={toggleSeriesDropdown} className={`w-full flex items-center justify-between p-3 rounded-xl shadow-sm hover:shadow-md transition-all duration-200 ${darkMode ? "bg-[#1E1E1E] text-gray-300" : "bg-white text-[#1e293b]"}`}>
                  <div className="flex items-center gap-2 text-sm font-semibold">
                    <MdOutlineSlowMotionVideo className="text-[#3b82f6]" />
                    <h2 className="font-poppins">Completed Videos</h2>
                  </div>
                  <i className={`fas fa-chevron-down transform text-[#64748b] transition-transform duration-200 ${seriesDropdownOpen ? 'rotate-180' : ''}`}></i>
                </button>
                {seriesDropdownOpen && (
                  <ul className="space-y-2 pt-2 transition-all duration-300">
                    {[
                      { to: "/app/view/aivideo", icon: "fas fa-video", label: "AI Video Shorts" },
                      { to: "/app/view/aishorts", icon: "fas fa-images", label: "AI Image Shorts" },
                      { to: "/app/view/aicreator", icon: "fas fa-user-circle", label: "AI Avatar Shorts" },
                      { to: "/app/view/aicaption", icon: "fas fa-closed-captioning", label: "AI Captions" },
                      { to: "/app/view/aitwitter", icon: "fab fa-twitter", label: "AI Twitter/X To Video" },
                    ].map(({ to, icon, label }) => (
                      <li key={to} className="border-b border-gray-300">
                        <Link
                          to={to}
                          className={`flex items-center gap-3 py-2 px-2 ${currentPath === to ? "text-blue-600 bg-gray-200" : `${darkMode ? "text-gray-300" : "text-black"} ${darkMode ? "bg-[#1E1E1E]" : "bg-white"}`} hover:bg-blue-100 hover:text-blue-600`}
                        >
                          <i className={`${icon} text-[#3b82f6]`}></i>
                          <div className="flex items-center justify-between w-full">
                            <span className="font-poppins text-xs font-medium">{label}</span>
                            <ArrowForwardIcon style={{ fontSize: "12px" }} />
                          </div>
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </div>


              {/* CREATE Dropdown */}
              <div>
                <button onClick={toggleCreateDropdown} className={`w-full flex items-center justify-between p-3 rounded-xl shadow-sm hover:shadow-md transition-all duration-200 ${darkMode ? "bg-[#1E1E1E] text-gray-300" : "bg-white text-[#1e293b]"}`}>
                  <div className="flex items-center gap-2 text-sm font-semibold">
                    <GoPlusCircle className="text-[#3b82f6]" />
                    <h2 className="font-poppins">CREATE</h2>
                  </div>
                  <i className={`fas fa-chevron-down transform text-[#64748b] transition-transform duration-200 ${createDropdownOpen ? 'rotate-180' : ''}`}></i>
                </button>
                {createDropdownOpen && (
                  <ul className="space-y-2 pt-2 transition-all duration-300">
                    {[
                      { to: "/app/videoCreate", icon: "fas fa-video", label: "AI Video Shorts" },
                      { to: "/app/create", icon: "fas fa-images", label: "AI Image Shorts" },
                      { to: "/app/avatarCreate", icon: "fas fa-user-circle", label: "AI Avatar Shorts" },
                      { to: "/app/captionCreate", icon: "fas fa-closed-captioning", label: "AI Captions" },
                      { to: "/app/twitterCreate", icon: "fab fa-twitter", label: "AI Twitter/X To Video" },
                    ].map(({ to, icon, label }) => (
                      <li key={to} className="border-b border-gray-300">
                        <Link
                          to={to}
                          className={`flex items-center gap-3 py-2 px-2 ${currentPath === to ? "text-blue-600 bg-gray-200" : `${darkMode ? "text-gray-300" : "text-black"} ${darkMode ? "bg-[#1E1E1E]" : "bg-white"}`} hover:bg-blue-100 hover:text-blue-600`}
                        >
                          <i className={`${icon} text-[#3b82f6]`}></i>
                          <div className="flex items-center justify-between w-full">
                            <span className="font-poppins text-xs font-medium">{label}</span>
                            <ArrowForwardIcon style={{ fontSize: "12px" }} />
                          </div>
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </div>

              {/* Other Links */}
              {[
                { to: "/app/guide", icon: <BiBookOpen className="text-[#3b82f6]" />, label: "Guides" },
                { to: "/app/billing", icon: <BiWallet className="text-[#3b82f6]" />, label: "Billing" },
                { to: "/app/account", icon: <MdFace className="text-[#3b82f6]" />, label: "Account" },
                { to: "/app/socialmedia", icon: <TiSocialAtCircular className="text-[#3b82f6]" />, label: "Social Media" },
              ].map(({ to, icon, label }) => (
                <div key={to} className="border-b border-gray-300">
                  <Link
                    to={to}
                    className={`flex items-center space-x-2 py-3 px-4 ${currentPath === to ? "text-blue-600 bg-gray-200" : `${darkMode ? "text-gray-300" : "text-black"} ${darkMode ? "bg-[#1E1E1E]" : "bg-white"}`} hover:bg-blue-100 hover:text-blue-600`}
                  >
                    {icon}
                    <span className="font-poppins text-sm font-semibold">{label}</span>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NavTab;
