"use client";
import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import "../../../index.css"
import loho from "../../../loho.png"
import LandingFooter from "../navbar/Footer";
import LandingNavbar from "../navbar/Navbar";

function AipDesclosure() {
    const [openIndex, setOpenIndex] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    return (
        <div className="bg-black text-white">
           
        <LandingNavbar/>
            <div className="min-h-screen items-center justify-center flex ">
                <div className="max-w-4xl rounded-md mx-auto bg-gradient-to-br px-5 pt-3 pb-5 sm:px-6 lg:px-8 from-[#1a1a1a] to-[#2d2d2d]">
                    <div className="text-center mb-16">
                        <h1 className="text-5xl p-2 font-bold bg-gradient-to-r from-green-400 to-green-600 inline-block text-transparent bg-clip-text font-roboto">
                            Google API Limited Use Disclosure
                        </h1>
                        <div className="mt-4 h-1 w-24 bg-gradient-to-r from-green-400 to-green-600 mx-auto rounded-full"></div>
                    </div>
                    <div className="text-white space-y-4">
                        <p className="text-lg">AutoTube.in uses Google APIs when you use your Google account to sign in and use AutoTube.in apps and services.</p>
                        <p className="text-lg">We place significant importance on maintaining the security and privacy of your data. You can also view our <a href="/privarypolicy" className="underline text-[#9292fd]">Privacy Policy</a> for information on our privacy policies regarding the use of the AutoTube.in platform and its services.</p>
                        <h2 className="text-2xl font-bold">Contact Us</h2>
                        <p className="text-lg">If you have any questions or suggestions about our Privacy Policy, do not hesitate to <a href="/contact" className="underline text-[#9292fd]">contact us</a>.</p>
                    </div>
                </div>
            </div>

           <LandingFooter/>
        </div>
    );
}

export default AipDesclosure;