import React, { useEffect, useState } from 'react';
import DashboardNavbar from '../navbar/DashboardNavbar';
import axios from 'axios';
import NavTab from '../navtab/NavTab';
import Footer from '../Footer/Footer';
import '../style.css';
import { useLocation, useNavigate } from 'react-router-dom';
import image1 from './loading.gif'
import ProgressBar from 'react-bootstrap/ProgressBar';
import { CheckVideo } from '../api/Service';
import { useModal } from '../../context/ModalContext';
import { useDarkMode } from '../../context/DarkModeContext';

const VideoPreview = () => {
    const {isModalOpen} = useModal()
    const { darkMode } = useDarkMode();
    const location = useLocation();
    const { videoUrl, videoid, userid } = location.state;
    const [loading, setLoading] = useState(true)
    const [videoCreated, setvideoCreated] = useState(null)
    const [completed, setCompleted] = useState(0);
    const [script, setScript] = useState('')
    const server_App_URl = process.env.REACT_APP_API_URL
    const [showModal, setShowModal] = React.useState(false);
    const navigate = useNavigate()
    const [outputPath, setOutputPath] = useState(null)
    const [s3, setS3] = useState(null)

    useEffect(() => {
        console.log('start interval');
        fetchData();
    }, []);

    useEffect(() => {

        const savedProgress = localStorage.getItem('progress');
        if (savedProgress) {
            setCompleted(parseFloat(savedProgress));
        }

        const duration = 720;
        const increment = 100 / duration;
        const interval = setInterval(() => {
            setCompleted(prev => {
                if (prev + increment >= 100) {
                    clearInterval(interval);
                    localStorage.removeItem('progress')
                    return 100;
                }
                const newProgress = prev + increment;
                localStorage.setItem('progress', newProgress);
                return newProgress;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    function sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }


    const fetchData = async () => {
        //console.log(videoid,'IDJDIDIDI')
        try {
            const Response = await CheckVideo({
                videoId: videoid
            });
            setS3(Response.is_s3)
            setOutputPath(Response.outputPath)
            setvideoCreated(Response.status);
            setScript(Response.script)
            if (Response.isfailed === 1) {
                setShowModal(true)
            } else {
                setShowModal(false)
            }
            // console.log(imageResponse.data)
            // console.log(videoCreated,'jdjdjddj')
            await checkVideoCreation();

            async function checkVideoCreation() {
                if (Response.status !== 8) {
                    await sleep(15000);
                    fetchData();
                } else {
                    return false;
                }
            }

        } catch (error) {
            console.error('Error fetching data', error);
            // setError(error.message);
        }
        // } finally {
        //     setLoading(false);
        // }
    };
    const handleDownload = async () => {
        try {
            console.log('downloadd check')
            const response = await axios.get(videoLink, {
                responseType: 'blob',
            });

            const blobUrl = window.URL.createObjectURL(new Blob([response.data]));

            const newWindow = window.open('');

            const link = newWindow.document.createElement('a');
            link.href = blobUrl;
            link.setAttribute('download', videoUrl.split('/').pop());
            newWindow.document.body.appendChild(link);

            link.click();

            newWindow.document.body.removeChild(link);
            newWindow.close();
        } catch (error) {
            console.error('Error downloading video', error);
        }
    };
    let videoLink;
    if(s3===1){
        videoLink=`https://shortsmedia.s3.us-east-2.amazonaws.com/AiCreator/${userid}/${videoid}/${outputPath}.mp4`
    }else{
        videoLink=`${server_App_URl}/media/AiCreator${videoUrl}`
    }

    return (
        <>
            <DashboardNavbar />
            <div className="flex md:h-max h-max">
            {!isModalOpen? (
                    <div className="absolute md:relative md:w-[23%] w-[21%] bg text-white">
                        <NavTab />
                    </div>
                ):(<div className="absolute md:relative md:w-[23%] w-[21%]  bg-white bg-opacity-50">
                  
              </div>)}
                <div className={`w-full p-[2.5rem] min-h-screen overflow-x-hidden font-montserrat h-max lg:h-full space-y-6 flex justify-center pt-12 pb-20 flex-col ${darkMode ? 'bg-black' : 'bg-gray-100'} text-[#6d28d9]`}>
                    {videoCreated !== 8 ? (
                        <>
                            <div className="App">
                                {completed < 100 && (
                                    <ProgressBar
                                        now={completed}
                                        label={`${Math.round(completed)}%`}
                                    />
                                )}
                            </div>
                        </>
                    ) : null}
                    <div className='video-center flex justify-evenly gap-3 items-start w-full'>
                        <div className={`margen-set md:p-12 p-[12px] h-max md:w-[80%] lg:p-6 mx-auto ${darkMode ? 'bg-black' : 'bg-gray-50'} rounded-md shadow-md space-y-4`}>
                            <div className='h-max w-full mb-5'>
                                <h1 className={`font-bold text-[16px] ${darkMode ? "text-white" : "text-blue-700"} pt-1 pb-2`}>Scripts</h1>
                                <div className='flex justify-start p-4 w-[100%] font-montserrat shadow-md border border-gray-100'>
                                    <p className={`mb-2 ${darkMode? 'text-white hover:text-gray-300':'text-gray-400 hover:text-gray-500'} cursor-context-menu`}>{script}</p>
                                </div>
                            </div>
                        </div>
                        <div className='video-h p-2 h-[450px] border-l'>
                            <h1 className={`${darkMode ? "text-white" : "text-blue-700"} mb-3`}>Output</h1>

                            <div className='down-btn h-[100%]'>



                                {videoCreated === 8 ? (

                                    <video
                                        className={!videoCreated ? 'd-none' : 'h-[400px] rounded-md'}
                                        autoPlay
                                        controls
                                        loop
                                        muted
                                        preload="auto"
                                        onCanPlayThrough={() => {
                                            console.log('video loaded');
                                            setLoading(false);
                                        }}
                                    >
                                        <source src={videoLink}/>
                                        {/* {outputPath && videoCreated=== 8?(<source src={`https://shortsmedia.s3.us-east-2.amazonaws.com/AiCreator/${userid}/${videoid}/${outputPath}.mp4`}/>):(<source src={`${server_App_URl}/media/AiCreator${videoUrl}`}/>)} */}
                                    </video>

                                ) : (<><p className='text-[#467ff7]'>Loading Video....</p><img className='' src={image1} alt="" /></>)}

                                {loading === false && (
                                    <button
                                        onClick={handleDownload}
                                        className='mb-5 px-4 py-2 cursor-pointer text-white bg-[#467ff7] rounded hover:bg-[#154abd]'
                                    >
                                        Download Video
                                    </button>
                                )}
                            </div>
                        </div>
                        {showModal ? (
                            <>
                                <div className="fixed inset-0 z-50 flex items-center justify-center overflow-hidden">
                                    <div className="relative w-full max-w-3xl bg-white rounded-lg shadow-lg">
                                        {/* Modal Content */}
                                        <div className="p-6">
                                            <h1 className="text-lg font-bold">Video Creation is failed Please Try Again</h1>
                                        </div>

                                        {/* Modal Footer */}
                                        <div className="flex items-center justify-end p-4">
                                            <button
                                                className="px-4 py-2 text-sm font-bold text-black transition-all duration-150 ease-linear bg-transparent rounded focus:outline-none"
                                                type="button"
                                                onClick={() => {
                                                    navigate('/app/view/aicreator');
                                                    setShowModal(false)
                                                }}
                                            >
                                                Okay
                                            </button>
                                        </div>
                                    </div>
                                </div>

                            </>
                        ) : null}


                    </div>
                </div>


            </div>
            <Footer style={{ width: '100vw;' }} />
        </>
    )
}

export default VideoPreview