import React, { useEffect, useState } from "react";
import { GiArtificialIntelligence } from "react-icons/gi";
import { FaUserCircle, FaLock } from "react-icons/fa";
import { RiErrorWarningLine } from "react-icons/ri";
import Navbar from "../navbar/Navbar";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../spinner/Spinner";
import { UpdatePassword } from "../api/Service";

const ResetPassword = () => {
    const [focusedField, setFocusedField] = useState(null);
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const [showModal, setShowModal] = useState(false); 
    const navigate = useNavigate()
    const handleCloseModal = () => {
        setShowModal(false);
        navigate('/app');  
    }
    const handleSubmit = async (e) => {
        setLoading(true)
        e.preventDefault();
        if (password !== confirmPassword) {
            setConfirmPasswordError('Passwords Do not Match');
        } else {
            setConfirmPasswordError('');
            try {
                let secretKey = "your_very_secure_long_key_here_12345678";

                // Get the full query string from the URL
                let queryString = window.location.search;

                // Use URLSearchParams to parse the query string
                let urlParams = new URLSearchParams(queryString);

                // Extract the value of the 'token' parameter
                let token = urlParams.get('token');
                // const decryptedData = decryptData(token, secretKey);
                //const [email, otp] = decryptedData.split(':');
                setLoading(true);
                let data = {
                    password: password,
                    token: token
                };
                console.log('enterr')
                const res = await UpdatePassword(data); 
                
                if (res.status === 200) {
                    setLoading(false);
                    setShowModal(true);  
                }
            } catch (err) {
                console.log(err);
                setLoading(false);
            }
        }
    }

    return (
        <>
            <div
                className="h-max-[100vh] h-min-[100vh] bg-[#532ff4]"
                style={{ minHeight: "100vh" }}
            >
                <div className="bg-[#532ff4]">
                    <div className="h-[70px]">
                        <Navbar />
                    </div>
                    <div className="flex justify-center items-center h-auto min-h-[calc(100vh-70px)] sm:h-[630px]">
                        <div className="w-full max-w-sm sm:w-[300px] md:w-[346px] md:py-3 px-4 sm:px-0">
                            <div className="w-full p-6 sm:p-8 md:p-7 space-y-4 bg-white shadow-lg rounded-lg">
                                {loading && (
                                    <div className="text-center py-4">
                                        <Loader />
                                    </div>
                                )}
                                <div className="flex justify-center text-[#467ff7]">
                                    <GiArtificialIntelligence className="h-6 w-6" />
                                </div>
                                <h1 className="text-2xl font-semibold pb-2 text-center font-montserrat text-blue-950">
                                    Please Enter Your New Password
                                </h1>
                                {passwordError && (
                                    <p className="text-sm mt-1 flex items-center justify-start gap-[8px] pl-3 rounded-sm bg-pink-100 h-10">
                                        <RiErrorWarningLine className="text-red-500 size-5" />
                                        {passwordError}
                                    </p>
                                )}
                                {confirmPasswordError && (
                                    <p className="text-sm mt-1 flex items-center justify-start gap-[8px] pl-3 rounded-sm bg-pink-100 h-10">
                                        <RiErrorWarningLine className="text-red-500 size-5" />
                                        {confirmPasswordError}
                                    </p>
                                )}
                                <form onSubmit={handleSubmit} className="space-y-6">
                                    <div className="relative">
                                        <label
                                            htmlFor="password"
                                            className={`absolute top-[-8px] left-3 text-[11px] bg-white px-1 z-10 ${focusedField === "password"
                                                ? "text-blue-500"
                                                : "text-gray-700"
                                                }`}
                                        >
                                            Password*
                                        </label>
                                        <div className="relative">
                                            <input
                                                type="password"
                                                id="password"
                                                name="password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                onFocus={() => setFocusedField("password")}
                                                onBlur={() => setFocusedField(null)}
                                                className={`w-full pl-10 h-12 border rounded-sm focus:outline-blue-700 ${focusedField === "password"
                                                    ? "border-blue-500"
                                                    : "border-gray-300"
                                                    }`}
                                            />
                                            <FaLock className="absolute top-1/2 left-3 transform -translate-y-1/2 w-5 h-5 text-gray-500" />
                                        </div>
                                    </div>
                                    <div className="relative">
                                        <label
                                            htmlFor="confirm-password"
                                            className={`absolute top-[-8px] left-3 text-[11px] font-sm bg-white px-1 z-10 ${focusedField === "confirm-password"
                                                    ? "text-blue-500"
                                                    : "text-gray-700"
                                                }`}
                                        >
                                            Confirm Password*
                                        </label>
                                        <div className="relative">
                                            <FaLock className="absolute top-1/2 left-3 transform -translate-y-1/2 w-5 h-5 text-gray-500 z-10" />
                                            <input
                                                type="password"
                                                id="confirm-password"
                                                name="confirm-password"
                                                value={confirmPassword}
                                                onChange={(e) => setConfirmPassword(e.target.value)}
                                                onFocus={() => setFocusedField("confirm-password")}
                                                onBlur={() => setFocusedField(null)}
                                                className={`w-full pl-10 h-12 border rounded-sm focus:outline-blue-700 ${focusedField === "confirm-password"
                                                        ? "border-blue-500 ring-blue-500"
                                                        : "border-gray-300"
                                                    }`}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <button
                                            type="submit"
                                            className="w-full text-[11px] py-2 px-4 bg-blue-500 text-white font-montserrat font-semibold rounded-sm shadow-sm hover:bg-blue-700 focus:outline focus:ring-2 focus:ring-blue-500"
                                        >
                                            Reset Password
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {showModal && (
                <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white rounded-lg shadow-lg p-6 max-w-sm w-full">
                        <h3 className="text-xl font-semibold mb-4">Password Updated!</h3>
                        <p>Your password has been updated successfully. You can now log in with your new password.</p>
                        <button
                            className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                            onClick={handleCloseModal}  
                        >
                            OK
                        </button>
                    </div>
                </div>
            )}
            </div>
        </>
    )
}

export default ResetPassword